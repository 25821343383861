import { gql } from '@apollo/client'

export const getUserListQuery = gql`
  query userList($cursor: ID!) {
    kitchenNode(cursor: $cursor) {
      userList(take: 99) {
        nodes {
          id
          role {
            name
          }
          user {
            _cursor
            id
            type
            firstName
            lastName
            email
            imageUrl
          }
        }
      }
    }
  }
`

export const getKitchenToUser = gql`
  query kitchenToUser($id: Int!) {
    kitchenToUser(id: $id) {
      id
      kitchen {
        name
      }
      role {
        id
        name
      }
      user {
        firstName
        lastName
        imageUrl
        email
        countryCode
        phoneNumberNational
      }
    }
    roles {
      id
      name
      description
    }
  }
`

export const getRoles = gql`
  query getRoles {
    roles {
      id
      name
      description
    }
  }
`

export const createKitchenInvite = gql`
  mutation createKitchenInvite($data: CreateKitchenInviteInput!) {
    createKitchenInvite(data: $data) {
      id
    }
  }
`

export const updateKitchenToUser = gql`
  mutation updateKitchenToUser($data: KitchenToUserUpdateInput!) {
    updateKitchenToUser(data: $data) {
      id
      roleId
    }
  }
`

export const deleteKitchenToUser = gql`
  mutation deleteKitchenToUser($data: KitchenToUserDeleteInput!) {
    deleteKitchenToUser(data: $data) {
      id
    }
  }
`
