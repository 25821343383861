import { useMutation } from '@apollo/client'
import { Box } from '@mui/material'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { omit } from 'ramda'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import {
  Mutation,
  Supplier,
  Kitchen,
  Ingredient,
  MutationUpdateOneIngredientArgs,
  ProductStatus,
} from 'api'
import { useReturnToPageContext } from 'app/contexts/ReturnToPage'
import {
  AlertWithIcon,
  NewTextField,
  NewButton,
  NewSelectUnit,
  Typography,
  NewLayout,
} from 'components/newUi'
import { Icon } from 'components/newUi/Icon'
import { SelectSupplier } from 'components/newUi/SelectSupplier'
import { useWindowSize } from 'hooks'
import { useNewStateStore } from 'mobx/StateStore/Meta'
import { updateIngredientMutation } from 'screens/Ingredient/graphql'
import { theme } from 'styles/newUi'
import { cleanErrorMessage, isEmptyOrNil } from 'utils'

import { useStyles } from './styles'

import { errorToast, successToast } from '../../../components/toasts'

export const Form = ({
  kitchen,
  supplier,
  ingredient,
}: {
  kitchen?: Kitchen
  supplier?: Supplier
  ingredient?: Ingredient
}) => {
  const { gte: isDesktop } = useWindowSize('md')

  const navigate = useNavigate()
  const classes = useStyles()
  const [showOptional, setShowOptional] = useState(false)
  const [conversionUnitLabel, setConversionUnitLabel] = useState('unit')
  const formContext = useNewStateStore()

  const navigationContext = useReturnToPageContext()

  const [updateIngredient, { loading }] = useMutation<
    { updateOneIngredient: Mutation['updateOneIngredient'] },
    MutationUpdateOneIngredientArgs
  >(updateIngredientMutation)

  const [showSelectSupplier, setShowSelectSupplier] = useState(true)

  const formik = useFormik({
    initialValues: ingredient
      ? {
          code: ingredient?.product?.code ?? '',
          conversionUnit: ingredient?.conversionUnit?.toString(),
          // conversionUnitType: ingredient?.conversionUnitType?.toString(),
          conversionUnitTypeId: ingredient?.conversionUnitType?.id,
          conversionUnitValue: ingredient?.conversionUnitValue?.toString(),
          name: ingredient?.product?.name,
          packSize: ingredient?.product?.packSize,
          price: ingredient?.price || 0,
          supplierId: ingredient?.product?.supplier.id,
          supplierName: ingredient?.product?.supplier.name,
          unit: ingredient?.product?.unit,
          unitId: ingredient?.product?.unit.id,
          unitName: ingredient?.product.unit.name,
          unitValue: ingredient?.product?.unitValue,
          wastage: ingredient?.wastage,
        }
      : {
          code: undefined,
          conversionUnit: undefined,
          conversionUnitTypeId: undefined,
          conversionUnitValue: undefined,
          name: '',
          packSize: 1,
          price: undefined,
          productId: undefined,
          supplierId: supplier ? supplier.id : undefined,
          supplierName: supplier ? supplier.name : undefined,
          unitId: undefined,
          unitPrice: 0,
          unitValue: undefined,
          wastage: 0,
        },
    isInitialValid: false,
    onSubmit: async (data) => {
      if (
        requiresUnitConversion &&
        (!data.conversionUnit ||
          !data.conversionUnitValue ||
          !data.conversionUnitTypeId)
      ) {
        formik.setFieldError('conversionUnit', 'Required')
        formik.setFieldError('conversionUnitValue', 'Required')
        formik.setFieldError('conversionUnitTypeId', 'Please select unit')

        return
      }

      if (
        (!isEmptyOrNil(data.conversionUnit) &&
          (isEmptyOrNil(data.conversionUnitTypeId) ||
            isEmptyOrNil(data.conversionUnitValue))) ||
        (!isEmptyOrNil(data.conversionUnitTypeId) &&
          (isEmptyOrNil(data.conversionUnit) ||
            isEmptyOrNil(data.conversionUnitValue))) ||
        (!isEmptyOrNil(data.conversionUnitValue) &&
          (isEmptyOrNil(data.conversionUnitTypeId) ||
            isEmptyOrNil(data.conversionUnit)))
      ) {
        const dirty =
          data.conversionUnit !== formik.initialValues.conversionUnit ||
          data.conversionUnitValue !==
            formik.initialValues.conversionUnitValue ||
          data.conversionUnitTypeId !==
            formik.initialValues.conversionUnitTypeId

        if (!data.conversionUnit && dirty) {
          formik.setFieldError('conversionUnit', 'Required')
        }

        if (!data.conversionUnitValue && dirty) {
          formik.setFieldError('conversionUnitValue', 'Required')
        }

        if (!data.conversionUnitTypeId && dirty) {
          formik.setFieldError('conversionUnitTypeId', 'Please select unit')
        }

        if (
          (!data.conversionUnit ||
            !data.conversionUnitValue ||
            !data.conversionUnitTypeId) &&
          dirty
        ) {
          return
        }
      }

      const { supplierName, supplierId, productId, ...rest } = data

      // @ts-ignore
      rest.price = parseFloat(rest.price)
      // @ts-ignore
      rest.unitValue = parseFloat(rest.unitValue)
      // @ts-ignore
      rest.wastage = parseFloat(rest.wastage)
      // @ts-ignore
      rest.conversionUnit = parseFloat(rest.conversionUnit)
      // @ts-ignore
      rest.conversionUnitValue = parseFloat(rest.conversionUnitValue)

      const hasUpdatedSupplierId =
        data.supplierId !== ingredient?.product.supplier.id

      const toSend = {
        data: {
          ...omit(['unit', 'unitName'], rest),
          id: ingredient?.id as number,
          unitId: data.unitId,
        },
        kitchenId: kitchen!.id,
        supplierData: showSelectSupplier ? undefined : { name: supplierName },
        supplierId:
          showSelectSupplier && hasUpdatedSupplierId ? supplierId : undefined,
      }

      // @ts-ignore
      await updateIngredient({ variables: toSend })
        .then(({ data }) => {
          if (!data) return

          const { product } = data.updateOneIngredient
          successToast(`Updated ${product?.name}`)

          navigate(-1)
        })
        .catch((error) => {
          errorToast(cleanErrorMessage(error.message))
        })
    },
    validationSchema: Yup.object({
      conversionUnit: Yup.number().nullable(),
      conversionUnitTypeId: Yup.number().nullable(),
      conversionUnitValue: Yup.number().nullable(),
      name: Yup.string().required('Required'),
      packSize: Yup.number()
        .required('Required')
        .min(0.000001, 'Must be greater than 0.000001'),
      price: Yup.number().required('Required'),
      productId: Yup.string().nullable(),
      supplierId: Yup.string().nullable(),
      unitId: Yup.number().required('Required'),
      unitValue: Yup.number()
        .required('Required')
        .min(0.000001, 'Must be greater than 0.000001'),
      wastage: Yup.number()
        .min(0, 'Must be greater than or equal to 0 and less than 100')
        .max(101, 'Must be greater than or equal to 0 and less than 100'),
    }),
  })

  const [requiresUnitConversion, setRequiresUnitConversion] = useState(false)
  const isFromCatalogue = ingredient?.product.status === ProductStatus.Available
  const initialUnit = ingredient?.product.unit

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <div className={classes.root}>
          <NewLayout
            title="Edit product"
            subtitle="Spending"
            rightContent={
              isDesktop ? (
                <div className={classes.buttonWrap}>
                  <NewButton
                    text="Cancel"
                    style={{
                      backgroundColor: 'white',
                      color: theme.palette.primary[100].toHexString(),
                    }}
                    disabled={loading}
                    loading={loading}
                    onClick={() => navigate(-1)}
                  />

                  <NewButton
                    text="Save Changes"
                    disabled={loading}
                    loading={loading}
                    onClick={() => formik.submitForm()}
                    data-testid="submitButton"
                  />
                </div>
              ) : undefined
            }
          />

          <div className={classes.innerWrap}>
            <Box>
              {isFromCatalogue && (
                <AlertWithIcon type="info" iconName="info">
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 400 }}
                  >{`As this is a catalogue product some details can't be changed.`}</Typography>
                </AlertWithIcon>
              )}

              <div
                style={{
                  alignItems: 'center',
                  backgroundColor: 'rgba(233, 234, 237, 1)',
                  display: 'flex',
                  height: 33,
                  marginBottom: 4,
                  paddingLeft: 18,
                }}
              >
                <Typography variant="button" className={classes.titleSlip}>
                  Product details
                </Typography>
              </div>

              <div
                style={{
                  borderBottomColor: theme.palette.primary[10].toHexString(),
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                  padding: theme.spacing(2),
                  paddingBottom: 4,
                }}
              >
                {!showSelectSupplier && (
                  <NewTextField
                    inputProps={{
                      'data-hj-allow': '',
                    }}
                    label="Supplier"
                    placeholder="Enter a Supplier Name..."
                    defaultValue={formik.values.supplierName}
                    disabled={!!supplier}
                    className={classes.supplierName}
                    onChange={(value) =>
                      formik.setFieldValue('supplierName', value)
                    }
                    error={
                      !!(
                        formik.touched.supplierName &&
                        formik.errors.supplierName
                      )
                    }
                  />
                )}

                {showSelectSupplier && kitchen && (
                  <SelectSupplier
                    kitchenId={kitchen.id}
                    className={classes.supplierName}
                    label="Supplier"
                    style={{
                      margin: 0,
                      marginBottom: 9,
                      marginTop: 9,
                      maxWidth: 'calc(100vw - 24px)',
                      padding: 0,
                    }}
                    placeholder=""
                    disabled={isFromCatalogue}
                    defaultValue={formik.values.supplierId}
                    onChange={(value) => {
                      if (value === 'NEW') {
                        setShowSelectSupplier(false)
                        formik.setFieldValue('supplierName', '')
                        return
                      }

                      formik.setFieldValue('supplierId', value)
                    }}
                    error={
                      !!(formik.touched.supplierId && formik.errors.supplierId)
                    }
                    required={showSelectSupplier}
                  />
                )}
              </div>
              <div className={classes.boxWithPointer}>
                <NewTextField
                  inputProps={{
                    'data-hj-allow': '',
                    'data-testid': 'ingredientNameField',
                  }}
                  disabled={isFromCatalogue}
                  label="Product Name"
                  placeholder="Enter a Product Name..."
                  style={{ paddingTop: 0, width: '100%' }}
                  required
                  defaultValue={formik.values.name}
                  onChange={(value) => formik.setFieldValue('name', value)}
                  error={!!(formik.touched.name && formik.errors.name)}
                />
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                    paddingRight: 8,
                    width: '100%',
                  }}
                >
                  <div style={{ flex: 7 }} className={classes.packAmountWrap}>
                    <NewTextField
                      min="0.000001"
                      inputProps={{
                        'data-hj-allow': '',
                        step: '0.000001',
                      }}
                      disabled={isFromCatalogue}
                      style={{ flex: 3, paddingBottom: 3 }}
                      label="Pack Size"
                      type="number"
                      hideNumberControls
                      required
                      defaultValue={formik.values.packSize}
                      onChange={(value) =>
                        formik.setFieldValue('packSize', value)
                      }
                      error={
                        !!(formik.touched.packSize && formik.errors.packSize)
                      }
                    />
                    <Icon iconName="clear" className={classes.cross} />
                    <NewTextField
                      min="0.000001"
                      inputProps={{
                        'data-hj-allow': '',
                        step: '0.000001',
                      }}
                      disabled={isFromCatalogue}
                      style={{ flex: 3, paddingBottom: 3 }}
                      label="Unit Value"
                      type="number"
                      hideNumberControls
                      required
                      defaultValue={formik.values.unitValue}
                      onChange={(value) =>
                        formik.setFieldValue('unitValue', value)
                      }
                      error={
                        !!(formik.touched.unitValue && formik.errors.unitValue)
                      }
                    />
                  </div>

                  <NewSelectUnit
                    label="Unit"
                    style={{ flex: 3, marginBottom: 9 }}
                    placeholder=""
                    disabled={isFromCatalogue}
                    defaultValue={formik.values.unitId}
                    showPortion
                    onChange={(value, label, data) => {
                      formik.setFieldValue('unitId', value)

                      setConversionUnitLabel(
                        data.type === 'custom' ? label : 'unit',
                      )

                      if (
                        (data.type === 'custom' &&
                          initialUnit?.type !== 'custom') ||
                        (data.type !== 'custom' &&
                          initialUnit?.type === 'custom')
                      ) {
                        setRequiresUnitConversion(true)

                        document.getElementById('amountInputField')?.focus()
                      } else {
                        setRequiresUnitConversion(false)
                      }
                    }}
                    error={!!(formik.touched.unitId && formik.errors.unitId)}
                    required
                  />
                </div>
              </div>

              {!requiresUnitConversion && (
                <Link
                  data-testid="advancedButton"
                  className={classes.link}
                  onClick={(e) => {
                    e.preventDefault()
                    setShowOptional(!showOptional)
                  }}
                  to=""
                  style={{
                    backgroundColor: 'rgba(233, 234, 237, 1)',
                    display: 'flex',
                    height: 40,
                    justifyContent: 'space-between',
                    marginTop: -18,
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                  }}
                >
                  <Typography variant="button" className={classes.titleSlip}>
                    Advanced options
                  </Typography>
                  <Icon
                    style={{ color: 'rgba(102, 102, 102, 1)' }}
                    iconName={showOptional ? 'chevronUp' : 'chevronDown'}
                  />
                </Link>
              )}

              <div
                className={clsx([
                  classes.hideableFields,
                  (showOptional || requiresUnitConversion) && classes.show,
                ])}
                style={{
                  paddingLeft: theme.spacing(2),
                  paddingRight: theme.spacing(2),
                }}
              >
                <NewTextField
                  inputProps={{
                    'data-hj-allow': '',
                  }}
                  disabled={!!isFromCatalogue}
                  label="Product code (Optional)"
                  style={{ width: '100%' }}
                  hideNumberControls
                  defaultValue={formik.values.code}
                  className={classes.productCode}
                  onChange={(value) => formik.setFieldValue('code', value)}
                  error={!!(formik.touched.code && formik.errors.code)}
                />
                <NewTextField
                  inputProps={{
                    'data-hj-allow': '',
                  }}
                  min="0"
                  label="Waste % (Optional)"
                  style={{ width: '100%' }}
                  placeholder="Enter percentage of waste..."
                  type="number"
                  hideNumberControls
                  endAdornment="%"
                  defaultValue={formik.values.wastage}
                  onChange={(value) => formik.setFieldValue('wastage', value)}
                  error={!!(formik.touched.wastage && formik.errors.wastage)}
                />
                {formik.errors.wastage && (
                  <Typography variant="body2" className={classes.errorMessage}>
                    {formik.errors.wastage}
                  </Typography>
                )}
                <div
                  style={{
                    borderTopColor: theme.palette.primary[10].toHexString(),
                    borderTopStyle: 'solid',
                    borderTopWidth: 1,
                    marginTop: theme.spacing(2),
                    paddingTop: theme.spacing(2),
                  }}
                >
                  <Typography
                    className={classes.unitConversionTitle}
                    variant="body1"
                  >
                    Unit Conversion
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.unitConversionDescription}
                  >
                    To get accurate costings, please specify how many grams per
                    unit, if known.
                  </Typography>

                  <Box className={classes.packAmountWrap} gap={2}>
                    <NewTextField
                      inputProps={{
                        'data-hj-allow': '',
                        id: 'amountInputField',
                        step: '0.01',
                      }}
                      min="0"
                      style={{ flex: 3 }}
                      label="Amount"
                      type="number"
                      endAdornment={conversionUnitLabel}
                      required={requiresUnitConversion}
                      hideNumberControls
                      defaultValue={formik.values.conversionUnit}
                      onChange={(value) =>
                        formik.setFieldValue('conversionUnit', value)
                      }
                      error={
                        !!(
                          formik.touched.conversionUnit &&
                          formik.errors.conversionUnit
                        )
                      }
                    />

                    <div className={classes.equals}>=</div>

                    <NewTextField
                      inputProps={{
                        'data-hj-allow': '',
                        step: '0.000001',
                      }}
                      min="0"
                      style={{ flex: 3 }}
                      label="Value"
                      type="number"
                      hideNumberControls
                      required={requiresUnitConversion}
                      defaultValue={formik.values.conversionUnitValue}
                      onChange={(value) =>
                        formik.setFieldValue('conversionUnitValue', value)
                      }
                      error={
                        !!(
                          formik.touched.conversionUnitValue &&
                          formik.errors.conversionUnitValue
                        )
                      }
                    />

                    <NewSelectUnit
                      hideCustom
                      label="Unit"
                      style={{ flex: 3, margin: 0, padding: '10px 0' }}
                      placeholder=""
                      defaultValue={formik.values.conversionUnitTypeId}
                      onChange={(value) =>
                        formik.setFieldValue('conversionUnitTypeId', value)
                      }
                      className={classes.selectUnit}
                      error={
                        !!(
                          formik.touched.conversionUnitTypeId &&
                          formik.errors.conversionUnitTypeId
                        )
                      }
                    />
                  </Box>
                </div>
              </div>
            </Box>
          </div>
        </div>
        <div>
          <div
            style={{
              alignItems: 'center',
              backgroundColor: theme.palette.secondary[50].toHexString(),
              display: 'flex',
              justifyContent: 'space-evenly',
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            }}
            className={classes.mobileButtonWrap}
          >
            <div>
              <Typography variant="subtitle2">Pack price</Typography>
              <Typography variant="body2" style={{ paddingRight: 6 }}>
                Approx. how much you last paid for this
              </Typography>
            </div>

            <div
              style={{
                alignItems: 'flex-end',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <NewTextField
                min="0"
                inputProps={{
                  'data-hj-allow': '',
                  step: '0.01',
                  style: {
                    width: '110px',
                  },
                }}
                startAdornment="£"
                type="number"
                hideNumberControls
                required
                defaultValue={formik.values.price}
                onChange={(value) => formik.setFieldValue('price', value)}
                error={!!(formik.touched.price && formik.errors.price)}
                focus={!!ingredient}
              />
            </div>
          </div>

          {!isDesktop && (
            <div className="flex justify-center">
              <div className="flex space-x-2">
                <NewButton
                  text="Cancel"
                  style={{
                    backgroundColor: 'white',
                    color: theme.palette.primary[100].toHexString(),
                  }}
                  disabled={loading}
                  loading={loading}
                  type="button"
                  onClick={() => navigate(-1)}
                />

                <NewButton
                  text="Save Changes"
                  disabled={loading}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}
