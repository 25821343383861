import { gql } from '@apollo/client'

export const getMenusQuery = gql`
  query getMenusQuery(
    $cursor: MenuWhereUniqueInput
    $orderBy: [MenuOrderByInput!]
    $skip: Int
    $take: Int
    $where: MenuWhereInput
  ) {
    menus(
      cursor: $cursor
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      _cursor
      id
      name
      gpTarget
      dishes {
        _cursor
        id
        menu {
          _cursor
          id
        }
        dish {
          _cursor
          id
          imageUrl
          name
          price
          gpTarget
          type
          portion
          overheads
          vat
          recipe {
            _cursor
            id
            unitCost
          }
        }
      }
    }
  }
`
export const getMenus = gql`
  query getMenus(
    $cursor: ID
    $orderBy: [MenuOrderByInput!]
    $skip: Int
    $take: Int
    $where: MenuWhereInput
  ) {
    menuList(
      cursor: $cursor
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
        skip
        take
        currentPage
        totalCount
        totalPages
      }
      nodes {
        _cursor
        id
        name
        gpTarget
        updatedAt
        status
        dishCount
        dishAverageGp
      }
    }
  }
`

export const getMenuByIdQuery = gql`
  query getMenuByIdQuery($where: MenuWhereUniqueInput!) {
    dishTypes {
      name
    }
    allergens(orderBy: [{ type: asc }]) {
      _cursor
      id
      imageUrl
      type
    }
    menu(where: $where) {
      _cursor
      id
      name
      gpTarget
      kitchen {
        id
        _cursor
        name
      }
      dishList(skip: 0, take: 999) {
        nodes {
          _cursor
          id
          dish {
            _cursor
            id
            imageUrl
            name
            price
            gpTarget
            type
            overheads
            vat
            portion
            recipe {
              _cursor
              id
              hasNoAllergens
              allergenList(skip: 0, take: 100) {
                nodes {
                  _cursor
                  id
                  contains
                  removable
                  allergen {
                    _cursor
                    id
                    imageUrl
                    type
                  }
                }
              }
              unitCost
            }
          }
        }
      }
    }
  }
`
