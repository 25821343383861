import { Button, Typography } from '@getjelly/jelly-ui'
import { IconChevronRight } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { CSSProperties } from 'react'
import { useNavigate } from 'react-router-dom'

import { Invoice, InvoiceNote, useApproveOneInvoiceMutation } from 'api'
import { routes } from 'routes/Paths'
import { InvoiceRowItemContainer } from 'screens/Invoices/InvoiceRowItemContainer'
import { ALERT_GREEN } from 'styles/colors'

import { useKitchen } from '../../../../../app/contexts/SelectedKitchen'
import { errorToast } from '../../../../../components/toasts'
import { cleanErrorMessage } from '../../../../../utils'

interface Props {
  invoice: Invoice
  notes: InvoiceNote[]
  pendingInvoiceId: number
  style: CSSProperties
}

export function InvoiceRowItem({
  invoice,
  notes,
  pendingInvoiceId,
  style,
}: Props) {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()

  const { id } = invoice
  const [approveInvoice] = useApproveOneInvoiceMutation({
    refetchQueries: ['pendingInvoices'],
  })

  const isNew = dayjs(invoice.createdAt).isAfter(dayjs().subtract(24, 'hours'))

  function getSubtitle() {
    if (['rejected', 'merged'].includes(invoice.pendingInvoice?.status || '')) {
      return ''
    }

    if (invoice.approved) {
      return ''
    }

    return `£${(invoice.invoiceTotal ?? 0).toFixed(2)} (incl. VAT)`
  }

  return (
    <InvoiceRowItemContainer
      style={style}
      automatic={invoice.isAuto}
      primaryText={invoice.supplier.name.toLowerCase()}
      subtitle={getSubtitle()}
      onClick={() => {
        navigate(
          `${routes.Spending}${routes.Invoice}${routes.History}/${invoice.id}`,
        )
      }}
      notes={invoice.pendingInvoice?.notes ?? notes}
      pendingInvoiceId={pendingInvoiceId}
      invoiceNumber={invoice.invoiceId ?? undefined}
      secondaryText={
        <>
          Invoice # {invoice.invoiceId}
          {isNew && (
            <span
              style={{
                color: ALERT_GREEN,
                display: 'inline-block',
                fontWeight: 600,
                marginLeft: 8,
                paddingRight: 5,
              }}
            >
              NEW
            </span>
          )}
        </>
      }
      action={
        <div className="flex space-x-1 items-center">
          {invoice.pendingInvoice?.status === 'rejected' && (
            <Typography style="subtitle1">skipped</Typography>
          )}

          {invoice.pendingInvoice?.status === 'merged' && (
            <Typography style="subtitle1">merged</Typography>
          )}

          {!['rejected', 'merged'].includes(
            invoice.pendingInvoice?.status || '',
          ) &&
            (invoice.approved ? (
              invoice.invoiceTotal && (
                <div className="text-right">
                  <Typography style="subtitle1" className="text-primary-900">
                    £{invoice.invoiceTotal.toFixed(2)}
                  </Typography>

                  <Typography style="body2" className="text-primary-400">
                    (inc. VAT)
                  </Typography>
                </div>
              )
            ) : (
              <>
                {selectedKitchen?.userPermissions.includes(
                  'approve-invoice',
                ) && (
                  <Button
                    style="secondary"
                    onClick={async (event) => {
                      event.stopPropagation()
                      try {
                        await approveInvoice({
                          update(cache) {
                            cache.modify({
                              fields: {
                                invoiceOverview(existingListRefs = {}) {
                                  return {
                                    ...existingListRefs,
                                    all: existingListRefs.all + 1,
                                    approved: existingListRefs.approved + 1,
                                    needsAttention:
                                      existingListRefs.needsAttention - 1,
                                  }
                                },
                                // needsAttentionInvoiceList(
                                //   existingListRefs = {},
                                //   { readField },
                                // ) {
                                //   if (!existingListRefs.nodes) {
                                //     return existingListRefs
                                //   }
                                //
                                //   return {
                                //     ...existingListRefs,
                                //     nodes: existingListRefs.nodes.filter(
                                //       (nodeRef: any) =>
                                //         readField('id', nodeRef) !==
                                //         pendingInvoiceId,
                                //     ),
                                //   }
                                // },
                              },
                            })
                          },
                          variables: {
                            approved: true,
                            id,
                          },
                        })
                      } catch (error) {
                        errorToast(cleanErrorMessage((error as Error).message))
                      }
                    }}
                    label="Approve"
                  />
                )}
              </>
            ))}

          <IconChevronRight className="text-primary-400" />
        </div>
      }
    />
  )
}
