import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Bytes: any;
  Date: any;
  DateTime: any;
  Decimal: any;
  Json: any;
  Time: any;
  Upload: any;
};

export type Account = Node & {
  __typename?: 'Account';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner?: Maybe<User>;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type Address = Node & {
  __typename?: 'Address';
  _cursor: Scalars['ID'];
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  deliveryInstructions?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line3?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type AddressConnection = {
  __typename?: 'AddressConnection';
  edges: Array<AddressEdge>;
  nodes: Array<Address>;
  pageInfo: PageInfo;
};

export type AddressCreateNestedOneWithoutKitchenToAddressInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutKitchenToAddressInput>;
  create?: InputMaybe<AddressCreateWithoutKitchenToAddressInput>;
};

export type AddressCreateOrConnectWithoutKitchenToAddressInput = {
  create: AddressCreateWithoutKitchenToAddressInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateWithoutKitchenToAddressInput = {
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deliveryInstructions?: InputMaybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  line3?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  postCode: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AddressEdge = {
  __typename?: 'AddressEdge';
  cursor: Scalars['ID'];
  node: Address;
};

export type AddressOrderByInput = {
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  kitchenToAddress?: InputMaybe<KitchenToAddressOrderByRelationAggregateInput>;
  line1?: InputMaybe<SortOrder>;
  line2?: InputMaybe<SortOrder>;
  line3?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  postCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AddressUpdateOneRequiredWithoutKitchenToAddressInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutKitchenToAddressInput>;
  create?: InputMaybe<AddressCreateWithoutKitchenToAddressInput>;
  update?: InputMaybe<AddressUpdateWithoutKitchenToAddressInput>;
  upsert?: InputMaybe<AddressUpsertWithoutKitchenToAddressInput>;
};

export type AddressUpdateWithoutKitchenToAddressInput = {
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryInstructions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  line1?: InputMaybe<StringFieldUpdateOperationsInput>;
  line2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  line3?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type AddressUpsertWithoutKitchenToAddressInput = {
  create: AddressCreateWithoutKitchenToAddressInput;
  update: AddressUpdateWithoutKitchenToAddressInput;
};

export type AddressWhereInput = {
  AND?: InputMaybe<Array<AddressWhereInput>>;
  KitchenToAddress?: InputMaybe<KitchenToAddressListRelationFilter>;
  NOT?: InputMaybe<Array<AddressWhereInput>>;
  OR?: InputMaybe<Array<AddressWhereInput>>;
  city?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  line1?: InputMaybe<StringFilter>;
  line2?: InputMaybe<StringNullableFilter>;
  line3?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  postCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type AddressWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type AdminCreateOneKitchenInputObject = {
  accountId?: InputMaybe<Scalars['Int']>;
  accountName?: InputMaybe<Scalars['String']>;
  kitchenSlug: Scalars['String'];
  name: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type AdminUpdateOneUserInputObject = {
  countryCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hubspotKitchenToSyncId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type Allergen = Node & {
  __typename?: 'Allergen';
  _cursor: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  recipeList: RecipeToAllergenConnection;
  /** @deprecated Use and extend recipeList field */
  recipes: Array<RecipeToAllergen>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type AllergenRecipeListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type AllergenRecipesArgs = {
  cursor?: InputMaybe<RecipeToAllergenWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RecipeToAllergenOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RecipeToAllergenWhereInput>;
};

export type AllergenConnection = {
  __typename?: 'AllergenConnection';
  edges: Array<AllergenEdge>;
  nodes: Array<Allergen>;
  pageInfo: PageInfo;
};

export type AllergenCreateNestedOneWithoutRecipesInput = {
  connect?: InputMaybe<AllergenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AllergenCreateOrConnectWithoutRecipesInput>;
  create?: InputMaybe<AllergenCreateWithoutRecipesInput>;
};

export type AllergenCreateOrConnectWithoutRecipesInput = {
  create: AllergenCreateWithoutRecipesInput;
  where: AllergenWhereUniqueInput;
};

export type AllergenCreateWithoutRecipesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AllergenEdge = {
  __typename?: 'AllergenEdge';
  cursor: Scalars['ID'];
  node: Allergen;
};

export type AllergenOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  recipes?: InputMaybe<RecipeToAllergenOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AllergenUpdateOneRequiredWithoutRecipesInput = {
  connect?: InputMaybe<AllergenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AllergenCreateOrConnectWithoutRecipesInput>;
  create?: InputMaybe<AllergenCreateWithoutRecipesInput>;
  update?: InputMaybe<AllergenUpdateWithoutRecipesInput>;
  upsert?: InputMaybe<AllergenUpsertWithoutRecipesInput>;
};

export type AllergenUpdateWithoutRecipesInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type AllergenUpsertWithoutRecipesInput = {
  create: AllergenCreateWithoutRecipesInput;
  update: AllergenUpdateWithoutRecipesInput;
};

export type AllergenWhereInput = {
  AND?: InputMaybe<Array<AllergenWhereInput>>;
  NOT?: InputMaybe<Array<AllergenWhereInput>>;
  OR?: InputMaybe<Array<AllergenWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  recipes?: InputMaybe<RecipeToAllergenListRelationFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type AllergenWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type ApproveOneInvoiceInputObject = {
  approved: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  accessToken: Scalars['String'];
  idToken: Scalars['String'];
  joinError?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  /** @deprecated Use accessToken. */
  token: Scalars['String'];
};

export type AvailablePlan = {
  __typename?: 'AvailablePlan';
  description: Scalars['String'];
  features?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  payment_link?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  tier: Scalars['String'];
};

export type Batch = Node & {
  __typename?: 'Batch';
  _cursor: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
};

export type BatchCreateManyCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type BatchCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<BatchCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BatchCreateManyUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BatchCreateManyUpdatedByInputEnvelope = {
  data?: InputMaybe<Array<BatchCreateManyUpdatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BatchCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<BatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BatchCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<BatchCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<BatchCreateManyCreatedByInputEnvelope>;
};

export type BatchCreateNestedManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<BatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BatchCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<BatchCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<BatchCreateManyUpdatedByInputEnvelope>;
};

export type BatchCreateNestedOneWithoutProductDuplicatesInput = {
  connect?: InputMaybe<BatchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BatchCreateOrConnectWithoutProductDuplicatesInput>;
  create?: InputMaybe<BatchCreateWithoutProductDuplicatesInput>;
};

export type BatchCreateNestedOneWithoutProductMergesInput = {
  connect?: InputMaybe<BatchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BatchCreateOrConnectWithoutProductMergesInput>;
  create?: InputMaybe<BatchCreateWithoutProductMergesInput>;
};

export type BatchCreateOrConnectWithoutCreatedByInput = {
  create: BatchCreateWithoutCreatedByInput;
  where: BatchWhereUniqueInput;
};

export type BatchCreateOrConnectWithoutProductDuplicatesInput = {
  create: BatchCreateWithoutProductDuplicatesInput;
  where: BatchWhereUniqueInput;
};

export type BatchCreateOrConnectWithoutProductMergesInput = {
  create: BatchCreateWithoutProductMergesInput;
  where: BatchWhereUniqueInput;
};

export type BatchCreateOrConnectWithoutUpdatedByInput = {
  create: BatchCreateWithoutUpdatedByInput;
  where: BatchWhereUniqueInput;
};

export type BatchCreateWithoutCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  productDuplicates?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutBatchInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutBatchInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutBatchUpdatedInput;
};

export type BatchCreateWithoutProductDuplicatesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutBatchCreatedInput;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutBatchInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutBatchUpdatedInput;
};

export type BatchCreateWithoutProductMergesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutBatchCreatedInput;
  productDuplicates?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutBatchInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutBatchUpdatedInput;
};

export type BatchCreateWithoutUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutBatchCreatedInput;
  productDuplicates?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutBatchInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutBatchInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BatchListRelationFilter = {
  every?: InputMaybe<BatchWhereInput>;
  none?: InputMaybe<BatchWhereInput>;
  some?: InputMaybe<BatchWhereInput>;
};

export type BatchOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productDuplicates?: InputMaybe<ProductDuplicatesOrderByRelationAggregateInput>;
  productMerges?: InputMaybe<ProductMergeOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
};

export type BatchOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type BatchScalarWhereInput = {
  AND?: InputMaybe<Array<BatchScalarWhereInput>>;
  NOT?: InputMaybe<Array<BatchScalarWhereInput>>;
  OR?: InputMaybe<Array<BatchScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedById?: InputMaybe<IntFilter>;
};

export type BatchUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BatchUpdateManyWithWhereWithoutCreatedByInput = {
  data: BatchUpdateManyMutationInput;
  where: BatchScalarWhereInput;
};

export type BatchUpdateManyWithWhereWithoutUpdatedByInput = {
  data: BatchUpdateManyMutationInput;
  where: BatchScalarWhereInput;
};

export type BatchUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<BatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BatchCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<BatchCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<BatchCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<BatchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BatchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BatchWhereUniqueInput>>;
  set?: InputMaybe<Array<BatchWhereUniqueInput>>;
  update?: InputMaybe<Array<BatchUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<BatchUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<BatchUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type BatchUpdateManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<BatchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BatchCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<BatchCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<BatchCreateManyUpdatedByInputEnvelope>;
  delete?: InputMaybe<Array<BatchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BatchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BatchWhereUniqueInput>>;
  set?: InputMaybe<Array<BatchWhereUniqueInput>>;
  update?: InputMaybe<Array<BatchUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: InputMaybe<Array<BatchUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: InputMaybe<Array<BatchUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type BatchUpdateOneRequiredWithoutProductDuplicatesInput = {
  connect?: InputMaybe<BatchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BatchCreateOrConnectWithoutProductDuplicatesInput>;
  create?: InputMaybe<BatchCreateWithoutProductDuplicatesInput>;
  update?: InputMaybe<BatchUpdateWithoutProductDuplicatesInput>;
  upsert?: InputMaybe<BatchUpsertWithoutProductDuplicatesInput>;
};

export type BatchUpdateOneWithoutProductMergesInput = {
  connect?: InputMaybe<BatchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BatchCreateOrConnectWithoutProductMergesInput>;
  create?: InputMaybe<BatchCreateWithoutProductMergesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BatchUpdateWithoutProductMergesInput>;
  upsert?: InputMaybe<BatchUpsertWithoutProductMergesInput>;
};

export type BatchUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: BatchUpdateWithoutCreatedByInput;
  where: BatchWhereUniqueInput;
};

export type BatchUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: BatchUpdateWithoutUpdatedByInput;
  where: BatchWhereUniqueInput;
};

export type BatchUpdateWithoutCreatedByInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  productDuplicates?: InputMaybe<ProductDuplicatesUpdateManyWithoutBatchInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutBatchInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutBatchUpdatedInput>;
};

export type BatchUpdateWithoutProductDuplicatesInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutBatchCreatedInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutBatchInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutBatchUpdatedInput>;
};

export type BatchUpdateWithoutProductMergesInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutBatchCreatedInput>;
  productDuplicates?: InputMaybe<ProductDuplicatesUpdateManyWithoutBatchInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutBatchUpdatedInput>;
};

export type BatchUpdateWithoutUpdatedByInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutBatchCreatedInput>;
  productDuplicates?: InputMaybe<ProductDuplicatesUpdateManyWithoutBatchInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutBatchInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BatchUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: BatchCreateWithoutCreatedByInput;
  update: BatchUpdateWithoutCreatedByInput;
  where: BatchWhereUniqueInput;
};

export type BatchUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: BatchCreateWithoutUpdatedByInput;
  update: BatchUpdateWithoutUpdatedByInput;
  where: BatchWhereUniqueInput;
};

export type BatchUpsertWithoutProductDuplicatesInput = {
  create: BatchCreateWithoutProductDuplicatesInput;
  update: BatchUpdateWithoutProductDuplicatesInput;
};

export type BatchUpsertWithoutProductMergesInput = {
  create: BatchCreateWithoutProductMergesInput;
  update: BatchUpdateWithoutProductMergesInput;
};

export type BatchWhereInput = {
  AND?: InputMaybe<Array<BatchWhereInput>>;
  NOT?: InputMaybe<Array<BatchWhereInput>>;
  OR?: InputMaybe<Array<BatchWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  productDuplicates?: InputMaybe<ProductDuplicatesListRelationFilter>;
  productMerges?: InputMaybe<ProductMergeListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type BatchWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BulkUploadResponseType = {
  __typename?: 'BulkUploadResponseType';
  createdCount: Scalars['Int'];
  errorCount?: Maybe<Scalars['Int']>;
  errors?: Maybe<Scalars['String']>;
  totalCount: Scalars['Int'];
  updatedCount: Scalars['Int'];
};

export type CatalogueUploadResponseType = {
  __typename?: 'CatalogueUploadResponseType';
  createdCount: Scalars['Int'];
  errorCount?: Maybe<Scalars['Int']>;
  errors?: Maybe<Scalars['String']>;
  totalCount: Scalars['Int'];
  updatedCount: Scalars['Int'];
};

export type ChangeHistory = Node & {
  __typename?: 'ChangeHistory';
  _cursor: Scalars['ID'];
  change: Scalars['String'];
  date: Scalars['Date'];
  id: Scalars['Int'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String'];
  label: Scalars['String'];
  phone: Scalars['String'];
  suggested?: Maybe<Scalars['Boolean']>;
};

export type CreateFlashPeriodInput = {
  closeAdjustments?: InputMaybe<Scalars['Float']>;
  closeStockIds: Array<Scalars['Int']>;
  kitchenId: Scalars['Int'];
  openAdjustments?: InputMaybe<Scalars['Float']>;
  openStockIds: Array<Scalars['Int']>;
  period: PeriodEnum;
  startDate: Scalars['DateTime'];
};

export type CreateKitchenInviteInput = {
  countryCode: Scalars['String'];
  kitchenId: Scalars['Int'];
  phoneNumberNational: Scalars['String'];
  roleId: Scalars['Int'];
};

export type CreateKitchenInvoiceRuleInput = {
  approvalThreshold?: InputMaybe<Scalars['Float']>;
  approveAllInvoices: Scalars['Boolean'];
  kitchenId: Scalars['Int'];
};

export type CreateKitchenTodoInput = {
  assigneeId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  kitchenId: Scalars['Int'];
  title: Scalars['String'];
};

export type CreateOneDishInputObject = {
  gpTarget?: InputMaybe<Scalars['Float']>;
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  overheads?: InputMaybe<Scalars['Float']>;
  portion?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  type: Scalars['String'];
  unitCost?: InputMaybe<Scalars['Float']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type CreateOneDishesSoldObject = {
  dishId: Scalars['Int'];
  period: PeriodEnum;
  quantity?: InputMaybe<Scalars['Int']>;
  soldAt: Scalars['DateTime'];
};

export type CreateOneFoodFlashInputObject = {
  complimentary?: InputMaybe<Scalars['Float']>;
  endDate: Scalars['DateTime'];
  expenses?: InputMaybe<Scalars['Float']>;
  kitchenId: Scalars['Int'];
  name: Scalars['String'];
  sales: Scalars['Float'];
  startDate: Scalars['DateTime'];
  stockIn: Array<InputMaybe<Scalars['Int']>>;
  stockOut: Array<InputMaybe<Scalars['Int']>>;
  wastage?: InputMaybe<Scalars['Float']>;
};

export type CreateOneIngredientInputObject = {
  code?: InputMaybe<Scalars['String']>;
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitTypeId?: InputMaybe<Scalars['Int']>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price: Scalars['Float'];
  unitId: Scalars['Int'];
  unitPrice: Scalars['Float'];
  unitValue: Scalars['Float'];
  wastage: Scalars['Int'];
};

export type CreateOneKitchenAddressInputObject = {
  city: Scalars['String'];
  deliveryInstructions?: InputMaybe<Scalars['String']>;
  kitchenId: Scalars['Int'];
  line1: Scalars['String'];
  mainAddress?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  postCode: Scalars['String'];
};

export type CreateOneKitchenInputObject = {
  name: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type CreateOneMenuInputObject = {
  gpTarget: Scalars['Float'];
  name: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type CreateOneProductInputObject = {
  code?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<ProductStatus>;
  unitId: Scalars['Int'];
  unitValue: Scalars['Float'];
};

export type CreateOneRecipeInputObject = {
  amount?: InputMaybe<Scalars['Float']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  section?: InputMaybe<Scalars['String']>;
  unitCost: Scalars['Float'];
  unitId?: InputMaybe<Scalars['Int']>;
};

export type CreateOneStockEntryInputObject = {
  ingredientId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
  recipeId?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Float']>;
  unitId: Scalars['Int'];
};

export type CreateOneStockInputObject = {
  date: Scalars['DateTime'];
  entries: Array<CreateOneStockEntryInputObject>;
  kitchenId: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  total: Scalars['Float'];
};

export type CreateOneSupplierInputObject = {
  name: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type CreateOneUnitInputType = {
  name: Scalars['String'];
  type: UnitType;
};

export type CreateOrUpdateOneSalesInputObject = {
  id: Scalars['Int'];
  kitchenId: Scalars['Int'];
  sales?: InputMaybe<Scalars['Float']>;
  salesDate: Scalars['DateTime'];
};

export type CreatePosAccountSquareInput = {
  code: Scalars['String'];
  kitchenId: Scalars['Int'];
  state: Scalars['String'];
};

export type CreateTodoNoteInput = {
  content: Scalars['String'];
  todoId: Scalars['Int'];
};

export type DailyUpdatesKitchenInput = {
  activated?: InputMaybe<Scalars['Boolean']>;
  biggestDrop?: InputMaybe<IngredientChangeInput>;
  biggestRise?: InputMaybe<IngredientChangeInput>;
  bothTotalSendAndWeekSoFarZero?: InputMaybe<Scalars['Boolean']>;
  changesCount?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['String']>;
  firstShot?: InputMaybe<Scalars['Boolean']>;
  fromLastWeek?: InputMaybe<Scalars['Int']>;
  hideComparisons?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  inPeriod?: InputMaybe<Scalars['Int']>;
  invoiceCount?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  newIngredients?: InputMaybe<Scalars['Boolean']>;
  newIngredientsCount?: InputMaybe<Scalars['Int']>;
  newIngredientsSupplierCount?: InputMaybe<Scalars['Int']>;
  noChanges?: InputMaybe<Scalars['Boolean']>;
  notFromLastWeek?: InputMaybe<Scalars['Int']>;
  showInvoiceSingularInPeriod?: InputMaybe<Scalars['Boolean']>;
  showInvoiceSingularInvoiceCount?: InputMaybe<Scalars['Boolean']>;
  showTotalSpend?: InputMaybe<Scalars['Boolean']>;
  showWeekSoFar?: InputMaybe<Scalars['Boolean']>;
  totalProcessedLastWeek?: InputMaybe<Scalars['Int']>;
  totalSpend?: InputMaybe<Scalars['String']>;
  totalSpendLastWeek?: InputMaybe<Scalars['String']>;
  weekSoFar?: InputMaybe<Scalars['String']>;
  weekly?: InputMaybe<Scalars['Boolean']>;
  weeklyPercentageChange?: InputMaybe<Scalars['String']>;
};

export type DashboardFinance = {
  __typename?: 'DashboardFinance';
  daysOfMissingSales: Scalars['Int'];
  topNegativePriceChange?: Maybe<DashboardPriceChange>;
  topPositivePriceChange?: Maybe<DashboardPriceChange>;
  totalInvoicesNeedAttention: Scalars['Int'];
  totalSales: Scalars['Float'];
  totalSpend: Scalars['Float'];
};

export type DashboardKitchen = {
  __typename?: 'DashboardKitchen';
  previousStockValue: Scalars['Float'];
  recentOrder?: Maybe<DashboardOrder>;
  topMenuDiff?: Maybe<DashboardMenuDiff>;
  topStockTake?: Maybe<DashboardStockTake>;
  totalCookbookUpdates: Scalars['Int'];
  totalOrdersPlaced: Scalars['Int'];
  totalStockValue: Scalars['Float'];
  unlinkedPosProducts: Scalars['Int'];
};

export type DashboardMenuDiff = {
  __typename?: 'DashboardMenuDiff';
  diff: Scalars['Float'];
  name: Scalars['String'];
  target: Scalars['Float'];
};

export type DashboardOrder = {
  __typename?: 'DashboardOrder';
  creatorName: Scalars['String'];
  date: Scalars['DateTime'];
  supplierName: Scalars['String'];
};

export type DashboardPriceChange = {
  __typename?: 'DashboardPriceChange';
  percentageChange: Scalars['Float'];
  productName: Scalars['String'];
  supplierName: Scalars['String'];
};

export type DashboardStockTake = {
  __typename?: 'DashboardStockTake';
  date: Scalars['Date'];
  name: Scalars['String'];
  value: Scalars['Float'];
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DeleteKitchenInviteInput = {
  id: Scalars['Int'];
};

export type DeleteOneFoodFlashInputObject = {
  id: Scalars['Int'];
};

export type DeleteOneIngredientInputObject = {
  id: Scalars['Int'];
};

export type DeleteOneInvoiceInputObject = {
  id: Scalars['Int'];
};

export type DeleteOneKitchenAddressInputObject = {
  id: Scalars['Int'];
};

export type DeleteOneStockInputObject = {
  id: Scalars['Int'];
};

export type DeletePosTokenInput = {
  id: Scalars['Int'];
};

export type Dish = Node & {
  __typename?: 'Dish';
  _cursor: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  gpTarget: Scalars['Float'];
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  ingredientCount: Scalars['Int'];
  isCalculatedDraft?: Maybe<Scalars['Boolean']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  isMatched: Scalars['Boolean'];
  kitchen: Kitchen;
  menuList: MenuToDishConnection;
  /** @deprecated Use and extend menuList field */
  menus: Array<MenuToDish>;
  name: Scalars['String'];
  overheads?: Maybe<Scalars['Float']>;
  portion: Scalars['Float'];
  price?: Maybe<Scalars['Float']>;
  recipe: Recipe;
  status: Status;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
  vat?: Maybe<Scalars['Float']>;
};


export type DishMenuListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type DishMenusArgs = {
  cursor?: InputMaybe<MenuToDishWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MenuToDishOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MenuToDishWhereInput>;
};

export type DishAndRecipe = Dish | Recipe;

export type DishAndRecipeConnection = {
  __typename?: 'DishAndRecipeConnection';
  edges: Array<DishAndRecipeEdge>;
  nodes: Array<DishAndRecipe>;
  pageInfo: PageInfo;
};

export type DishAndRecipeEdge = {
  __typename?: 'DishAndRecipeEdge';
  cursor: Scalars['ID'];
  node: DishAndRecipe;
};

export type DishConnection = {
  __typename?: 'DishConnection';
  edges: Array<DishEdge>;
  nodes: Array<Dish>;
  pageInfo: PageInfo;
};

export type DishCreateManyCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  gpTarget?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchenId: Scalars['Int'];
  name: Scalars['String'];
  overheads?: InputMaybe<Scalars['Float']>;
  portion?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  recipeId: Scalars['Int'];
  status?: InputMaybe<Status>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
  vat?: InputMaybe<Scalars['Float']>;
};

export type DishCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<DishCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DishCreateManyKitchenInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  gpTarget?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  overheads?: InputMaybe<Scalars['Float']>;
  portion?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  recipeId: Scalars['Int'];
  status?: InputMaybe<Status>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
  vat?: InputMaybe<Scalars['Float']>;
};

export type DishCreateManyKitchenInputEnvelope = {
  data?: InputMaybe<Array<DishCreateManyKitchenInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DishCreateManyRecipeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  gpTarget?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchenId: Scalars['Int'];
  name: Scalars['String'];
  overheads?: InputMaybe<Scalars['Float']>;
  portion?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
  vat?: InputMaybe<Scalars['Float']>;
};

export type DishCreateManyRecipeInputEnvelope = {
  data?: InputMaybe<Array<DishCreateManyRecipeInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DishCreateManyUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  gpTarget?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchenId: Scalars['Int'];
  name: Scalars['String'];
  overheads?: InputMaybe<Scalars['Float']>;
  portion?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  recipeId: Scalars['Int'];
  status?: InputMaybe<Status>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type DishCreateManyUpdatedByInputEnvelope = {
  data?: InputMaybe<Array<DishCreateManyUpdatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DishCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<DishWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DishCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<DishCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<DishCreateManyCreatedByInputEnvelope>;
};

export type DishCreateNestedManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<DishWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DishCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<DishCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<DishCreateManyKitchenInputEnvelope>;
};

export type DishCreateNestedManyWithoutRecipeInput = {
  connect?: InputMaybe<Array<DishWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DishCreateOrConnectWithoutRecipeInput>>;
  create?: InputMaybe<Array<DishCreateWithoutRecipeInput>>;
  createMany?: InputMaybe<DishCreateManyRecipeInputEnvelope>;
};

export type DishCreateNestedManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<DishWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DishCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<DishCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<DishCreateManyUpdatedByInputEnvelope>;
};

export type DishCreateNestedOneWithoutMenusInput = {
  connect?: InputMaybe<DishWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DishCreateOrConnectWithoutMenusInput>;
  create?: InputMaybe<DishCreateWithoutMenusInput>;
};

export type DishCreateOrConnectWithoutCreatedByInput = {
  create: DishCreateWithoutCreatedByInput;
  where: DishWhereUniqueInput;
};

export type DishCreateOrConnectWithoutKitchenInput = {
  create: DishCreateWithoutKitchenInput;
  where: DishWhereUniqueInput;
};

export type DishCreateOrConnectWithoutMenusInput = {
  create: DishCreateWithoutMenusInput;
  where: DishWhereUniqueInput;
};

export type DishCreateOrConnectWithoutRecipeInput = {
  create: DishCreateWithoutRecipeInput;
  where: DishWhereUniqueInput;
};

export type DishCreateOrConnectWithoutUpdatedByInput = {
  create: DishCreateWithoutUpdatedByInput;
  where: DishWhereUniqueInput;
};

export type DishCreateWithoutCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  gpTarget?: InputMaybe<Scalars['Float']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutDishesInput;
  menus?: InputMaybe<MenuToDishCreateNestedManyWithoutDishInput>;
  name: Scalars['String'];
  overheads?: InputMaybe<Scalars['Float']>;
  portion?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  recipe: RecipeCreateNestedOneWithoutDishesInput;
  status?: InputMaybe<Status>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutDishUpdatedInput;
  vat?: InputMaybe<Scalars['Float']>;
};

export type DishCreateWithoutKitchenInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutDishCreatedInput;
  gpTarget?: InputMaybe<Scalars['Float']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  menus?: InputMaybe<MenuToDishCreateNestedManyWithoutDishInput>;
  name: Scalars['String'];
  overheads?: InputMaybe<Scalars['Float']>;
  portion?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  recipe: RecipeCreateNestedOneWithoutDishesInput;
  status?: InputMaybe<Status>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutDishUpdatedInput;
  vat?: InputMaybe<Scalars['Float']>;
};

export type DishCreateWithoutMenusInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutDishCreatedInput;
  gpTarget?: InputMaybe<Scalars['Float']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutDishesInput;
  name: Scalars['String'];
  overheads?: InputMaybe<Scalars['Float']>;
  portion?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  recipe: RecipeCreateNestedOneWithoutDishesInput;
  status?: InputMaybe<Status>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutDishUpdatedInput;
  vat?: InputMaybe<Scalars['Float']>;
};

export type DishCreateWithoutRecipeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutDishCreatedInput;
  gpTarget?: InputMaybe<Scalars['Float']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutDishesInput;
  menus?: InputMaybe<MenuToDishCreateNestedManyWithoutDishInput>;
  name: Scalars['String'];
  overheads?: InputMaybe<Scalars['Float']>;
  portion?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutDishUpdatedInput;
  vat?: InputMaybe<Scalars['Float']>;
};

export type DishCreateWithoutUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutDishCreatedInput;
  gpTarget?: InputMaybe<Scalars['Float']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutDishesInput;
  menus?: InputMaybe<MenuToDishCreateNestedManyWithoutDishInput>;
  name: Scalars['String'];
  overheads?: InputMaybe<Scalars['Float']>;
  portion?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  recipe: RecipeCreateNestedOneWithoutDishesInput;
  status?: InputMaybe<Status>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type DishEdge = {
  __typename?: 'DishEdge';
  cursor: Scalars['ID'];
  node: Dish;
};

export type DishListRelationFilter = {
  every?: InputMaybe<DishWhereInput>;
  none?: InputMaybe<DishWhereInput>;
  some?: InputMaybe<DishWhereInput>;
};

export type DishOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  gpTarget?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  isDraft?: InputMaybe<SortOrder>;
  kitchen?: InputMaybe<KitchenOrderByInput>;
  kitchenId?: InputMaybe<SortOrder>;
  menus?: InputMaybe<MenuToDishOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  overheads?: InputMaybe<SortOrder>;
  portion?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  recipe?: InputMaybe<RecipeOrderByInput>;
  recipeId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
  vat?: InputMaybe<SortOrder>;
};

export type DishOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type DishScalarWhereInput = {
  AND?: InputMaybe<Array<DishScalarWhereInput>>;
  NOT?: InputMaybe<Array<DishScalarWhereInput>>;
  OR?: InputMaybe<Array<DishScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdById?: InputMaybe<IntFilter>;
  gpTarget?: InputMaybe<FloatNullableFilter>;
  id?: InputMaybe<IntFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  isDraft?: InputMaybe<BoolNullableFilter>;
  kitchenId?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  overheads?: InputMaybe<FloatNullableFilter>;
  portion?: InputMaybe<FloatFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  recipeId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedById?: InputMaybe<IntFilter>;
  vat?: InputMaybe<FloatNullableFilter>;
};

export type DishToAllergenInputObject = {
  contains: Scalars['Boolean'];
  id: Scalars['Int'];
  removable: Scalars['Boolean'];
};

export type DishToIngredientInputObject = {
  amount: Scalars['Float'];
  id: Scalars['Int'];
  unitCost: Scalars['Float'];
  unitId: Scalars['Int'];
};

export type DishToRecipeInputObject = {
  amount: Scalars['Float'];
  id: Scalars['Int'];
  unitCost: Scalars['Float'];
  unitId: Scalars['Int'];
};

export type DishType = {
  __typename?: 'DishType';
  name: Scalars['String'];
};

export type DishUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  gpTarget?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheads?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  portion?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vat?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type DishUpdateManyWithWhereWithoutCreatedByInput = {
  data: DishUpdateManyMutationInput;
  where: DishScalarWhereInput;
};

export type DishUpdateManyWithWhereWithoutKitchenInput = {
  data: DishUpdateManyMutationInput;
  where: DishScalarWhereInput;
};

export type DishUpdateManyWithWhereWithoutRecipeInput = {
  data: DishUpdateManyMutationInput;
  where: DishScalarWhereInput;
};

export type DishUpdateManyWithWhereWithoutUpdatedByInput = {
  data: DishUpdateManyMutationInput;
  where: DishScalarWhereInput;
};

export type DishUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<DishWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DishCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<DishCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<DishCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<DishWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DishScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DishWhereUniqueInput>>;
  set?: InputMaybe<Array<DishWhereUniqueInput>>;
  update?: InputMaybe<Array<DishUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<DishUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<DishUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type DishUpdateManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<DishWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DishCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<DishCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<DishCreateManyKitchenInputEnvelope>;
  delete?: InputMaybe<Array<DishWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DishScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DishWhereUniqueInput>>;
  set?: InputMaybe<Array<DishWhereUniqueInput>>;
  update?: InputMaybe<Array<DishUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: InputMaybe<Array<DishUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: InputMaybe<Array<DishUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type DishUpdateManyWithoutRecipeInput = {
  connect?: InputMaybe<Array<DishWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DishCreateOrConnectWithoutRecipeInput>>;
  create?: InputMaybe<Array<DishCreateWithoutRecipeInput>>;
  createMany?: InputMaybe<DishCreateManyRecipeInputEnvelope>;
  delete?: InputMaybe<Array<DishWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DishScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DishWhereUniqueInput>>;
  set?: InputMaybe<Array<DishWhereUniqueInput>>;
  update?: InputMaybe<Array<DishUpdateWithWhereUniqueWithoutRecipeInput>>;
  updateMany?: InputMaybe<Array<DishUpdateManyWithWhereWithoutRecipeInput>>;
  upsert?: InputMaybe<Array<DishUpsertWithWhereUniqueWithoutRecipeInput>>;
};

export type DishUpdateManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<DishWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DishCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<DishCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<DishCreateManyUpdatedByInputEnvelope>;
  delete?: InputMaybe<Array<DishWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DishScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DishWhereUniqueInput>>;
  set?: InputMaybe<Array<DishWhereUniqueInput>>;
  update?: InputMaybe<Array<DishUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: InputMaybe<Array<DishUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: InputMaybe<Array<DishUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type DishUpdateOneRequiredWithoutMenusInput = {
  connect?: InputMaybe<DishWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DishCreateOrConnectWithoutMenusInput>;
  create?: InputMaybe<DishCreateWithoutMenusInput>;
  update?: InputMaybe<DishUpdateWithoutMenusInput>;
  upsert?: InputMaybe<DishUpsertWithoutMenusInput>;
};

export type DishUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: DishUpdateWithoutCreatedByInput;
  where: DishWhereUniqueInput;
};

export type DishUpdateWithWhereUniqueWithoutKitchenInput = {
  data: DishUpdateWithoutKitchenInput;
  where: DishWhereUniqueInput;
};

export type DishUpdateWithWhereUniqueWithoutRecipeInput = {
  data: DishUpdateWithoutRecipeInput;
  where: DishWhereUniqueInput;
};

export type DishUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: DishUpdateWithoutUpdatedByInput;
  where: DishWhereUniqueInput;
};

export type DishUpdateWithoutCreatedByInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  gpTarget?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutDishesInput>;
  menus?: InputMaybe<MenuToDishUpdateManyWithoutDishInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheads?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  portion?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recipe?: InputMaybe<RecipeUpdateOneRequiredWithoutDishesInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutDishUpdatedInput>;
  vat?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type DishUpdateWithoutKitchenInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutDishCreatedInput>;
  gpTarget?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  menus?: InputMaybe<MenuToDishUpdateManyWithoutDishInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheads?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  portion?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recipe?: InputMaybe<RecipeUpdateOneRequiredWithoutDishesInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutDishUpdatedInput>;
  vat?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type DishUpdateWithoutMenusInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutDishCreatedInput>;
  gpTarget?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutDishesInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheads?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  portion?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recipe?: InputMaybe<RecipeUpdateOneRequiredWithoutDishesInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutDishUpdatedInput>;
  vat?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type DishUpdateWithoutRecipeInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutDishCreatedInput>;
  gpTarget?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutDishesInput>;
  menus?: InputMaybe<MenuToDishUpdateManyWithoutDishInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheads?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  portion?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutDishUpdatedInput>;
  vat?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type DishUpdateWithoutUpdatedByInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutDishCreatedInput>;
  gpTarget?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutDishesInput>;
  menus?: InputMaybe<MenuToDishUpdateManyWithoutDishInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheads?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  portion?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recipe?: InputMaybe<RecipeUpdateOneRequiredWithoutDishesInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vat?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type DishUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: DishCreateWithoutCreatedByInput;
  update: DishUpdateWithoutCreatedByInput;
  where: DishWhereUniqueInput;
};

export type DishUpsertWithWhereUniqueWithoutKitchenInput = {
  create: DishCreateWithoutKitchenInput;
  update: DishUpdateWithoutKitchenInput;
  where: DishWhereUniqueInput;
};

export type DishUpsertWithWhereUniqueWithoutRecipeInput = {
  create: DishCreateWithoutRecipeInput;
  update: DishUpdateWithoutRecipeInput;
  where: DishWhereUniqueInput;
};

export type DishUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: DishCreateWithoutUpdatedByInput;
  update: DishUpdateWithoutUpdatedByInput;
  where: DishWhereUniqueInput;
};

export type DishUpsertWithoutMenusInput = {
  create: DishCreateWithoutMenusInput;
  update: DishUpdateWithoutMenusInput;
};

export type DishWhereInput = {
  AND?: InputMaybe<Array<DishWhereInput>>;
  NOT?: InputMaybe<Array<DishWhereInput>>;
  OR?: InputMaybe<Array<DishWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  gpTarget?: InputMaybe<FloatNullableFilter>;
  id?: InputMaybe<IntFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  isDraft?: InputMaybe<BoolNullableFilter>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  menus?: InputMaybe<MenuToDishListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  overheads?: InputMaybe<FloatNullableFilter>;
  portion?: InputMaybe<FloatFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  recipe?: InputMaybe<RecipeWhereInput>;
  recipeId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
  vat?: InputMaybe<FloatNullableFilter>;
};

export type DishWhereUniqueInput = {
  id: Scalars['Int'];
};

export type DishesSold = Node & {
  __typename?: 'DishesSold';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  dish: Dish;
  dishId: Scalars['Int'];
  id: Scalars['Int'];
  period: InsightPeriod;
  soldAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EnumInvoiceRejectionReasonFilter = {
  equals?: InputMaybe<InvoiceRejectionReason>;
  in?: InputMaybe<Array<InvoiceRejectionReason>>;
  not?: InputMaybe<NestedEnumInvoiceRejectionReasonFilter>;
  notIn?: InputMaybe<Array<InvoiceRejectionReason>>;
};

export type EnumInvoiceStatusFilter = {
  equals?: InputMaybe<InvoiceStatus>;
  in?: InputMaybe<Array<InvoiceStatus>>;
  not?: InputMaybe<NestedEnumInvoiceStatusFilter>;
  notIn?: InputMaybe<Array<InvoiceStatus>>;
};

export type EnumKitchenSupplierDataTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<KitchenSupplierDataType>;
};

export type EnumKitchenSupplierDataTypeFilter = {
  equals?: InputMaybe<KitchenSupplierDataType>;
  in?: InputMaybe<Array<KitchenSupplierDataType>>;
  not?: InputMaybe<NestedEnumKitchenSupplierDataTypeFilter>;
  notIn?: InputMaybe<Array<KitchenSupplierDataType>>;
};

export type EnumMeasurementSystemFieldUpdateOperationsInput = {
  set?: InputMaybe<MeasurementSystem>;
};

export type EnumMeasurementSystemFilter = {
  equals?: InputMaybe<MeasurementSystem>;
  in?: InputMaybe<Array<MeasurementSystem>>;
  not?: InputMaybe<NestedEnumMeasurementSystemFilter>;
  notIn?: InputMaybe<Array<MeasurementSystem>>;
};

export type EnumProductStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ProductStatus>;
};

export type EnumProductStatusFilter = {
  equals?: InputMaybe<ProductStatus>;
  in?: InputMaybe<Array<ProductStatus>>;
  not?: InputMaybe<NestedEnumProductStatusFilter>;
  notIn?: InputMaybe<Array<ProductStatus>>;
};

export type EnumPurchaseOrderSizeTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<PurchaseOrderSizeType>;
};

export type EnumPurchaseOrderSizeTypeFilter = {
  equals?: InputMaybe<PurchaseOrderSizeType>;
  in?: InputMaybe<Array<PurchaseOrderSizeType>>;
  not?: InputMaybe<NestedEnumPurchaseOrderSizeTypeFilter>;
  notIn?: InputMaybe<Array<PurchaseOrderSizeType>>;
};

export type EnumStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<Status>;
};

export type EnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type EnumUnitTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<UnitType>;
};

export type EnumUnitTypeFilter = {
  equals?: InputMaybe<UnitType>;
  in?: InputMaybe<Array<UnitType>>;
  not?: InputMaybe<NestedEnumUnitTypeFilter>;
  notIn?: InputMaybe<Array<UnitType>>;
};

export type EnumUserRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<UserRole>;
};

export type EnumUserRoleFilter = {
  equals?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  not?: InputMaybe<NestedEnumUserRoleFilter>;
  notIn?: InputMaybe<Array<UserRole>>;
};

export type EventLog = Node & {
  __typename?: 'EventLog';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  data: Scalars['Json'];
  entityId: Scalars['Int'];
  id: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type EventLogConnection = {
  __typename?: 'EventLogConnection';
  edges: Array<EventLogEdge>;
  nodes: Array<EventLog>;
  pageInfo: PageInfo;
};

export type EventLogCreateManyCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['Json'];
  entityId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  type: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type EventLogCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<EventLogCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type EventLogCreateManyLookupInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  data: Scalars['Json'];
  entityId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type EventLogCreateManyLookupInputEnvelope = {
  data?: InputMaybe<Array<EventLogCreateManyLookupInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type EventLogCreateManyUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  data: Scalars['Json'];
  entityId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  type: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EventLogCreateManyUpdatedByInputEnvelope = {
  data?: InputMaybe<Array<EventLogCreateManyUpdatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type EventLogCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventLogCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<EventLogCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<EventLogCreateManyCreatedByInputEnvelope>;
};

export type EventLogCreateNestedManyWithoutLookupInput = {
  connect?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventLogCreateOrConnectWithoutLookupInput>>;
  create?: InputMaybe<Array<EventLogCreateWithoutLookupInput>>;
  createMany?: InputMaybe<EventLogCreateManyLookupInputEnvelope>;
};

export type EventLogCreateNestedManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventLogCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<EventLogCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<EventLogCreateManyUpdatedByInputEnvelope>;
};

export type EventLogCreateOrConnectWithoutCreatedByInput = {
  create: EventLogCreateWithoutCreatedByInput;
  where: EventLogWhereUniqueInput;
};

export type EventLogCreateOrConnectWithoutLookupInput = {
  create: EventLogCreateWithoutLookupInput;
  where: EventLogWhereUniqueInput;
};

export type EventLogCreateOrConnectWithoutUpdatedByInput = {
  create: EventLogCreateWithoutUpdatedByInput;
  where: EventLogWhereUniqueInput;
};

export type EventLogCreateWithoutCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['Json'];
  entityId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutEventLogUpdatedInput;
};

export type EventLogCreateWithoutLookupInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutEventLogCreatedInput;
  data: Scalars['Json'];
  entityId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutEventLogUpdatedInput;
};

export type EventLogCreateWithoutUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutEventLogCreatedInput;
  data: Scalars['Json'];
  entityId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EventLogEdge = {
  __typename?: 'EventLogEdge';
  cursor: Scalars['ID'];
  node: EventLog;
};

export type EventLogListRelationFilter = {
  every?: InputMaybe<EventLogWhereInput>;
  none?: InputMaybe<EventLogWhereInput>;
  some?: InputMaybe<EventLogWhereInput>;
};

export type EventLogOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type EventLogScalarWhereInput = {
  AND?: InputMaybe<Array<EventLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<EventLogScalarWhereInput>>;
  OR?: InputMaybe<Array<EventLogScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<IntFilter>;
  entityId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  type?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  updatedById?: InputMaybe<IntFilter>;
};

export type EventLogUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']>;
  entityId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventLogUpdateManyWithWhereWithoutCreatedByInput = {
  data: EventLogUpdateManyMutationInput;
  where: EventLogScalarWhereInput;
};

export type EventLogUpdateManyWithWhereWithoutLookupInput = {
  data: EventLogUpdateManyMutationInput;
  where: EventLogScalarWhereInput;
};

export type EventLogUpdateManyWithWhereWithoutUpdatedByInput = {
  data: EventLogUpdateManyMutationInput;
  where: EventLogScalarWhereInput;
};

export type EventLogUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventLogCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<EventLogCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<EventLogCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  set?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  update?: InputMaybe<Array<EventLogUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<EventLogUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<EventLogUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type EventLogUpdateManyWithoutLookupInput = {
  connect?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventLogCreateOrConnectWithoutLookupInput>>;
  create?: InputMaybe<Array<EventLogCreateWithoutLookupInput>>;
  createMany?: InputMaybe<EventLogCreateManyLookupInputEnvelope>;
  delete?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  set?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  update?: InputMaybe<Array<EventLogUpdateWithWhereUniqueWithoutLookupInput>>;
  updateMany?: InputMaybe<Array<EventLogUpdateManyWithWhereWithoutLookupInput>>;
  upsert?: InputMaybe<Array<EventLogUpsertWithWhereUniqueWithoutLookupInput>>;
};

export type EventLogUpdateManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventLogCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<EventLogCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<EventLogCreateManyUpdatedByInputEnvelope>;
  delete?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  set?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  update?: InputMaybe<Array<EventLogUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: InputMaybe<Array<EventLogUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: InputMaybe<Array<EventLogUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type EventLogUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: EventLogUpdateWithoutCreatedByInput;
  where: EventLogWhereUniqueInput;
};

export type EventLogUpdateWithWhereUniqueWithoutLookupInput = {
  data: EventLogUpdateWithoutLookupInput;
  where: EventLogWhereUniqueInput;
};

export type EventLogUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: EventLogUpdateWithoutUpdatedByInput;
  where: EventLogWhereUniqueInput;
};

export type EventLogUpdateWithoutCreatedByInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']>;
  entityId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutEventLogUpdatedInput>;
};

export type EventLogUpdateWithoutLookupInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutEventLogCreatedInput>;
  data?: InputMaybe<Scalars['Json']>;
  entityId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutEventLogUpdatedInput>;
};

export type EventLogUpdateWithoutUpdatedByInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutEventLogCreatedInput>;
  data?: InputMaybe<Scalars['Json']>;
  entityId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventLogUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: EventLogCreateWithoutCreatedByInput;
  update: EventLogUpdateWithoutCreatedByInput;
  where: EventLogWhereUniqueInput;
};

export type EventLogUpsertWithWhereUniqueWithoutLookupInput = {
  create: EventLogCreateWithoutLookupInput;
  update: EventLogUpdateWithoutLookupInput;
  where: EventLogWhereUniqueInput;
};

export type EventLogUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: EventLogCreateWithoutUpdatedByInput;
  update: EventLogUpdateWithoutUpdatedByInput;
  where: EventLogWhereUniqueInput;
};

export type EventLogWhereInput = {
  AND?: InputMaybe<Array<EventLogWhereInput>>;
  NOT?: InputMaybe<Array<EventLogWhereInput>>;
  OR?: InputMaybe<Array<EventLogWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  entityId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  type?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type EventLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type FeatureGuards = {
  __typename?: 'FeatureGuards';
  availableFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FlashInsights = {
  __typename?: 'FlashInsights';
  sales: Array<FlashSales>;
  salesData: Array<FlashSalesData>;
  totalInvoicesNeedAttention: Scalars['Int'];
};

export type FlashPeriod = {
  __typename?: 'FlashPeriod';
  closeAdjustments?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  flashPeriodToStock: Array<FlashPeriodToStock>;
  id: Scalars['Int'];
  kitchen: Kitchen;
  kitchenId: Scalars['Int'];
  openAdjustments?: Maybe<Scalars['Float']>;
  period: PeriodEnum;
  startDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type FlashPeriodToStock = {
  __typename?: 'FlashPeriodToStock';
  createdAt: Scalars['DateTime'];
  flashPeriod: FlashPeriod;
  flashPeriodId: Scalars['Int'];
  id: Scalars['Int'];
  stock: Stock;
  stockId: Scalars['Int'];
  type: FlashStockType;
  updatedAt: Scalars['DateTime'];
};

export type FlashSales = {
  __typename?: 'FlashSales';
  automated?: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
  total?: Maybe<Scalars['Float']>;
};

export type FlashSalesData = {
  __typename?: 'FlashSalesData';
  date: Scalars['Date'];
  sales: Scalars['Float'];
  spend: Scalars['Float'];
};

export enum FlashStockType {
  Close = 'close',
  Open = 'open'
}

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FoodFlash = Node & {
  __typename?: 'FoodFlash';
  _cursor: Scalars['ID'];
  complimentary?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  endDate: Scalars['DateTime'];
  expenses?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  invoices: InvoiceConnection;
  kitchen: Kitchen;
  name: Scalars['String'];
  sales: Scalars['Float'];
  startDate: Scalars['DateTime'];
  stockIn: FoodFlashToStockConnection;
  stockOut: FoodFlashToStockConnection;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
  wastage?: Maybe<Scalars['Float']>;
};


export type FoodFlashInvoicesArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type FoodFlashStockInArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type FoodFlashStockOutArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type FoodFlashConnection = {
  __typename?: 'FoodFlashConnection';
  edges: Array<FoodFlashEdge>;
  nodes: Array<FoodFlash>;
  pageInfo: PageInfo;
};

export type FoodFlashEdge = {
  __typename?: 'FoodFlashEdge';
  cursor: Scalars['ID'];
  node: FoodFlash;
};

export type FoodFlashListRelationFilter = {
  every?: InputMaybe<FoodFlashWhereInput>;
  none?: InputMaybe<FoodFlashWhereInput>;
  some?: InputMaybe<FoodFlashWhereInput>;
};

export type FoodFlashOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  kitchen?: InputMaybe<KitchenOrderByInput>;
  kitchenId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
};

export type FoodFlashOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type FoodFlashToStock = Node & {
  __typename?: 'FoodFlashToStock';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  id: Scalars['Int'];
  in: Scalars['Boolean'];
  stock: Stock;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type FoodFlashToStockConnection = {
  __typename?: 'FoodFlashToStockConnection';
  edges: Array<FoodFlashToStockEdge>;
  nodes: Array<FoodFlashToStock>;
  pageInfo: PageInfo;
};

export type FoodFlashToStockEdge = {
  __typename?: 'FoodFlashToStockEdge';
  cursor: Scalars['ID'];
  node: FoodFlashToStock;
};

export type FoodFlashWhereInput = {
  AND?: InputMaybe<Array<FoodFlashWhereInput>>;
  NOT?: InputMaybe<Array<FoodFlashWhereInput>>;
  OR?: InputMaybe<Array<FoodFlashWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type FoodFlashWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type FuzzyProductSearch = {
  __typename?: 'FuzzyProductSearch';
  count?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Product>>;
};

export type GetProductDuplicateSupplier = {
  __typename?: 'GetProductDuplicateSupplier';
  count: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type GooglePlaceDetailsApi = {
  __typename?: 'GooglePlaceDetailsAPI';
  city: Scalars['String'];
  line1: Scalars['String'];
  name: Scalars['String'];
  placeId: Scalars['String'];
  postCode: Scalars['String'];
};

export type GooglePlacesAutoCompleteApi = {
  __typename?: 'GooglePlacesAutoCompleteAPI';
  description: Scalars['String'];
  placeId: Scalars['String'];
};

export type HubspotContact = {
  __typename?: 'HubspotContact';
  identificationEmail: Scalars['String'];
  identificationToken: Scalars['String'];
};

export type Ingredient = Node & {
  __typename?: 'Ingredient';
  _cursor: Scalars['ID'];
  conversionUnit?: Maybe<Scalars['Float']>;
  conversionUnitType?: Maybe<Unit>;
  conversionUnitValue?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['Int'];
  isUsed?: Maybe<Scalars['Boolean']>;
  kitchen: Kitchen;
  price?: Maybe<Scalars['Float']>;
  priceChangesList: PriceChangesOverview;
  product: Product;
  recipeList: RecipeToIngredientConnection;
  /** @deprecated Use and extend recipeList field */
  recipes: Array<RecipeToIngredient>;
  stockData?: Maybe<Scalars['Json']>;
  unitPrice?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
  wastage: Scalars['Float'];
  wasteAdjustedUnitPrice?: Maybe<Scalars['Float']>;
};


export type IngredientPriceChangesListArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type IngredientRecipeListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RecipeToIngredientWhereInput>;
};


export type IngredientRecipesArgs = {
  cursor?: InputMaybe<RecipeToIngredientWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RecipeToIngredientOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RecipeToIngredientWhereInput>;
};

export type IngredientAndRecipe = {
  __typename?: 'IngredientAndRecipe';
  ingredients: Array<Ingredient>;
  ingredientsCount: Scalars['Int'];
  recipes: Array<Recipe>;
};

export type IngredientAndRecipeConnection = {
  __typename?: 'IngredientAndRecipeConnection';
  edges: Array<IngredientAndRecipeEdge>;
  nodes: Array<IngredientAndRecipe>;
  pageInfo: PageInfo;
};

export type IngredientAndRecipeEdge = {
  __typename?: 'IngredientAndRecipeEdge';
  cursor: Scalars['ID'];
  node: IngredientAndRecipe;
};

export type IngredientChange = {
  __typename?: 'IngredientChange';
  currentData: IngredientLogData;
  currentDate: Scalars['Date'];
  currentUnit: Scalars['String'];
  ingredientId: Scalars['Int'];
  ingredientName: Scalars['String'];
  percentageChange: Scalars['Float'];
  previousData: IngredientLogData;
  previousDate: Scalars['Date'];
  previousUnit: Scalars['String'];
  supplierName: Scalars['String'];
};

export type IngredientChangeInput = {
  ingredientId: Scalars['Int'];
  ingredientName: Scalars['String'];
  percentageChange: Scalars['Float'];
};

export type IngredientChanges = {
  __typename?: 'IngredientChanges';
  changesCount: Scalars['Int'];
  negativeChanges: Array<IngredientChange>;
  positiveChanges: Array<IngredientChange>;
};

export type IngredientConnection = {
  __typename?: 'IngredientConnection';
  edges: Array<IngredientEdge>;
  nodes: Array<Ingredient>;
  pageInfo: PageInfo;
};

export type IngredientCreateManyConversionUnitTypeInput = {
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  kitchenId: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  productId: Scalars['Int'];
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
  wastage?: InputMaybe<Scalars['Float']>;
  wasteAdjustedUnitPrice?: InputMaybe<Scalars['Float']>;
};

export type IngredientCreateManyConversionUnitTypeInputEnvelope = {
  data?: InputMaybe<Array<IngredientCreateManyConversionUnitTypeInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type IngredientCreateManyCreatedByInput = {
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitTypeId?: InputMaybe<Scalars['Int']>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  kitchenId: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  productId: Scalars['Int'];
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
  wastage?: InputMaybe<Scalars['Float']>;
  wasteAdjustedUnitPrice?: InputMaybe<Scalars['Float']>;
};

export type IngredientCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<IngredientCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type IngredientCreateManyKitchenInput = {
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitTypeId?: InputMaybe<Scalars['Int']>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
  productId: Scalars['Int'];
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
  wastage?: InputMaybe<Scalars['Float']>;
  wasteAdjustedUnitPrice?: InputMaybe<Scalars['Float']>;
};

export type IngredientCreateManyKitchenInputEnvelope = {
  data?: InputMaybe<Array<IngredientCreateManyKitchenInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type IngredientCreateManyProductInput = {
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitTypeId?: InputMaybe<Scalars['Int']>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  kitchenId: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
  wastage?: InputMaybe<Scalars['Float']>;
  wasteAdjustedUnitPrice?: InputMaybe<Scalars['Float']>;
};

export type IngredientCreateManyProductInputEnvelope = {
  data?: InputMaybe<Array<IngredientCreateManyProductInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type IngredientCreateManyUpdatedByInput = {
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitTypeId?: InputMaybe<Scalars['Int']>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  kitchenId: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  productId: Scalars['Int'];
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wastage?: InputMaybe<Scalars['Float']>;
  wasteAdjustedUnitPrice?: InputMaybe<Scalars['Float']>;
};

export type IngredientCreateManyUpdatedByInputEnvelope = {
  data?: InputMaybe<Array<IngredientCreateManyUpdatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type IngredientCreateNestedManyWithoutConversionUnitTypeInput = {
  connect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientCreateOrConnectWithoutConversionUnitTypeInput>>;
  create?: InputMaybe<Array<IngredientCreateWithoutConversionUnitTypeInput>>;
  createMany?: InputMaybe<IngredientCreateManyConversionUnitTypeInputEnvelope>;
};

export type IngredientCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<IngredientCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<IngredientCreateManyCreatedByInputEnvelope>;
};

export type IngredientCreateNestedManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<IngredientCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<IngredientCreateManyKitchenInputEnvelope>;
};

export type IngredientCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<IngredientCreateWithoutProductInput>>;
  createMany?: InputMaybe<IngredientCreateManyProductInputEnvelope>;
};

export type IngredientCreateNestedManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<IngredientCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<IngredientCreateManyUpdatedByInputEnvelope>;
};

export type IngredientCreateNestedOneWithoutIngredientLogInput = {
  connect?: InputMaybe<IngredientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<IngredientCreateOrConnectWithoutIngredientLogInput>;
  create?: InputMaybe<IngredientCreateWithoutIngredientLogInput>;
};

export type IngredientCreateNestedOneWithoutRecipesInput = {
  connect?: InputMaybe<IngredientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<IngredientCreateOrConnectWithoutRecipesInput>;
  create?: InputMaybe<IngredientCreateWithoutRecipesInput>;
};

export type IngredientCreateOrConnectWithoutConversionUnitTypeInput = {
  create: IngredientCreateWithoutConversionUnitTypeInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientCreateOrConnectWithoutCreatedByInput = {
  create: IngredientCreateWithoutCreatedByInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientCreateOrConnectWithoutIngredientLogInput = {
  create: IngredientCreateWithoutIngredientLogInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientCreateOrConnectWithoutKitchenInput = {
  create: IngredientCreateWithoutKitchenInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientCreateOrConnectWithoutProductInput = {
  create: IngredientCreateWithoutProductInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientCreateOrConnectWithoutRecipesInput = {
  create: IngredientCreateWithoutRecipesInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientCreateOrConnectWithoutUpdatedByInput = {
  create: IngredientCreateWithoutUpdatedByInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientCreateWithoutConversionUnitTypeInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutIngredientInput>;
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutIngredientCreatedInput;
  kitchen: KitchenCreateNestedOneWithoutIngredientsInput;
  price?: InputMaybe<Scalars['Float']>;
  product: ProductCreateNestedOneWithoutIngredientsInput;
  recipes?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutIngredientInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutIngredientUpdatedInput;
  wastage?: InputMaybe<Scalars['Float']>;
  wasteAdjustedUnitPrice?: InputMaybe<Scalars['Float']>;
};

export type IngredientCreateWithoutCreatedByInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutIngredientInput>;
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitType?: InputMaybe<UnitCreateNestedOneWithoutIngredientConversionUnitTypeInput>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  kitchen: KitchenCreateNestedOneWithoutIngredientsInput;
  price?: InputMaybe<Scalars['Float']>;
  product: ProductCreateNestedOneWithoutIngredientsInput;
  recipes?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutIngredientInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutIngredientUpdatedInput;
  wastage?: InputMaybe<Scalars['Float']>;
  wasteAdjustedUnitPrice?: InputMaybe<Scalars['Float']>;
};

export type IngredientCreateWithoutIngredientLogInput = {
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitType?: InputMaybe<UnitCreateNestedOneWithoutIngredientConversionUnitTypeInput>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutIngredientCreatedInput;
  kitchen: KitchenCreateNestedOneWithoutIngredientsInput;
  price?: InputMaybe<Scalars['Float']>;
  product: ProductCreateNestedOneWithoutIngredientsInput;
  recipes?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutIngredientInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutIngredientUpdatedInput;
  wastage?: InputMaybe<Scalars['Float']>;
  wasteAdjustedUnitPrice?: InputMaybe<Scalars['Float']>;
};

export type IngredientCreateWithoutKitchenInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutIngredientInput>;
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitType?: InputMaybe<UnitCreateNestedOneWithoutIngredientConversionUnitTypeInput>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutIngredientCreatedInput;
  price?: InputMaybe<Scalars['Float']>;
  product: ProductCreateNestedOneWithoutIngredientsInput;
  recipes?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutIngredientInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutIngredientUpdatedInput;
  wastage?: InputMaybe<Scalars['Float']>;
  wasteAdjustedUnitPrice?: InputMaybe<Scalars['Float']>;
};

export type IngredientCreateWithoutProductInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutIngredientInput>;
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitType?: InputMaybe<UnitCreateNestedOneWithoutIngredientConversionUnitTypeInput>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutIngredientCreatedInput;
  kitchen: KitchenCreateNestedOneWithoutIngredientsInput;
  price?: InputMaybe<Scalars['Float']>;
  recipes?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutIngredientInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutIngredientUpdatedInput;
  wastage?: InputMaybe<Scalars['Float']>;
  wasteAdjustedUnitPrice?: InputMaybe<Scalars['Float']>;
};

export type IngredientCreateWithoutRecipesInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutIngredientInput>;
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitType?: InputMaybe<UnitCreateNestedOneWithoutIngredientConversionUnitTypeInput>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutIngredientCreatedInput;
  kitchen: KitchenCreateNestedOneWithoutIngredientsInput;
  price?: InputMaybe<Scalars['Float']>;
  product: ProductCreateNestedOneWithoutIngredientsInput;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutIngredientUpdatedInput;
  wastage?: InputMaybe<Scalars['Float']>;
  wasteAdjustedUnitPrice?: InputMaybe<Scalars['Float']>;
};

export type IngredientCreateWithoutUpdatedByInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutIngredientInput>;
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitType?: InputMaybe<UnitCreateNestedOneWithoutIngredientConversionUnitTypeInput>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutIngredientCreatedInput;
  kitchen: KitchenCreateNestedOneWithoutIngredientsInput;
  price?: InputMaybe<Scalars['Float']>;
  product: ProductCreateNestedOneWithoutIngredientsInput;
  recipes?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutIngredientInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  wastage?: InputMaybe<Scalars['Float']>;
  wasteAdjustedUnitPrice?: InputMaybe<Scalars['Float']>;
};

export type IngredientEdge = {
  __typename?: 'IngredientEdge';
  cursor: Scalars['ID'];
  node: Ingredient;
};

export type IngredientListRelationFilter = {
  every?: InputMaybe<IngredientWhereInput>;
  none?: InputMaybe<IngredientWhereInput>;
  some?: InputMaybe<IngredientWhereInput>;
};

export type IngredientLog = Node & {
  __typename?: 'IngredientLog';
  _cursor: Scalars['ID'];
  id: Scalars['Int'];
  invoiceEntry?: Maybe<InvoiceEntry>;
  price?: Maybe<Scalars['Float']>;
};

export type IngredientLogCreateManyCreatedByInput = {
  caseSize?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['Json'];
  id?: InputMaybe<Scalars['Int']>;
  ingredientId: Scalars['Int'];
  kitchenId: Scalars['Int'];
  packSize?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  type: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type IngredientLogCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<IngredientLogCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type IngredientLogCreateManyIngredientInput = {
  caseSize?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  data: Scalars['Json'];
  id?: InputMaybe<Scalars['Int']>;
  kitchenId: Scalars['Int'];
  packSize?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  type: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type IngredientLogCreateManyIngredientInputEnvelope = {
  data?: InputMaybe<Array<IngredientLogCreateManyIngredientInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type IngredientLogCreateManyKitchenInput = {
  caseSize?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  data: Scalars['Json'];
  id?: InputMaybe<Scalars['Int']>;
  ingredientId: Scalars['Int'];
  packSize?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  type: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type IngredientLogCreateManyKitchenInputEnvelope = {
  data?: InputMaybe<Array<IngredientLogCreateManyKitchenInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type IngredientLogCreateManyLookupInput = {
  caseSize?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  data: Scalars['Json'];
  id?: InputMaybe<Scalars['Int']>;
  ingredientId: Scalars['Int'];
  kitchenId: Scalars['Int'];
  packSize?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type IngredientLogCreateManyLookupInputEnvelope = {
  data?: InputMaybe<Array<IngredientLogCreateManyLookupInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type IngredientLogCreateManyUpdatedByInput = {
  caseSize?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  data: Scalars['Json'];
  id?: InputMaybe<Scalars['Int']>;
  ingredientId: Scalars['Int'];
  kitchenId: Scalars['Int'];
  packSize?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  type: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type IngredientLogCreateManyUpdatedByInputEnvelope = {
  data?: InputMaybe<Array<IngredientLogCreateManyUpdatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type IngredientLogCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientLogCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<IngredientLogCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<IngredientLogCreateManyCreatedByInputEnvelope>;
};

export type IngredientLogCreateNestedManyWithoutIngredientInput = {
  connect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientLogCreateOrConnectWithoutIngredientInput>>;
  create?: InputMaybe<Array<IngredientLogCreateWithoutIngredientInput>>;
  createMany?: InputMaybe<IngredientLogCreateManyIngredientInputEnvelope>;
};

export type IngredientLogCreateNestedManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientLogCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<IngredientLogCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<IngredientLogCreateManyKitchenInputEnvelope>;
};

export type IngredientLogCreateNestedManyWithoutLookupInput = {
  connect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientLogCreateOrConnectWithoutLookupInput>>;
  create?: InputMaybe<Array<IngredientLogCreateWithoutLookupInput>>;
  createMany?: InputMaybe<IngredientLogCreateManyLookupInputEnvelope>;
};

export type IngredientLogCreateNestedManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientLogCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<IngredientLogCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<IngredientLogCreateManyUpdatedByInputEnvelope>;
};

export type IngredientLogCreateOrConnectWithoutCreatedByInput = {
  create: IngredientLogCreateWithoutCreatedByInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogCreateOrConnectWithoutIngredientInput = {
  create: IngredientLogCreateWithoutIngredientInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogCreateOrConnectWithoutKitchenInput = {
  create: IngredientLogCreateWithoutKitchenInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogCreateOrConnectWithoutLookupInput = {
  create: IngredientLogCreateWithoutLookupInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogCreateOrConnectWithoutUpdatedByInput = {
  create: IngredientLogCreateWithoutUpdatedByInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogCreateWithoutCreatedByInput = {
  caseSize?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['Json'];
  ingredient: IngredientCreateNestedOneWithoutIngredientLogInput;
  kitchen: KitchenCreateNestedOneWithoutIngredientLogInput;
  packSize?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutIngredientLogUpdatedInput;
};

export type IngredientLogCreateWithoutIngredientInput = {
  caseSize?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutIngredientLogCreatedInput;
  data: Scalars['Json'];
  kitchen: KitchenCreateNestedOneWithoutIngredientLogInput;
  packSize?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutIngredientLogUpdatedInput;
};

export type IngredientLogCreateWithoutKitchenInput = {
  caseSize?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutIngredientLogCreatedInput;
  data: Scalars['Json'];
  ingredient: IngredientCreateNestedOneWithoutIngredientLogInput;
  packSize?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutIngredientLogUpdatedInput;
};

export type IngredientLogCreateWithoutLookupInput = {
  caseSize?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutIngredientLogCreatedInput;
  data: Scalars['Json'];
  ingredient: IngredientCreateNestedOneWithoutIngredientLogInput;
  kitchen: KitchenCreateNestedOneWithoutIngredientLogInput;
  packSize?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutIngredientLogUpdatedInput;
};

export type IngredientLogCreateWithoutUpdatedByInput = {
  caseSize?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutIngredientLogCreatedInput;
  data: Scalars['Json'];
  ingredient: IngredientCreateNestedOneWithoutIngredientLogInput;
  kitchen: KitchenCreateNestedOneWithoutIngredientLogInput;
  packSize?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type IngredientLogData = {
  __typename?: 'IngredientLogData';
  CaseSize?: Maybe<Scalars['Float']>;
  /** @deprecated Not used and redundant data */
  DateTime?: Maybe<Scalars['String']>;
  IngredientId?: Maybe<Scalars['Int']>;
  KitchenId?: Maybe<Scalars['Int']>;
  KitchenName?: Maybe<Scalars['String']>;
  OperationType?: Maybe<Scalars['Int']>;
  PackSize?: Maybe<Scalars['Float']>;
  Price?: Maybe<Scalars['Float']>;
  ProductCode?: Maybe<Scalars['String']>;
  ProductId?: Maybe<Scalars['Int']>;
  SupplierId?: Maybe<Scalars['Int']>;
  Unit?: Maybe<Scalars['Int']>;
  UserId?: Maybe<Scalars['Int']>;
  Wastage?: Maybe<Scalars['Float']>;
  /** @deprecated Not used and redundant data */
  createdAt?: Maybe<Scalars['String']>;
  /** @deprecated Not used and redundant data */
  createdBy?: Maybe<Scalars['Int']>;
  /** @deprecated Not used and redundant data */
  updatedAt?: Maybe<Scalars['String']>;
  /** @deprecated Not used and redundant data */
  updatedBy?: Maybe<Scalars['Int']>;
};

export type IngredientLogListRelationFilter = {
  every?: InputMaybe<IngredientLogWhereInput>;
  none?: InputMaybe<IngredientLogWhereInput>;
  some?: InputMaybe<IngredientLogWhereInput>;
};

export type IngredientLogOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type IngredientLogScalarWhereInput = {
  AND?: InputMaybe<Array<IngredientLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<IngredientLogScalarWhereInput>>;
  OR?: InputMaybe<Array<IngredientLogScalarWhereInput>>;
  caseSize?: InputMaybe<FloatNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  ingredientId?: InputMaybe<IntFilter>;
  kitchenId?: InputMaybe<IntFilter>;
  packSize?: InputMaybe<FloatNullableFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  type?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  updatedById?: InputMaybe<IntFilter>;
};

export type IngredientLogUpdateManyMutationInput = {
  caseSize?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']>;
  packSize?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IngredientLogUpdateManyWithWhereWithoutCreatedByInput = {
  data: IngredientLogUpdateManyMutationInput;
  where: IngredientLogScalarWhereInput;
};

export type IngredientLogUpdateManyWithWhereWithoutIngredientInput = {
  data: IngredientLogUpdateManyMutationInput;
  where: IngredientLogScalarWhereInput;
};

export type IngredientLogUpdateManyWithWhereWithoutKitchenInput = {
  data: IngredientLogUpdateManyMutationInput;
  where: IngredientLogScalarWhereInput;
};

export type IngredientLogUpdateManyWithWhereWithoutLookupInput = {
  data: IngredientLogUpdateManyMutationInput;
  where: IngredientLogScalarWhereInput;
};

export type IngredientLogUpdateManyWithWhereWithoutUpdatedByInput = {
  data: IngredientLogUpdateManyMutationInput;
  where: IngredientLogScalarWhereInput;
};

export type IngredientLogUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientLogCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<IngredientLogCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<IngredientLogCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IngredientLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  set?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  update?: InputMaybe<Array<IngredientLogUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<IngredientLogUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<IngredientLogUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type IngredientLogUpdateManyWithoutIngredientInput = {
  connect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientLogCreateOrConnectWithoutIngredientInput>>;
  create?: InputMaybe<Array<IngredientLogCreateWithoutIngredientInput>>;
  createMany?: InputMaybe<IngredientLogCreateManyIngredientInputEnvelope>;
  delete?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IngredientLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  set?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  update?: InputMaybe<Array<IngredientLogUpdateWithWhereUniqueWithoutIngredientInput>>;
  updateMany?: InputMaybe<Array<IngredientLogUpdateManyWithWhereWithoutIngredientInput>>;
  upsert?: InputMaybe<Array<IngredientLogUpsertWithWhereUniqueWithoutIngredientInput>>;
};

export type IngredientLogUpdateManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientLogCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<IngredientLogCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<IngredientLogCreateManyKitchenInputEnvelope>;
  delete?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IngredientLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  set?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  update?: InputMaybe<Array<IngredientLogUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: InputMaybe<Array<IngredientLogUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: InputMaybe<Array<IngredientLogUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type IngredientLogUpdateManyWithoutLookupInput = {
  connect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientLogCreateOrConnectWithoutLookupInput>>;
  create?: InputMaybe<Array<IngredientLogCreateWithoutLookupInput>>;
  createMany?: InputMaybe<IngredientLogCreateManyLookupInputEnvelope>;
  delete?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IngredientLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  set?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  update?: InputMaybe<Array<IngredientLogUpdateWithWhereUniqueWithoutLookupInput>>;
  updateMany?: InputMaybe<Array<IngredientLogUpdateManyWithWhereWithoutLookupInput>>;
  upsert?: InputMaybe<Array<IngredientLogUpsertWithWhereUniqueWithoutLookupInput>>;
};

export type IngredientLogUpdateManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientLogCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<IngredientLogCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<IngredientLogCreateManyUpdatedByInputEnvelope>;
  delete?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IngredientLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  set?: InputMaybe<Array<IngredientLogWhereUniqueInput>>;
  update?: InputMaybe<Array<IngredientLogUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: InputMaybe<Array<IngredientLogUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: InputMaybe<Array<IngredientLogUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type IngredientLogUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: IngredientLogUpdateWithoutCreatedByInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogUpdateWithWhereUniqueWithoutIngredientInput = {
  data: IngredientLogUpdateWithoutIngredientInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogUpdateWithWhereUniqueWithoutKitchenInput = {
  data: IngredientLogUpdateWithoutKitchenInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogUpdateWithWhereUniqueWithoutLookupInput = {
  data: IngredientLogUpdateWithoutLookupInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: IngredientLogUpdateWithoutUpdatedByInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogUpdateWithoutCreatedByInput = {
  caseSize?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']>;
  ingredient?: InputMaybe<IngredientUpdateOneRequiredWithoutIngredientLogInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutIngredientLogInput>;
  packSize?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientLogUpdatedInput>;
};

export type IngredientLogUpdateWithoutIngredientInput = {
  caseSize?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientLogCreatedInput>;
  data?: InputMaybe<Scalars['Json']>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutIngredientLogInput>;
  packSize?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientLogUpdatedInput>;
};

export type IngredientLogUpdateWithoutKitchenInput = {
  caseSize?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientLogCreatedInput>;
  data?: InputMaybe<Scalars['Json']>;
  ingredient?: InputMaybe<IngredientUpdateOneRequiredWithoutIngredientLogInput>;
  packSize?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientLogUpdatedInput>;
};

export type IngredientLogUpdateWithoutLookupInput = {
  caseSize?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientLogCreatedInput>;
  data?: InputMaybe<Scalars['Json']>;
  ingredient?: InputMaybe<IngredientUpdateOneRequiredWithoutIngredientLogInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutIngredientLogInput>;
  packSize?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientLogUpdatedInput>;
};

export type IngredientLogUpdateWithoutUpdatedByInput = {
  caseSize?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientLogCreatedInput>;
  data?: InputMaybe<Scalars['Json']>;
  ingredient?: InputMaybe<IngredientUpdateOneRequiredWithoutIngredientLogInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutIngredientLogInput>;
  packSize?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IngredientLogUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: IngredientLogCreateWithoutCreatedByInput;
  update: IngredientLogUpdateWithoutCreatedByInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogUpsertWithWhereUniqueWithoutIngredientInput = {
  create: IngredientLogCreateWithoutIngredientInput;
  update: IngredientLogUpdateWithoutIngredientInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogUpsertWithWhereUniqueWithoutKitchenInput = {
  create: IngredientLogCreateWithoutKitchenInput;
  update: IngredientLogUpdateWithoutKitchenInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogUpsertWithWhereUniqueWithoutLookupInput = {
  create: IngredientLogCreateWithoutLookupInput;
  update: IngredientLogUpdateWithoutLookupInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: IngredientLogCreateWithoutUpdatedByInput;
  update: IngredientLogUpdateWithoutUpdatedByInput;
  where: IngredientLogWhereUniqueInput;
};

export type IngredientLogWhereInput = {
  AND?: InputMaybe<Array<IngredientLogWhereInput>>;
  NOT?: InputMaybe<Array<IngredientLogWhereInput>>;
  OR?: InputMaybe<Array<IngredientLogWhereInput>>;
  caseSize?: InputMaybe<FloatNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  ingredient?: InputMaybe<IngredientWhereInput>;
  ingredientId?: InputMaybe<IntFilter>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  packSize?: InputMaybe<FloatNullableFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  type?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type IngredientLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type IngredientOrderByInput = {
  conversionUnit?: InputMaybe<SortOrder>;
  conversionUnitType?: InputMaybe<UnitOrderByInput>;
  conversionUnitTypeId?: InputMaybe<SortOrder>;
  conversionUnitValue?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ingredientLog?: InputMaybe<IngredientLogOrderByRelationAggregateInput>;
  kitchen?: InputMaybe<KitchenOrderByInput>;
  kitchenId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByInput>;
  productId?: InputMaybe<SortOrder>;
  recipes?: InputMaybe<RecipeToIngredientOrderByRelationAggregateInput>;
  stockData?: InputMaybe<SortOrder>;
  unitPrice?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
  wastage?: InputMaybe<SortOrder>;
  wasteAdjustedUnitPrice?: InputMaybe<SortOrder>;
};

export type IngredientOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type IngredientScalarWhereInput = {
  AND?: InputMaybe<Array<IngredientScalarWhereInput>>;
  NOT?: InputMaybe<Array<IngredientScalarWhereInput>>;
  OR?: InputMaybe<Array<IngredientScalarWhereInput>>;
  conversionUnit?: InputMaybe<FloatNullableFilter>;
  conversionUnitTypeId?: InputMaybe<IntNullableFilter>;
  conversionUnitValue?: InputMaybe<FloatNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  kitchenId?: InputMaybe<IntFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  productId?: InputMaybe<IntFilter>;
  stockData?: InputMaybe<JsonNullableFilter>;
  unitPrice?: InputMaybe<FloatNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedById?: InputMaybe<IntFilter>;
  wastage?: InputMaybe<FloatFilter>;
  wasteAdjustedUnitPrice?: InputMaybe<FloatNullableFilter>;
};

export type IngredientUpdateManyMutationInput = {
  conversionUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  conversionUnitValue?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  wastage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  wasteAdjustedUnitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type IngredientUpdateManyWithWhereWithoutConversionUnitTypeInput = {
  data: IngredientUpdateManyMutationInput;
  where: IngredientScalarWhereInput;
};

export type IngredientUpdateManyWithWhereWithoutCreatedByInput = {
  data: IngredientUpdateManyMutationInput;
  where: IngredientScalarWhereInput;
};

export type IngredientUpdateManyWithWhereWithoutKitchenInput = {
  data: IngredientUpdateManyMutationInput;
  where: IngredientScalarWhereInput;
};

export type IngredientUpdateManyWithWhereWithoutProductInput = {
  data: IngredientUpdateManyMutationInput;
  where: IngredientScalarWhereInput;
};

export type IngredientUpdateManyWithWhereWithoutUpdatedByInput = {
  data: IngredientUpdateManyMutationInput;
  where: IngredientScalarWhereInput;
};

export type IngredientUpdateManyWithoutConversionUnitTypeInput = {
  connect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientCreateOrConnectWithoutConversionUnitTypeInput>>;
  create?: InputMaybe<Array<IngredientCreateWithoutConversionUnitTypeInput>>;
  createMany?: InputMaybe<IngredientCreateManyConversionUnitTypeInputEnvelope>;
  delete?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IngredientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  set?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  update?: InputMaybe<Array<IngredientUpdateWithWhereUniqueWithoutConversionUnitTypeInput>>;
  updateMany?: InputMaybe<Array<IngredientUpdateManyWithWhereWithoutConversionUnitTypeInput>>;
  upsert?: InputMaybe<Array<IngredientUpsertWithWhereUniqueWithoutConversionUnitTypeInput>>;
};

export type IngredientUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<IngredientCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<IngredientCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IngredientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  set?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  update?: InputMaybe<Array<IngredientUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<IngredientUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<IngredientUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type IngredientUpdateManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<IngredientCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<IngredientCreateManyKitchenInputEnvelope>;
  delete?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IngredientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  set?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  update?: InputMaybe<Array<IngredientUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: InputMaybe<Array<IngredientUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: InputMaybe<Array<IngredientUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type IngredientUpdateManyWithoutProductInput = {
  connect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<IngredientCreateWithoutProductInput>>;
  createMany?: InputMaybe<IngredientCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IngredientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  set?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  update?: InputMaybe<Array<IngredientUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<IngredientUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<IngredientUpsertWithWhereUniqueWithoutProductInput>>;
};

export type IngredientUpdateManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<IngredientCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<IngredientCreateManyUpdatedByInputEnvelope>;
  delete?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IngredientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  set?: InputMaybe<Array<IngredientWhereUniqueInput>>;
  update?: InputMaybe<Array<IngredientUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: InputMaybe<Array<IngredientUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: InputMaybe<Array<IngredientUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type IngredientUpdateOneRequiredWithoutIngredientLogInput = {
  connect?: InputMaybe<IngredientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<IngredientCreateOrConnectWithoutIngredientLogInput>;
  create?: InputMaybe<IngredientCreateWithoutIngredientLogInput>;
  update?: InputMaybe<IngredientUpdateWithoutIngredientLogInput>;
  upsert?: InputMaybe<IngredientUpsertWithoutIngredientLogInput>;
};

export type IngredientUpdateOneRequiredWithoutRecipesInput = {
  connect?: InputMaybe<IngredientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<IngredientCreateOrConnectWithoutRecipesInput>;
  create?: InputMaybe<IngredientCreateWithoutRecipesInput>;
  update?: InputMaybe<IngredientUpdateWithoutRecipesInput>;
  upsert?: InputMaybe<IngredientUpsertWithoutRecipesInput>;
};

export type IngredientUpdateWithWhereUniqueWithoutConversionUnitTypeInput = {
  data: IngredientUpdateWithoutConversionUnitTypeInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: IngredientUpdateWithoutCreatedByInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientUpdateWithWhereUniqueWithoutKitchenInput = {
  data: IngredientUpdateWithoutKitchenInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientUpdateWithWhereUniqueWithoutProductInput = {
  data: IngredientUpdateWithoutProductInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: IngredientUpdateWithoutUpdatedByInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientUpdateWithoutConversionUnitTypeInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutIngredientInput>;
  conversionUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  conversionUnitValue?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientCreatedInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutIngredientsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutIngredientsInput>;
  recipes?: InputMaybe<RecipeToIngredientUpdateManyWithoutIngredientInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientUpdatedInput>;
  wastage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  wasteAdjustedUnitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type IngredientUpdateWithoutCreatedByInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutIngredientInput>;
  conversionUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  conversionUnitType?: InputMaybe<UnitUpdateOneWithoutIngredientConversionUnitTypeInput>;
  conversionUnitValue?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutIngredientsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutIngredientsInput>;
  recipes?: InputMaybe<RecipeToIngredientUpdateManyWithoutIngredientInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientUpdatedInput>;
  wastage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  wasteAdjustedUnitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type IngredientUpdateWithoutIngredientLogInput = {
  conversionUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  conversionUnitType?: InputMaybe<UnitUpdateOneWithoutIngredientConversionUnitTypeInput>;
  conversionUnitValue?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientCreatedInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutIngredientsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutIngredientsInput>;
  recipes?: InputMaybe<RecipeToIngredientUpdateManyWithoutIngredientInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientUpdatedInput>;
  wastage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  wasteAdjustedUnitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type IngredientUpdateWithoutKitchenInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutIngredientInput>;
  conversionUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  conversionUnitType?: InputMaybe<UnitUpdateOneWithoutIngredientConversionUnitTypeInput>;
  conversionUnitValue?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientCreatedInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutIngredientsInput>;
  recipes?: InputMaybe<RecipeToIngredientUpdateManyWithoutIngredientInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientUpdatedInput>;
  wastage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  wasteAdjustedUnitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type IngredientUpdateWithoutProductInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutIngredientInput>;
  conversionUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  conversionUnitType?: InputMaybe<UnitUpdateOneWithoutIngredientConversionUnitTypeInput>;
  conversionUnitValue?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientCreatedInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutIngredientsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recipes?: InputMaybe<RecipeToIngredientUpdateManyWithoutIngredientInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientUpdatedInput>;
  wastage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  wasteAdjustedUnitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type IngredientUpdateWithoutRecipesInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutIngredientInput>;
  conversionUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  conversionUnitType?: InputMaybe<UnitUpdateOneWithoutIngredientConversionUnitTypeInput>;
  conversionUnitValue?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientCreatedInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutIngredientsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutIngredientsInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientUpdatedInput>;
  wastage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  wasteAdjustedUnitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type IngredientUpdateWithoutUpdatedByInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutIngredientInput>;
  conversionUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  conversionUnitType?: InputMaybe<UnitUpdateOneWithoutIngredientConversionUnitTypeInput>;
  conversionUnitValue?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutIngredientCreatedInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutIngredientsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutIngredientsInput>;
  recipes?: InputMaybe<RecipeToIngredientUpdateManyWithoutIngredientInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  wastage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  wasteAdjustedUnitPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type IngredientUpsertWithWhereUniqueWithoutConversionUnitTypeInput = {
  create: IngredientCreateWithoutConversionUnitTypeInput;
  update: IngredientUpdateWithoutConversionUnitTypeInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: IngredientCreateWithoutCreatedByInput;
  update: IngredientUpdateWithoutCreatedByInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientUpsertWithWhereUniqueWithoutKitchenInput = {
  create: IngredientCreateWithoutKitchenInput;
  update: IngredientUpdateWithoutKitchenInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientUpsertWithWhereUniqueWithoutProductInput = {
  create: IngredientCreateWithoutProductInput;
  update: IngredientUpdateWithoutProductInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: IngredientCreateWithoutUpdatedByInput;
  update: IngredientUpdateWithoutUpdatedByInput;
  where: IngredientWhereUniqueInput;
};

export type IngredientUpsertWithoutIngredientLogInput = {
  create: IngredientCreateWithoutIngredientLogInput;
  update: IngredientUpdateWithoutIngredientLogInput;
};

export type IngredientUpsertWithoutRecipesInput = {
  create: IngredientCreateWithoutRecipesInput;
  update: IngredientUpdateWithoutRecipesInput;
};

export type IngredientWhereInput = {
  AND?: InputMaybe<Array<IngredientWhereInput>>;
  IngredientLog?: InputMaybe<IngredientLogListRelationFilter>;
  NOT?: InputMaybe<Array<IngredientWhereInput>>;
  OR?: InputMaybe<Array<IngredientWhereInput>>;
  conversionUnit?: InputMaybe<FloatNullableFilter>;
  conversionUnitType?: InputMaybe<UnitWhereInput>;
  conversionUnitTypeId?: InputMaybe<IntNullableFilter>;
  conversionUnitValue?: InputMaybe<FloatNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  product?: InputMaybe<ProductWhereInput>;
  productId?: InputMaybe<IntFilter>;
  recipes?: InputMaybe<RecipeToIngredientListRelationFilter>;
  status?: InputMaybe<Status>;
  stockData?: InputMaybe<JsonNullableFilter>;
  unitPrice?: InputMaybe<FloatNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
  wastage?: InputMaybe<FloatFilter>;
  wasteAdjustedUnitPrice?: InputMaybe<FloatNullableFilter>;
};

export type IngredientWhereUniqueInput = {
  id: Scalars['Int'];
};

export enum InsightPeriod {
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week'
}

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['Int']>>;
  has?: InputMaybe<Scalars['Int']>;
  hasEvery?: InputMaybe<Array<Scalars['Int']>>;
  hasSome?: InputMaybe<Array<Scalars['Int']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type InviteDetails = {
  __typename?: 'InviteDetails';
  isExistingUser: Scalars['Boolean'];
  kitchenId: Scalars['Int'];
  kitchenName: Scalars['String'];
};

export type Invoice = Node & {
  __typename?: 'Invoice';
  _cursor: Scalars['ID'];
  approved: Scalars['Boolean'];
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  /** @deprecated Use and extend entryList field */
  entries: Array<InvoiceEntry>;
  entryList: InvoiceEntryConnection;
  id: Scalars['Int'];
  images: Array<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  invoiceTotal?: Maybe<Scalars['Float']>;
  isAuto: Scalars['Boolean'];
  kitchen: Kitchen;
  notes: InvoiceEntryConnection;
  pendingInvoice?: Maybe<PendingInvoice>;
  signedImages: Array<Scalars['String']>;
  supplier: Supplier;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
  vat?: Maybe<Scalars['Float']>;
};


export type InvoiceEntriesArgs = {
  cursor?: InputMaybe<InvoiceEntryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InvoiceEntryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvoiceEntryWhereInput>;
};


export type InvoiceEntryListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type InvoiceNotesArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type InvoiceSignedImagesArgs = {
  download?: InputMaybe<Scalars['Boolean']>;
};

export type InvoiceAggregate = {
  __typename?: 'InvoiceAggregate';
  invoicesByDate: Array<InvoiceByDate>;
  invoicesBySupplier: Array<InvoiceBySupplier>;
  totalSpend: Scalars['Float'];
  totalVat: Scalars['Float'];
};

export type InvoiceByDate = {
  __typename?: 'InvoiceByDate';
  date: Scalars['String'];
  invoices: Array<Invoice>;
  totalSpend: Scalars['Float'];
  totalVat: Scalars['Float'];
};

export type InvoiceBySupplier = {
  __typename?: 'InvoiceBySupplier';
  id: Scalars['Int'];
  invoices: Array<Invoice>;
  name: Scalars['String'];
  totalSpend: Scalars['Float'];
  totalVat: Scalars['Float'];
};

export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  edges: Array<InvoiceEdge>;
  nodes: Array<Invoice>;
  pageInfo: PageInfo;
};

export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  cursor: Scalars['ID'];
  node: Invoice;
};

export type InvoiceEntry = Node & {
  __typename?: 'InvoiceEntry';
  _cursor: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['Int'];
  invoice: Invoice;
  packSize: Scalars['Float'];
  price: Scalars['Float'];
  product?: Maybe<Product>;
  productCode?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unit: Unit;
  unitValue: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
};

export type InvoiceEntryConnection = {
  __typename?: 'InvoiceEntryConnection';
  edges: Array<InvoiceEntryEdge>;
  nodes: Array<InvoiceEntry>;
  pageInfo: PageInfo;
};

export type InvoiceEntryEdge = {
  __typename?: 'InvoiceEntryEdge';
  cursor: Scalars['ID'];
  node: InvoiceEntry;
};

export type InvoiceEntryListRelationFilter = {
  every?: InputMaybe<InvoiceEntryWhereInput>;
  none?: InputMaybe<InvoiceEntryWhereInput>;
  some?: InputMaybe<InvoiceEntryWhereInput>;
};

export type InvoiceEntryOrderByInput = {
  IngredientLog?: InputMaybe<IngredientLogOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoice?: InputMaybe<InvoiceOrderByInput>;
  invoiceId?: InputMaybe<SortOrder>;
  packSize?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByInput>;
  productCode?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<IntFilter>;
  unit?: InputMaybe<UnitOrderByInput>;
  unitId?: InputMaybe<SortOrder>;
  unitValue?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
};

export type InvoiceEntryOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type InvoiceEntryWhereInput = {
  AND?: InputMaybe<Array<InvoiceEntryWhereInput>>;
  IngredientLog?: InputMaybe<IngredientLogListRelationFilter>;
  NOT?: InputMaybe<Array<InvoiceEntryWhereInput>>;
  OR?: InputMaybe<Array<InvoiceEntryWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  invoice?: InputMaybe<InvoiceWhereInput>;
  invoiceId?: InputMaybe<IntFilter>;
  packSize?: InputMaybe<FloatFilter>;
  price?: InputMaybe<FloatFilter>;
  product?: InputMaybe<ProductWhereInput>;
  productCode?: InputMaybe<StringNullableFilter>;
  productId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<IntFilter>;
  unit?: InputMaybe<UnitWhereInput>;
  unitId?: InputMaybe<IntFilter>;
  unitValue?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type InvoiceEntryWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type InvoiceListRelationFilter = {
  every?: InputMaybe<InvoiceWhereInput>;
  none?: InputMaybe<InvoiceWhereInput>;
  some?: InputMaybe<InvoiceWhereInput>;
};

export type InvoiceNote = Node & {
  __typename?: 'InvoiceNote';
  _cursor: Scalars['ID'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  user: User;
};

export type InvoiceOrderByInput = {
  approved?: InputMaybe<SortOrder>;
  approvedAt?: InputMaybe<SortOrder>;
  approvedBy?: InputMaybe<UserOrderByInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  entries?: InputMaybe<InvoiceEntryOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  invoiceDate?: InputMaybe<SortOrder>;
  invoiceId?: InputMaybe<SortOrder>;
  invoiceTotal?: InputMaybe<SortOrder>;
  kitchen?: InputMaybe<KitchenOrderByInput>;
  kitchenId?: InputMaybe<SortOrder>;
  supplier?: InputMaybe<SupplierOrderByInput>;
  supplierId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
  vat?: InputMaybe<SortOrder>;
};

export type InvoiceOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type InvoiceOverview = {
  __typename?: 'InvoiceOverview';
  all: Scalars['Int'];
  approved: Scalars['Int'];
  needsAttention: Scalars['Int'];
  processing: Scalars['Int'];
};

export enum InvoiceRejectionReason {
  IrrelevantDuplicateInvoice = 'irrelevant__duplicate_invoice',
  IrrelevantNotAnInvoice = 'irrelevant__not_an_invoice',
  IrrelevantWrongKitchenOrSite = 'irrelevant__wrong_kitchen_or_site',
  MissingDataCroppedOutOfFrame = 'missing_data__cropped___out_of_frame___',
  MissingDataNoPrices = 'missing_data__no_prices',
  MissingDataNoProducts = 'missing_data__no_products',
  MissingDataNoSupplier = 'missing_data__no_supplier',
  MissingDataObscuredSomethingIsInTheWay = 'missing_data__obscured___something_is_in_the_way___',
  UnreadableHandwrittenIllegible = 'unreadable__handwritten_illegible',
  UnreadablePoorLighting = 'unreadable__poor_lighting',
  UnreadablePoorQualityImage = 'unreadable__poor_quality_image',
  UnreadableUnfocused = 'unreadable__unfocused'
}

export enum InvoiceStatus {
  Completed = 'completed',
  Merged = 'merged',
  Pending = 'pending',
  Processing = 'processing',
  Rejected = 'rejected'
}

export type InvoiceUpdateCreateIngredient = {
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitTypeId?: InputMaybe<Scalars['Int']>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price: Scalars['Float'];
  productId: Scalars['Int'];
  unitValue: Scalars['Float'];
  wastage: Scalars['Float'];
};

export type InvoiceUpdateCreateProduct = {
  code?: InputMaybe<Scalars['String']>;
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitTypeId?: InputMaybe<Scalars['Int']>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price: Scalars['Float'];
  unitId: Scalars['Int'];
  unitValue: Scalars['Float'];
  wastage: Scalars['Float'];
};

export type InvoiceUpdateIngredientProduct = {
  code?: InputMaybe<Scalars['String']>;
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitType?: InputMaybe<Scalars['Int']>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price: Scalars['Float'];
  productId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Float']>;
  unitId: Scalars['Int'];
  unitValue: Scalars['Float'];
  wastage: Scalars['Float'];
};

export type InvoiceUpdateSupplier = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type InvoiceUpdateUnchangedProduct = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price: Scalars['Float'];
  productId: Scalars['Int'];
  unitId: Scalars['Int'];
  unitValue: Scalars['Float'];
};

export type InvoiceUpdateUpdateIngredient = {
  changes: Array<Scalars['String']>;
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitTypeId?: InputMaybe<Scalars['Int']>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  packSize?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  productId: Scalars['Int'];
  unitValue?: InputMaybe<Scalars['Float']>;
  wastage: Scalars['Float'];
};

export type InvoiceUpdateUpdateProduct = {
  changes: Array<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  packSize?: InputMaybe<Scalars['Float']>;
  productId: Scalars['Int'];
  unitId?: InputMaybe<Scalars['Int']>;
  unitValue?: InputMaybe<Scalars['Float']>;
};

export type InvoiceWhereInput = {
  AND?: InputMaybe<Array<InvoiceWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceWhereInput>>;
  OR?: InputMaybe<Array<InvoiceWhereInput>>;
  approved?: InputMaybe<BoolNullableFilter>;
  approvedAt?: InputMaybe<DateTimeNullableFilter>;
  approvedBy?: InputMaybe<UserWhereInput>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  entries?: InputMaybe<InvoiceEntryListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringNullableListFilter>;
  invoiceDate?: InputMaybe<DateTimeNullableFilter>;
  invoiceId?: InputMaybe<StringNullableFilter>;
  invoiceTotal?: InputMaybe<FloatNullableFilter>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  pendingInvoice?: InputMaybe<PendingInvoiceWhereInput>;
  some?: InputMaybe<InvoiceWhereInput>;
  supplier?: InputMaybe<SupplierWhereInput>;
  supplierId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
  vat?: InputMaybe<FloatNullableFilter>;
};

export type InvoiceWhereUniqueInput = {
  id: Scalars['Int'];
};

export type JobResponsibility = {
  __typename?: 'JobResponsibility';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type JsonNullableFilter = {
  equals?: InputMaybe<Scalars['Json']>;
  not?: InputMaybe<Scalars['Json']>;
};

export type Kitchen = Node & {
  __typename?: 'Kitchen';
  _cursor: Scalars['ID'];
  account?: Maybe<Account>;
  activatedPixelFiredTime?: Maybe<Scalars['DateTime']>;
  activatedTime?: Maybe<Scalars['DateTime']>;
  checkedPriceChangeAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  deliveryAddressList: KitchenToAddressConnection;
  /** @deprecated Use and extend deliveryAddressList field */
  deliveryAddresses: Array<KitchenToAddress>;
  dishCount: Scalars['Int'];
  dishList: DishConnection;
  /** @deprecated Use and extend dishList field */
  dishes: Array<Dish>;
  featureGuards: FeatureGuards;
  hasInvoices?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  ingredientCount: Scalars['Int'];
  ingredientList: IngredientConnection;
  /** @deprecated Use and extend ingredientList field */
  ingredients: Array<Ingredient>;
  integrationState: KitchenIntegrationState;
  invites: Array<KitchenInvite>;
  invoiceCode?: Maybe<Scalars['String']>;
  invoiceCount: Scalars['Int'];
  invoiceRule?: Maybe<KitchenInvoiceRule>;
  invoiceStatusCount?: Maybe<Scalars['Int']>;
  invoices: Array<Invoice>;
  isDemoAccount?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  joinCode?: Maybe<Scalars['String']>;
  kitchenToCurrentUser: KitchenToUser;
  menuCount: Scalars['Int'];
  menuList: MenuConnection;
  /** @deprecated Use and extend menuList field */
  menus: Array<Menu>;
  name: Scalars['String'];
  onboardState?: Maybe<OnboardState>;
  orderCount: Scalars['Int'];
  pendingInvoiceList: PendingInvoiceConnection;
  /** @deprecated Use and extend pendingInvoiceList field */
  pendingInvoices: Array<PendingInvoice>;
  permissions?: Maybe<KitchenPermissions>;
  posIncludeGratuity: Scalars['Boolean'];
  posIncludeRefunds: Scalars['Boolean'];
  posIncludeServiceCharge: Scalars['Boolean'];
  posLocations: Array<PosLocation>;
  priceChangeCount: Scalars['Int'];
  priceChangesList: PriceChangesOverview;
  recipeCount: Scalars['Int'];
  recipeList: RecipeConnection;
  /** @deprecated Use and extend recipeList field */
  recipes: Array<Recipe>;
  salesCount: Scalars['Int'];
  setupCompletedAt?: Maybe<Scalars['DateTime']>;
  status: Status;
  stock: Array<Stock>;
  stocktakeCount: Scalars['Int'];
  supplierDetailList: KitchenSupplierDetailConnection;
  /** @deprecated Use and extend supplierDetailList field */
  supplierDetails: Array<KitchenSupplierDetail>;
  supplierList: KitchenToSupplierConnection;
  /** @deprecated Use and extend supplierList field */
  suppliers: Array<KitchenToSupplier>;
  supportLink?: Maybe<Scalars['String']>;
  todoCount: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
  userList: KitchenToUserConnection;
  userPermissions: Array<Scalars['String']>;
  /** @deprecated Use and extend userList field */
  users: Array<KitchenToUser>;
};


export type KitchenDeliveryAddressListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type KitchenDeliveryAddressesArgs = {
  cursor?: InputMaybe<KitchenToAddressWhereUniqueInput>;
  orderBy?: InputMaybe<Array<KitchenToAddressOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KitchenToAddressWhereInput>;
};


export type KitchenDishListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type KitchenDishesArgs = {
  cursor?: InputMaybe<DishWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DishOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DishWhereInput>;
};


export type KitchenIngredientListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type KitchenIngredientsArgs = {
  cursor?: InputMaybe<IngredientWhereUniqueInput>;
  orderBy?: InputMaybe<Array<IngredientOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IngredientWhereInput>;
};


export type KitchenInvoiceStatusCountArgs = {
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  status: EnumInvoiceStatusFilter;
};


export type KitchenInvoicesArgs = {
  where?: InputMaybe<InvoiceListRelationFilter>;
};


export type KitchenMenuListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type KitchenMenusArgs = {
  cursor?: InputMaybe<MenuWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MenuOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MenuWhereInput>;
};


export type KitchenPendingInvoiceListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type KitchenPendingInvoicesArgs = {
  where?: InputMaybe<PendingInvoiceListRelationFilter>;
};


export type KitchenPriceChangesListArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type KitchenRecipeListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type KitchenRecipesArgs = {
  cursor?: InputMaybe<RecipeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RecipeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RecipeWhereInput>;
};


export type KitchenSupplierDetailListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type KitchenSupplierDetailsArgs = {
  cursor?: InputMaybe<KitchenSupplierDetailWhereUniqueInput>;
  orderBy?: InputMaybe<Array<KitchenSupplierDetailOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KitchenSupplierDetailWhereInput>;
};


export type KitchenSupplierListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type KitchenSuppliersArgs = {
  cursor?: InputMaybe<KitchenToSupplierWhereUniqueInput>;
  orderBy?: InputMaybe<Array<KitchenToSupplierOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KitchenToSupplierWhereInput>;
};


export type KitchenUserListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type KitchenUsersArgs = {
  cursor?: InputMaybe<KitchenToUserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<KitchenToUserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KitchenToUserWhereInput>;
};

export type KitchenConnection = {
  __typename?: 'KitchenConnection';
  edges: Array<KitchenEdge>;
  nodes: Array<Kitchen>;
  pageInfo: PageInfo;
};

export type KitchenCreateManyCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  joinCode?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type KitchenCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<KitchenCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type KitchenCreateManyUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  joinCode?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type KitchenCreateManyUpdatedByInputEnvelope = {
  data?: InputMaybe<Array<KitchenCreateManyUpdatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type KitchenCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<KitchenCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<KitchenCreateManyCreatedByInputEnvelope>;
};

export type KitchenCreateNestedManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<KitchenCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<KitchenCreateManyUpdatedByInputEnvelope>;
};

export type KitchenCreateNestedOneWithoutDishesInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutDishesInput>;
  create?: InputMaybe<KitchenCreateWithoutDishesInput>;
};

export type KitchenCreateNestedOneWithoutIngredientLogInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutIngredientLogInput>;
  create?: InputMaybe<KitchenCreateWithoutIngredientLogInput>;
};

export type KitchenCreateNestedOneWithoutIngredientsInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutIngredientsInput>;
  create?: InputMaybe<KitchenCreateWithoutIngredientsInput>;
};

export type KitchenCreateNestedOneWithoutMenusInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutMenusInput>;
  create?: InputMaybe<KitchenCreateWithoutMenusInput>;
};

export type KitchenCreateNestedOneWithoutPurchaseOrderInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutPurchaseOrderInput>;
  create?: InputMaybe<KitchenCreateWithoutPurchaseOrderInput>;
};

export type KitchenCreateNestedOneWithoutRecipesInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutRecipesInput>;
  create?: InputMaybe<KitchenCreateWithoutRecipesInput>;
};

export type KitchenCreateNestedOneWithoutSupplierDetailsInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutSupplierDetailsInput>;
  create?: InputMaybe<KitchenCreateWithoutSupplierDetailsInput>;
};

export type KitchenCreateNestedOneWithoutSuppliersInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutSuppliersInput>;
  create?: InputMaybe<KitchenCreateWithoutSuppliersInput>;
};

export type KitchenCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<KitchenCreateWithoutUsersInput>;
};

export type KitchenCreateOrConnectWithoutCreatedByInput = {
  create: KitchenCreateWithoutCreatedByInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutDishesInput = {
  create: KitchenCreateWithoutDishesInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutIngredientLogInput = {
  create: KitchenCreateWithoutIngredientLogInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutIngredientsInput = {
  create: KitchenCreateWithoutIngredientsInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutMenusInput = {
  create: KitchenCreateWithoutMenusInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutPurchaseOrderInput = {
  create: KitchenCreateWithoutPurchaseOrderInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutRecipesInput = {
  create: KitchenCreateWithoutRecipesInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutSupplierDetailsInput = {
  create: KitchenCreateWithoutSupplierDetailsInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutSuppliersInput = {
  create: KitchenCreateWithoutSuppliersInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutUpdatedByInput = {
  create: KitchenCreateWithoutUpdatedByInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutUsersInput = {
  create: KitchenCreateWithoutUsersInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateWithoutCreatedByInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderCreateNestedManyWithoutKitchenInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deliveryAddresses?: InputMaybe<KitchenToAddressCreateNestedManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishCreateNestedManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutKitchenInput>;
  joinCode?: InputMaybe<Scalars['String']>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  recipes?: InputMaybe<RecipeCreateNestedManyWithoutKitchenInput>;
  status?: InputMaybe<Status>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutKitchenUpdatedInput;
  users?: InputMaybe<KitchenToUserCreateNestedManyWithoutKitchenInput>;
};

export type KitchenCreateWithoutDishesInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderCreateNestedManyWithoutKitchenInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutKitchenCreatedInput;
  deliveryAddresses?: InputMaybe<KitchenToAddressCreateNestedManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutKitchenInput>;
  joinCode?: InputMaybe<Scalars['String']>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  recipes?: InputMaybe<RecipeCreateNestedManyWithoutKitchenInput>;
  status?: InputMaybe<Status>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutKitchenUpdatedInput;
  users?: InputMaybe<KitchenToUserCreateNestedManyWithoutKitchenInput>;
};

export type KitchenCreateWithoutIngredientLogInput = {
  PurchaseOrder?: InputMaybe<PurchaseOrderCreateNestedManyWithoutKitchenInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutKitchenCreatedInput;
  deliveryAddresses?: InputMaybe<KitchenToAddressCreateNestedManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishCreateNestedManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutKitchenInput>;
  joinCode?: InputMaybe<Scalars['String']>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  recipes?: InputMaybe<RecipeCreateNestedManyWithoutKitchenInput>;
  status?: InputMaybe<Status>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutKitchenUpdatedInput;
  users?: InputMaybe<KitchenToUserCreateNestedManyWithoutKitchenInput>;
};

export type KitchenCreateWithoutIngredientsInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderCreateNestedManyWithoutKitchenInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutKitchenCreatedInput;
  deliveryAddresses?: InputMaybe<KitchenToAddressCreateNestedManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishCreateNestedManyWithoutKitchenInput>;
  joinCode?: InputMaybe<Scalars['String']>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  recipes?: InputMaybe<RecipeCreateNestedManyWithoutKitchenInput>;
  status?: InputMaybe<Status>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutKitchenUpdatedInput;
  users?: InputMaybe<KitchenToUserCreateNestedManyWithoutKitchenInput>;
};

export type KitchenCreateWithoutMenusInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderCreateNestedManyWithoutKitchenInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutKitchenCreatedInput;
  deliveryAddresses?: InputMaybe<KitchenToAddressCreateNestedManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishCreateNestedManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutKitchenInput>;
  joinCode?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recipes?: InputMaybe<RecipeCreateNestedManyWithoutKitchenInput>;
  status?: InputMaybe<Status>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutKitchenUpdatedInput;
  users?: InputMaybe<KitchenToUserCreateNestedManyWithoutKitchenInput>;
};

export type KitchenCreateWithoutPurchaseOrderInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutKitchenInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutKitchenCreatedInput;
  deliveryAddresses?: InputMaybe<KitchenToAddressCreateNestedManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishCreateNestedManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutKitchenInput>;
  joinCode?: InputMaybe<Scalars['String']>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  recipes?: InputMaybe<RecipeCreateNestedManyWithoutKitchenInput>;
  status?: InputMaybe<Status>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutKitchenUpdatedInput;
  users?: InputMaybe<KitchenToUserCreateNestedManyWithoutKitchenInput>;
};

export type KitchenCreateWithoutRecipesInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderCreateNestedManyWithoutKitchenInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutKitchenCreatedInput;
  deliveryAddresses?: InputMaybe<KitchenToAddressCreateNestedManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishCreateNestedManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutKitchenInput>;
  joinCode?: InputMaybe<Scalars['String']>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutKitchenUpdatedInput;
  users?: InputMaybe<KitchenToUserCreateNestedManyWithoutKitchenInput>;
};

export type KitchenCreateWithoutSupplierDetailsInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderCreateNestedManyWithoutKitchenInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutKitchenCreatedInput;
  deliveryAddresses?: InputMaybe<KitchenToAddressCreateNestedManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishCreateNestedManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutKitchenInput>;
  joinCode?: InputMaybe<Scalars['String']>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  recipes?: InputMaybe<RecipeCreateNestedManyWithoutKitchenInput>;
  status?: InputMaybe<Status>;
  suppliers?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutKitchenUpdatedInput;
  users?: InputMaybe<KitchenToUserCreateNestedManyWithoutKitchenInput>;
};

export type KitchenCreateWithoutSuppliersInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderCreateNestedManyWithoutKitchenInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutKitchenCreatedInput;
  deliveryAddresses?: InputMaybe<KitchenToAddressCreateNestedManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishCreateNestedManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutKitchenInput>;
  joinCode?: InputMaybe<Scalars['String']>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  recipes?: InputMaybe<RecipeCreateNestedManyWithoutKitchenInput>;
  status?: InputMaybe<Status>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutKitchenUpdatedInput;
  users?: InputMaybe<KitchenToUserCreateNestedManyWithoutKitchenInput>;
};

export type KitchenCreateWithoutUpdatedByInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderCreateNestedManyWithoutKitchenInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutKitchenCreatedInput;
  deliveryAddresses?: InputMaybe<KitchenToAddressCreateNestedManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishCreateNestedManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutKitchenInput>;
  joinCode?: InputMaybe<Scalars['String']>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  recipes?: InputMaybe<RecipeCreateNestedManyWithoutKitchenInput>;
  status?: InputMaybe<Status>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<KitchenToUserCreateNestedManyWithoutKitchenInput>;
};

export type KitchenCreateWithoutUsersInput = {
  IngredientLog?: InputMaybe<IngredientLogCreateNestedManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderCreateNestedManyWithoutKitchenInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutKitchenCreatedInput;
  deliveryAddresses?: InputMaybe<KitchenToAddressCreateNestedManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishCreateNestedManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutKitchenInput>;
  joinCode?: InputMaybe<Scalars['String']>;
  menus?: InputMaybe<MenuCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  recipes?: InputMaybe<RecipeCreateNestedManyWithoutKitchenInput>;
  status?: InputMaybe<Status>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutKitchenUpdatedInput;
};

export type KitchenEdge = {
  __typename?: 'KitchenEdge';
  cursor: Scalars['ID'];
  node: Kitchen;
};

export type KitchenIntegrationState = {
  __typename?: 'KitchenIntegrationState';
  eposNow?: Maybe<PosStatus>;
  square?: Maybe<PosStatus>;
};

export type KitchenInvite = {
  __typename?: 'KitchenInvite';
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  kitchen: Kitchen;
  kitchenId: Scalars['Int'];
  phoneNumberNational: Scalars['String'];
  role: Role;
  roleId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type KitchenInvoiceRule = {
  __typename?: 'KitchenInvoiceRule';
  approvalThresholdValue?: Maybe<Scalars['Float']>;
  approveAllInvoices: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  kitchen: Kitchen;
  kitchenId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type KitchenListRelationFilter = {
  every?: InputMaybe<KitchenWhereInput>;
  none?: InputMaybe<KitchenWhereInput>;
  some?: InputMaybe<KitchenWhereInput>;
};

export type KitchenOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  deliveryAddresses?: InputMaybe<KitchenToAddressOrderByRelationAggregateInput>;
  dishes?: InputMaybe<DishOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  ingredientLog?: InputMaybe<IngredientLogOrderByRelationAggregateInput>;
  ingredients?: InputMaybe<IngredientOrderByRelationAggregateInput>;
  joinCode?: InputMaybe<SortOrder>;
  menus?: InputMaybe<MenuOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  purchaseOrder?: InputMaybe<PurchaseOrderOrderByRelationAggregateInput>;
  recipes?: InputMaybe<RecipeOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailOrderByRelationAggregateInput>;
  suppliers?: InputMaybe<KitchenToSupplierOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
  users?: InputMaybe<KitchenToUserOrderByRelationAggregateInput>;
};

export type KitchenOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type KitchenPermissions = {
  __typename?: 'KitchenPermissions';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  foodFlash: Scalars['Boolean'];
  ordering: Scalars['Boolean'];
  requests?: Maybe<Scalars['Json']>;
  stock: Scalars['Boolean'];
  tier?: Maybe<Scalars['String']>;
  trialExpiresAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
};

export type KitchenPermissionsUpdatedInput = {
  foodFlash?: InputMaybe<Scalars['Boolean']>;
  kitchenId?: InputMaybe<Scalars['Int']>;
  ordering?: InputMaybe<Scalars['Boolean']>;
  stock?: InputMaybe<Scalars['Boolean']>;
};

export type KitchenPrivate = {
  __typename?: 'KitchenPrivate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type KitchenScalarWhereInput = {
  AND?: InputMaybe<Array<KitchenScalarWhereInput>>;
  NOT?: InputMaybe<Array<KitchenScalarWhereInput>>;
  OR?: InputMaybe<Array<KitchenScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  joinCode?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedById?: InputMaybe<IntFilter>;
};

export type KitchenSetupState = {
  __typename?: 'KitchenSetupState';
  addedOneDish: Scalars['Boolean'];
  addedOneMenu: Scalars['Boolean'];
  addedOneOrder: Scalars['Boolean'];
  addedOneSale: Scalars['Boolean'];
  addedOneStockSheet: Scalars['Boolean'];
  addedOneTeamMember: Scalars['Boolean'];
  addedSixInvoices: Scalars['Boolean'];
  automatedOneInvoice: Scalars['Boolean'];
  checkedPriceChanges: Scalars['Boolean'];
};

export enum KitchenSupplierDataType {
  Email = 'email',
  Notes = 'notes',
  Phone = 'phone'
}

export type KitchenSupplierDetail = Node & {
  __typename?: 'KitchenSupplierDetail';
  _cursor: Scalars['ID'];
  id: Scalars['Int'];
  kitchen: Kitchen;
  supplier: Supplier;
  type: KitchenSupplierDataType;
  value: Scalars['String'];
};

export type KitchenSupplierDetailConnection = {
  __typename?: 'KitchenSupplierDetailConnection';
  edges: Array<KitchenSupplierDetailEdge>;
  nodes: Array<KitchenSupplierDetail>;
  pageInfo: PageInfo;
};

export type KitchenSupplierDetailCreateManyKitchenInput = {
  id?: InputMaybe<Scalars['Int']>;
  supplierId: Scalars['Int'];
  type: KitchenSupplierDataType;
  value: Scalars['String'];
};

export type KitchenSupplierDetailCreateManyKitchenInputEnvelope = {
  data?: InputMaybe<Array<KitchenSupplierDetailCreateManyKitchenInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type KitchenSupplierDetailCreateManySupplierInput = {
  id?: InputMaybe<Scalars['Int']>;
  kitchenId: Scalars['Int'];
  type: KitchenSupplierDataType;
  value: Scalars['String'];
};

export type KitchenSupplierDetailCreateManySupplierInputEnvelope = {
  data?: InputMaybe<Array<KitchenSupplierDetailCreateManySupplierInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type KitchenSupplierDetailCreateNestedManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<KitchenSupplierDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenSupplierDetailCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<KitchenSupplierDetailCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<KitchenSupplierDetailCreateManyKitchenInputEnvelope>;
};

export type KitchenSupplierDetailCreateNestedManyWithoutSupplierInput = {
  connect?: InputMaybe<Array<KitchenSupplierDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenSupplierDetailCreateOrConnectWithoutSupplierInput>>;
  create?: InputMaybe<Array<KitchenSupplierDetailCreateWithoutSupplierInput>>;
  createMany?: InputMaybe<KitchenSupplierDetailCreateManySupplierInputEnvelope>;
};

export type KitchenSupplierDetailCreateOrConnectWithoutKitchenInput = {
  create: KitchenSupplierDetailCreateWithoutKitchenInput;
  where: KitchenSupplierDetailWhereUniqueInput;
};

export type KitchenSupplierDetailCreateOrConnectWithoutSupplierInput = {
  create: KitchenSupplierDetailCreateWithoutSupplierInput;
  where: KitchenSupplierDetailWhereUniqueInput;
};

export type KitchenSupplierDetailCreateWithoutKitchenInput = {
  supplier: SupplierCreateNestedOneWithoutKitchenDetailsInput;
  type: KitchenSupplierDataType;
  value: Scalars['String'];
};

export type KitchenSupplierDetailCreateWithoutSupplierInput = {
  kitchen: KitchenCreateNestedOneWithoutSupplierDetailsInput;
  type: KitchenSupplierDataType;
  value: Scalars['String'];
};

export type KitchenSupplierDetailEdge = {
  __typename?: 'KitchenSupplierDetailEdge';
  cursor: Scalars['ID'];
  node: KitchenSupplierDetail;
};

export type KitchenSupplierDetailListRelationFilter = {
  every?: InputMaybe<KitchenSupplierDetailWhereInput>;
  none?: InputMaybe<KitchenSupplierDetailWhereInput>;
  some?: InputMaybe<KitchenSupplierDetailWhereInput>;
};

export type KitchenSupplierDetailOrderByInput = {
  id?: InputMaybe<SortOrder>;
  kitchen?: InputMaybe<KitchenOrderByInput>;
  kitchenId?: InputMaybe<SortOrder>;
  supplier?: InputMaybe<SupplierOrderByInput>;
  supplierId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type KitchenSupplierDetailOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type KitchenSupplierDetailScalarWhereInput = {
  AND?: InputMaybe<Array<KitchenSupplierDetailScalarWhereInput>>;
  NOT?: InputMaybe<Array<KitchenSupplierDetailScalarWhereInput>>;
  OR?: InputMaybe<Array<KitchenSupplierDetailScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  kitchenId?: InputMaybe<IntFilter>;
  supplierId?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumKitchenSupplierDataTypeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type KitchenSupplierDetailUpdateManyMutationInput = {
  type?: InputMaybe<EnumKitchenSupplierDataTypeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type KitchenSupplierDetailUpdateManyWithWhereWithoutKitchenInput = {
  data: KitchenSupplierDetailUpdateManyMutationInput;
  where: KitchenSupplierDetailScalarWhereInput;
};

export type KitchenSupplierDetailUpdateManyWithWhereWithoutSupplierInput = {
  data: KitchenSupplierDetailUpdateManyMutationInput;
  where: KitchenSupplierDetailScalarWhereInput;
};

export type KitchenSupplierDetailUpdateManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<KitchenSupplierDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenSupplierDetailCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<KitchenSupplierDetailCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<KitchenSupplierDetailCreateManyKitchenInputEnvelope>;
  delete?: InputMaybe<Array<KitchenSupplierDetailWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<KitchenSupplierDetailScalarWhereInput>>;
  disconnect?: InputMaybe<Array<KitchenSupplierDetailWhereUniqueInput>>;
  set?: InputMaybe<Array<KitchenSupplierDetailWhereUniqueInput>>;
  update?: InputMaybe<Array<KitchenSupplierDetailUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: InputMaybe<Array<KitchenSupplierDetailUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: InputMaybe<Array<KitchenSupplierDetailUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type KitchenSupplierDetailUpdateManyWithoutSupplierInput = {
  connect?: InputMaybe<Array<KitchenSupplierDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenSupplierDetailCreateOrConnectWithoutSupplierInput>>;
  create?: InputMaybe<Array<KitchenSupplierDetailCreateWithoutSupplierInput>>;
  createMany?: InputMaybe<KitchenSupplierDetailCreateManySupplierInputEnvelope>;
  delete?: InputMaybe<Array<KitchenSupplierDetailWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<KitchenSupplierDetailScalarWhereInput>>;
  disconnect?: InputMaybe<Array<KitchenSupplierDetailWhereUniqueInput>>;
  set?: InputMaybe<Array<KitchenSupplierDetailWhereUniqueInput>>;
  update?: InputMaybe<Array<KitchenSupplierDetailUpdateWithWhereUniqueWithoutSupplierInput>>;
  updateMany?: InputMaybe<Array<KitchenSupplierDetailUpdateManyWithWhereWithoutSupplierInput>>;
  upsert?: InputMaybe<Array<KitchenSupplierDetailUpsertWithWhereUniqueWithoutSupplierInput>>;
};

export type KitchenSupplierDetailUpdateWithWhereUniqueWithoutKitchenInput = {
  data: KitchenSupplierDetailUpdateWithoutKitchenInput;
  where: KitchenSupplierDetailWhereUniqueInput;
};

export type KitchenSupplierDetailUpdateWithWhereUniqueWithoutSupplierInput = {
  data: KitchenSupplierDetailUpdateWithoutSupplierInput;
  where: KitchenSupplierDetailWhereUniqueInput;
};

export type KitchenSupplierDetailUpdateWithoutKitchenInput = {
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutKitchenDetailsInput>;
  type?: InputMaybe<EnumKitchenSupplierDataTypeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type KitchenSupplierDetailUpdateWithoutSupplierInput = {
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutSupplierDetailsInput>;
  type?: InputMaybe<EnumKitchenSupplierDataTypeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type KitchenSupplierDetailUpsertWithWhereUniqueWithoutKitchenInput = {
  create: KitchenSupplierDetailCreateWithoutKitchenInput;
  update: KitchenSupplierDetailUpdateWithoutKitchenInput;
  where: KitchenSupplierDetailWhereUniqueInput;
};

export type KitchenSupplierDetailUpsertWithWhereUniqueWithoutSupplierInput = {
  create: KitchenSupplierDetailCreateWithoutSupplierInput;
  update: KitchenSupplierDetailUpdateWithoutSupplierInput;
  where: KitchenSupplierDetailWhereUniqueInput;
};

export type KitchenSupplierDetailWhereInput = {
  AND?: InputMaybe<Array<KitchenSupplierDetailWhereInput>>;
  NOT?: InputMaybe<Array<KitchenSupplierDetailWhereInput>>;
  OR?: InputMaybe<Array<KitchenSupplierDetailWhereInput>>;
  id?: InputMaybe<IntFilter>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  supplier?: InputMaybe<SupplierWhereInput>;
  supplierId?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumKitchenSupplierDataTypeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type KitchenSupplierDetailWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type KitchenToAddress = Node & {
  __typename?: 'KitchenToAddress';
  _cursor: Scalars['ID'];
  address: Address;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  id: Scalars['Int'];
  kitchen: Kitchen;
  mainAddress: Scalars['Boolean'];
  status: Status;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type KitchenToAddressConnection = {
  __typename?: 'KitchenToAddressConnection';
  edges: Array<KitchenToAddressEdge>;
  nodes: Array<KitchenToAddress>;
  pageInfo: PageInfo;
};

export type KitchenToAddressCreateManyKitchenInput = {
  addressId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type KitchenToAddressCreateManyKitchenInputEnvelope = {
  data?: InputMaybe<Array<KitchenToAddressCreateManyKitchenInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type KitchenToAddressCreateNestedManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<KitchenToAddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenToAddressCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<KitchenToAddressCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<KitchenToAddressCreateManyKitchenInputEnvelope>;
};

export type KitchenToAddressCreateOrConnectWithoutKitchenInput = {
  create: KitchenToAddressCreateWithoutKitchenInput;
  where: KitchenToAddressWhereUniqueInput;
};

export type KitchenToAddressCreateWithoutKitchenInput = {
  address: AddressCreateNestedOneWithoutKitchenToAddressInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type KitchenToAddressEdge = {
  __typename?: 'KitchenToAddressEdge';
  cursor: Scalars['ID'];
  node: KitchenToAddress;
};

export type KitchenToAddressKitchenIdAddressIdCompoundUniqueInput = {
  addressId: Scalars['Int'];
  kitchenId: Scalars['Int'];
};

export type KitchenToAddressListRelationFilter = {
  every?: InputMaybe<KitchenToAddressWhereInput>;
  none?: InputMaybe<KitchenToAddressWhereInput>;
  some?: InputMaybe<KitchenToAddressWhereInput>;
};

export type KitchenToAddressOrderByInput = {
  address?: InputMaybe<AddressOrderByInput>;
  addressId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  kitchen?: InputMaybe<KitchenOrderByInput>;
  kitchenId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type KitchenToAddressOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type KitchenToAddressScalarWhereInput = {
  AND?: InputMaybe<Array<KitchenToAddressScalarWhereInput>>;
  NOT?: InputMaybe<Array<KitchenToAddressScalarWhereInput>>;
  OR?: InputMaybe<Array<KitchenToAddressScalarWhereInput>>;
  addressId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  kitchenId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type KitchenToAddressUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenToAddressUpdateManyWithWhereWithoutKitchenInput = {
  data: KitchenToAddressUpdateManyMutationInput;
  where: KitchenToAddressScalarWhereInput;
};

export type KitchenToAddressUpdateManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<KitchenToAddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenToAddressCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<KitchenToAddressCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<KitchenToAddressCreateManyKitchenInputEnvelope>;
  delete?: InputMaybe<Array<KitchenToAddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<KitchenToAddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<KitchenToAddressWhereUniqueInput>>;
  set?: InputMaybe<Array<KitchenToAddressWhereUniqueInput>>;
  update?: InputMaybe<Array<KitchenToAddressUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: InputMaybe<Array<KitchenToAddressUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: InputMaybe<Array<KitchenToAddressUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type KitchenToAddressUpdateWithWhereUniqueWithoutKitchenInput = {
  data: KitchenToAddressUpdateWithoutKitchenInput;
  where: KitchenToAddressWhereUniqueInput;
};

export type KitchenToAddressUpdateWithoutKitchenInput = {
  address?: InputMaybe<AddressUpdateOneRequiredWithoutKitchenToAddressInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenToAddressUpsertWithWhereUniqueWithoutKitchenInput = {
  create: KitchenToAddressCreateWithoutKitchenInput;
  update: KitchenToAddressUpdateWithoutKitchenInput;
  where: KitchenToAddressWhereUniqueInput;
};

export type KitchenToAddressWhereInput = {
  AND?: InputMaybe<Array<KitchenToAddressWhereInput>>;
  NOT?: InputMaybe<Array<KitchenToAddressWhereInput>>;
  OR?: InputMaybe<Array<KitchenToAddressWhereInput>>;
  address?: InputMaybe<AddressWhereInput>;
  addressId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type KitchenToAddressWhereUniqueInput = {
  kitchenId_addressId?: InputMaybe<KitchenToAddressKitchenIdAddressIdCompoundUniqueInput>;
};

export type KitchenToPosAccount = Node & {
  __typename?: 'KitchenToPosAccount';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  kitchen: Kitchen;
  kitchenId: Scalars['Int'];
  posAccount: PosAccount;
  posAccountId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type KitchenToPosCategory = {
  __typename?: 'KitchenToPosCategory';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  kitchen: Kitchen;
  kitchenId: Scalars['Int'];
  posCategory?: Maybe<PosCategory>;
  posCategoryId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type KitchenToPosLocation = Node & {
  __typename?: 'KitchenToPosLocation';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  kitchen: Kitchen;
  kitchenId: Scalars['Int'];
  posLocation: PosLocation;
  posLocationId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type KitchenToPosProduct = Node & {
  __typename?: 'KitchenToPosProduct';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  dish?: Maybe<Dish>;
  dishId: Scalars['Int'];
  id: Scalars['Int'];
  kitchen: Kitchen;
  kitchenId: Scalars['Int'];
  posProduct: PosProduct;
  posProductId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type KitchenToSupplier = Node & {
  __typename?: 'KitchenToSupplier';
  _cursor: Scalars['ID'];
  accountReference?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  emails: Array<Scalars['String']>;
  id: Scalars['Int'];
  ingredientsCount: Scalars['Int'];
  kitchen: Kitchen;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  supplier: Supplier;
  updatedAt: Scalars['DateTime'];
};

export type KitchenToSupplierConnection = {
  __typename?: 'KitchenToSupplierConnection';
  edges: Array<KitchenToSupplierEdge>;
  nodes: Array<KitchenToSupplier>;
  pageInfo: PageInfo;
};

export type KitchenToSupplierCreateManyKitchenInput = {
  accountReference?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  supplierId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type KitchenToSupplierCreateManyKitchenInputEnvelope = {
  data?: InputMaybe<Array<KitchenToSupplierCreateManyKitchenInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type KitchenToSupplierCreateManySupplierInput = {
  accountReference?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  kitchenId: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type KitchenToSupplierCreateManySupplierInputEnvelope = {
  data?: InputMaybe<Array<KitchenToSupplierCreateManySupplierInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type KitchenToSupplierCreateNestedManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<KitchenToSupplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenToSupplierCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<KitchenToSupplierCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<KitchenToSupplierCreateManyKitchenInputEnvelope>;
};

export type KitchenToSupplierCreateNestedManyWithoutSupplierInput = {
  connect?: InputMaybe<Array<KitchenToSupplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenToSupplierCreateOrConnectWithoutSupplierInput>>;
  create?: InputMaybe<Array<KitchenToSupplierCreateWithoutSupplierInput>>;
  createMany?: InputMaybe<KitchenToSupplierCreateManySupplierInputEnvelope>;
};

export type KitchenToSupplierCreateOrConnectWithoutKitchenInput = {
  create: KitchenToSupplierCreateWithoutKitchenInput;
  where: KitchenToSupplierWhereUniqueInput;
};

export type KitchenToSupplierCreateOrConnectWithoutSupplierInput = {
  create: KitchenToSupplierCreateWithoutSupplierInput;
  where: KitchenToSupplierWhereUniqueInput;
};

export type KitchenToSupplierCreateWithoutKitchenInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  supplier: SupplierCreateNestedOneWithoutKitchensInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type KitchenToSupplierCreateWithoutSupplierInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  kitchen: KitchenCreateNestedOneWithoutSuppliersInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type KitchenToSupplierEdge = {
  __typename?: 'KitchenToSupplierEdge';
  cursor: Scalars['ID'];
  node: KitchenToSupplier;
};

export type KitchenToSupplierKitchenIdSupplierIdCompoundUniqueInput = {
  kitchenId: Scalars['Int'];
  supplierId: Scalars['Int'];
};

export type KitchenToSupplierListRelationFilter = {
  every?: InputMaybe<KitchenToSupplierWhereInput>;
  none?: InputMaybe<KitchenToSupplierWhereInput>;
  some?: InputMaybe<KitchenToSupplierWhereInput>;
};

export type KitchenToSupplierOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  kitchen?: InputMaybe<KitchenOrderByInput>;
  kitchenId?: InputMaybe<SortOrder>;
  supplier?: InputMaybe<SupplierOrderByInput>;
  supplierId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type KitchenToSupplierOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type KitchenToSupplierScalarWhereInput = {
  AND?: InputMaybe<Array<KitchenToSupplierScalarWhereInput>>;
  NOT?: InputMaybe<Array<KitchenToSupplierScalarWhereInput>>;
  OR?: InputMaybe<Array<KitchenToSupplierScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  kitchenId?: InputMaybe<IntFilter>;
  supplierId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type KitchenToSupplierUpdateInput = {
  accountReference?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  kitchenId?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type KitchenToSupplierUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenToSupplierUpdateManyWithWhereWithoutKitchenInput = {
  data: KitchenToSupplierUpdateManyMutationInput;
  where: KitchenToSupplierScalarWhereInput;
};

export type KitchenToSupplierUpdateManyWithWhereWithoutSupplierInput = {
  data: KitchenToSupplierUpdateManyMutationInput;
  where: KitchenToSupplierScalarWhereInput;
};

export type KitchenToSupplierUpdateManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<KitchenToSupplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenToSupplierCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<KitchenToSupplierCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<KitchenToSupplierCreateManyKitchenInputEnvelope>;
  delete?: InputMaybe<Array<KitchenToSupplierWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<KitchenToSupplierScalarWhereInput>>;
  disconnect?: InputMaybe<Array<KitchenToSupplierWhereUniqueInput>>;
  set?: InputMaybe<Array<KitchenToSupplierWhereUniqueInput>>;
  update?: InputMaybe<Array<KitchenToSupplierUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: InputMaybe<Array<KitchenToSupplierUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: InputMaybe<Array<KitchenToSupplierUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type KitchenToSupplierUpdateManyWithoutSupplierInput = {
  connect?: InputMaybe<Array<KitchenToSupplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenToSupplierCreateOrConnectWithoutSupplierInput>>;
  create?: InputMaybe<Array<KitchenToSupplierCreateWithoutSupplierInput>>;
  createMany?: InputMaybe<KitchenToSupplierCreateManySupplierInputEnvelope>;
  delete?: InputMaybe<Array<KitchenToSupplierWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<KitchenToSupplierScalarWhereInput>>;
  disconnect?: InputMaybe<Array<KitchenToSupplierWhereUniqueInput>>;
  set?: InputMaybe<Array<KitchenToSupplierWhereUniqueInput>>;
  update?: InputMaybe<Array<KitchenToSupplierUpdateWithWhereUniqueWithoutSupplierInput>>;
  updateMany?: InputMaybe<Array<KitchenToSupplierUpdateManyWithWhereWithoutSupplierInput>>;
  upsert?: InputMaybe<Array<KitchenToSupplierUpsertWithWhereUniqueWithoutSupplierInput>>;
};

export type KitchenToSupplierUpdateWithWhereUniqueWithoutKitchenInput = {
  data: KitchenToSupplierUpdateWithoutKitchenInput;
  where: KitchenToSupplierWhereUniqueInput;
};

export type KitchenToSupplierUpdateWithWhereUniqueWithoutSupplierInput = {
  data: KitchenToSupplierUpdateWithoutSupplierInput;
  where: KitchenToSupplierWhereUniqueInput;
};

export type KitchenToSupplierUpdateWithoutKitchenInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutKitchensInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenToSupplierUpdateWithoutSupplierInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutSuppliersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenToSupplierUpsertWithWhereUniqueWithoutKitchenInput = {
  create: KitchenToSupplierCreateWithoutKitchenInput;
  update: KitchenToSupplierUpdateWithoutKitchenInput;
  where: KitchenToSupplierWhereUniqueInput;
};

export type KitchenToSupplierUpsertWithWhereUniqueWithoutSupplierInput = {
  create: KitchenToSupplierCreateWithoutSupplierInput;
  update: KitchenToSupplierUpdateWithoutSupplierInput;
  where: KitchenToSupplierWhereUniqueInput;
};

export type KitchenToSupplierWhereInput = {
  AND?: InputMaybe<Array<KitchenToSupplierWhereInput>>;
  NOT?: InputMaybe<Array<KitchenToSupplierWhereInput>>;
  OR?: InputMaybe<Array<KitchenToSupplierWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  supplier?: InputMaybe<SupplierWhereInput>;
  supplierId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type KitchenToSupplierWhereUniqueInput = {
  kitchenId_supplierId?: InputMaybe<KitchenToSupplierKitchenIdSupplierIdCompoundUniqueInput>;
};

export type KitchenToUser = Node & {
  __typename?: 'KitchenToUser';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  kitchen: Kitchen;
  kitchenId: Scalars['Int'];
  role: Role;
  roleId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Int'];
};

export type KitchenToUserConnection = {
  __typename?: 'KitchenToUserConnection';
  edges: Array<KitchenToUserEdge>;
  nodes: Array<KitchenToUser>;
  pageInfo: PageInfo;
};

export type KitchenToUserCreateManyKitchenInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['Int'];
};

export type KitchenToUserCreateManyKitchenInputEnvelope = {
  data?: InputMaybe<Array<KitchenToUserCreateManyKitchenInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type KitchenToUserCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  kitchenId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type KitchenToUserCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<KitchenToUserCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type KitchenToUserCreateNestedManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<KitchenToUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenToUserCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<KitchenToUserCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<KitchenToUserCreateManyKitchenInputEnvelope>;
};

export type KitchenToUserCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<KitchenToUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenToUserCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<KitchenToUserCreateWithoutUserInput>>;
  createMany?: InputMaybe<KitchenToUserCreateManyUserInputEnvelope>;
};

export type KitchenToUserCreateOrConnectWithoutKitchenInput = {
  create: KitchenToUserCreateWithoutKitchenInput;
  where: KitchenToUserWhereUniqueInput;
};

export type KitchenToUserCreateOrConnectWithoutUserInput = {
  create: KitchenToUserCreateWithoutUserInput;
  where: KitchenToUserWhereUniqueInput;
};

export type KitchenToUserCreateWithoutKitchenInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutKitchensInput;
};

export type KitchenToUserCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  kitchen: KitchenCreateNestedOneWithoutUsersInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type KitchenToUserDeleteInput = {
  id: Scalars['Int'];
};

export type KitchenToUserEdge = {
  __typename?: 'KitchenToUserEdge';
  cursor: Scalars['ID'];
  node: KitchenToUser;
};

export type KitchenToUserInputObject = {
  id: Scalars['Int'];
};

export type KitchenToUserListRelationFilter = {
  every?: InputMaybe<KitchenToUserWhereInput>;
  none?: InputMaybe<KitchenToUserWhereInput>;
  some?: InputMaybe<KitchenToUserWhereInput>;
};

export type KitchenToUserOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  kitchen?: InputMaybe<KitchenOrderByInput>;
  kitchenId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type KitchenToUserOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type KitchenToUserQuery = {
  id: Scalars['Int'];
};

export type KitchenToUserScalarWhereInput = {
  AND?: InputMaybe<Array<KitchenToUserScalarWhereInput>>;
  NOT?: InputMaybe<Array<KitchenToUserScalarWhereInput>>;
  OR?: InputMaybe<Array<KitchenToUserScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  kitchenId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type KitchenToUserUpdateInput = {
  id: Scalars['Int'];
  roleId: Scalars['Int'];
};

export type KitchenToUserUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenToUserUpdateManyWithWhereWithoutKitchenInput = {
  data: KitchenToUserUpdateManyMutationInput;
  where: KitchenToUserScalarWhereInput;
};

export type KitchenToUserUpdateManyWithWhereWithoutUserInput = {
  data: KitchenToUserUpdateManyMutationInput;
  where: KitchenToUserScalarWhereInput;
};

export type KitchenToUserUpdateManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<KitchenToUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenToUserCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<KitchenToUserCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<KitchenToUserCreateManyKitchenInputEnvelope>;
  delete?: InputMaybe<Array<KitchenToUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<KitchenToUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<KitchenToUserWhereUniqueInput>>;
  set?: InputMaybe<Array<KitchenToUserWhereUniqueInput>>;
  update?: InputMaybe<Array<KitchenToUserUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: InputMaybe<Array<KitchenToUserUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: InputMaybe<Array<KitchenToUserUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type KitchenToUserUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<KitchenToUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenToUserCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<KitchenToUserCreateWithoutUserInput>>;
  createMany?: InputMaybe<KitchenToUserCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<KitchenToUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<KitchenToUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<KitchenToUserWhereUniqueInput>>;
  set?: InputMaybe<Array<KitchenToUserWhereUniqueInput>>;
  update?: InputMaybe<Array<KitchenToUserUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<KitchenToUserUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<KitchenToUserUpsertWithWhereUniqueWithoutUserInput>>;
};

export type KitchenToUserUpdateWithWhereUniqueWithoutKitchenInput = {
  data: KitchenToUserUpdateWithoutKitchenInput;
  where: KitchenToUserWhereUniqueInput;
};

export type KitchenToUserUpdateWithWhereUniqueWithoutUserInput = {
  data: KitchenToUserUpdateWithoutUserInput;
  where: KitchenToUserWhereUniqueInput;
};

export type KitchenToUserUpdateWithoutKitchenInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutKitchensInput>;
};

export type KitchenToUserUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenToUserUpsertWithWhereUniqueWithoutKitchenInput = {
  create: KitchenToUserCreateWithoutKitchenInput;
  update: KitchenToUserUpdateWithoutKitchenInput;
  where: KitchenToUserWhereUniqueInput;
};

export type KitchenToUserUpsertWithWhereUniqueWithoutUserInput = {
  create: KitchenToUserCreateWithoutUserInput;
  update: KitchenToUserUpdateWithoutUserInput;
  where: KitchenToUserWhereUniqueInput;
};

export type KitchenToUserUserIdKitchenIdCompoundUniqueInput = {
  kitchenId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type KitchenToUserWhereInput = {
  AND?: InputMaybe<Array<KitchenToUserWhereInput>>;
  NOT?: InputMaybe<Array<KitchenToUserWhereInput>>;
  OR?: InputMaybe<Array<KitchenToUserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<IntFilter>;
};

export type KitchenToUserWhereUniqueInput = {
  userId_kitchenId?: InputMaybe<KitchenToUserUserIdKitchenIdCompoundUniqueInput>;
};

export type KitchenTodo = {
  __typename?: 'KitchenTodo';
  assignee?: Maybe<User>;
  assigneeId?: Maybe<Scalars['Int']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  kitchen: Kitchen;
  kitchenId: Scalars['Int'];
  notes: Array<TodoNote>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type KitchenUpdateInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderUpdateManyWithoutKitchenInput>;
  accountId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenCreatedInput>;
  deliveryAddresses?: InputMaybe<KitchenToAddressUpdateManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutKitchenInput>;
  joinCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menus?: InputMaybe<MenuUpdateManyWithoutKitchenInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  permissions?: InputMaybe<KitchenPermissionsUpdatedInput>;
  recipes?: InputMaybe<RecipeUpdateManyWithoutKitchenInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierUpdateManyWithoutKitchenInput>;
  supportLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tier?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenUpdatedInput>;
  users?: InputMaybe<KitchenToUserUpdateManyWithoutKitchenInput>;
};

export type KitchenUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  joinCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type KitchenUpdateManyWithWhereWithoutCreatedByInput = {
  data: KitchenUpdateManyMutationInput;
  where: KitchenScalarWhereInput;
};

export type KitchenUpdateManyWithWhereWithoutUpdatedByInput = {
  data: KitchenUpdateManyMutationInput;
  where: KitchenScalarWhereInput;
};

export type KitchenUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<KitchenCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<KitchenCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<KitchenWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<KitchenScalarWhereInput>>;
  disconnect?: InputMaybe<Array<KitchenWhereUniqueInput>>;
  set?: InputMaybe<Array<KitchenWhereUniqueInput>>;
  update?: InputMaybe<Array<KitchenUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<KitchenUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<KitchenUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type KitchenUpdateManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KitchenCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<KitchenCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<KitchenCreateManyUpdatedByInputEnvelope>;
  delete?: InputMaybe<Array<KitchenWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<KitchenScalarWhereInput>>;
  disconnect?: InputMaybe<Array<KitchenWhereUniqueInput>>;
  set?: InputMaybe<Array<KitchenWhereUniqueInput>>;
  update?: InputMaybe<Array<KitchenUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: InputMaybe<Array<KitchenUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: InputMaybe<Array<KitchenUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type KitchenUpdateOneRequiredWithoutDishesInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutDishesInput>;
  create?: InputMaybe<KitchenCreateWithoutDishesInput>;
  update?: InputMaybe<KitchenUpdateWithoutDishesInput>;
  upsert?: InputMaybe<KitchenUpsertWithoutDishesInput>;
};

export type KitchenUpdateOneRequiredWithoutIngredientLogInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutIngredientLogInput>;
  create?: InputMaybe<KitchenCreateWithoutIngredientLogInput>;
  update?: InputMaybe<KitchenUpdateWithoutIngredientLogInput>;
  upsert?: InputMaybe<KitchenUpsertWithoutIngredientLogInput>;
};

export type KitchenUpdateOneRequiredWithoutIngredientsInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutIngredientsInput>;
  create?: InputMaybe<KitchenCreateWithoutIngredientsInput>;
  update?: InputMaybe<KitchenUpdateWithoutIngredientsInput>;
  upsert?: InputMaybe<KitchenUpsertWithoutIngredientsInput>;
};

export type KitchenUpdateOneRequiredWithoutMenusInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutMenusInput>;
  create?: InputMaybe<KitchenCreateWithoutMenusInput>;
  update?: InputMaybe<KitchenUpdateWithoutMenusInput>;
  upsert?: InputMaybe<KitchenUpsertWithoutMenusInput>;
};

export type KitchenUpdateOneRequiredWithoutPurchaseOrderInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutPurchaseOrderInput>;
  create?: InputMaybe<KitchenCreateWithoutPurchaseOrderInput>;
  update?: InputMaybe<KitchenUpdateWithoutPurchaseOrderInput>;
  upsert?: InputMaybe<KitchenUpsertWithoutPurchaseOrderInput>;
};

export type KitchenUpdateOneRequiredWithoutRecipesInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutRecipesInput>;
  create?: InputMaybe<KitchenCreateWithoutRecipesInput>;
  update?: InputMaybe<KitchenUpdateWithoutRecipesInput>;
  upsert?: InputMaybe<KitchenUpsertWithoutRecipesInput>;
};

export type KitchenUpdateOneRequiredWithoutSupplierDetailsInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutSupplierDetailsInput>;
  create?: InputMaybe<KitchenCreateWithoutSupplierDetailsInput>;
  update?: InputMaybe<KitchenUpdateWithoutSupplierDetailsInput>;
  upsert?: InputMaybe<KitchenUpsertWithoutSupplierDetailsInput>;
};

export type KitchenUpdateOneRequiredWithoutSuppliersInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutSuppliersInput>;
  create?: InputMaybe<KitchenCreateWithoutSuppliersInput>;
  update?: InputMaybe<KitchenUpdateWithoutSuppliersInput>;
  upsert?: InputMaybe<KitchenUpsertWithoutSuppliersInput>;
};

export type KitchenUpdateOneRequiredWithoutUsersInput = {
  connect?: InputMaybe<KitchenWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KitchenCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<KitchenCreateWithoutUsersInput>;
  update?: InputMaybe<KitchenUpdateWithoutUsersInput>;
  upsert?: InputMaybe<KitchenUpsertWithoutUsersInput>;
};

export type KitchenUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: KitchenUpdateWithoutCreatedByInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: KitchenUpdateWithoutUpdatedByInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpdateWithoutCreatedByInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderUpdateManyWithoutKitchenInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryAddresses?: InputMaybe<KitchenToAddressUpdateManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutKitchenInput>;
  joinCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menus?: InputMaybe<MenuUpdateManyWithoutKitchenInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recipes?: InputMaybe<RecipeUpdateManyWithoutKitchenInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierUpdateManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenUpdatedInput>;
  users?: InputMaybe<KitchenToUserUpdateManyWithoutKitchenInput>;
};

export type KitchenUpdateWithoutDishesInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderUpdateManyWithoutKitchenInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenCreatedInput>;
  deliveryAddresses?: InputMaybe<KitchenToAddressUpdateManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutKitchenInput>;
  joinCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menus?: InputMaybe<MenuUpdateManyWithoutKitchenInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recipes?: InputMaybe<RecipeUpdateManyWithoutKitchenInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierUpdateManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenUpdatedInput>;
  users?: InputMaybe<KitchenToUserUpdateManyWithoutKitchenInput>;
};

export type KitchenUpdateWithoutIngredientLogInput = {
  PurchaseOrder?: InputMaybe<PurchaseOrderUpdateManyWithoutKitchenInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenCreatedInput>;
  deliveryAddresses?: InputMaybe<KitchenToAddressUpdateManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutKitchenInput>;
  joinCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menus?: InputMaybe<MenuUpdateManyWithoutKitchenInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recipes?: InputMaybe<RecipeUpdateManyWithoutKitchenInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierUpdateManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenUpdatedInput>;
  users?: InputMaybe<KitchenToUserUpdateManyWithoutKitchenInput>;
};

export type KitchenUpdateWithoutIngredientsInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderUpdateManyWithoutKitchenInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenCreatedInput>;
  deliveryAddresses?: InputMaybe<KitchenToAddressUpdateManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutKitchenInput>;
  joinCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menus?: InputMaybe<MenuUpdateManyWithoutKitchenInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recipes?: InputMaybe<RecipeUpdateManyWithoutKitchenInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierUpdateManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenUpdatedInput>;
  users?: InputMaybe<KitchenToUserUpdateManyWithoutKitchenInput>;
};

export type KitchenUpdateWithoutMenusInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderUpdateManyWithoutKitchenInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenCreatedInput>;
  deliveryAddresses?: InputMaybe<KitchenToAddressUpdateManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutKitchenInput>;
  joinCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recipes?: InputMaybe<RecipeUpdateManyWithoutKitchenInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierUpdateManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenUpdatedInput>;
  users?: InputMaybe<KitchenToUserUpdateManyWithoutKitchenInput>;
};

export type KitchenUpdateWithoutPurchaseOrderInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutKitchenInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenCreatedInput>;
  deliveryAddresses?: InputMaybe<KitchenToAddressUpdateManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutKitchenInput>;
  joinCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menus?: InputMaybe<MenuUpdateManyWithoutKitchenInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recipes?: InputMaybe<RecipeUpdateManyWithoutKitchenInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierUpdateManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenUpdatedInput>;
  users?: InputMaybe<KitchenToUserUpdateManyWithoutKitchenInput>;
};

export type KitchenUpdateWithoutRecipesInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderUpdateManyWithoutKitchenInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenCreatedInput>;
  deliveryAddresses?: InputMaybe<KitchenToAddressUpdateManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutKitchenInput>;
  joinCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menus?: InputMaybe<MenuUpdateManyWithoutKitchenInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierUpdateManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenUpdatedInput>;
  users?: InputMaybe<KitchenToUserUpdateManyWithoutKitchenInput>;
};

export type KitchenUpdateWithoutSupplierDetailsInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderUpdateManyWithoutKitchenInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenCreatedInput>;
  deliveryAddresses?: InputMaybe<KitchenToAddressUpdateManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutKitchenInput>;
  joinCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menus?: InputMaybe<MenuUpdateManyWithoutKitchenInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recipes?: InputMaybe<RecipeUpdateManyWithoutKitchenInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  suppliers?: InputMaybe<KitchenToSupplierUpdateManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenUpdatedInput>;
  users?: InputMaybe<KitchenToUserUpdateManyWithoutKitchenInput>;
};

export type KitchenUpdateWithoutSuppliersInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderUpdateManyWithoutKitchenInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenCreatedInput>;
  deliveryAddresses?: InputMaybe<KitchenToAddressUpdateManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutKitchenInput>;
  joinCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menus?: InputMaybe<MenuUpdateManyWithoutKitchenInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recipes?: InputMaybe<RecipeUpdateManyWithoutKitchenInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenUpdatedInput>;
  users?: InputMaybe<KitchenToUserUpdateManyWithoutKitchenInput>;
};

export type KitchenUpdateWithoutUpdatedByInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderUpdateManyWithoutKitchenInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenCreatedInput>;
  deliveryAddresses?: InputMaybe<KitchenToAddressUpdateManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutKitchenInput>;
  joinCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menus?: InputMaybe<MenuUpdateManyWithoutKitchenInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recipes?: InputMaybe<RecipeUpdateManyWithoutKitchenInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierUpdateManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<KitchenToUserUpdateManyWithoutKitchenInput>;
};

export type KitchenUpdateWithoutUsersInput = {
  IngredientLog?: InputMaybe<IngredientLogUpdateManyWithoutKitchenInput>;
  PurchaseOrder?: InputMaybe<PurchaseOrderUpdateManyWithoutKitchenInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenCreatedInput>;
  deliveryAddresses?: InputMaybe<KitchenToAddressUpdateManyWithoutKitchenInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutKitchenInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutKitchenInput>;
  joinCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menus?: InputMaybe<MenuUpdateManyWithoutKitchenInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recipes?: InputMaybe<RecipeUpdateManyWithoutKitchenInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutKitchenInput>;
  suppliers?: InputMaybe<KitchenToSupplierUpdateManyWithoutKitchenInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutKitchenUpdatedInput>;
};

export type KitchenUpdates = {
  __typename?: 'KitchenUpdates';
  activated?: Maybe<Scalars['Boolean']>;
  changesCount?: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
  emailCount?: Maybe<Scalars['Int']>;
  firstShot?: Maybe<Scalars['Boolean']>;
  fromLastWeek?: Maybe<Scalars['Int']>;
  fromThisPeriod?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  ingredientChanges: IngredientChanges;
  invoicesToProcess?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  newIngredientsCount?: Maybe<Scalars['Int']>;
  newIngredientsSupplierCount?: Maybe<Scalars['Int']>;
  noChanges?: Maybe<Scalars['Boolean']>;
  notFromLastWeek?: Maybe<Scalars['Int']>;
  notFromThisPeriod?: Maybe<Scalars['Int']>;
  notifcationSentToday?: Maybe<Scalars['Boolean']>;
  notificationSentThisWeek?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<Scalars['String']>;
  totalProcessed?: Maybe<Scalars['Int']>;
  totalProcessedLastWeek?: Maybe<Scalars['Int']>;
  totalSpend?: Maybe<Scalars['Float']>;
  totalSpendLastWeek?: Maybe<Scalars['Float']>;
  totalSpendWeekBeforeLast?: Maybe<Scalars['Float']>;
  weekSoFar?: Maybe<Scalars['Float']>;
};

export type KitchenUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: KitchenCreateWithoutCreatedByInput;
  update: KitchenUpdateWithoutCreatedByInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: KitchenCreateWithoutUpdatedByInput;
  update: KitchenUpdateWithoutUpdatedByInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpsertWithoutDishesInput = {
  create: KitchenCreateWithoutDishesInput;
  update: KitchenUpdateWithoutDishesInput;
};

export type KitchenUpsertWithoutIngredientLogInput = {
  create: KitchenCreateWithoutIngredientLogInput;
  update: KitchenUpdateWithoutIngredientLogInput;
};

export type KitchenUpsertWithoutIngredientsInput = {
  create: KitchenCreateWithoutIngredientsInput;
  update: KitchenUpdateWithoutIngredientsInput;
};

export type KitchenUpsertWithoutMenusInput = {
  create: KitchenCreateWithoutMenusInput;
  update: KitchenUpdateWithoutMenusInput;
};

export type KitchenUpsertWithoutPurchaseOrderInput = {
  create: KitchenCreateWithoutPurchaseOrderInput;
  update: KitchenUpdateWithoutPurchaseOrderInput;
};

export type KitchenUpsertWithoutRecipesInput = {
  create: KitchenCreateWithoutRecipesInput;
  update: KitchenUpdateWithoutRecipesInput;
};

export type KitchenUpsertWithoutSupplierDetailsInput = {
  create: KitchenCreateWithoutSupplierDetailsInput;
  update: KitchenUpdateWithoutSupplierDetailsInput;
};

export type KitchenUpsertWithoutSuppliersInput = {
  create: KitchenCreateWithoutSuppliersInput;
  update: KitchenUpdateWithoutSuppliersInput;
};

export type KitchenUpsertWithoutUsersInput = {
  create: KitchenCreateWithoutUsersInput;
  update: KitchenUpdateWithoutUsersInput;
};

export type KitchenWhereInput = {
  AND?: InputMaybe<Array<KitchenWhereInput>>;
  IngredientLog?: InputMaybe<IngredientLogListRelationFilter>;
  NOT?: InputMaybe<Array<KitchenWhereInput>>;
  OR?: InputMaybe<Array<KitchenWhereInput>>;
  PurchaseOrder?: InputMaybe<PurchaseOrderListRelationFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  deliveryAddresses?: InputMaybe<KitchenToAddressListRelationFilter>;
  dishes?: InputMaybe<DishListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  ingredients?: InputMaybe<IngredientListRelationFilter>;
  invoices?: InputMaybe<InvoiceListRelationFilter>;
  isDemoAccount?: InputMaybe<Scalars['Boolean']>;
  joinCode?: InputMaybe<StringNullableFilter>;
  menus?: InputMaybe<MenuListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  pendingInvoices?: InputMaybe<PendingInvoiceListRelationFilter>;
  recipes?: InputMaybe<RecipeListRelationFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  supplierDetails?: InputMaybe<KitchenSupplierDetailListRelationFilter>;
  suppliers?: InputMaybe<KitchenToSupplierListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
  users?: InputMaybe<KitchenToUserListRelationFilter>;
};

export type KitchenWhereUniqueInput = {
  id: Scalars['Int'];
};

export type LinkPosLocationInput = {
  kitchenId: Scalars['Int'];
  linked: Scalars['Boolean'];
  posLocationId: Scalars['Int'];
};

export type LinkPosProductInput = {
  dishId: Scalars['Int'];
  ktoId: Scalars['Int'];
};

export enum MeasurementSystem {
  Custom = 'custom',
  Imperial = 'imperial',
  Metric = 'metric'
}

export type Menu = Node & {
  __typename?: 'Menu';
  _cursor: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  dishAverageGp: Scalars['Float'];
  dishCount: Scalars['Int'];
  dishList: MenuToDishConnection;
  /** @deprecated Use and extend dishList field */
  dishes: Array<MenuToDish>;
  gpTarget: Scalars['Float'];
  id: Scalars['Int'];
  kitchen: Kitchen;
  name: Scalars['String'];
  status: Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
};


export type MenuDishListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type MenuDishesArgs = {
  cursor?: InputMaybe<MenuToDishWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MenuToDishOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MenuToDishWhereInput>;
};

export type MenuConnection = {
  __typename?: 'MenuConnection';
  edges: Array<MenuEdge>;
  nodes: Array<Menu>;
  pageInfo: PageInfo;
};

export type MenuCreateManyCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  gpTarget: Scalars['Float'];
  id?: InputMaybe<Scalars['Int']>;
  kitchenId: Scalars['Int'];
  name: Scalars['String'];
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type MenuCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<MenuCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MenuCreateManyKitchenInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  gpTarget: Scalars['Float'];
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type MenuCreateManyKitchenInputEnvelope = {
  data?: InputMaybe<Array<MenuCreateManyKitchenInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MenuCreateManyUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  gpTarget: Scalars['Float'];
  id?: InputMaybe<Scalars['Int']>;
  kitchenId: Scalars['Int'];
  name: Scalars['String'];
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MenuCreateManyUpdatedByInputEnvelope = {
  data?: InputMaybe<Array<MenuCreateManyUpdatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MenuCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MenuCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<MenuCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<MenuCreateManyCreatedByInputEnvelope>;
};

export type MenuCreateNestedManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MenuCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<MenuCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<MenuCreateManyKitchenInputEnvelope>;
};

export type MenuCreateNestedManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MenuCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<MenuCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<MenuCreateManyUpdatedByInputEnvelope>;
};

export type MenuCreateNestedOneWithoutDishesInput = {
  connect?: InputMaybe<MenuWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MenuCreateOrConnectWithoutDishesInput>;
  create?: InputMaybe<MenuCreateWithoutDishesInput>;
};

export type MenuCreateOrConnectWithoutCreatedByInput = {
  create: MenuCreateWithoutCreatedByInput;
  where: MenuWhereUniqueInput;
};

export type MenuCreateOrConnectWithoutDishesInput = {
  create: MenuCreateWithoutDishesInput;
  where: MenuWhereUniqueInput;
};

export type MenuCreateOrConnectWithoutKitchenInput = {
  create: MenuCreateWithoutKitchenInput;
  where: MenuWhereUniqueInput;
};

export type MenuCreateOrConnectWithoutUpdatedByInput = {
  create: MenuCreateWithoutUpdatedByInput;
  where: MenuWhereUniqueInput;
};

export type MenuCreateWithoutCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishes?: InputMaybe<MenuToDishCreateNestedManyWithoutMenuInput>;
  gpTarget: Scalars['Float'];
  kitchen: KitchenCreateNestedOneWithoutMenusInput;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutMenuUpdatedInput;
};

export type MenuCreateWithoutDishesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutMenuCreatedInput;
  gpTarget: Scalars['Float'];
  kitchen: KitchenCreateNestedOneWithoutMenusInput;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutMenuUpdatedInput;
};

export type MenuCreateWithoutKitchenInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutMenuCreatedInput;
  dishes?: InputMaybe<MenuToDishCreateNestedManyWithoutMenuInput>;
  gpTarget: Scalars['Float'];
  name: Scalars['String'];
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutMenuUpdatedInput;
};

export type MenuCreateWithoutUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutMenuCreatedInput;
  dishes?: InputMaybe<MenuToDishCreateNestedManyWithoutMenuInput>;
  gpTarget: Scalars['Float'];
  kitchen: KitchenCreateNestedOneWithoutMenusInput;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MenuEdge = {
  __typename?: 'MenuEdge';
  cursor: Scalars['ID'];
  node: Menu;
};

export type MenuListRelationFilter = {
  every?: InputMaybe<MenuWhereInput>;
  none?: InputMaybe<MenuWhereInput>;
  some?: InputMaybe<MenuWhereInput>;
};

export type MenuOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  dishes?: InputMaybe<MenuToDishOrderByRelationAggregateInput>;
  gpTarget?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  kitchen?: InputMaybe<KitchenOrderByInput>;
  kitchenId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
};

export type MenuOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type MenuScalarWhereInput = {
  AND?: InputMaybe<Array<MenuScalarWhereInput>>;
  NOT?: InputMaybe<Array<MenuScalarWhereInput>>;
  OR?: InputMaybe<Array<MenuScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdById?: InputMaybe<IntFilter>;
  gpTarget?: InputMaybe<FloatFilter>;
  id?: InputMaybe<IntFilter>;
  kitchenId?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedById?: InputMaybe<IntFilter>;
};

export type MenuToDish = Node & {
  __typename?: 'MenuToDish';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  dish: Dish;
  id: Scalars['Int'];
  menu: Menu;
  updatedAt: Scalars['DateTime'];
};

export type MenuToDishConnection = {
  __typename?: 'MenuToDishConnection';
  edges: Array<MenuToDishEdge>;
  nodes: Array<MenuToDish>;
  pageInfo: PageInfo;
};

export type MenuToDishCreateManyDishInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  menuId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MenuToDishCreateManyDishInputEnvelope = {
  data?: InputMaybe<Array<MenuToDishCreateManyDishInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MenuToDishCreateManyMenuInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MenuToDishCreateManyMenuInputEnvelope = {
  data?: InputMaybe<Array<MenuToDishCreateManyMenuInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MenuToDishCreateNestedManyWithoutDishInput = {
  connect?: InputMaybe<Array<MenuToDishWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MenuToDishCreateOrConnectWithoutDishInput>>;
  create?: InputMaybe<Array<MenuToDishCreateWithoutDishInput>>;
  createMany?: InputMaybe<MenuToDishCreateManyDishInputEnvelope>;
};

export type MenuToDishCreateNestedManyWithoutMenuInput = {
  connect?: InputMaybe<Array<MenuToDishWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MenuToDishCreateOrConnectWithoutMenuInput>>;
  create?: InputMaybe<Array<MenuToDishCreateWithoutMenuInput>>;
  createMany?: InputMaybe<MenuToDishCreateManyMenuInputEnvelope>;
};

export type MenuToDishCreateOrConnectWithoutDishInput = {
  create: MenuToDishCreateWithoutDishInput;
  where: MenuToDishWhereUniqueInput;
};

export type MenuToDishCreateOrConnectWithoutMenuInput = {
  create: MenuToDishCreateWithoutMenuInput;
  where: MenuToDishWhereUniqueInput;
};

export type MenuToDishCreateWithoutDishInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  menu: MenuCreateNestedOneWithoutDishesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MenuToDishCreateWithoutMenuInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dish: DishCreateNestedOneWithoutMenusInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MenuToDishDishIdMenuIdCompoundUniqueInput = {
  dishId: Scalars['Int'];
  menuId: Scalars['Int'];
};

export type MenuToDishEdge = {
  __typename?: 'MenuToDishEdge';
  cursor: Scalars['ID'];
  node: MenuToDish;
};

export type MenuToDishInputObject = {
  id: Scalars['Int'];
};

export type MenuToDishListRelationFilter = {
  every?: InputMaybe<MenuToDishWhereInput>;
  none?: InputMaybe<MenuToDishWhereInput>;
  some?: InputMaybe<MenuToDishWhereInput>;
};

export type MenuToDishOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  dish?: InputMaybe<DishOrderByInput>;
  dishId?: InputMaybe<SortOrder>;
  menu?: InputMaybe<MenuOrderByInput>;
  menuId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MenuToDishOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type MenuToDishScalarWhereInput = {
  AND?: InputMaybe<Array<MenuToDishScalarWhereInput>>;
  NOT?: InputMaybe<Array<MenuToDishScalarWhereInput>>;
  OR?: InputMaybe<Array<MenuToDishScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dishId?: InputMaybe<IntFilter>;
  menuId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MenuToDishUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuToDishUpdateManyWithWhereWithoutDishInput = {
  data: MenuToDishUpdateManyMutationInput;
  where: MenuToDishScalarWhereInput;
};

export type MenuToDishUpdateManyWithWhereWithoutMenuInput = {
  data: MenuToDishUpdateManyMutationInput;
  where: MenuToDishScalarWhereInput;
};

export type MenuToDishUpdateManyWithoutDishInput = {
  connect?: InputMaybe<Array<MenuToDishWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MenuToDishCreateOrConnectWithoutDishInput>>;
  create?: InputMaybe<Array<MenuToDishCreateWithoutDishInput>>;
  createMany?: InputMaybe<MenuToDishCreateManyDishInputEnvelope>;
  delete?: InputMaybe<Array<MenuToDishWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MenuToDishScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MenuToDishWhereUniqueInput>>;
  set?: InputMaybe<Array<MenuToDishWhereUniqueInput>>;
  update?: InputMaybe<Array<MenuToDishUpdateWithWhereUniqueWithoutDishInput>>;
  updateMany?: InputMaybe<Array<MenuToDishUpdateManyWithWhereWithoutDishInput>>;
  upsert?: InputMaybe<Array<MenuToDishUpsertWithWhereUniqueWithoutDishInput>>;
};

export type MenuToDishUpdateManyWithoutMenuInput = {
  connect?: InputMaybe<Array<MenuToDishWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MenuToDishCreateOrConnectWithoutMenuInput>>;
  create?: InputMaybe<Array<MenuToDishCreateWithoutMenuInput>>;
  createMany?: InputMaybe<MenuToDishCreateManyMenuInputEnvelope>;
  delete?: InputMaybe<Array<MenuToDishWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MenuToDishScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MenuToDishWhereUniqueInput>>;
  set?: InputMaybe<Array<MenuToDishWhereUniqueInput>>;
  update?: InputMaybe<Array<MenuToDishUpdateWithWhereUniqueWithoutMenuInput>>;
  updateMany?: InputMaybe<Array<MenuToDishUpdateManyWithWhereWithoutMenuInput>>;
  upsert?: InputMaybe<Array<MenuToDishUpsertWithWhereUniqueWithoutMenuInput>>;
};

export type MenuToDishUpdateWithWhereUniqueWithoutDishInput = {
  data: MenuToDishUpdateWithoutDishInput;
  where: MenuToDishWhereUniqueInput;
};

export type MenuToDishUpdateWithWhereUniqueWithoutMenuInput = {
  data: MenuToDishUpdateWithoutMenuInput;
  where: MenuToDishWhereUniqueInput;
};

export type MenuToDishUpdateWithoutDishInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  menu?: InputMaybe<MenuUpdateOneRequiredWithoutDishesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuToDishUpdateWithoutMenuInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dish?: InputMaybe<DishUpdateOneRequiredWithoutMenusInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuToDishUpsertWithWhereUniqueWithoutDishInput = {
  create: MenuToDishCreateWithoutDishInput;
  update: MenuToDishUpdateWithoutDishInput;
  where: MenuToDishWhereUniqueInput;
};

export type MenuToDishUpsertWithWhereUniqueWithoutMenuInput = {
  create: MenuToDishCreateWithoutMenuInput;
  update: MenuToDishUpdateWithoutMenuInput;
  where: MenuToDishWhereUniqueInput;
};

export type MenuToDishWhereInput = {
  AND?: InputMaybe<Array<MenuToDishWhereInput>>;
  NOT?: InputMaybe<Array<MenuToDishWhereInput>>;
  OR?: InputMaybe<Array<MenuToDishWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dish?: InputMaybe<DishWhereInput>;
  dishId?: InputMaybe<IntFilter>;
  menu?: InputMaybe<MenuWhereInput>;
  menuId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MenuToDishWhereUniqueInput = {
  dishId_menuId?: InputMaybe<MenuToDishDishIdMenuIdCompoundUniqueInput>;
};

export type MenuUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  gpTarget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type MenuUpdateManyWithWhereWithoutCreatedByInput = {
  data: MenuUpdateManyMutationInput;
  where: MenuScalarWhereInput;
};

export type MenuUpdateManyWithWhereWithoutKitchenInput = {
  data: MenuUpdateManyMutationInput;
  where: MenuScalarWhereInput;
};

export type MenuUpdateManyWithWhereWithoutUpdatedByInput = {
  data: MenuUpdateManyMutationInput;
  where: MenuScalarWhereInput;
};

export type MenuUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MenuCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<MenuCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<MenuCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<MenuWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MenuScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  set?: InputMaybe<Array<MenuWhereUniqueInput>>;
  update?: InputMaybe<Array<MenuUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<MenuUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<MenuUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type MenuUpdateManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MenuCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<MenuCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<MenuCreateManyKitchenInputEnvelope>;
  delete?: InputMaybe<Array<MenuWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MenuScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  set?: InputMaybe<Array<MenuWhereUniqueInput>>;
  update?: InputMaybe<Array<MenuUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: InputMaybe<Array<MenuUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: InputMaybe<Array<MenuUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type MenuUpdateManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MenuCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<MenuCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<MenuCreateManyUpdatedByInputEnvelope>;
  delete?: InputMaybe<Array<MenuWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MenuScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  set?: InputMaybe<Array<MenuWhereUniqueInput>>;
  update?: InputMaybe<Array<MenuUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: InputMaybe<Array<MenuUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: InputMaybe<Array<MenuUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type MenuUpdateOneRequiredWithoutDishesInput = {
  connect?: InputMaybe<MenuWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MenuCreateOrConnectWithoutDishesInput>;
  create?: InputMaybe<MenuCreateWithoutDishesInput>;
  update?: InputMaybe<MenuUpdateWithoutDishesInput>;
  upsert?: InputMaybe<MenuUpsertWithoutDishesInput>;
};

export type MenuUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: MenuUpdateWithoutCreatedByInput;
  where: MenuWhereUniqueInput;
};

export type MenuUpdateWithWhereUniqueWithoutKitchenInput = {
  data: MenuUpdateWithoutKitchenInput;
  where: MenuWhereUniqueInput;
};

export type MenuUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: MenuUpdateWithoutUpdatedByInput;
  where: MenuWhereUniqueInput;
};

export type MenuUpdateWithoutCreatedByInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishes?: InputMaybe<MenuToDishUpdateManyWithoutMenuInput>;
  gpTarget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutMenusInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutMenuUpdatedInput>;
};

export type MenuUpdateWithoutDishesInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutMenuCreatedInput>;
  gpTarget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutMenusInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutMenuUpdatedInput>;
};

export type MenuUpdateWithoutKitchenInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutMenuCreatedInput>;
  dishes?: InputMaybe<MenuToDishUpdateManyWithoutMenuInput>;
  gpTarget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutMenuUpdatedInput>;
};

export type MenuUpdateWithoutUpdatedByInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutMenuCreatedInput>;
  dishes?: InputMaybe<MenuToDishUpdateManyWithoutMenuInput>;
  gpTarget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutMenusInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type MenuUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: MenuCreateWithoutCreatedByInput;
  update: MenuUpdateWithoutCreatedByInput;
  where: MenuWhereUniqueInput;
};

export type MenuUpsertWithWhereUniqueWithoutKitchenInput = {
  create: MenuCreateWithoutKitchenInput;
  update: MenuUpdateWithoutKitchenInput;
  where: MenuWhereUniqueInput;
};

export type MenuUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: MenuCreateWithoutUpdatedByInput;
  update: MenuUpdateWithoutUpdatedByInput;
  where: MenuWhereUniqueInput;
};

export type MenuUpsertWithoutDishesInput = {
  create: MenuCreateWithoutDishesInput;
  update: MenuUpdateWithoutDishesInput;
};

export type MenuWhereInput = {
  AND?: InputMaybe<Array<MenuWhereInput>>;
  NOT?: InputMaybe<Array<MenuWhereInput>>;
  OR?: InputMaybe<Array<MenuWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  dishes?: InputMaybe<MenuToDishListRelationFilter>;
  gpTarget?: InputMaybe<FloatFilter>;
  id?: InputMaybe<IntFilter>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type MenuWhereUniqueInput = {
  id: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite: Scalars['Boolean'];
  adminCreateOneKitchen: Kitchen;
  adminCreateOneUser: User;
  adminGenerateKitchenInvoiceCode?: Maybe<Kitchen>;
  adminGenerateKitchenJoinCode?: Maybe<Kitchen>;
  adminMergeProducts: Product;
  adminMergeSupplierProducts?: Maybe<Scalars['Int']>;
  adminMergeSuppliers: Supplier;
  adminSendJoinCodeSMS: Scalars['Boolean'];
  adminSignin: AuthPayload;
  adminUnmergeProducts?: Maybe<Array<Maybe<Product>>>;
  adminUnmergeSuppliers: Array<Supplier>;
  adminUpdateOneKitchen?: Maybe<Kitchen>;
  adminUpdateOneProduct?: Maybe<Product>;
  adminUpdateOneUser: User;
  approveOneInvoice: Invoice;
  bulkUpload: BulkUploadResponseType;
  calculateCosting: Scalars['Boolean'];
  catalogueUpload?: Maybe<CatalogueUploadResponseType>;
  completeSignUpPhone: Scalars['Boolean'];
  connectOneSupplier: Supplier;
  createKitchenInvite: KitchenInvite;
  createKitchenNotification?: Maybe<Array<Maybe<UserNotificationQueue>>>;
  createMultiplePendingInvoice: Array<Maybe<PendingInvoice>>;
  createOneDish: Dish;
  createOneFlashPeriod: FlashPeriod;
  createOneFoodFlash: FoodFlash;
  createOneIngredient: Ingredient;
  createOneKitchen: Kitchen;
  createOneKitchenAddress: Kitchen;
  createOneKitchenInvoiceRule: KitchenInvoiceRule;
  createOneKitchenToPosAccount?: Maybe<KitchenToPosAccount>;
  createOneKitchenTodo: KitchenTodo;
  createOneMenu: Menu;
  createOnePosAccountForSquare: PosAccount;
  createOneProduct: Product;
  createOnePurchaseOrder: PurchaseOrder;
  createOneRecipe: Recipe;
  createOneSquareAuthoriseUrl: SquareAuthoriseUrl;
  createOneStock: Stock;
  createOneSupplier: Supplier;
  createOneTodoNote: TodoNote;
  createOneUnit: Unit;
  createOrUpdateDishesSold: DishesSold;
  createOrUpdateOneSale: Sales;
  createProductActionAlert: Scalars['Boolean'];
  createProductDuplicatesList?: Maybe<Scalars['Int']>;
  deleteKitchenInvite: KitchenInvite;
  deleteKitchenToUser: KitchenToUser;
  deleteManyPendingInvoice: Scalars['Int'];
  deleteOneDish?: Maybe<Dish>;
  deleteOneFoodFlash: FoodFlash;
  deleteOneIngredient?: Maybe<Ingredient>;
  deleteOneInvoice?: Maybe<Invoice>;
  deleteOneKitchenAddress: Scalars['Boolean'];
  deleteOneKitchenToPosAccount?: Maybe<KitchenToPosAccount>;
  deleteOneMenu?: Maybe<Menu>;
  deleteOnePendingInvoice?: Maybe<PendingInvoice>;
  deleteOneRecipe: Recipe;
  deleteOneStock: Stock;
  deletePosToken: PosToken;
  duplicateOneRecipe: Recipe;
  eventUserSelectedKitchen?: Maybe<Scalars['Boolean']>;
  invoiceUpdateV2: Invoice;
  linkPosLocation: PosLocation;
  linkPosProduct: Scalars['Boolean'];
  mergePendingInvoices: PendingInvoice;
  refresh: AuthPayload;
  refreshPosTokenSquare: PosToken;
  requestKitchenPermissions: Kitchen;
  requestPasswordResetCode?: Maybe<PasswordResetCodeResponse>;
  resendEmailVerification: Scalars['Boolean'];
  resetPassword?: Maybe<PasswordResetCodeResponse>;
  setFirstPassword: User;
  signUpPhone?: Maybe<SignUpPhoneResult>;
  signin: AuthPayload;
  signup: AuthPayload;
  signupV2: SignUpPhoneResult;
  signupWithJoinCode: PasswordResetCodeResponse;
  trackBookACall: Scalars['Boolean'];
  unlinkPosProduct: Scalars['Boolean'];
  updateIngredientStockData: Scalars['Boolean'];
  updateKitchenPosCategories: Kitchen;
  updateKitchenPosIncludes: Kitchen;
  updateKitchenToUser: KitchenToUser;
  updateManyPendingInvoice: Scalars['Int'];
  updateOneDish?: Maybe<Dish>;
  updateOneFlashPeriod: FlashPeriod;
  updateOneFoodFlash: FoodFlash;
  updateOneIngredient: Ingredient;
  updateOneInvoice: Invoice;
  updateOneKitchen: Kitchen;
  updateOneKitchenAddress: KitchenToAddress;
  updateOneKitchenInvoiceRule: KitchenInvoiceRule;
  updateOneKitchenToSupplier?: Maybe<KitchenToSupplier>;
  updateOneKitchenTodo: KitchenTodo;
  updateOneMenu: Menu;
  updateOnePendingInvoice: PendingInvoice;
  updateOneProduct: Product;
  updateOneRecipe?: Maybe<Recipe>;
  updateOneStock: Stock;
  updateOneSupplier: Supplier;
  updateOneUser: User;
  updateProductDuplicate?: Maybe<Scalars['Int']>;
  updateRecipeStockData: Scalars['Boolean'];
  upsertPosAccount: PosAccount;
  upsertPosCategory: PosCategory;
  upsertPosLocation: PosLocation;
  upsertPosProduct: PosProduct;
  upsertPosTill: PosTill;
  upsertPosToken: PosToken;
  upsertPosTransaction: PosTransaction;
  upsertPosTransactionItem: PosTransactionItem;
  upsertPosType: PosType;
  userTracking: Scalars['Boolean'];
  verifyEmail: User;
  verifySignUpPhone: AuthPayload;
};


export type MutationAcceptInviteArgs = {
  inviteCode?: InputMaybe<Scalars['String']>;
  joinCode: Scalars['String'];
};


export type MutationAdminCreateOneKitchenArgs = {
  data: AdminCreateOneKitchenInputObject;
};


export type MutationAdminCreateOneUserArgs = {
  data: UserCreateInput;
  kitchens: Array<KitchenToUserInputObject>;
};


export type MutationAdminGenerateKitchenInvoiceCodeArgs = {
  id: Scalars['Int'];
};


export type MutationAdminGenerateKitchenJoinCodeArgs = {
  id: Scalars['Int'];
};


export type MutationAdminMergeProductsArgs = {
  primaryId: Scalars['Int'];
  secondaryIds: Array<Scalars['Int']>;
  supplierId: Scalars['Int'];
};


export type MutationAdminMergeSupplierProductsArgs = {
  id: Scalars['Int'];
};


export type MutationAdminMergeSuppliersArgs = {
  primaryId: Scalars['Int'];
  secondaryIds: Array<Scalars['Int']>;
};


export type MutationAdminSendJoinCodeSmsArgs = {
  countryCode: Scalars['String'];
  joinCode?: InputMaybe<Scalars['String']>;
  kitchenId?: InputMaybe<Scalars['Int']>;
  phoneNumber: Scalars['String'];
};


export type MutationAdminSigninArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAdminUnmergeProductsArgs = {
  primaryId: Scalars['Int'];
};


export type MutationAdminUnmergeSuppliersArgs = {
  primaryId: Scalars['Int'];
};


export type MutationAdminUpdateOneKitchenArgs = {
  data: KitchenUpdateInput;
  where: KitchenWhereUniqueInput;
};


export type MutationAdminUpdateOneProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationAdminUpdateOneUserArgs = {
  data: AdminUpdateOneUserInputObject;
  kitchens?: InputMaybe<Array<KitchenToUserInputObject>>;
  removeKitchens?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationApproveOneInvoiceArgs = {
  approved: Scalars['Boolean'];
  id: Scalars['Int'];
};


export type MutationBulkUploadArgs = {
  file: Scalars['Upload'];
  kitchenId: Scalars['Int'];
};


export type MutationCalculateCostingArgs = {
  ingredientIds: Array<Scalars['Int']>;
  recipeIds: Array<Scalars['Int']>;
};


export type MutationCatalogueUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationCompleteSignUpPhoneArgs = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  kitchenName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};


export type MutationConnectOneSupplierArgs = {
  kitchenId: Scalars['Int'];
  supplierId: Scalars['Int'];
};


export type MutationCreateKitchenInviteArgs = {
  data: CreateKitchenInviteInput;
};


export type MutationCreateKitchenNotificationArgs = {
  data: DailyUpdatesKitchenInput;
  kitchenId: Scalars['Int'];
  streamId: Scalars['Int'];
};


export type MutationCreateMultiplePendingInvoiceArgs = {
  imageUrls: Array<InputMaybe<Scalars['String']>>;
  kitchenId: Scalars['Int'];
};


export type MutationCreateOneDishArgs = {
  allergens?: InputMaybe<Array<RecipeToAllergenInputObject>>;
  childRecipes?: InputMaybe<Array<RecipeToRecipeInputObject>>;
  data: CreateOneDishInputObject;
  ingredients?: InputMaybe<Array<RecipeToIngredientInputObject>>;
  kitchenId: Scalars['Int'];
};


export type MutationCreateOneFlashPeriodArgs = {
  data: CreateFlashPeriodInput;
};


export type MutationCreateOneFoodFlashArgs = {
  data: CreateOneFoodFlashInputObject;
};


export type MutationCreateOneIngredientArgs = {
  data: CreateOneIngredientInputObject;
  kitchenId: Scalars['Int'];
  productId?: InputMaybe<Scalars['Int']>;
  supplierData?: InputMaybe<CreateOneSupplierInputObject>;
  supplierId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateOneKitchenArgs = {
  data: CreateOneKitchenInputObject;
};


export type MutationCreateOneKitchenAddressArgs = {
  data: CreateOneKitchenAddressInputObject;
};


export type MutationCreateOneKitchenInvoiceRuleArgs = {
  data: CreateKitchenInvoiceRuleInput;
};


export type MutationCreateOneKitchenToPosAccountArgs = {
  kitchenId: Scalars['Int'];
  posAccountExternalId: Scalars['String'];
  posTypeId: Scalars['Int'];
};


export type MutationCreateOneKitchenTodoArgs = {
  data: CreateKitchenTodoInput;
};


export type MutationCreateOneMenuArgs = {
  data: CreateOneMenuInputObject;
  dishes?: InputMaybe<Array<MenuToDishInputObject>>;
  kitchenId: Scalars['Int'];
};


export type MutationCreateOnePosAccountForSquareArgs = {
  data: CreatePosAccountSquareInput;
};


export type MutationCreateOneProductArgs = {
  data: CreateOneProductInputObject;
  supplierId: Scalars['Int'];
};


export type MutationCreateOnePurchaseOrderArgs = {
  addressName?: InputMaybe<Scalars['String']>;
  deliveryAddress: Scalars['String'];
  deliveryDate?: InputMaybe<Scalars['DateTime']>;
  items: Array<PurchaseOrderEntryInput>;
  kitchenAddressCursor?: InputMaybe<Scalars['String']>;
  kitchenId: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  siteName: Scalars['String'];
  supplierEmail: Scalars['String'];
  supplierId: Scalars['Int'];
};


export type MutationCreateOneRecipeArgs = {
  allergens?: InputMaybe<Array<RecipeToAllergenInputObject>>;
  childRecipes?: InputMaybe<Array<RecipeToRecipeInputObject>>;
  data: CreateOneRecipeInputObject;
  ingredients?: InputMaybe<Array<RecipeToIngredientInputObject>>;
  kitchenId: Scalars['Int'];
  parentRecipes?: InputMaybe<Array<RecipeToRecipeInputObject>>;
};


export type MutationCreateOneSquareAuthoriseUrlArgs = {
  kitchenId: Scalars['Int'];
};


export type MutationCreateOneStockArgs = {
  data: CreateOneStockInputObject;
};


export type MutationCreateOneSupplierArgs = {
  data: CreateOneSupplierInputObject;
};


export type MutationCreateOneTodoNoteArgs = {
  data: CreateTodoNoteInput;
};


export type MutationCreateOneUnitArgs = {
  data: CreateOneUnitInputType;
};


export type MutationCreateOrUpdateDishesSoldArgs = {
  data: CreateOneDishesSoldObject;
  kitchenId: Scalars['Int'];
};


export type MutationCreateOrUpdateOneSaleArgs = {
  data: CreateOrUpdateOneSalesInputObject;
};


export type MutationCreateProductActionAlertArgs = {
  action: Scalars['String'];
  kitchenId: Scalars['Int'];
};


export type MutationDeleteKitchenInviteArgs = {
  data: DeleteKitchenInviteInput;
};


export type MutationDeleteKitchenToUserArgs = {
  data: KitchenToUserDeleteInput;
};


export type MutationDeleteManyPendingInvoiceArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteOneDishArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteOneFoodFlashArgs = {
  data: DeleteOneFoodFlashInputObject;
};


export type MutationDeleteOneIngredientArgs = {
  data: DeleteOneIngredientInputObject;
};


export type MutationDeleteOneInvoiceArgs = {
  data: DeleteOneInvoiceInputObject;
};


export type MutationDeleteOneKitchenAddressArgs = {
  data: DeleteOneKitchenAddressInputObject;
};


export type MutationDeleteOneKitchenToPosAccountArgs = {
  kitchenId: Scalars['Int'];
  posAccountId: Scalars['Int'];
};


export type MutationDeleteOneMenuArgs = {
  where: MenuWhereUniqueInput;
};


export type MutationDeleteOnePendingInvoiceArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteOneRecipeArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteOneStockArgs = {
  data: DeleteOneStockInputObject;
};


export type MutationDeletePosTokenArgs = {
  data: DeletePosTokenInput;
};


export type MutationDuplicateOneRecipeArgs = {
  kitchenId: Scalars['Int'];
  recipeId: Scalars['Int'];
};


export type MutationEventUserSelectedKitchenArgs = {
  appVersion?: InputMaybe<Scalars['String']>;
  kitchenId: Scalars['Int'];
};


export type MutationInvoiceUpdateV2Args = {
  entries: Array<InvoiceUpdateIngredientProduct>;
  invoiceDate?: InputMaybe<Scalars['DateTime']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  invoiceTotal?: InputMaybe<Scalars['Float']>;
  kitchenId: Scalars['Int'];
  pendingInvoiceId?: InputMaybe<Scalars['Int']>;
  supplier: InvoiceUpdateSupplier;
  vat?: InputMaybe<Scalars['Float']>;
};


export type MutationLinkPosLocationArgs = {
  data: LinkPosLocationInput;
};


export type MutationLinkPosProductArgs = {
  data: LinkPosProductInput;
};


export type MutationMergePendingInvoicesArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationRefreshArgs = {
  data?: InputMaybe<UserRefreshAdditionalDataInput>;
};


export type MutationRefreshPosTokenSquareArgs = {
  id: Scalars['Int'];
};


export type MutationRequestKitchenPermissionsArgs = {
  foodFlash?: InputMaybe<Scalars['Boolean']>;
  kitchenId: Scalars['Int'];
  ordering?: InputMaybe<Scalars['Boolean']>;
  stock?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRequestPasswordResetCodeArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  newPassword: Scalars['String'];
  newPasswordConfirm: Scalars['String'];
  passwordResetCode: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationSetFirstPasswordArgs = {
  password: Scalars['String'];
};


export type MutationSignUpPhoneArgs = {
  countryCode: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationSigninArgs = {
  appVersion?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  inviteCode?: InputMaybe<Scalars['String']>;
  joinCode?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationSignupArgs = {
  countryCode: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationSignupV2Args = {
  countryCode: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  inviteCode?: InputMaybe<Scalars['String']>;
  joinCode: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationSignupWithJoinCodeArgs = {
  appVersion?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  hubspotId?: InputMaybe<Scalars['String']>;
  joinCode: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationUnlinkPosProductArgs = {
  data: LinkPosProductInput;
};


export type MutationUpdateIngredientStockDataArgs = {
  data?: InputMaybe<Scalars['Json']>;
};


export type MutationUpdateKitchenPosCategoriesArgs = {
  kitchenId: Scalars['Int'];
  posCategories: Array<Scalars['Int']>;
  posTypeName: Scalars['String'];
};


export type MutationUpdateKitchenPosIncludesArgs = {
  includeGratuity: Scalars['Boolean'];
  includeRefunds: Scalars['Boolean'];
  includeServiceCharge: Scalars['Boolean'];
  kitchenId: Scalars['Int'];
};


export type MutationUpdateKitchenToUserArgs = {
  data: KitchenToUserUpdateInput;
};


export type MutationUpdateManyPendingInvoiceArgs = {
  data: PendingUpdateOneInvoiceInputObject;
  where: Array<PendingInvoiceWhereUniqueInput>;
};


export type MutationUpdateOneDishArgs = {
  allergens?: InputMaybe<Array<RecipeToAllergenInputObject>>;
  childRecipes?: InputMaybe<Array<RecipeToRecipeInputObject>>;
  data: UpdateOneDishInputObject;
  ingredients?: InputMaybe<Array<RecipeToIngredientInputObject>>;
  removeAllergens?: InputMaybe<Array<Scalars['Int']>>;
  removeChildRecipes?: InputMaybe<Array<Scalars['Int']>>;
  removeIngredients?: InputMaybe<Array<Scalars['Int']>>;
  updateAllergens?: InputMaybe<Array<UpdateRecipeToAllergenInputObject>>;
  updateChildRecipes?: InputMaybe<Array<UpdateRecipeToRecipeInputObject>>;
  updateIngredients?: InputMaybe<Array<UpdateRecipeToIngredientInputObject>>;
};


export type MutationUpdateOneFlashPeriodArgs = {
  data: UpdateFlashPeriodInput;
};


export type MutationUpdateOneFoodFlashArgs = {
  data: UpdateOneFoodFlashInputObject;
};


export type MutationUpdateOneIngredientArgs = {
  data: UpdateOneIngredientInputObject;
  kitchenId: Scalars['Int'];
  supplierData?: InputMaybe<CreateOneSupplierInputObject>;
  supplierId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateOneInvoiceArgs = {
  data: UpdateOneInvoiceInputObject;
  id: Scalars['Int'];
};


export type MutationUpdateOneKitchenArgs = {
  data: UpdateOneKitchenInputObject;
  removeUsers?: InputMaybe<Array<Scalars['Int']>>;
  suppliers?: InputMaybe<Array<Scalars['Int']>>;
  users?: InputMaybe<Array<KitchenToUserInputObject>>;
};


export type MutationUpdateOneKitchenAddressArgs = {
  data: UpdateOneKitchenAddressInputObject;
};


export type MutationUpdateOneKitchenInvoiceRuleArgs = {
  data: UpdateKitchenInvoiceRuleInput;
};


export type MutationUpdateOneKitchenToSupplierArgs = {
  data: KitchenToSupplierUpdateInput;
  where: KitchenToSupplierWhereUniqueInput;
};


export type MutationUpdateOneKitchenTodoArgs = {
  data: UpdateKitchenTodoInput;
};


export type MutationUpdateOneMenuArgs = {
  data: UpdateOneMenuInputObject;
  dishes?: InputMaybe<Array<MenuToDishInputObject>>;
  removeDishes?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationUpdateOnePendingInvoiceArgs = {
  data: PendingUpdateOneInvoiceInputObject;
  id: Scalars['Int'];
};


export type MutationUpdateOneProductArgs = {
  data: UpdateOneProductInputObject;
};


export type MutationUpdateOneRecipeArgs = {
  allergens?: InputMaybe<Array<RecipeToAllergenInputObject>>;
  childRecipes?: InputMaybe<Array<RecipeToRecipeInputObject>>;
  data: UpdateOneRecipeInputObject;
  ingredients?: InputMaybe<Array<RecipeToIngredientInputObject>>;
  parentRecipes?: InputMaybe<Array<RecipeToRecipeInputObject>>;
  removeAllergens?: InputMaybe<Array<Scalars['Int']>>;
  removeChildRecipes?: InputMaybe<Array<Scalars['Int']>>;
  removeIngredients?: InputMaybe<Array<Scalars['Int']>>;
  removeParentRecipes?: InputMaybe<Array<Scalars['Int']>>;
  updateAllergens?: InputMaybe<Array<UpdateRecipeToAllergenInputObject>>;
  updateChildRecipes?: InputMaybe<Array<UpdateRecipeToRecipeInputObject>>;
  updateIngredients?: InputMaybe<Array<UpdateRecipeToIngredientInputObject>>;
  updateParentRecipes?: InputMaybe<Array<UpdateRecipeToRecipeInputObject>>;
};


export type MutationUpdateOneStockArgs = {
  data: UpdateOneStockInputObject;
};


export type MutationUpdateOneSupplierArgs = {
  data: UpdateOneSupplierInputObject;
  kitchenId?: InputMaybe<Scalars['Int']>;
  supplierId: Scalars['Int'];
};


export type MutationUpdateOneUserArgs = {
  data: UpdateOneUserInputObject;
  removeKitchens?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationUpdateProductDuplicateArgs = {
  diffPrimaryId?: InputMaybe<Scalars['Boolean']>;
  duplicateId: Scalars['Int'];
  merge?: InputMaybe<Scalars['Boolean']>;
  primaryId: Scalars['Int'];
  productMergeId: Scalars['Int'];
};


export type MutationUpdateRecipeStockDataArgs = {
  data?: InputMaybe<Scalars['Json']>;
};


export type MutationUpsertPosAccountArgs = {
  data: UpsertPosAccountInput;
};


export type MutationUpsertPosCategoryArgs = {
  data: UpsertPosCategoryInput;
};


export type MutationUpsertPosLocationArgs = {
  data: UpsertPosLocationInput;
};


export type MutationUpsertPosProductArgs = {
  data: UpsertPosProductInput;
};


export type MutationUpsertPosTillArgs = {
  data: UpsertPosTillInput;
};


export type MutationUpsertPosTokenArgs = {
  data: UpsertPosTokenInput;
};


export type MutationUpsertPosTransactionArgs = {
  data: UpsertPosTransactionInput;
};


export type MutationUpsertPosTransactionItemArgs = {
  data: UpsertPosTransactionItemInput;
};


export type MutationUpsertPosTypeArgs = {
  data: UpsertPosTypeInput;
};


export type MutationUserTrackingArgs = {
  data: UserTrackingInputObject;
};


export type MutationVerifyEmailArgs = {
  emailVerificationCode: Scalars['String'];
};


export type MutationVerifySignUpPhoneArgs = {
  countryCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  pin: Scalars['String'];
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumInvoiceRejectionReasonFilter = {
  equals?: InputMaybe<InvoiceRejectionReason>;
  in?: InputMaybe<Array<InvoiceRejectionReason>>;
  not?: InputMaybe<NestedEnumInvoiceRejectionReasonFilter>;
  notIn?: InputMaybe<Array<InvoiceRejectionReason>>;
};

export type NestedEnumInvoiceStatusFilter = {
  equals?: InputMaybe<InvoiceStatus>;
  in?: InputMaybe<Array<InvoiceStatus>>;
  not?: InputMaybe<NestedEnumInvoiceStatusFilter>;
  notIn?: InputMaybe<Array<InvoiceStatus>>;
};

export type NestedEnumKitchenSupplierDataTypeFilter = {
  equals?: InputMaybe<KitchenSupplierDataType>;
  in?: InputMaybe<Array<KitchenSupplierDataType>>;
  not?: InputMaybe<NestedEnumKitchenSupplierDataTypeFilter>;
  notIn?: InputMaybe<Array<KitchenSupplierDataType>>;
};

export type NestedEnumMeasurementSystemFilter = {
  equals?: InputMaybe<MeasurementSystem>;
  in?: InputMaybe<Array<MeasurementSystem>>;
  not?: InputMaybe<NestedEnumMeasurementSystemFilter>;
  notIn?: InputMaybe<Array<MeasurementSystem>>;
};

export type NestedEnumProductStatusFilter = {
  equals?: InputMaybe<ProductStatus>;
  in?: InputMaybe<Array<ProductStatus>>;
  not?: InputMaybe<NestedEnumProductStatusFilter>;
  notIn?: InputMaybe<Array<ProductStatus>>;
};

export type NestedEnumPurchaseOrderSizeTypeFilter = {
  equals?: InputMaybe<PurchaseOrderSizeType>;
  in?: InputMaybe<Array<PurchaseOrderSizeType>>;
  not?: InputMaybe<NestedEnumPurchaseOrderSizeTypeFilter>;
  notIn?: InputMaybe<Array<PurchaseOrderSizeType>>;
};

export type NestedEnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type NestedEnumUnitTypeFilter = {
  equals?: InputMaybe<UnitType>;
  in?: InputMaybe<Array<UnitType>>;
  not?: InputMaybe<NestedEnumUnitTypeFilter>;
  notIn?: InputMaybe<Array<UnitType>>;
};

export type NestedEnumUserRoleFilter = {
  equals?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  not?: InputMaybe<NestedEnumUserRoleFilter>;
  notIn?: InputMaybe<Array<UserRole>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Node = {
  _cursor: Scalars['ID'];
};

export enum NoteTypeEnum {
  Error = 'error',
  System = 'system',
  User = 'user'
}

export enum NotificationStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Processing = 'processing'
}

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export enum OnboardState {
  AddInvoice = 'ADD_INVOICE',
  AddOrder = 'ADD_ORDER',
  AddRecipe = 'ADD_RECIPE',
  AddStocktake = 'ADD_STOCKTAKE',
  Complete = 'COMPLETE',
  ViewSpend = 'VIEW_SPEND',
  WatchPromo = 'WATCH_PROMO'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage?: Maybe<Scalars['Int']>;
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  skip: Scalars['Int'];
  startCursor?: Maybe<Scalars['String']>;
  take: Scalars['Int'];
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type PasswordResetCodeResponse = {
  __typename?: 'PasswordResetCodeResponse';
  message: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};

export type PendingInvoice = Node & {
  __typename?: 'PendingInvoice';
  _cursor: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['Int'];
  images: Array<Scalars['String']>;
  imagesProcessed: Array<Scalars['String']>;
  imagesProcessedSigned: Array<Scalars['String']>;
  invoice?: Maybe<Invoice>;
  isAuto: Scalars['Boolean'];
  isMulti: Scalars['Boolean'];
  kitchen: Kitchen;
  mergedInto?: Maybe<PendingInvoice>;
  notes: Array<InvoiceNote>;
  processJobId?: Maybe<Scalars['String']>;
  processReadyAt?: Maybe<Scalars['DateTime']>;
  processStartedAt?: Maybe<Scalars['DateTime']>;
  rejectionReason: Array<InvoiceRejectionReason>;
  rejectionReasonNotes?: Maybe<Scalars['String']>;
  signedImages: Array<Scalars['String']>;
  status: InvoiceStatus;
  suggestions: Array<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
};


export type PendingInvoiceSignedImagesArgs = {
  download?: InputMaybe<Scalars['Boolean']>;
};


export type PendingInvoiceSuggestionsArgs = {
  refresh?: InputMaybe<Scalars['Boolean']>;
};

export type PendingInvoiceConnection = {
  __typename?: 'PendingInvoiceConnection';
  edges: Array<PendingInvoiceEdge>;
  nodes: Array<PendingInvoice>;
  pageInfo: PageInfo;
};

export type PendingInvoiceEdge = {
  __typename?: 'PendingInvoiceEdge';
  cursor: Scalars['ID'];
  node: PendingInvoice;
};

export type PendingInvoiceGroupedConnection = {
  __typename?: 'PendingInvoiceGroupedConnection';
  count: Scalars['Int'];
  edges: Array<PendingInvoiceEdge>;
  nodes: Array<PendingInvoice>;
  pageInfo: PageInfo;
  pendingCount: Scalars['Int'];
};

export type PendingInvoiceListRelationFilter = {
  every?: InputMaybe<PendingInvoiceWhereInput>;
  none?: InputMaybe<PendingInvoiceWhereInput>;
  some?: InputMaybe<PendingInvoiceWhereInput>;
};

export type PendingInvoiceNoteWhereInput = {
  AND?: InputMaybe<Array<PendingInvoiceNoteWhereInput>>;
  NOT?: InputMaybe<Array<PendingInvoiceNoteWhereInput>>;
  OR?: InputMaybe<Array<PendingInvoiceNoteWhereInput>>;
  content?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  pendingInvoice?: InputMaybe<PendingInvoiceWhereInput>;
  pendingInvoiceId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type PendingInvoiceOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  isMulti?: InputMaybe<SortOrder>;
  kitchen?: InputMaybe<KitchenOrderByInput>;
  kitchenId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
};

export type PendingInvoiceOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type PendingInvoiceSummary = {
  __typename?: 'PendingInvoiceSummary';
  pendingKitchenInvoiceSummary: Array<PendingKitchenInvoiceSummary>;
  totalInvoices: Scalars['Int'];
  totalInvoicesCompleted: Scalars['Int'];
  totalInvoicesRejected: Scalars['Int'];
  totalTimeSpent: Scalars['Float'];
};

export type PendingInvoiceWhereInput = {
  AND?: InputMaybe<Array<PendingInvoiceWhereInput>>;
  NOT?: InputMaybe<Array<PendingInvoiceWhereInput>>;
  OR?: InputMaybe<Array<PendingInvoiceWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringNullableListFilter>;
  invoice?: InputMaybe<InvoiceWhereInput>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  notes?: InputMaybe<PendingInvoiceNoteWhereInput>;
  status?: InputMaybe<EnumInvoiceStatusFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type PendingInvoiceWhereUniqueInput = {
  id: Scalars['Int'];
};

export type PendingKitchenInvoiceSummary = {
  __typename?: 'PendingKitchenInvoiceSummary';
  invoicesCompleted: Scalars['Int'];
  invoicesRejected: Scalars['Int'];
  invoicesRemaining: Scalars['Int'];
  kitchenId: Scalars['Int'];
  kitchenName: Scalars['String'];
  kitchenPermissions: KitchenPermissions;
  timeSpent: Scalars['Float'];
  totalInvoices: Scalars['Int'];
};

export type PendingUpdateManyInvoiceInputObject = {
  data?: InputMaybe<PendingUpdateOneInvoiceInputObject>;
  where?: InputMaybe<PendingInvoiceWhereInput>;
};

export type PendingUpdateOneInvoiceInputObject = {
  isMulti?: InputMaybe<Scalars['Boolean']>;
  kitchenId?: InputMaybe<Scalars['Int']>;
  processedAt?: InputMaybe<Scalars['DateTime']>;
  processedById?: InputMaybe<Scalars['Int']>;
  rejectionReason?: InputMaybe<Array<InputMaybe<InvoiceRejectionReason>>>;
  rejectionReasonNotes?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<InvoiceStatus>;
};

export enum PeriodEnum {
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week'
}

export type PlacesAutoCompleteListWhereInput = {
  search: Scalars['String'];
};

export type PlacesDetailsListWhereInput = {
  placeId: Scalars['String'];
};

export type PosAccount = Node & {
  __typename?: 'PosAccount';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  posType: PosType;
  posTypeId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PosAllowedFilters = {
  __typename?: 'PosAllowedFilters';
  dry: Scalars['Boolean'];
  other: Scalars['Boolean'];
  wet: Scalars['Boolean'];
};

export type PosCategory = Node & {
  __typename?: 'PosCategory';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  id: Scalars['Int'];
  isWet?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  posAccount?: Maybe<PosAccount>;
  posAccountId?: Maybe<Scalars['Int']>;
  posCategory?: Maybe<PosCategory>;
  posParentCategoryId?: Maybe<Scalars['Int']>;
  posProductsCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};


export type PosCategoryPosProductsCountArgs = {
  kitchenId?: InputMaybe<Scalars['Int']>;
  linked?: InputMaybe<Scalars['Boolean']>;
};

export type PosLocation = Node & {
  __typename?: 'PosLocation';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  id: Scalars['Int'];
  kitchenId?: Maybe<Scalars['Int']>;
  kitchens: Array<Kitchen>;
  name: Scalars['String'];
  posAccount: PosAccount;
  posAccountId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PosProduct = Node & {
  __typename?: 'PosProduct';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  id: Scalars['Int'];
  isWet?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  posAccount: PosAccount;
  posAccountId: Scalars['Int'];
  posCategory?: Maybe<PosCategory>;
  posCategoryId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export enum PosStatus {
  Connected = 'connected',
  Filters = 'filters',
  Location = 'location'
}

export type PosTill = Node & {
  __typename?: 'PosTill';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  id: Scalars['Int'];
  posLocationId: Scalars['Int'];
  posTypeId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PosToken = Node & {
  __typename?: 'PosToken';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  posAccountId: Scalars['Int'];
  posLocationId?: Maybe<Scalars['Int']>;
  posTypeId: Scalars['Int'];
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PosTransaction = Node & {
  __typename?: 'PosTransaction';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  discount: Scalars['Int'];
  externalId: Scalars['String'];
  fees: Scalars['Int'];
  gratuity: Scalars['Int'];
  id: Scalars['Int'];
  posLocationId?: Maybe<Scalars['Int']>;
  sales: Scalars['Int'];
  serviceCharge: Scalars['Int'];
  tax: Scalars['Int'];
  total: Scalars['Int'];
  transactionAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PosTransactionItem = Node & {
  __typename?: 'PosTransactionItem';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  discount: Scalars['Int'];
  externalId: Scalars['String'];
  gratuity: Scalars['Int'];
  id: Scalars['Int'];
  modifiers: Scalars['Int'];
  posProductId: Scalars['Int'];
  posTransactionId: Scalars['Int'];
  quantity: Scalars['Int'];
  serviceCharge: Scalars['Int'];
  tax: Scalars['Int'];
  total: Scalars['Int'];
  unitPrice: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PosType = Node & {
  __typename?: 'PosType';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PriceChange = Node & {
  __typename?: 'PriceChange';
  _cursor: Scalars['ID'];
  id: Scalars['Int'];
  ingredientName: Scalars['String'];
  percentageChange: Scalars['Float'];
  price: Scalars['Float'];
  supplierId: Scalars['Int'];
  supplierName: Scalars['String'];
};

export type PriceChanges = {
  __typename?: 'PriceChanges';
  changes: Array<Maybe<PriceChange>>;
  date: Scalars['Date'];
};

export type PriceChangesOverview = {
  __typename?: 'PriceChangesOverview';
  priceChanges: Array<Maybe<PriceChanges>>;
  total: Scalars['Int'];
};

export type Product = Node & {
  __typename?: 'Product';
  _cursor: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['Int'];
  ingredientList: IngredientConnection;
  /** @deprecated Use or extend ingredientList field */
  ingredients: Array<Ingredient>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: Maybe<Scalars['Float']>;
  productMergeList: ProductMergeConnection;
  /** @deprecated Use or extend productMergeList field */
  productMerges: Array<ProductMerge>;
  similarProductList: ProductConnection;
  /** @deprecated Use or extend similarProductList field */
  similarProducts: Array<Product>;
  status: ProductStatus;
  supplier: Supplier;
  unit: Unit;
  unitValue: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
};


export type ProductIngredientListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IngredientWhereInput>;
};


export type ProductIngredientsArgs = {
  cursor?: InputMaybe<IngredientWhereUniqueInput>;
  orderBy?: InputMaybe<Array<IngredientOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IngredientWhereInput>;
};


export type ProductProductMergeListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type ProductProductMergesArgs = {
  cursor?: InputMaybe<ProductMergeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductMergeWhereInput>;
};


export type ProductSimilarProductListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type ProductSimilarProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  edges: Array<ProductEdge>;
  nodes: Array<Product>;
  pageInfo: PageInfo;
};

export type ProductCreateManyCreatedByInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<ProductStatus>;
  supplierId: Scalars['Int'];
  unitId: Scalars['Int'];
  unitValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type ProductCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<ProductCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCreateManySupplierInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<ProductStatus>;
  unitId: Scalars['Int'];
  unitValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type ProductCreateManySupplierInputEnvelope = {
  data?: InputMaybe<Array<ProductCreateManySupplierInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCreateManyUnitInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<ProductStatus>;
  supplierId: Scalars['Int'];
  unitValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type ProductCreateManyUnitInputEnvelope = {
  data?: InputMaybe<Array<ProductCreateManyUnitInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCreateManyUpdatedByInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<ProductStatus>;
  supplierId: Scalars['Int'];
  unitId: Scalars['Int'];
  unitValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductCreateManyUpdatedByInputEnvelope = {
  data?: InputMaybe<Array<ProductCreateManyUpdatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<ProductCreateManyCreatedByInputEnvelope>;
};

export type ProductCreateNestedManyWithoutSupplierInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutSupplierInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutSupplierInput>>;
  createMany?: InputMaybe<ProductCreateManySupplierInputEnvelope>;
};

export type ProductCreateNestedManyWithoutUnitInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutUnitInput>>;
  createMany?: InputMaybe<ProductCreateManyUnitInputEnvelope>;
};

export type ProductCreateNestedManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<ProductCreateManyUpdatedByInputEnvelope>;
};

export type ProductCreateNestedOneWithoutIngredientsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutIngredientsInput>;
  create?: InputMaybe<ProductCreateWithoutIngredientsInput>;
};

export type ProductCreateNestedOneWithoutMergedProductsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutMergedProductsInput>;
  create?: InputMaybe<ProductCreateWithoutMergedProductsInput>;
};

export type ProductCreateNestedOneWithoutProductDuplicatesDuplicateInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductDuplicatesDuplicateInput>;
  create?: InputMaybe<ProductCreateWithoutProductDuplicatesDuplicateInput>;
};

export type ProductCreateNestedOneWithoutProductDuplicatesPrimaryInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductDuplicatesPrimaryInput>;
  create?: InputMaybe<ProductCreateWithoutProductDuplicatesPrimaryInput>;
};

export type ProductCreateNestedOneWithoutProductMergesInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductMergesInput>;
  create?: InputMaybe<ProductCreateWithoutProductMergesInput>;
};

export type ProductCreateNestedOneWithoutPurchaseOrderEntriesInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutPurchaseOrderEntriesInput>;
  create?: InputMaybe<ProductCreateWithoutPurchaseOrderEntriesInput>;
};

export type ProductCreateOrConnectWithoutCreatedByInput = {
  create: ProductCreateWithoutCreatedByInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutIngredientsInput = {
  create: ProductCreateWithoutIngredientsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutMergedProductsInput = {
  create: ProductCreateWithoutMergedProductsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutProductDuplicatesDuplicateInput = {
  create: ProductCreateWithoutProductDuplicatesDuplicateInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutProductDuplicatesPrimaryInput = {
  create: ProductCreateWithoutProductDuplicatesPrimaryInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutProductMergesInput = {
  create: ProductCreateWithoutProductMergesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutPurchaseOrderEntriesInput = {
  create: ProductCreateWithoutPurchaseOrderEntriesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutSupplierInput = {
  create: ProductCreateWithoutSupplierInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutUnitInput = {
  create: ProductCreateWithoutUnitInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutUpdatedByInput = {
  create: ProductCreateWithoutUpdatedByInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutCreatedByInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeCreateNestedManyWithoutMergedProductInput>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<ProductStatus>;
  supplier: SupplierCreateNestedOneWithoutProductsInput;
  unit: UnitCreateNestedOneWithoutProductUnitInput;
  unitValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductUpdatedInput;
};

export type ProductCreateWithoutIngredientsInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutProductCreatedInput;
  mergedProducts?: InputMaybe<ProductMergeCreateNestedManyWithoutMergedProductInput>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<ProductStatus>;
  supplier: SupplierCreateNestedOneWithoutProductsInput;
  unit: UnitCreateNestedOneWithoutProductUnitInput;
  unitValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductUpdatedInput;
};

export type ProductCreateWithoutMergedProductsInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutProductCreatedInput;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutProductInput>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<ProductStatus>;
  supplier: SupplierCreateNestedOneWithoutProductsInput;
  unit: UnitCreateNestedOneWithoutProductUnitInput;
  unitValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductUpdatedInput;
};

export type ProductCreateWithoutProductDuplicatesDuplicateInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutProductCreatedInput;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeCreateNestedManyWithoutMergedProductInput>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<ProductStatus>;
  supplier: SupplierCreateNestedOneWithoutProductsInput;
  unit: UnitCreateNestedOneWithoutProductUnitInput;
  unitValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductUpdatedInput;
};

export type ProductCreateWithoutProductDuplicatesPrimaryInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutProductCreatedInput;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeCreateNestedManyWithoutMergedProductInput>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutDuplicateProductInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<ProductStatus>;
  supplier: SupplierCreateNestedOneWithoutProductsInput;
  unit: UnitCreateNestedOneWithoutProductUnitInput;
  unitValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductUpdatedInput;
};

export type ProductCreateWithoutProductMergesInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutProductCreatedInput;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeCreateNestedManyWithoutMergedProductInput>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<ProductStatus>;
  supplier: SupplierCreateNestedOneWithoutProductsInput;
  unit: UnitCreateNestedOneWithoutProductUnitInput;
  unitValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductUpdatedInput;
};

export type ProductCreateWithoutPurchaseOrderEntriesInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutProductCreatedInput;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeCreateNestedManyWithoutMergedProductInput>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutPrimaryProductInput>;
  status?: InputMaybe<ProductStatus>;
  supplier: SupplierCreateNestedOneWithoutProductsInput;
  unit: UnitCreateNestedOneWithoutProductUnitInput;
  unitValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductUpdatedInput;
};

export type ProductCreateWithoutSupplierInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutProductCreatedInput;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeCreateNestedManyWithoutMergedProductInput>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<ProductStatus>;
  unit: UnitCreateNestedOneWithoutProductUnitInput;
  unitValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductUpdatedInput;
};

export type ProductCreateWithoutUnitInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutProductCreatedInput;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeCreateNestedManyWithoutMergedProductInput>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<ProductStatus>;
  supplier: SupplierCreateNestedOneWithoutProductsInput;
  unitValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductUpdatedInput;
};

export type ProductCreateWithoutUpdatedByInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutProductCreatedInput;
  ingredients?: InputMaybe<IngredientCreateNestedManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeCreateNestedManyWithoutMergedProductInput>;
  name: Scalars['String'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<ProductStatus>;
  supplier: SupplierCreateNestedOneWithoutProductsInput;
  unit: UnitCreateNestedOneWithoutProductUnitInput;
  unitValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductDuplicateListSummary = {
  __typename?: 'ProductDuplicateListSummary';
  matchesChecked: Scalars['Int'];
  matchesRemaining: Scalars['Int'];
  total: Scalars['Int'];
};

export type ProductDuplicates = Node & {
  __typename?: 'ProductDuplicates';
  _cursor: Scalars['ID'];
  assignedAt?: Maybe<Scalars['DateTime']>;
  assignedTo?: Maybe<User>;
  batchId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  duplicateProduct: Product;
  id: Scalars['Int'];
  primaryProduct: Product;
  similiarity?: Maybe<Scalars['Float']>;
  status: Scalars['Int'];
  supplier: Supplier;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type ProductDuplicatesConnection = {
  __typename?: 'ProductDuplicatesConnection';
  edges: Array<ProductDuplicatesEdge>;
  nodes: Array<ProductDuplicates>;
  pageInfo: PageInfo;
};

export type ProductDuplicatesCreateManyAssignedToInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  batchId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  duplicateProductId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  primaryProductId: Scalars['Int'];
  similiarity?: InputMaybe<Scalars['Float']>;
  status: Scalars['Int'];
  supplierId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type ProductDuplicatesCreateManyAssignedToInputEnvelope = {
  data?: InputMaybe<Array<ProductDuplicatesCreateManyAssignedToInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductDuplicatesCreateManyBatchInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  assignedToId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  duplicateProductId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  primaryProductId: Scalars['Int'];
  similiarity?: InputMaybe<Scalars['Float']>;
  status: Scalars['Int'];
  supplierId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type ProductDuplicatesCreateManyBatchInputEnvelope = {
  data?: InputMaybe<Array<ProductDuplicatesCreateManyBatchInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductDuplicatesCreateManyDuplicateProductInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  assignedToId?: InputMaybe<Scalars['Int']>;
  batchId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  primaryProductId: Scalars['Int'];
  similiarity?: InputMaybe<Scalars['Float']>;
  status: Scalars['Int'];
  supplierId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type ProductDuplicatesCreateManyDuplicateProductInputEnvelope = {
  data?: InputMaybe<Array<ProductDuplicatesCreateManyDuplicateProductInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductDuplicatesCreateManyLookupStatusInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  assignedToId?: InputMaybe<Scalars['Int']>;
  batchId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  duplicateProductId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  primaryProductId: Scalars['Int'];
  similiarity?: InputMaybe<Scalars['Float']>;
  supplierId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type ProductDuplicatesCreateManyLookupStatusInputEnvelope = {
  data?: InputMaybe<Array<ProductDuplicatesCreateManyLookupStatusInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductDuplicatesCreateManyPrimaryProductInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  assignedToId?: InputMaybe<Scalars['Int']>;
  batchId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  duplicateProductId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  similiarity?: InputMaybe<Scalars['Float']>;
  status: Scalars['Int'];
  supplierId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type ProductDuplicatesCreateManyPrimaryProductInputEnvelope = {
  data?: InputMaybe<Array<ProductDuplicatesCreateManyPrimaryProductInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductDuplicatesCreateManySupplierInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  assignedToId?: InputMaybe<Scalars['Int']>;
  batchId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  duplicateProductId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  primaryProductId: Scalars['Int'];
  similiarity?: InputMaybe<Scalars['Float']>;
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type ProductDuplicatesCreateManySupplierInputEnvelope = {
  data?: InputMaybe<Array<ProductDuplicatesCreateManySupplierInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductDuplicatesCreateManyUpdatedByInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  assignedToId?: InputMaybe<Scalars['Int']>;
  batchId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  duplicateProductId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  primaryProductId: Scalars['Int'];
  similiarity?: InputMaybe<Scalars['Float']>;
  status: Scalars['Int'];
  supplierId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductDuplicatesCreateManyUpdatedByInputEnvelope = {
  data?: InputMaybe<Array<ProductDuplicatesCreateManyUpdatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductDuplicatesCreateNestedManyWithoutAssignedToInput = {
  connect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductDuplicatesCreateOrConnectWithoutAssignedToInput>>;
  create?: InputMaybe<Array<ProductDuplicatesCreateWithoutAssignedToInput>>;
  createMany?: InputMaybe<ProductDuplicatesCreateManyAssignedToInputEnvelope>;
};

export type ProductDuplicatesCreateNestedManyWithoutBatchInput = {
  connect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductDuplicatesCreateOrConnectWithoutBatchInput>>;
  create?: InputMaybe<Array<ProductDuplicatesCreateWithoutBatchInput>>;
  createMany?: InputMaybe<ProductDuplicatesCreateManyBatchInputEnvelope>;
};

export type ProductDuplicatesCreateNestedManyWithoutDuplicateProductInput = {
  connect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductDuplicatesCreateOrConnectWithoutDuplicateProductInput>>;
  create?: InputMaybe<Array<ProductDuplicatesCreateWithoutDuplicateProductInput>>;
  createMany?: InputMaybe<ProductDuplicatesCreateManyDuplicateProductInputEnvelope>;
};

export type ProductDuplicatesCreateNestedManyWithoutLookupStatusInput = {
  connect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductDuplicatesCreateOrConnectWithoutLookupStatusInput>>;
  create?: InputMaybe<Array<ProductDuplicatesCreateWithoutLookupStatusInput>>;
  createMany?: InputMaybe<ProductDuplicatesCreateManyLookupStatusInputEnvelope>;
};

export type ProductDuplicatesCreateNestedManyWithoutPrimaryProductInput = {
  connect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductDuplicatesCreateOrConnectWithoutPrimaryProductInput>>;
  create?: InputMaybe<Array<ProductDuplicatesCreateWithoutPrimaryProductInput>>;
  createMany?: InputMaybe<ProductDuplicatesCreateManyPrimaryProductInputEnvelope>;
};

export type ProductDuplicatesCreateNestedManyWithoutSupplierInput = {
  connect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductDuplicatesCreateOrConnectWithoutSupplierInput>>;
  create?: InputMaybe<Array<ProductDuplicatesCreateWithoutSupplierInput>>;
  createMany?: InputMaybe<ProductDuplicatesCreateManySupplierInputEnvelope>;
};

export type ProductDuplicatesCreateNestedManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductDuplicatesCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<ProductDuplicatesCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<ProductDuplicatesCreateManyUpdatedByInputEnvelope>;
};

export type ProductDuplicatesCreateOrConnectWithoutAssignedToInput = {
  create: ProductDuplicatesCreateWithoutAssignedToInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesCreateOrConnectWithoutBatchInput = {
  create: ProductDuplicatesCreateWithoutBatchInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesCreateOrConnectWithoutDuplicateProductInput = {
  create: ProductDuplicatesCreateWithoutDuplicateProductInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesCreateOrConnectWithoutLookupStatusInput = {
  create: ProductDuplicatesCreateWithoutLookupStatusInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesCreateOrConnectWithoutPrimaryProductInput = {
  create: ProductDuplicatesCreateWithoutPrimaryProductInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesCreateOrConnectWithoutSupplierInput = {
  create: ProductDuplicatesCreateWithoutSupplierInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesCreateOrConnectWithoutUpdatedByInput = {
  create: ProductDuplicatesCreateWithoutUpdatedByInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesCreateWithoutAssignedToInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  batch: BatchCreateNestedOneWithoutProductDuplicatesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  duplicateProduct: ProductCreateNestedOneWithoutProductDuplicatesDuplicateInput;
  primaryProduct: ProductCreateNestedOneWithoutProductDuplicatesPrimaryInput;
  similiarity?: InputMaybe<Scalars['Float']>;
  supplier: SupplierCreateNestedOneWithoutProductDuplicatesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductDuplicatesUpdatedInput;
};

export type ProductDuplicatesCreateWithoutBatchInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  assignedTo?: InputMaybe<UserCreateNestedOneWithoutProductDuplicatesAssignedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  duplicateProduct: ProductCreateNestedOneWithoutProductDuplicatesDuplicateInput;
  primaryProduct: ProductCreateNestedOneWithoutProductDuplicatesPrimaryInput;
  similiarity?: InputMaybe<Scalars['Float']>;
  supplier: SupplierCreateNestedOneWithoutProductDuplicatesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductDuplicatesUpdatedInput;
};

export type ProductDuplicatesCreateWithoutDuplicateProductInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  assignedTo?: InputMaybe<UserCreateNestedOneWithoutProductDuplicatesAssignedInput>;
  batch: BatchCreateNestedOneWithoutProductDuplicatesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  primaryProduct: ProductCreateNestedOneWithoutProductDuplicatesPrimaryInput;
  similiarity?: InputMaybe<Scalars['Float']>;
  supplier: SupplierCreateNestedOneWithoutProductDuplicatesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductDuplicatesUpdatedInput;
};

export type ProductDuplicatesCreateWithoutLookupStatusInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  assignedTo?: InputMaybe<UserCreateNestedOneWithoutProductDuplicatesAssignedInput>;
  batch: BatchCreateNestedOneWithoutProductDuplicatesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  duplicateProduct: ProductCreateNestedOneWithoutProductDuplicatesDuplicateInput;
  primaryProduct: ProductCreateNestedOneWithoutProductDuplicatesPrimaryInput;
  similiarity?: InputMaybe<Scalars['Float']>;
  supplier: SupplierCreateNestedOneWithoutProductDuplicatesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductDuplicatesUpdatedInput;
};

export type ProductDuplicatesCreateWithoutPrimaryProductInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  assignedTo?: InputMaybe<UserCreateNestedOneWithoutProductDuplicatesAssignedInput>;
  batch: BatchCreateNestedOneWithoutProductDuplicatesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  duplicateProduct: ProductCreateNestedOneWithoutProductDuplicatesDuplicateInput;
  similiarity?: InputMaybe<Scalars['Float']>;
  supplier: SupplierCreateNestedOneWithoutProductDuplicatesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductDuplicatesUpdatedInput;
};

export type ProductDuplicatesCreateWithoutSupplierInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  assignedTo?: InputMaybe<UserCreateNestedOneWithoutProductDuplicatesAssignedInput>;
  batch: BatchCreateNestedOneWithoutProductDuplicatesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  duplicateProduct: ProductCreateNestedOneWithoutProductDuplicatesDuplicateInput;
  primaryProduct: ProductCreateNestedOneWithoutProductDuplicatesPrimaryInput;
  similiarity?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductDuplicatesUpdatedInput;
};

export type ProductDuplicatesCreateWithoutUpdatedByInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  assignedTo?: InputMaybe<UserCreateNestedOneWithoutProductDuplicatesAssignedInput>;
  batch: BatchCreateNestedOneWithoutProductDuplicatesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  duplicateProduct: ProductCreateNestedOneWithoutProductDuplicatesDuplicateInput;
  primaryProduct: ProductCreateNestedOneWithoutProductDuplicatesPrimaryInput;
  similiarity?: InputMaybe<Scalars['Float']>;
  supplier: SupplierCreateNestedOneWithoutProductDuplicatesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductDuplicatesEdge = {
  __typename?: 'ProductDuplicatesEdge';
  cursor: Scalars['ID'];
  node: ProductDuplicates;
};

export type ProductDuplicatesListRelationFilter = {
  every?: InputMaybe<ProductDuplicatesWhereInput>;
  none?: InputMaybe<ProductDuplicatesWhereInput>;
  some?: InputMaybe<ProductDuplicatesWhereInput>;
};

export type ProductDuplicatesOrderByInput = {
  assignedAt?: InputMaybe<SortOrder>;
  assignedTo?: InputMaybe<UserOrderByInput>;
  assignedToId?: InputMaybe<SortOrder>;
  batch?: InputMaybe<BatchOrderByInput>;
  batchId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  duplicateProduct?: InputMaybe<ProductOrderByInput>;
  duplicateProductId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  primaryProduct?: InputMaybe<ProductOrderByInput>;
  primaryProductId?: InputMaybe<SortOrder>;
  similiarity?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  supplier?: InputMaybe<SupplierOrderByInput>;
  supplierId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
};

export type ProductDuplicatesOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type ProductDuplicatesPrimaryProductIdDuplicateProductIdStatusCompoundUniqueInput = {
  duplicateProductId: Scalars['Int'];
  primaryProductId: Scalars['Int'];
  status: Scalars['Int'];
};

export type ProductDuplicatesScalarWhereInput = {
  AND?: InputMaybe<Array<ProductDuplicatesScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductDuplicatesScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductDuplicatesScalarWhereInput>>;
  assignedAt?: InputMaybe<DateTimeNullableFilter>;
  assignedToId?: InputMaybe<IntNullableFilter>;
  batchId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  duplicateProductId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  primaryProductId?: InputMaybe<IntFilter>;
  similiarity?: InputMaybe<FloatNullableFilter>;
  status?: InputMaybe<IntFilter>;
  supplierId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  updatedById?: InputMaybe<IntFilter>;
};

export type ProductDuplicatesUpdateManyMutationInput = {
  assignedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  similiarity?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductDuplicatesUpdateManyWithWhereWithoutAssignedToInput = {
  data: ProductDuplicatesUpdateManyMutationInput;
  where: ProductDuplicatesScalarWhereInput;
};

export type ProductDuplicatesUpdateManyWithWhereWithoutBatchInput = {
  data: ProductDuplicatesUpdateManyMutationInput;
  where: ProductDuplicatesScalarWhereInput;
};

export type ProductDuplicatesUpdateManyWithWhereWithoutDuplicateProductInput = {
  data: ProductDuplicatesUpdateManyMutationInput;
  where: ProductDuplicatesScalarWhereInput;
};

export type ProductDuplicatesUpdateManyWithWhereWithoutLookupStatusInput = {
  data: ProductDuplicatesUpdateManyMutationInput;
  where: ProductDuplicatesScalarWhereInput;
};

export type ProductDuplicatesUpdateManyWithWhereWithoutPrimaryProductInput = {
  data: ProductDuplicatesUpdateManyMutationInput;
  where: ProductDuplicatesScalarWhereInput;
};

export type ProductDuplicatesUpdateManyWithWhereWithoutSupplierInput = {
  data: ProductDuplicatesUpdateManyMutationInput;
  where: ProductDuplicatesScalarWhereInput;
};

export type ProductDuplicatesUpdateManyWithWhereWithoutUpdatedByInput = {
  data: ProductDuplicatesUpdateManyMutationInput;
  where: ProductDuplicatesScalarWhereInput;
};

export type ProductDuplicatesUpdateManyWithoutAssignedToInput = {
  connect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductDuplicatesCreateOrConnectWithoutAssignedToInput>>;
  create?: InputMaybe<Array<ProductDuplicatesCreateWithoutAssignedToInput>>;
  createMany?: InputMaybe<ProductDuplicatesCreateManyAssignedToInputEnvelope>;
  delete?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductDuplicatesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductDuplicatesUpdateWithWhereUniqueWithoutAssignedToInput>>;
  updateMany?: InputMaybe<Array<ProductDuplicatesUpdateManyWithWhereWithoutAssignedToInput>>;
  upsert?: InputMaybe<Array<ProductDuplicatesUpsertWithWhereUniqueWithoutAssignedToInput>>;
};

export type ProductDuplicatesUpdateManyWithoutBatchInput = {
  connect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductDuplicatesCreateOrConnectWithoutBatchInput>>;
  create?: InputMaybe<Array<ProductDuplicatesCreateWithoutBatchInput>>;
  createMany?: InputMaybe<ProductDuplicatesCreateManyBatchInputEnvelope>;
  delete?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductDuplicatesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductDuplicatesUpdateWithWhereUniqueWithoutBatchInput>>;
  updateMany?: InputMaybe<Array<ProductDuplicatesUpdateManyWithWhereWithoutBatchInput>>;
  upsert?: InputMaybe<Array<ProductDuplicatesUpsertWithWhereUniqueWithoutBatchInput>>;
};

export type ProductDuplicatesUpdateManyWithoutDuplicateProductInput = {
  connect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductDuplicatesCreateOrConnectWithoutDuplicateProductInput>>;
  create?: InputMaybe<Array<ProductDuplicatesCreateWithoutDuplicateProductInput>>;
  createMany?: InputMaybe<ProductDuplicatesCreateManyDuplicateProductInputEnvelope>;
  delete?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductDuplicatesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductDuplicatesUpdateWithWhereUniqueWithoutDuplicateProductInput>>;
  updateMany?: InputMaybe<Array<ProductDuplicatesUpdateManyWithWhereWithoutDuplicateProductInput>>;
  upsert?: InputMaybe<Array<ProductDuplicatesUpsertWithWhereUniqueWithoutDuplicateProductInput>>;
};

export type ProductDuplicatesUpdateManyWithoutLookupStatusInput = {
  connect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductDuplicatesCreateOrConnectWithoutLookupStatusInput>>;
  create?: InputMaybe<Array<ProductDuplicatesCreateWithoutLookupStatusInput>>;
  createMany?: InputMaybe<ProductDuplicatesCreateManyLookupStatusInputEnvelope>;
  delete?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductDuplicatesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductDuplicatesUpdateWithWhereUniqueWithoutLookupStatusInput>>;
  updateMany?: InputMaybe<Array<ProductDuplicatesUpdateManyWithWhereWithoutLookupStatusInput>>;
  upsert?: InputMaybe<Array<ProductDuplicatesUpsertWithWhereUniqueWithoutLookupStatusInput>>;
};

export type ProductDuplicatesUpdateManyWithoutPrimaryProductInput = {
  connect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductDuplicatesCreateOrConnectWithoutPrimaryProductInput>>;
  create?: InputMaybe<Array<ProductDuplicatesCreateWithoutPrimaryProductInput>>;
  createMany?: InputMaybe<ProductDuplicatesCreateManyPrimaryProductInputEnvelope>;
  delete?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductDuplicatesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductDuplicatesUpdateWithWhereUniqueWithoutPrimaryProductInput>>;
  updateMany?: InputMaybe<Array<ProductDuplicatesUpdateManyWithWhereWithoutPrimaryProductInput>>;
  upsert?: InputMaybe<Array<ProductDuplicatesUpsertWithWhereUniqueWithoutPrimaryProductInput>>;
};

export type ProductDuplicatesUpdateManyWithoutSupplierInput = {
  connect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductDuplicatesCreateOrConnectWithoutSupplierInput>>;
  create?: InputMaybe<Array<ProductDuplicatesCreateWithoutSupplierInput>>;
  createMany?: InputMaybe<ProductDuplicatesCreateManySupplierInputEnvelope>;
  delete?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductDuplicatesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductDuplicatesUpdateWithWhereUniqueWithoutSupplierInput>>;
  updateMany?: InputMaybe<Array<ProductDuplicatesUpdateManyWithWhereWithoutSupplierInput>>;
  upsert?: InputMaybe<Array<ProductDuplicatesUpsertWithWhereUniqueWithoutSupplierInput>>;
};

export type ProductDuplicatesUpdateManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductDuplicatesCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<ProductDuplicatesCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<ProductDuplicatesCreateManyUpdatedByInputEnvelope>;
  delete?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductDuplicatesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductDuplicatesWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductDuplicatesUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: InputMaybe<Array<ProductDuplicatesUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: InputMaybe<Array<ProductDuplicatesUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type ProductDuplicatesUpdateWithWhereUniqueWithoutAssignedToInput = {
  data: ProductDuplicatesUpdateWithoutAssignedToInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesUpdateWithWhereUniqueWithoutBatchInput = {
  data: ProductDuplicatesUpdateWithoutBatchInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesUpdateWithWhereUniqueWithoutDuplicateProductInput = {
  data: ProductDuplicatesUpdateWithoutDuplicateProductInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesUpdateWithWhereUniqueWithoutLookupStatusInput = {
  data: ProductDuplicatesUpdateWithoutLookupStatusInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesUpdateWithWhereUniqueWithoutPrimaryProductInput = {
  data: ProductDuplicatesUpdateWithoutPrimaryProductInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesUpdateWithWhereUniqueWithoutSupplierInput = {
  data: ProductDuplicatesUpdateWithoutSupplierInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: ProductDuplicatesUpdateWithoutUpdatedByInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesUpdateWithoutAssignedToInput = {
  assignedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  batch?: InputMaybe<BatchUpdateOneRequiredWithoutProductDuplicatesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicateProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductDuplicatesDuplicateInput>;
  primaryProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductDuplicatesPrimaryInput>;
  similiarity?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductDuplicatesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductDuplicatesUpdatedInput>;
};

export type ProductDuplicatesUpdateWithoutBatchInput = {
  assignedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assignedTo?: InputMaybe<UserUpdateOneWithoutProductDuplicatesAssignedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicateProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductDuplicatesDuplicateInput>;
  primaryProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductDuplicatesPrimaryInput>;
  similiarity?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductDuplicatesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductDuplicatesUpdatedInput>;
};

export type ProductDuplicatesUpdateWithoutDuplicateProductInput = {
  assignedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assignedTo?: InputMaybe<UserUpdateOneWithoutProductDuplicatesAssignedInput>;
  batch?: InputMaybe<BatchUpdateOneRequiredWithoutProductDuplicatesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  primaryProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductDuplicatesPrimaryInput>;
  similiarity?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductDuplicatesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductDuplicatesUpdatedInput>;
};

export type ProductDuplicatesUpdateWithoutLookupStatusInput = {
  assignedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assignedTo?: InputMaybe<UserUpdateOneWithoutProductDuplicatesAssignedInput>;
  batch?: InputMaybe<BatchUpdateOneRequiredWithoutProductDuplicatesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicateProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductDuplicatesDuplicateInput>;
  primaryProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductDuplicatesPrimaryInput>;
  similiarity?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductDuplicatesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductDuplicatesUpdatedInput>;
};

export type ProductDuplicatesUpdateWithoutPrimaryProductInput = {
  assignedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assignedTo?: InputMaybe<UserUpdateOneWithoutProductDuplicatesAssignedInput>;
  batch?: InputMaybe<BatchUpdateOneRequiredWithoutProductDuplicatesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicateProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductDuplicatesDuplicateInput>;
  similiarity?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductDuplicatesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductDuplicatesUpdatedInput>;
};

export type ProductDuplicatesUpdateWithoutSupplierInput = {
  assignedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assignedTo?: InputMaybe<UserUpdateOneWithoutProductDuplicatesAssignedInput>;
  batch?: InputMaybe<BatchUpdateOneRequiredWithoutProductDuplicatesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicateProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductDuplicatesDuplicateInput>;
  primaryProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductDuplicatesPrimaryInput>;
  similiarity?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductDuplicatesUpdatedInput>;
};

export type ProductDuplicatesUpdateWithoutUpdatedByInput = {
  assignedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assignedTo?: InputMaybe<UserUpdateOneWithoutProductDuplicatesAssignedInput>;
  batch?: InputMaybe<BatchUpdateOneRequiredWithoutProductDuplicatesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicateProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductDuplicatesDuplicateInput>;
  primaryProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductDuplicatesPrimaryInput>;
  similiarity?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductDuplicatesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductDuplicatesUpsertWithWhereUniqueWithoutAssignedToInput = {
  create: ProductDuplicatesCreateWithoutAssignedToInput;
  update: ProductDuplicatesUpdateWithoutAssignedToInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesUpsertWithWhereUniqueWithoutBatchInput = {
  create: ProductDuplicatesCreateWithoutBatchInput;
  update: ProductDuplicatesUpdateWithoutBatchInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesUpsertWithWhereUniqueWithoutDuplicateProductInput = {
  create: ProductDuplicatesCreateWithoutDuplicateProductInput;
  update: ProductDuplicatesUpdateWithoutDuplicateProductInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesUpsertWithWhereUniqueWithoutLookupStatusInput = {
  create: ProductDuplicatesCreateWithoutLookupStatusInput;
  update: ProductDuplicatesUpdateWithoutLookupStatusInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesUpsertWithWhereUniqueWithoutPrimaryProductInput = {
  create: ProductDuplicatesCreateWithoutPrimaryProductInput;
  update: ProductDuplicatesUpdateWithoutPrimaryProductInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesUpsertWithWhereUniqueWithoutSupplierInput = {
  create: ProductDuplicatesCreateWithoutSupplierInput;
  update: ProductDuplicatesUpdateWithoutSupplierInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: ProductDuplicatesCreateWithoutUpdatedByInput;
  update: ProductDuplicatesUpdateWithoutUpdatedByInput;
  where: ProductDuplicatesWhereUniqueInput;
};

export type ProductDuplicatesWhereInput = {
  AND?: InputMaybe<Array<ProductDuplicatesWhereInput>>;
  NOT?: InputMaybe<Array<ProductDuplicatesWhereInput>>;
  OR?: InputMaybe<Array<ProductDuplicatesWhereInput>>;
  assignedAt?: InputMaybe<DateTimeNullableFilter>;
  assignedTo?: InputMaybe<UserWhereInput>;
  assignedToId?: InputMaybe<IntNullableFilter>;
  batch?: InputMaybe<BatchWhereInput>;
  batchId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  duplicateProduct?: InputMaybe<ProductWhereInput>;
  duplicateProductId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  primaryProduct?: InputMaybe<ProductWhereInput>;
  primaryProductId?: InputMaybe<IntFilter>;
  similiarity?: InputMaybe<FloatNullableFilter>;
  status?: InputMaybe<IntFilter>;
  supplier?: InputMaybe<SupplierWhereInput>;
  supplierId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type ProductDuplicatesWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  primaryProductId_duplicateProductId_status?: InputMaybe<ProductDuplicatesPrimaryProductIdDuplicateProductIdStatusCompoundUniqueInput>;
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  cursor: Scalars['ID'];
  node: Product;
};

export type ProductFuzzyConnection = {
  __typename?: 'ProductFuzzyConnection';
  catalogueCount: Scalars['Int'];
  edges: Array<ProductEdge>;
  kitchenCount: Scalars['Int'];
  nodes: Array<Product>;
  pageInfo: PageInfo;
  recentCount: Scalars['Int'];
};

export type ProductListRelationFilter = {
  every?: InputMaybe<ProductWhereInput>;
  none?: InputMaybe<ProductWhereInput>;
  some?: InputMaybe<ProductWhereInput>;
};

export type ProductMerge = Node & {
  __typename?: 'ProductMerge';
  _cursor: Scalars['ID'];
  batchId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['Int'];
  ingredientIds: Array<Scalars['Int']>;
  kitchenNames: Array<Scalars['String']>;
  mergedProduct: Product;
  primaryProduct: Product;
  productData: Scalars['Json'];
  unmerged: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
};

export type ProductMergeConnection = {
  __typename?: 'ProductMergeConnection';
  edges: Array<ProductMergeEdge>;
  nodes: Array<ProductMerge>;
  pageInfo: PageInfo;
};

export type ProductMergeCreateManyBatchInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  ingredientIds?: InputMaybe<ProductMergeCreateManyingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeCreateManykitchenNamesInput>;
  mergedProductId: Scalars['Int'];
  primaryProductId: Scalars['Int'];
  productData: Scalars['Json'];
  supplierId: Scalars['Int'];
  unmerged?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type ProductMergeCreateManyBatchInputEnvelope = {
  data?: InputMaybe<Array<ProductMergeCreateManyBatchInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductMergeCreateManyCreatedByInput = {
  batchId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  ingredientIds?: InputMaybe<ProductMergeCreateManyingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeCreateManykitchenNamesInput>;
  mergedProductId: Scalars['Int'];
  primaryProductId: Scalars['Int'];
  productData: Scalars['Json'];
  supplierId: Scalars['Int'];
  unmerged?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type ProductMergeCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<ProductMergeCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductMergeCreateManyMergedProductInput = {
  batchId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  ingredientIds?: InputMaybe<ProductMergeCreateManyingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeCreateManykitchenNamesInput>;
  primaryProductId: Scalars['Int'];
  productData: Scalars['Json'];
  supplierId: Scalars['Int'];
  unmerged?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type ProductMergeCreateManyMergedProductInputEnvelope = {
  data?: InputMaybe<Array<ProductMergeCreateManyMergedProductInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductMergeCreateManyPrimaryProductInput = {
  batchId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  ingredientIds?: InputMaybe<ProductMergeCreateManyingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeCreateManykitchenNamesInput>;
  mergedProductId: Scalars['Int'];
  productData: Scalars['Json'];
  supplierId: Scalars['Int'];
  unmerged?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type ProductMergeCreateManyPrimaryProductInputEnvelope = {
  data?: InputMaybe<Array<ProductMergeCreateManyPrimaryProductInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductMergeCreateManySupplierInput = {
  batchId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  ingredientIds?: InputMaybe<ProductMergeCreateManyingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeCreateManykitchenNamesInput>;
  mergedProductId: Scalars['Int'];
  primaryProductId: Scalars['Int'];
  productData: Scalars['Json'];
  unmerged?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type ProductMergeCreateManySupplierInputEnvelope = {
  data?: InputMaybe<Array<ProductMergeCreateManySupplierInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductMergeCreateManyUpdatedByInput = {
  batchId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  ingredientIds?: InputMaybe<ProductMergeCreateManyingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeCreateManykitchenNamesInput>;
  mergedProductId: Scalars['Int'];
  primaryProductId: Scalars['Int'];
  productData: Scalars['Json'];
  supplierId: Scalars['Int'];
  unmerged?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductMergeCreateManyUpdatedByInputEnvelope = {
  data?: InputMaybe<Array<ProductMergeCreateManyUpdatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductMergeCreateManyingredientIdsInput = {
  set?: InputMaybe<Array<Scalars['Int']>>;
};

export type ProductMergeCreateManykitchenNamesInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ProductMergeCreateNestedManyWithoutBatchInput = {
  connect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductMergeCreateOrConnectWithoutBatchInput>>;
  create?: InputMaybe<Array<ProductMergeCreateWithoutBatchInput>>;
  createMany?: InputMaybe<ProductMergeCreateManyBatchInputEnvelope>;
};

export type ProductMergeCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductMergeCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<ProductMergeCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<ProductMergeCreateManyCreatedByInputEnvelope>;
};

export type ProductMergeCreateNestedManyWithoutMergedProductInput = {
  connect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductMergeCreateOrConnectWithoutMergedProductInput>>;
  create?: InputMaybe<Array<ProductMergeCreateWithoutMergedProductInput>>;
  createMany?: InputMaybe<ProductMergeCreateManyMergedProductInputEnvelope>;
};

export type ProductMergeCreateNestedManyWithoutPrimaryProductInput = {
  connect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductMergeCreateOrConnectWithoutPrimaryProductInput>>;
  create?: InputMaybe<Array<ProductMergeCreateWithoutPrimaryProductInput>>;
  createMany?: InputMaybe<ProductMergeCreateManyPrimaryProductInputEnvelope>;
};

export type ProductMergeCreateNestedManyWithoutSupplierInput = {
  connect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductMergeCreateOrConnectWithoutSupplierInput>>;
  create?: InputMaybe<Array<ProductMergeCreateWithoutSupplierInput>>;
  createMany?: InputMaybe<ProductMergeCreateManySupplierInputEnvelope>;
};

export type ProductMergeCreateNestedManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductMergeCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<ProductMergeCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<ProductMergeCreateManyUpdatedByInputEnvelope>;
};

export type ProductMergeCreateOrConnectWithoutBatchInput = {
  create: ProductMergeCreateWithoutBatchInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeCreateOrConnectWithoutCreatedByInput = {
  create: ProductMergeCreateWithoutCreatedByInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeCreateOrConnectWithoutMergedProductInput = {
  create: ProductMergeCreateWithoutMergedProductInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeCreateOrConnectWithoutPrimaryProductInput = {
  create: ProductMergeCreateWithoutPrimaryProductInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeCreateOrConnectWithoutSupplierInput = {
  create: ProductMergeCreateWithoutSupplierInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeCreateOrConnectWithoutUpdatedByInput = {
  create: ProductMergeCreateWithoutUpdatedByInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeCreateWithoutBatchInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutProductMergeCreatedInput;
  ingredientIds?: InputMaybe<ProductMergeCreateingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeCreatekitchenNamesInput>;
  mergedProduct: ProductCreateNestedOneWithoutMergedProductsInput;
  primaryProduct: ProductCreateNestedOneWithoutProductMergesInput;
  productData: Scalars['Json'];
  supplier: SupplierCreateNestedOneWithoutProductMergesInput;
  unmerged?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductMergeUpdatedInput;
};

export type ProductMergeCreateWithoutCreatedByInput = {
  batch?: InputMaybe<BatchCreateNestedOneWithoutProductMergesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ingredientIds?: InputMaybe<ProductMergeCreateingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeCreatekitchenNamesInput>;
  mergedProduct: ProductCreateNestedOneWithoutMergedProductsInput;
  primaryProduct: ProductCreateNestedOneWithoutProductMergesInput;
  productData: Scalars['Json'];
  supplier: SupplierCreateNestedOneWithoutProductMergesInput;
  unmerged?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductMergeUpdatedInput;
};

export type ProductMergeCreateWithoutMergedProductInput = {
  batch?: InputMaybe<BatchCreateNestedOneWithoutProductMergesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutProductMergeCreatedInput;
  ingredientIds?: InputMaybe<ProductMergeCreateingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeCreatekitchenNamesInput>;
  primaryProduct: ProductCreateNestedOneWithoutProductMergesInput;
  productData: Scalars['Json'];
  supplier: SupplierCreateNestedOneWithoutProductMergesInput;
  unmerged?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductMergeUpdatedInput;
};

export type ProductMergeCreateWithoutPrimaryProductInput = {
  batch?: InputMaybe<BatchCreateNestedOneWithoutProductMergesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutProductMergeCreatedInput;
  ingredientIds?: InputMaybe<ProductMergeCreateingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeCreatekitchenNamesInput>;
  mergedProduct: ProductCreateNestedOneWithoutMergedProductsInput;
  productData: Scalars['Json'];
  supplier: SupplierCreateNestedOneWithoutProductMergesInput;
  unmerged?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductMergeUpdatedInput;
};

export type ProductMergeCreateWithoutSupplierInput = {
  batch?: InputMaybe<BatchCreateNestedOneWithoutProductMergesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutProductMergeCreatedInput;
  ingredientIds?: InputMaybe<ProductMergeCreateingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeCreatekitchenNamesInput>;
  mergedProduct: ProductCreateNestedOneWithoutMergedProductsInput;
  primaryProduct: ProductCreateNestedOneWithoutProductMergesInput;
  productData: Scalars['Json'];
  unmerged?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutProductMergeUpdatedInput;
};

export type ProductMergeCreateWithoutUpdatedByInput = {
  batch?: InputMaybe<BatchCreateNestedOneWithoutProductMergesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutProductMergeCreatedInput;
  ingredientIds?: InputMaybe<ProductMergeCreateingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeCreatekitchenNamesInput>;
  mergedProduct: ProductCreateNestedOneWithoutMergedProductsInput;
  primaryProduct: ProductCreateNestedOneWithoutProductMergesInput;
  productData: Scalars['Json'];
  supplier: SupplierCreateNestedOneWithoutProductMergesInput;
  unmerged?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductMergeCreateingredientIdsInput = {
  set?: InputMaybe<Array<Scalars['Int']>>;
};

export type ProductMergeCreatekitchenNamesInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ProductMergeEdge = {
  __typename?: 'ProductMergeEdge';
  cursor: Scalars['ID'];
  node: ProductMerge;
};

export type ProductMergeListRelationFilter = {
  every?: InputMaybe<ProductMergeWhereInput>;
  none?: InputMaybe<ProductMergeWhereInput>;
  some?: InputMaybe<ProductMergeWhereInput>;
};

export type ProductMergeOrderByInput = {
  batch?: InputMaybe<BatchOrderByInput>;
  batchId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ingredientIds?: InputMaybe<SortOrder>;
  kitchenNames?: InputMaybe<SortOrder>;
  mergedProduct?: InputMaybe<ProductOrderByInput>;
  mergedProductId?: InputMaybe<SortOrder>;
  primaryProduct?: InputMaybe<ProductOrderByInput>;
  primaryProductId?: InputMaybe<SortOrder>;
  productData?: InputMaybe<SortOrder>;
  supplier?: InputMaybe<SupplierOrderByInput>;
  supplierId?: InputMaybe<SortOrder>;
  unmerged?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
};

export type ProductMergeOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type ProductMergeScalarWhereInput = {
  AND?: InputMaybe<Array<ProductMergeScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductMergeScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductMergeScalarWhereInput>>;
  batchId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  ingredientIds?: InputMaybe<IntNullableListFilter>;
  kitchenNames?: InputMaybe<StringNullableListFilter>;
  mergedProductId?: InputMaybe<IntFilter>;
  primaryProductId?: InputMaybe<IntFilter>;
  supplierId?: InputMaybe<IntFilter>;
  unmerged?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedById?: InputMaybe<IntFilter>;
};

export type ProductMergeUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ingredientIds?: InputMaybe<ProductMergeUpdateingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeUpdatekitchenNamesInput>;
  productData?: InputMaybe<Scalars['Json']>;
  unmerged?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ProductMergeUpdateManyWithWhereWithoutBatchInput = {
  data: ProductMergeUpdateManyMutationInput;
  where: ProductMergeScalarWhereInput;
};

export type ProductMergeUpdateManyWithWhereWithoutCreatedByInput = {
  data: ProductMergeUpdateManyMutationInput;
  where: ProductMergeScalarWhereInput;
};

export type ProductMergeUpdateManyWithWhereWithoutMergedProductInput = {
  data: ProductMergeUpdateManyMutationInput;
  where: ProductMergeScalarWhereInput;
};

export type ProductMergeUpdateManyWithWhereWithoutPrimaryProductInput = {
  data: ProductMergeUpdateManyMutationInput;
  where: ProductMergeScalarWhereInput;
};

export type ProductMergeUpdateManyWithWhereWithoutSupplierInput = {
  data: ProductMergeUpdateManyMutationInput;
  where: ProductMergeScalarWhereInput;
};

export type ProductMergeUpdateManyWithWhereWithoutUpdatedByInput = {
  data: ProductMergeUpdateManyMutationInput;
  where: ProductMergeScalarWhereInput;
};

export type ProductMergeUpdateManyWithoutBatchInput = {
  connect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductMergeCreateOrConnectWithoutBatchInput>>;
  create?: InputMaybe<Array<ProductMergeCreateWithoutBatchInput>>;
  createMany?: InputMaybe<ProductMergeCreateManyBatchInputEnvelope>;
  delete?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductMergeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductMergeUpdateWithWhereUniqueWithoutBatchInput>>;
  updateMany?: InputMaybe<Array<ProductMergeUpdateManyWithWhereWithoutBatchInput>>;
  upsert?: InputMaybe<Array<ProductMergeUpsertWithWhereUniqueWithoutBatchInput>>;
};

export type ProductMergeUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductMergeCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<ProductMergeCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<ProductMergeCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductMergeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductMergeUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<ProductMergeUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<ProductMergeUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type ProductMergeUpdateManyWithoutMergedProductInput = {
  connect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductMergeCreateOrConnectWithoutMergedProductInput>>;
  create?: InputMaybe<Array<ProductMergeCreateWithoutMergedProductInput>>;
  createMany?: InputMaybe<ProductMergeCreateManyMergedProductInputEnvelope>;
  delete?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductMergeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductMergeUpdateWithWhereUniqueWithoutMergedProductInput>>;
  updateMany?: InputMaybe<Array<ProductMergeUpdateManyWithWhereWithoutMergedProductInput>>;
  upsert?: InputMaybe<Array<ProductMergeUpsertWithWhereUniqueWithoutMergedProductInput>>;
};

export type ProductMergeUpdateManyWithoutPrimaryProductInput = {
  connect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductMergeCreateOrConnectWithoutPrimaryProductInput>>;
  create?: InputMaybe<Array<ProductMergeCreateWithoutPrimaryProductInput>>;
  createMany?: InputMaybe<ProductMergeCreateManyPrimaryProductInputEnvelope>;
  delete?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductMergeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductMergeUpdateWithWhereUniqueWithoutPrimaryProductInput>>;
  updateMany?: InputMaybe<Array<ProductMergeUpdateManyWithWhereWithoutPrimaryProductInput>>;
  upsert?: InputMaybe<Array<ProductMergeUpsertWithWhereUniqueWithoutPrimaryProductInput>>;
};

export type ProductMergeUpdateManyWithoutSupplierInput = {
  connect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductMergeCreateOrConnectWithoutSupplierInput>>;
  create?: InputMaybe<Array<ProductMergeCreateWithoutSupplierInput>>;
  createMany?: InputMaybe<ProductMergeCreateManySupplierInputEnvelope>;
  delete?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductMergeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductMergeUpdateWithWhereUniqueWithoutSupplierInput>>;
  updateMany?: InputMaybe<Array<ProductMergeUpdateManyWithWhereWithoutSupplierInput>>;
  upsert?: InputMaybe<Array<ProductMergeUpsertWithWhereUniqueWithoutSupplierInput>>;
};

export type ProductMergeUpdateManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductMergeCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<ProductMergeCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<ProductMergeCreateManyUpdatedByInputEnvelope>;
  delete?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductMergeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductMergeWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductMergeUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: InputMaybe<Array<ProductMergeUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: InputMaybe<Array<ProductMergeUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type ProductMergeUpdateWithWhereUniqueWithoutBatchInput = {
  data: ProductMergeUpdateWithoutBatchInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: ProductMergeUpdateWithoutCreatedByInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeUpdateWithWhereUniqueWithoutMergedProductInput = {
  data: ProductMergeUpdateWithoutMergedProductInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeUpdateWithWhereUniqueWithoutPrimaryProductInput = {
  data: ProductMergeUpdateWithoutPrimaryProductInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeUpdateWithWhereUniqueWithoutSupplierInput = {
  data: ProductMergeUpdateWithoutSupplierInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: ProductMergeUpdateWithoutUpdatedByInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeUpdateWithoutBatchInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductMergeCreatedInput>;
  ingredientIds?: InputMaybe<ProductMergeUpdateingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeUpdatekitchenNamesInput>;
  mergedProduct?: InputMaybe<ProductUpdateOneRequiredWithoutMergedProductsInput>;
  primaryProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductMergesInput>;
  productData?: InputMaybe<Scalars['Json']>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductMergesInput>;
  unmerged?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductMergeUpdatedInput>;
};

export type ProductMergeUpdateWithoutCreatedByInput = {
  batch?: InputMaybe<BatchUpdateOneWithoutProductMergesInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ingredientIds?: InputMaybe<ProductMergeUpdateingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeUpdatekitchenNamesInput>;
  mergedProduct?: InputMaybe<ProductUpdateOneRequiredWithoutMergedProductsInput>;
  primaryProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductMergesInput>;
  productData?: InputMaybe<Scalars['Json']>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductMergesInput>;
  unmerged?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductMergeUpdatedInput>;
};

export type ProductMergeUpdateWithoutMergedProductInput = {
  batch?: InputMaybe<BatchUpdateOneWithoutProductMergesInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductMergeCreatedInput>;
  ingredientIds?: InputMaybe<ProductMergeUpdateingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeUpdatekitchenNamesInput>;
  primaryProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductMergesInput>;
  productData?: InputMaybe<Scalars['Json']>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductMergesInput>;
  unmerged?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductMergeUpdatedInput>;
};

export type ProductMergeUpdateWithoutPrimaryProductInput = {
  batch?: InputMaybe<BatchUpdateOneWithoutProductMergesInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductMergeCreatedInput>;
  ingredientIds?: InputMaybe<ProductMergeUpdateingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeUpdatekitchenNamesInput>;
  mergedProduct?: InputMaybe<ProductUpdateOneRequiredWithoutMergedProductsInput>;
  productData?: InputMaybe<Scalars['Json']>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductMergesInput>;
  unmerged?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductMergeUpdatedInput>;
};

export type ProductMergeUpdateWithoutSupplierInput = {
  batch?: InputMaybe<BatchUpdateOneWithoutProductMergesInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductMergeCreatedInput>;
  ingredientIds?: InputMaybe<ProductMergeUpdateingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeUpdatekitchenNamesInput>;
  mergedProduct?: InputMaybe<ProductUpdateOneRequiredWithoutMergedProductsInput>;
  primaryProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductMergesInput>;
  productData?: InputMaybe<Scalars['Json']>;
  unmerged?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductMergeUpdatedInput>;
};

export type ProductMergeUpdateWithoutUpdatedByInput = {
  batch?: InputMaybe<BatchUpdateOneWithoutProductMergesInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductMergeCreatedInput>;
  ingredientIds?: InputMaybe<ProductMergeUpdateingredientIdsInput>;
  kitchenNames?: InputMaybe<ProductMergeUpdatekitchenNamesInput>;
  mergedProduct?: InputMaybe<ProductUpdateOneRequiredWithoutMergedProductsInput>;
  primaryProduct?: InputMaybe<ProductUpdateOneRequiredWithoutProductMergesInput>;
  productData?: InputMaybe<Scalars['Json']>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductMergesInput>;
  unmerged?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ProductMergeUpdateingredientIdsInput = {
  push?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Array<Scalars['Int']>>;
};

export type ProductMergeUpdatekitchenNamesInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ProductMergeUpsertWithWhereUniqueWithoutBatchInput = {
  create: ProductMergeCreateWithoutBatchInput;
  update: ProductMergeUpdateWithoutBatchInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: ProductMergeCreateWithoutCreatedByInput;
  update: ProductMergeUpdateWithoutCreatedByInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeUpsertWithWhereUniqueWithoutMergedProductInput = {
  create: ProductMergeCreateWithoutMergedProductInput;
  update: ProductMergeUpdateWithoutMergedProductInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeUpsertWithWhereUniqueWithoutPrimaryProductInput = {
  create: ProductMergeCreateWithoutPrimaryProductInput;
  update: ProductMergeUpdateWithoutPrimaryProductInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeUpsertWithWhereUniqueWithoutSupplierInput = {
  create: ProductMergeCreateWithoutSupplierInput;
  update: ProductMergeUpdateWithoutSupplierInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: ProductMergeCreateWithoutUpdatedByInput;
  update: ProductMergeUpdateWithoutUpdatedByInput;
  where: ProductMergeWhereUniqueInput;
};

export type ProductMergeWhereInput = {
  AND?: InputMaybe<Array<ProductMergeWhereInput>>;
  NOT?: InputMaybe<Array<ProductMergeWhereInput>>;
  OR?: InputMaybe<Array<ProductMergeWhereInput>>;
  batch?: InputMaybe<BatchWhereInput>;
  batchId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  ingredientIds?: InputMaybe<IntNullableListFilter>;
  kitchenNames?: InputMaybe<StringNullableListFilter>;
  mergedProduct?: InputMaybe<ProductWhereInput>;
  mergedProductId?: InputMaybe<IntFilter>;
  primaryProduct?: InputMaybe<ProductWhereInput>;
  primaryProductId?: InputMaybe<IntFilter>;
  supplier?: InputMaybe<SupplierWhereInput>;
  supplierId?: InputMaybe<IntFilter>;
  unmerged?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type ProductMergeWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type ProductOrderByInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ingredients?: InputMaybe<IngredientOrderByRelationAggregateInput>;
  mergedProducts?: InputMaybe<ProductMergeOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  packSize?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesOrderByRelationAggregateInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesOrderByRelationAggregateInput>;
  productMerges?: InputMaybe<ProductMergeOrderByRelationAggregateInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  supplier?: InputMaybe<SupplierOrderByInput>;
  supplierId?: InputMaybe<SortOrder>;
  unit?: InputMaybe<UnitOrderByInput>;
  unitId?: InputMaybe<SortOrder>;
  unitValue?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
};

export type ProductOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type ProductScalarWhereInput = {
  AND?: InputMaybe<Array<ProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductScalarWhereInput>>;
  code?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  packSize?: InputMaybe<FloatFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  status?: InputMaybe<EnumProductStatusFilter>;
  supplierId?: InputMaybe<IntFilter>;
  unitId?: InputMaybe<IntFilter>;
  unitValue?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedById?: InputMaybe<IntFilter>;
};

export enum ProductStatus {
  Available = 'available',
  Custom = 'custom',
  Discontinued = 'discontinued',
  Inactive = 'inactive',
  OutOfStock = 'out_of_stock',
  Pending = 'pending'
}

export type ProductUpdateInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductCreatedInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeUpdateManyWithoutMergedProductInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesUpdateManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesUpdateManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutProductInput>;
  status?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutProductUnitInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductUpdatedInput>;
};

export type ProductUpdateManyMutationInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ProductUpdateManyWithWhereWithoutCreatedByInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutSupplierInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutUnitInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutUpdatedByInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<ProductCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type ProductUpdateManyWithoutSupplierInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutSupplierInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutSupplierInput>>;
  createMany?: InputMaybe<ProductCreateManySupplierInputEnvelope>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutSupplierInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutSupplierInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutSupplierInput>>;
};

export type ProductUpdateManyWithoutUnitInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutUnitInput>>;
  createMany?: InputMaybe<ProductCreateManyUnitInputEnvelope>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutUnitInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutUnitInput>>;
};

export type ProductUpdateManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<ProductCreateManyUpdatedByInputEnvelope>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type ProductUpdateOneRequiredWithoutIngredientsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutIngredientsInput>;
  create?: InputMaybe<ProductCreateWithoutIngredientsInput>;
  update?: InputMaybe<ProductUpdateWithoutIngredientsInput>;
  upsert?: InputMaybe<ProductUpsertWithoutIngredientsInput>;
};

export type ProductUpdateOneRequiredWithoutMergedProductsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutMergedProductsInput>;
  create?: InputMaybe<ProductCreateWithoutMergedProductsInput>;
  update?: InputMaybe<ProductUpdateWithoutMergedProductsInput>;
  upsert?: InputMaybe<ProductUpsertWithoutMergedProductsInput>;
};

export type ProductUpdateOneRequiredWithoutProductDuplicatesDuplicateInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductDuplicatesDuplicateInput>;
  create?: InputMaybe<ProductCreateWithoutProductDuplicatesDuplicateInput>;
  update?: InputMaybe<ProductUpdateWithoutProductDuplicatesDuplicateInput>;
  upsert?: InputMaybe<ProductUpsertWithoutProductDuplicatesDuplicateInput>;
};

export type ProductUpdateOneRequiredWithoutProductDuplicatesPrimaryInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductDuplicatesPrimaryInput>;
  create?: InputMaybe<ProductCreateWithoutProductDuplicatesPrimaryInput>;
  update?: InputMaybe<ProductUpdateWithoutProductDuplicatesPrimaryInput>;
  upsert?: InputMaybe<ProductUpsertWithoutProductDuplicatesPrimaryInput>;
};

export type ProductUpdateOneRequiredWithoutProductMergesInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductMergesInput>;
  create?: InputMaybe<ProductCreateWithoutProductMergesInput>;
  update?: InputMaybe<ProductUpdateWithoutProductMergesInput>;
  upsert?: InputMaybe<ProductUpsertWithoutProductMergesInput>;
};

export type ProductUpdateOneRequiredWithoutPurchaseOrderEntriesInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutPurchaseOrderEntriesInput>;
  create?: InputMaybe<ProductCreateWithoutPurchaseOrderEntriesInput>;
  update?: InputMaybe<ProductUpdateWithoutPurchaseOrderEntriesInput>;
  upsert?: InputMaybe<ProductUpsertWithoutPurchaseOrderEntriesInput>;
};

export type ProductUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: ProductUpdateWithoutCreatedByInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutSupplierInput = {
  data: ProductUpdateWithoutSupplierInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutUnitInput = {
  data: ProductUpdateWithoutUnitInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: ProductUpdateWithoutUpdatedByInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithoutCreatedByInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeUpdateManyWithoutMergedProductInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesUpdateManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesUpdateManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutProductInput>;
  status?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutProductUnitInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductUpdatedInput>;
};

export type ProductUpdateWithoutIngredientsInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductCreatedInput>;
  mergedProducts?: InputMaybe<ProductMergeUpdateManyWithoutMergedProductInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesUpdateManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesUpdateManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutProductInput>;
  status?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutProductUnitInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductUpdatedInput>;
};

export type ProductUpdateWithoutMergedProductsInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductCreatedInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutProductInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesUpdateManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesUpdateManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutProductInput>;
  status?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutProductUnitInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductUpdatedInput>;
};

export type ProductUpdateWithoutProductDuplicatesDuplicateInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductCreatedInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeUpdateManyWithoutMergedProductInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesUpdateManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutProductInput>;
  status?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutProductUnitInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductUpdatedInput>;
};

export type ProductUpdateWithoutProductDuplicatesPrimaryInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductCreatedInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeUpdateManyWithoutMergedProductInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesUpdateManyWithoutDuplicateProductInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutProductInput>;
  status?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutProductUnitInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductUpdatedInput>;
};

export type ProductUpdateWithoutProductMergesInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductCreatedInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeUpdateManyWithoutMergedProductInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesUpdateManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesUpdateManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutProductInput>;
  status?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutProductUnitInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductUpdatedInput>;
};

export type ProductUpdateWithoutPurchaseOrderEntriesInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductCreatedInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeUpdateManyWithoutMergedProductInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesUpdateManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesUpdateManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutPrimaryProductInput>;
  status?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutProductUnitInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductUpdatedInput>;
};

export type ProductUpdateWithoutSupplierInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductCreatedInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeUpdateManyWithoutMergedProductInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesUpdateManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesUpdateManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutProductInput>;
  status?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutProductUnitInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductUpdatedInput>;
};

export type ProductUpdateWithoutUnitInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductCreatedInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeUpdateManyWithoutMergedProductInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesUpdateManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesUpdateManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutProductInput>;
  status?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductsInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutProductUpdatedInput>;
};

export type ProductUpdateWithoutUpdatedByInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutProductCreatedInput>;
  ingredients?: InputMaybe<IngredientUpdateManyWithoutProductInput>;
  mergedProducts?: InputMaybe<ProductMergeUpdateManyWithoutMergedProductInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesUpdateManyWithoutDuplicateProductInput>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesUpdateManyWithoutPrimaryProductInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutPrimaryProductInput>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutProductInput>;
  status?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutProductsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutProductUnitInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ProductUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: ProductCreateWithoutCreatedByInput;
  update: ProductUpdateWithoutCreatedByInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutSupplierInput = {
  create: ProductCreateWithoutSupplierInput;
  update: ProductUpdateWithoutSupplierInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutUnitInput = {
  create: ProductCreateWithoutUnitInput;
  update: ProductUpdateWithoutUnitInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: ProductCreateWithoutUpdatedByInput;
  update: ProductUpdateWithoutUpdatedByInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithoutIngredientsInput = {
  create: ProductCreateWithoutIngredientsInput;
  update: ProductUpdateWithoutIngredientsInput;
};

export type ProductUpsertWithoutMergedProductsInput = {
  create: ProductCreateWithoutMergedProductsInput;
  update: ProductUpdateWithoutMergedProductsInput;
};

export type ProductUpsertWithoutProductDuplicatesDuplicateInput = {
  create: ProductCreateWithoutProductDuplicatesDuplicateInput;
  update: ProductUpdateWithoutProductDuplicatesDuplicateInput;
};

export type ProductUpsertWithoutProductDuplicatesPrimaryInput = {
  create: ProductCreateWithoutProductDuplicatesPrimaryInput;
  update: ProductUpdateWithoutProductDuplicatesPrimaryInput;
};

export type ProductUpsertWithoutProductMergesInput = {
  create: ProductCreateWithoutProductMergesInput;
  update: ProductUpdateWithoutProductMergesInput;
};

export type ProductUpsertWithoutPurchaseOrderEntriesInput = {
  create: ProductCreateWithoutPurchaseOrderEntriesInput;
  update: ProductUpdateWithoutPurchaseOrderEntriesInput;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  code?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  ingredients?: InputMaybe<IngredientListRelationFilter>;
  mergedProducts?: InputMaybe<ProductMergeListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  packSize?: InputMaybe<FloatFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  productDuplicatesDuplicate?: InputMaybe<ProductDuplicatesListRelationFilter>;
  productDuplicatesPrimary?: InputMaybe<ProductDuplicatesListRelationFilter>;
  productMerges?: InputMaybe<ProductMergeListRelationFilter>;
  purchaseOrderEntries?: InputMaybe<PurchaseOrderEntryListRelationFilter>;
  status?: InputMaybe<EnumProductStatusFilter>;
  supplier?: InputMaybe<SupplierWhereInput>;
  supplierId?: InputMaybe<IntFilter>;
  unit?: InputMaybe<UnitWhereInput>;
  unitId?: InputMaybe<IntFilter>;
  unitValue?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type ProductWhereUniqueInput = {
  id: Scalars['Int'];
};

export type PurchaseOrder = Node & {
  __typename?: 'PurchaseOrder';
  _cursor: Scalars['ID'];
  address?: Maybe<Address>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  deliverySiteAddress: Scalars['String'];
  deliverySiteName: Scalars['String'];
  /** @deprecated Use and extend entryList field */
  entries: Array<PurchaseOrderEntry>;
  entryList: PurchaseOrderEntryConnection;
  id: Scalars['Int'];
  kitchen: Kitchen;
  notes?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  supplier: Supplier;
  supplierEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
};


export type PurchaseOrderEntriesArgs = {
  cursor?: InputMaybe<PurchaseOrderEntryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PurchaseOrderEntryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PurchaseOrderEntryWhereInput>;
};


export type PurchaseOrderEntryListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PurchaseOrderEntryWhereInput>;
};

export type PurchaseOrderConnection = {
  __typename?: 'PurchaseOrderConnection';
  edges: Array<PurchaseOrderEdge>;
  nodes: Array<PurchaseOrder>;
  pageInfo: PageInfo;
};

export type PurchaseOrderCreateManyCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deliverySiteAddress: Scalars['String'];
  deliverySiteName: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  kitchenId: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  number: Scalars['Int'];
  supplierEmail: Scalars['String'];
  supplierId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type PurchaseOrderCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<PurchaseOrderCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseOrderCreateManyKitchenInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  deliverySiteAddress: Scalars['String'];
  deliverySiteName: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  number: Scalars['Int'];
  supplierEmail: Scalars['String'];
  supplierId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type PurchaseOrderCreateManyKitchenInputEnvelope = {
  data?: InputMaybe<Array<PurchaseOrderCreateManyKitchenInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseOrderCreateManySupplierInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  deliverySiteAddress: Scalars['String'];
  deliverySiteName: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  kitchenId: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  number: Scalars['Int'];
  supplierEmail: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type PurchaseOrderCreateManySupplierInputEnvelope = {
  data?: InputMaybe<Array<PurchaseOrderCreateManySupplierInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseOrderCreateManyUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  deliverySiteAddress: Scalars['String'];
  deliverySiteName: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  kitchenId: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  number: Scalars['Int'];
  supplierEmail: Scalars['String'];
  supplierId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseOrderCreateManyUpdatedByInputEnvelope = {
  data?: InputMaybe<Array<PurchaseOrderCreateManyUpdatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseOrderCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<PurchaseOrderCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<PurchaseOrderCreateManyCreatedByInputEnvelope>;
};

export type PurchaseOrderCreateNestedManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<PurchaseOrderCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<PurchaseOrderCreateManyKitchenInputEnvelope>;
};

export type PurchaseOrderCreateNestedManyWithoutSupplierInput = {
  connect?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderCreateOrConnectWithoutSupplierInput>>;
  create?: InputMaybe<Array<PurchaseOrderCreateWithoutSupplierInput>>;
  createMany?: InputMaybe<PurchaseOrderCreateManySupplierInputEnvelope>;
};

export type PurchaseOrderCreateNestedManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<PurchaseOrderCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<PurchaseOrderCreateManyUpdatedByInputEnvelope>;
};

export type PurchaseOrderCreateNestedOneWithoutEntriesInput = {
  connect?: InputMaybe<PurchaseOrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PurchaseOrderCreateOrConnectWithoutEntriesInput>;
  create?: InputMaybe<PurchaseOrderCreateWithoutEntriesInput>;
};

export type PurchaseOrderCreateOrConnectWithoutCreatedByInput = {
  create: PurchaseOrderCreateWithoutCreatedByInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderCreateOrConnectWithoutEntriesInput = {
  create: PurchaseOrderCreateWithoutEntriesInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderCreateOrConnectWithoutKitchenInput = {
  create: PurchaseOrderCreateWithoutKitchenInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderCreateOrConnectWithoutSupplierInput = {
  create: PurchaseOrderCreateWithoutSupplierInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderCreateOrConnectWithoutUpdatedByInput = {
  create: PurchaseOrderCreateWithoutUpdatedByInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderCreateWithoutCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deliverySiteAddress: Scalars['String'];
  deliverySiteName: Scalars['String'];
  entries?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutPurchaseOrderInput>;
  kitchen: KitchenCreateNestedOneWithoutPurchaseOrderInput;
  notes?: InputMaybe<Scalars['String']>;
  number: Scalars['Int'];
  supplier: SupplierCreateNestedOneWithoutPurchaseOrdersInput;
  supplierEmail: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutPurchaseOrderUpdatedInput;
};

export type PurchaseOrderCreateWithoutEntriesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutPurchaseOrderCreatedInput;
  deliverySiteAddress: Scalars['String'];
  deliverySiteName: Scalars['String'];
  kitchen: KitchenCreateNestedOneWithoutPurchaseOrderInput;
  notes?: InputMaybe<Scalars['String']>;
  number: Scalars['Int'];
  supplier: SupplierCreateNestedOneWithoutPurchaseOrdersInput;
  supplierEmail: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutPurchaseOrderUpdatedInput;
};

export type PurchaseOrderCreateWithoutKitchenInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutPurchaseOrderCreatedInput;
  deliverySiteAddress: Scalars['String'];
  deliverySiteName: Scalars['String'];
  entries?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutPurchaseOrderInput>;
  notes?: InputMaybe<Scalars['String']>;
  number: Scalars['Int'];
  supplier: SupplierCreateNestedOneWithoutPurchaseOrdersInput;
  supplierEmail: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutPurchaseOrderUpdatedInput;
};

export type PurchaseOrderCreateWithoutSupplierInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutPurchaseOrderCreatedInput;
  deliverySiteAddress: Scalars['String'];
  deliverySiteName: Scalars['String'];
  entries?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutPurchaseOrderInput>;
  kitchen: KitchenCreateNestedOneWithoutPurchaseOrderInput;
  notes?: InputMaybe<Scalars['String']>;
  number: Scalars['Int'];
  supplierEmail: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutPurchaseOrderUpdatedInput;
};

export type PurchaseOrderCreateWithoutUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutPurchaseOrderCreatedInput;
  deliverySiteAddress: Scalars['String'];
  deliverySiteName: Scalars['String'];
  entries?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutPurchaseOrderInput>;
  kitchen: KitchenCreateNestedOneWithoutPurchaseOrderInput;
  notes?: InputMaybe<Scalars['String']>;
  number: Scalars['Int'];
  supplier: SupplierCreateNestedOneWithoutPurchaseOrdersInput;
  supplierEmail: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseOrderEdge = {
  __typename?: 'PurchaseOrderEdge';
  cursor: Scalars['ID'];
  node: PurchaseOrder;
};

export type PurchaseOrderEntry = Node & {
  __typename?: 'PurchaseOrderEntry';
  _cursor: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  packSize: Scalars['Float'];
  price?: Maybe<Scalars['Float']>;
  product: Product;
  productCode?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  sizeType: PurchaseOrderSizeType;
  unit: Unit;
  unitValue: Scalars['Float'];
};

export type PurchaseOrderEntryConnection = {
  __typename?: 'PurchaseOrderEntryConnection';
  edges: Array<PurchaseOrderEntryEdge>;
  nodes: Array<PurchaseOrderEntry>;
  pageInfo: PageInfo;
};

export type PurchaseOrderEntryCreateManyProductInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  packSize: Scalars['Float'];
  price: Scalars['Float'];
  productCode?: InputMaybe<Scalars['String']>;
  purchaseOrderId: Scalars['Int'];
  quantity: Scalars['Float'];
  sizeType: PurchaseOrderSizeType;
  unitId: Scalars['Int'];
  unitValue: Scalars['Float'];
};

export type PurchaseOrderEntryCreateManyProductInputEnvelope = {
  data?: InputMaybe<Array<PurchaseOrderEntryCreateManyProductInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseOrderEntryCreateManyPurchaseOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  packSize: Scalars['Float'];
  price: Scalars['Float'];
  productCode?: InputMaybe<Scalars['String']>;
  productId: Scalars['Int'];
  quantity: Scalars['Float'];
  sizeType: PurchaseOrderSizeType;
  unitId: Scalars['Int'];
  unitValue: Scalars['Float'];
};

export type PurchaseOrderEntryCreateManyPurchaseOrderInputEnvelope = {
  data?: InputMaybe<Array<PurchaseOrderEntryCreateManyPurchaseOrderInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseOrderEntryCreateManyUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  packSize: Scalars['Float'];
  price: Scalars['Float'];
  productCode?: InputMaybe<Scalars['String']>;
  productId: Scalars['Int'];
  purchaseOrderId: Scalars['Int'];
  quantity: Scalars['Float'];
  sizeType: PurchaseOrderSizeType;
  unitValue: Scalars['Float'];
};

export type PurchaseOrderEntryCreateManyUnitInputEnvelope = {
  data?: InputMaybe<Array<PurchaseOrderEntryCreateManyUnitInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseOrderEntryCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderEntryCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<PurchaseOrderEntryCreateWithoutProductInput>>;
  createMany?: InputMaybe<PurchaseOrderEntryCreateManyProductInputEnvelope>;
};

export type PurchaseOrderEntryCreateNestedManyWithoutPurchaseOrderInput = {
  connect?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderEntryCreateOrConnectWithoutPurchaseOrderInput>>;
  create?: InputMaybe<Array<PurchaseOrderEntryCreateWithoutPurchaseOrderInput>>;
  createMany?: InputMaybe<PurchaseOrderEntryCreateManyPurchaseOrderInputEnvelope>;
};

export type PurchaseOrderEntryCreateNestedManyWithoutUnitInput = {
  connect?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderEntryCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<PurchaseOrderEntryCreateWithoutUnitInput>>;
  createMany?: InputMaybe<PurchaseOrderEntryCreateManyUnitInputEnvelope>;
};

export type PurchaseOrderEntryCreateOrConnectWithoutProductInput = {
  create: PurchaseOrderEntryCreateWithoutProductInput;
  where: PurchaseOrderEntryWhereUniqueInput;
};

export type PurchaseOrderEntryCreateOrConnectWithoutPurchaseOrderInput = {
  create: PurchaseOrderEntryCreateWithoutPurchaseOrderInput;
  where: PurchaseOrderEntryWhereUniqueInput;
};

export type PurchaseOrderEntryCreateOrConnectWithoutUnitInput = {
  create: PurchaseOrderEntryCreateWithoutUnitInput;
  where: PurchaseOrderEntryWhereUniqueInput;
};

export type PurchaseOrderEntryCreateWithoutProductInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  packSize: Scalars['Float'];
  price: Scalars['Float'];
  productCode?: InputMaybe<Scalars['String']>;
  purchaseOrder: PurchaseOrderCreateNestedOneWithoutEntriesInput;
  quantity: Scalars['Float'];
  sizeType: PurchaseOrderSizeType;
  unit: UnitCreateNestedOneWithoutPurchaseOrderEntityUnitInput;
  unitValue: Scalars['Float'];
};

export type PurchaseOrderEntryCreateWithoutPurchaseOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  product: ProductCreateNestedOneWithoutPurchaseOrderEntriesInput;
  productCode?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Float'];
  sizeType: PurchaseOrderSizeType;
  unit: UnitCreateNestedOneWithoutPurchaseOrderEntityUnitInput;
  unitValue: Scalars['Float'];
};

export type PurchaseOrderEntryCreateWithoutUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  packSize: Scalars['Float'];
  price: Scalars['Float'];
  product: ProductCreateNestedOneWithoutPurchaseOrderEntriesInput;
  productCode?: InputMaybe<Scalars['String']>;
  purchaseOrder: PurchaseOrderCreateNestedOneWithoutEntriesInput;
  quantity: Scalars['Float'];
  sizeType: PurchaseOrderSizeType;
  unitValue: Scalars['Float'];
};

export type PurchaseOrderEntryEdge = {
  __typename?: 'PurchaseOrderEntryEdge';
  cursor: Scalars['ID'];
  node: PurchaseOrderEntry;
};

export type PurchaseOrderEntryInput = {
  code: Scalars['String'];
  id: Scalars['Int'];
  packSize: Scalars['Float'];
  price?: InputMaybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  sizeType: PurchaseOrderSizeType;
  unitId: Scalars['Int'];
  unitValue: Scalars['Float'];
};

export type PurchaseOrderEntryListRelationFilter = {
  every?: InputMaybe<PurchaseOrderEntryWhereInput>;
  none?: InputMaybe<PurchaseOrderEntryWhereInput>;
  some?: InputMaybe<PurchaseOrderEntryWhereInput>;
};

export type PurchaseOrderEntryOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  packSize?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByInput>;
  productCode?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  purchaseOrder?: InputMaybe<PurchaseOrderOrderByInput>;
  purchaseOrderId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  sizeType?: InputMaybe<SortOrder>;
  unit?: InputMaybe<UnitOrderByInput>;
  unitId?: InputMaybe<SortOrder>;
  unitValue?: InputMaybe<SortOrder>;
};

export type PurchaseOrderEntryOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type PurchaseOrderEntryScalarWhereInput = {
  AND?: InputMaybe<Array<PurchaseOrderEntryScalarWhereInput>>;
  NOT?: InputMaybe<Array<PurchaseOrderEntryScalarWhereInput>>;
  OR?: InputMaybe<Array<PurchaseOrderEntryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  packSize?: InputMaybe<FloatFilter>;
  price?: InputMaybe<FloatFilter>;
  productCode?: InputMaybe<StringNullableFilter>;
  productId?: InputMaybe<IntFilter>;
  purchaseOrderId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  sizeType?: InputMaybe<EnumPurchaseOrderSizeTypeFilter>;
  unitId?: InputMaybe<IntFilter>;
  unitValue?: InputMaybe<FloatFilter>;
};

export type PurchaseOrderEntryUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  productCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sizeType?: InputMaybe<EnumPurchaseOrderSizeTypeFieldUpdateOperationsInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type PurchaseOrderEntryUpdateManyWithWhereWithoutProductInput = {
  data: PurchaseOrderEntryUpdateManyMutationInput;
  where: PurchaseOrderEntryScalarWhereInput;
};

export type PurchaseOrderEntryUpdateManyWithWhereWithoutPurchaseOrderInput = {
  data: PurchaseOrderEntryUpdateManyMutationInput;
  where: PurchaseOrderEntryScalarWhereInput;
};

export type PurchaseOrderEntryUpdateManyWithWhereWithoutUnitInput = {
  data: PurchaseOrderEntryUpdateManyMutationInput;
  where: PurchaseOrderEntryScalarWhereInput;
};

export type PurchaseOrderEntryUpdateManyWithoutProductInput = {
  connect?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderEntryCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<PurchaseOrderEntryCreateWithoutProductInput>>;
  createMany?: InputMaybe<PurchaseOrderEntryCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseOrderEntryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseOrderEntryUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<PurchaseOrderEntryUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<PurchaseOrderEntryUpsertWithWhereUniqueWithoutProductInput>>;
};

export type PurchaseOrderEntryUpdateManyWithoutPurchaseOrderInput = {
  connect?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderEntryCreateOrConnectWithoutPurchaseOrderInput>>;
  create?: InputMaybe<Array<PurchaseOrderEntryCreateWithoutPurchaseOrderInput>>;
  createMany?: InputMaybe<PurchaseOrderEntryCreateManyPurchaseOrderInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseOrderEntryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseOrderEntryUpdateWithWhereUniqueWithoutPurchaseOrderInput>>;
  updateMany?: InputMaybe<Array<PurchaseOrderEntryUpdateManyWithWhereWithoutPurchaseOrderInput>>;
  upsert?: InputMaybe<Array<PurchaseOrderEntryUpsertWithWhereUniqueWithoutPurchaseOrderInput>>;
};

export type PurchaseOrderEntryUpdateManyWithoutUnitInput = {
  connect?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderEntryCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<PurchaseOrderEntryCreateWithoutUnitInput>>;
  createMany?: InputMaybe<PurchaseOrderEntryCreateManyUnitInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseOrderEntryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseOrderEntryWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseOrderEntryUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: InputMaybe<Array<PurchaseOrderEntryUpdateManyWithWhereWithoutUnitInput>>;
  upsert?: InputMaybe<Array<PurchaseOrderEntryUpsertWithWhereUniqueWithoutUnitInput>>;
};

export type PurchaseOrderEntryUpdateWithWhereUniqueWithoutProductInput = {
  data: PurchaseOrderEntryUpdateWithoutProductInput;
  where: PurchaseOrderEntryWhereUniqueInput;
};

export type PurchaseOrderEntryUpdateWithWhereUniqueWithoutPurchaseOrderInput = {
  data: PurchaseOrderEntryUpdateWithoutPurchaseOrderInput;
  where: PurchaseOrderEntryWhereUniqueInput;
};

export type PurchaseOrderEntryUpdateWithWhereUniqueWithoutUnitInput = {
  data: PurchaseOrderEntryUpdateWithoutUnitInput;
  where: PurchaseOrderEntryWhereUniqueInput;
};

export type PurchaseOrderEntryUpdateWithoutProductInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  productCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  purchaseOrder?: InputMaybe<PurchaseOrderUpdateOneRequiredWithoutEntriesInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sizeType?: InputMaybe<EnumPurchaseOrderSizeTypeFieldUpdateOperationsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutPurchaseOrderEntityUnitInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type PurchaseOrderEntryUpdateWithoutPurchaseOrderInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutPurchaseOrderEntriesInput>;
  productCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sizeType?: InputMaybe<EnumPurchaseOrderSizeTypeFieldUpdateOperationsInput>;
  unit?: InputMaybe<UnitUpdateOneRequiredWithoutPurchaseOrderEntityUnitInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type PurchaseOrderEntryUpdateWithoutUnitInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  packSize?: InputMaybe<FloatFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutPurchaseOrderEntriesInput>;
  productCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  purchaseOrder?: InputMaybe<PurchaseOrderUpdateOneRequiredWithoutEntriesInput>;
  quantity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sizeType?: InputMaybe<EnumPurchaseOrderSizeTypeFieldUpdateOperationsInput>;
  unitValue?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type PurchaseOrderEntryUpsertWithWhereUniqueWithoutProductInput = {
  create: PurchaseOrderEntryCreateWithoutProductInput;
  update: PurchaseOrderEntryUpdateWithoutProductInput;
  where: PurchaseOrderEntryWhereUniqueInput;
};

export type PurchaseOrderEntryUpsertWithWhereUniqueWithoutPurchaseOrderInput = {
  create: PurchaseOrderEntryCreateWithoutPurchaseOrderInput;
  update: PurchaseOrderEntryUpdateWithoutPurchaseOrderInput;
  where: PurchaseOrderEntryWhereUniqueInput;
};

export type PurchaseOrderEntryUpsertWithWhereUniqueWithoutUnitInput = {
  create: PurchaseOrderEntryCreateWithoutUnitInput;
  update: PurchaseOrderEntryUpdateWithoutUnitInput;
  where: PurchaseOrderEntryWhereUniqueInput;
};

export type PurchaseOrderEntryWhereInput = {
  AND?: InputMaybe<Array<PurchaseOrderEntryWhereInput>>;
  NOT?: InputMaybe<Array<PurchaseOrderEntryWhereInput>>;
  OR?: InputMaybe<Array<PurchaseOrderEntryWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  packSize?: InputMaybe<FloatFilter>;
  price?: InputMaybe<FloatFilter>;
  product?: InputMaybe<ProductWhereInput>;
  productCode?: InputMaybe<StringNullableFilter>;
  productId?: InputMaybe<IntFilter>;
  purchaseOrder?: InputMaybe<PurchaseOrderWhereInput>;
  purchaseOrderId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  sizeType?: InputMaybe<EnumPurchaseOrderSizeTypeFilter>;
  unit?: InputMaybe<UnitWhereInput>;
  unitId?: InputMaybe<IntFilter>;
  unitValue?: InputMaybe<FloatFilter>;
};

export type PurchaseOrderEntryWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type PurchaseOrderListRelationFilter = {
  every?: InputMaybe<PurchaseOrderWhereInput>;
  none?: InputMaybe<PurchaseOrderWhereInput>;
  some?: InputMaybe<PurchaseOrderWhereInput>;
};

export type PurchaseOrderOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  deliverySiteAddress?: InputMaybe<SortOrder>;
  deliverySiteName?: InputMaybe<SortOrder>;
  entries?: InputMaybe<PurchaseOrderEntryOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  kitchen?: InputMaybe<KitchenOrderByInput>;
  kitchenId?: InputMaybe<SortOrder>;
  notes?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  supplier?: InputMaybe<SupplierOrderByInput>;
  supplierEmail?: InputMaybe<SortOrder>;
  supplierId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
};

export type PurchaseOrderOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type PurchaseOrderScalarWhereInput = {
  AND?: InputMaybe<Array<PurchaseOrderScalarWhereInput>>;
  NOT?: InputMaybe<Array<PurchaseOrderScalarWhereInput>>;
  OR?: InputMaybe<Array<PurchaseOrderScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdById?: InputMaybe<IntFilter>;
  deliverySiteAddress?: InputMaybe<StringFilter>;
  deliverySiteName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  kitchenId?: InputMaybe<IntFilter>;
  notes?: InputMaybe<StringNullableFilter>;
  number?: InputMaybe<IntFilter>;
  supplierEmail?: InputMaybe<StringFilter>;
  supplierId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedById?: InputMaybe<IntFilter>;
};

export enum PurchaseOrderSizeType {
  Box = 'box',
  Default = 'default',
  Unit = 'unit'
}

export type PurchaseOrderUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliverySiteAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  deliverySiteName?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  supplierEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PurchaseOrderUpdateManyWithWhereWithoutCreatedByInput = {
  data: PurchaseOrderUpdateManyMutationInput;
  where: PurchaseOrderScalarWhereInput;
};

export type PurchaseOrderUpdateManyWithWhereWithoutKitchenInput = {
  data: PurchaseOrderUpdateManyMutationInput;
  where: PurchaseOrderScalarWhereInput;
};

export type PurchaseOrderUpdateManyWithWhereWithoutSupplierInput = {
  data: PurchaseOrderUpdateManyMutationInput;
  where: PurchaseOrderScalarWhereInput;
};

export type PurchaseOrderUpdateManyWithWhereWithoutUpdatedByInput = {
  data: PurchaseOrderUpdateManyMutationInput;
  where: PurchaseOrderScalarWhereInput;
};

export type PurchaseOrderUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<PurchaseOrderCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<PurchaseOrderCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseOrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseOrderUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<PurchaseOrderUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<PurchaseOrderUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type PurchaseOrderUpdateManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<PurchaseOrderCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<PurchaseOrderCreateManyKitchenInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseOrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseOrderUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: InputMaybe<Array<PurchaseOrderUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: InputMaybe<Array<PurchaseOrderUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type PurchaseOrderUpdateManyWithoutSupplierInput = {
  connect?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderCreateOrConnectWithoutSupplierInput>>;
  create?: InputMaybe<Array<PurchaseOrderCreateWithoutSupplierInput>>;
  createMany?: InputMaybe<PurchaseOrderCreateManySupplierInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseOrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseOrderUpdateWithWhereUniqueWithoutSupplierInput>>;
  updateMany?: InputMaybe<Array<PurchaseOrderUpdateManyWithWhereWithoutSupplierInput>>;
  upsert?: InputMaybe<Array<PurchaseOrderUpsertWithWhereUniqueWithoutSupplierInput>>;
};

export type PurchaseOrderUpdateManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseOrderCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<PurchaseOrderCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<PurchaseOrderCreateManyUpdatedByInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseOrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseOrderWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseOrderUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: InputMaybe<Array<PurchaseOrderUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: InputMaybe<Array<PurchaseOrderUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type PurchaseOrderUpdateOneRequiredWithoutEntriesInput = {
  connect?: InputMaybe<PurchaseOrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PurchaseOrderCreateOrConnectWithoutEntriesInput>;
  create?: InputMaybe<PurchaseOrderCreateWithoutEntriesInput>;
  update?: InputMaybe<PurchaseOrderUpdateWithoutEntriesInput>;
  upsert?: InputMaybe<PurchaseOrderUpsertWithoutEntriesInput>;
};

export type PurchaseOrderUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: PurchaseOrderUpdateWithoutCreatedByInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderUpdateWithWhereUniqueWithoutKitchenInput = {
  data: PurchaseOrderUpdateWithoutKitchenInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderUpdateWithWhereUniqueWithoutSupplierInput = {
  data: PurchaseOrderUpdateWithoutSupplierInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: PurchaseOrderUpdateWithoutUpdatedByInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderUpdateWithoutCreatedByInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliverySiteAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  deliverySiteName?: InputMaybe<StringFieldUpdateOperationsInput>;
  entries?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutPurchaseOrderInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutPurchaseOrderInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutPurchaseOrdersInput>;
  supplierEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutPurchaseOrderUpdatedInput>;
};

export type PurchaseOrderUpdateWithoutEntriesInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutPurchaseOrderCreatedInput>;
  deliverySiteAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  deliverySiteName?: InputMaybe<StringFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutPurchaseOrderInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutPurchaseOrdersInput>;
  supplierEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutPurchaseOrderUpdatedInput>;
};

export type PurchaseOrderUpdateWithoutKitchenInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutPurchaseOrderCreatedInput>;
  deliverySiteAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  deliverySiteName?: InputMaybe<StringFieldUpdateOperationsInput>;
  entries?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutPurchaseOrderInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutPurchaseOrdersInput>;
  supplierEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutPurchaseOrderUpdatedInput>;
};

export type PurchaseOrderUpdateWithoutSupplierInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutPurchaseOrderCreatedInput>;
  deliverySiteAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  deliverySiteName?: InputMaybe<StringFieldUpdateOperationsInput>;
  entries?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutPurchaseOrderInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutPurchaseOrderInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  supplierEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutPurchaseOrderUpdatedInput>;
};

export type PurchaseOrderUpdateWithoutUpdatedByInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutPurchaseOrderCreatedInput>;
  deliverySiteAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  deliverySiteName?: InputMaybe<StringFieldUpdateOperationsInput>;
  entries?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutPurchaseOrderInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutPurchaseOrderInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  supplier?: InputMaybe<SupplierUpdateOneRequiredWithoutPurchaseOrdersInput>;
  supplierEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PurchaseOrderUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: PurchaseOrderCreateWithoutCreatedByInput;
  update: PurchaseOrderUpdateWithoutCreatedByInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderUpsertWithWhereUniqueWithoutKitchenInput = {
  create: PurchaseOrderCreateWithoutKitchenInput;
  update: PurchaseOrderUpdateWithoutKitchenInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderUpsertWithWhereUniqueWithoutSupplierInput = {
  create: PurchaseOrderCreateWithoutSupplierInput;
  update: PurchaseOrderUpdateWithoutSupplierInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: PurchaseOrderCreateWithoutUpdatedByInput;
  update: PurchaseOrderUpdateWithoutUpdatedByInput;
  where: PurchaseOrderWhereUniqueInput;
};

export type PurchaseOrderUpsertWithoutEntriesInput = {
  create: PurchaseOrderCreateWithoutEntriesInput;
  update: PurchaseOrderUpdateWithoutEntriesInput;
};

export type PurchaseOrderWhereInput = {
  AND?: InputMaybe<Array<PurchaseOrderWhereInput>>;
  NOT?: InputMaybe<Array<PurchaseOrderWhereInput>>;
  OR?: InputMaybe<Array<PurchaseOrderWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  deliverySiteAddress?: InputMaybe<StringFilter>;
  deliverySiteName?: InputMaybe<StringFilter>;
  entries?: InputMaybe<PurchaseOrderEntryListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  notes?: InputMaybe<StringNullableFilter>;
  number?: InputMaybe<IntFilter>;
  supplier?: InputMaybe<SupplierWhereInput>;
  supplierEmail?: InputMaybe<StringFilter>;
  supplierId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type PurchaseOrderWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  number?: InputMaybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  /** @deprecated Please use multipleSignedS3UrlsV2 */
  MultipleSignedS3Urls: Array<SignedS3Url>;
  accountNode?: Maybe<Account>;
  /** @deprecated Use and extend addressNode method */
  address?: Maybe<Address>;
  addressList: AddressConnection;
  addressNode?: Maybe<Address>;
  /** @deprecated Use and extend addressList method */
  addresses: Array<Address>;
  /** @deprecated Use pageInfo on list method */
  addressesCount: Scalars['Int'];
  adminAccounts: Array<Account>;
  /** @deprecated Use and extend addressNode method */
  adminAddress?: Maybe<Address>;
  /** @deprecated Use and extend addressList method */
  adminAddresses: Array<Address>;
  /** @deprecated Use and extend addressesCount method */
  adminAddressesCount: Scalars['Int'];
  /** @deprecated Use and extend dishNode method */
  adminDish?: Maybe<Dish>;
  /** @deprecated Use and extend dishList method */
  adminDishes: Array<Dish>;
  /** @deprecated Use and extend dishesCount method */
  adminDishesCount: Scalars['Int'];
  /** @deprecated Use and extend ingredientNode method */
  adminIngredient?: Maybe<Ingredient>;
  /** @deprecated Use and extend ingredientList method */
  adminIngredients: Array<Ingredient>;
  /** @deprecated Use and extend ingredientsCount method */
  adminIngredientsCount: Scalars['Int'];
  /** @deprecated Use and extend kitchenNode method */
  adminKitchen?: Maybe<Kitchen>;
  /** @deprecated Use and extend kitchenList method */
  adminKitchens: Array<Kitchen>;
  /** @deprecated Use and extend kitchensCount method */
  adminKitchensCount: Scalars['Int'];
  /** @deprecated Use and extend menuNode method */
  adminMenu?: Maybe<Menu>;
  /** @deprecated Use and extend menuList method */
  adminMenus: Array<Menu>;
  /** @deprecated Use and extend menusCount method */
  adminMenusCount: Scalars['Int'];
  /** @deprecated Use and extend purchaseOrderNode */
  adminPurchaseOrder?: Maybe<PurchaseOrder>;
  /** @deprecated Use and extend purchaseOrderList */
  adminPurchaseOrders: Array<PurchaseOrder>;
  /** @deprecated Use and extend purchaseOrdersCount */
  adminPurchaseOrdersCount: Scalars['Int'];
  /** @deprecated Use and extend recipeNode method */
  adminRecipe?: Maybe<Recipe>;
  /** @deprecated Use and extend recipeList method */
  adminRecipes: Array<Recipe>;
  /** @deprecated Use and extend recipesCount method */
  adminRecipesCount: Scalars['Int'];
  /** @deprecated Use and extend stockNode method */
  adminStock?: Maybe<Stock>;
  adminSupplierList: SupplierConnection;
  /** @deprecated Use and extend adminSupplierList method */
  adminSuppliers?: Maybe<Array<Supplier>>;
  /** @deprecated Use and extend suppliersCount method */
  adminSuppliersCount: Scalars['Int'];
  /** @deprecated Use and extend userNode method */
  adminUser?: Maybe<User>;
  /** @deprecated Use and extend userList method */
  adminUsers: Array<User>;
  /** @deprecated Use and extend usersCount method */
  adminUsersCount: Scalars['Int'];
  allInvoiceList: PendingInvoiceGroupedConnection;
  /** @deprecated Use and extend allergenNode method */
  allergen?: Maybe<Allergen>;
  allergenList: AllergenConnection;
  allergenNode?: Maybe<Allergen>;
  /** @deprecated Use and extend allergenList method */
  allergens: Array<Allergen>;
  /** @deprecated Use pageInfo on list method */
  allergensCount: Scalars['Int'];
  approvedInvoiceList: PendingInvoiceGroupedConnection;
  availablePlans: Array<AvailablePlan>;
  countries: Array<Country>;
  countriesCount: Scalars['Int'];
  dashboardFinance: DashboardFinance;
  dashboardKitchen: DashboardKitchen;
  /** @deprecated Use and extend dishNode method */
  dish?: Maybe<Dish>;
  dishList: DishConnection;
  dishNode?: Maybe<Dish>;
  dishTypes: Array<DishType>;
  dishTypesCount: Scalars['Int'];
  /** @deprecated Use and extend dishList method */
  dishes: Array<Dish>;
  dishesAndRecipes: Array<DishAndRecipe>;
  dishesAndRecipesCount: Scalars['Int'];
  /** @deprecated Use pageInfo on list method */
  dishesCount: Scalars['Int'];
  flashInsights: FlashInsights;
  flashPeriod?: Maybe<FlashPeriod>;
  /** @deprecated Use pageInfo on list method */
  foodFlashCount: Scalars['Int'];
  foodFlashList: FoodFlashConnection;
  foodFlashNode?: Maybe<FoodFlash>;
  /** @deprecated Please use fuzzySearchProductListV2 */
  fuzzySearchProductList: ProductFuzzyConnection;
  fuzzySearchProductListV2: ProductFuzzyConnection;
  /** @deprecated Please use fuzzySearchProductsV2 */
  fuzzySearchProducts: FuzzyProductSearch;
  fuzzySearchProductsV2: FuzzyProductSearch;
  getProductDuplicate?: Maybe<ProductDuplicates>;
  getProductDuplicateListSummary?: Maybe<ProductDuplicateListSummary>;
  getProductDuplicateSuppliers?: Maybe<Array<Maybe<GetProductDuplicateSupplier>>>;
  hubspotContact?: Maybe<HubspotContact>;
  /** @deprecated Use and extend ingredientNode method */
  ingredient?: Maybe<Ingredient>;
  ingredientChanges?: Maybe<IngredientChanges>;
  ingredientList: IngredientConnection;
  ingredientNode?: Maybe<Ingredient>;
  /** @deprecated Use and extend ingredientList method */
  ingredients: Array<Ingredient>;
  ingredientsAndRecipes: IngredientAndRecipe;
  /** @deprecated Use pageInfo on list method */
  ingredientsCount: Scalars['Int'];
  /** @deprecated Use and extend invoiceNode method */
  invoice?: Maybe<Invoice>;
  invoiceAggregate?: Maybe<InvoiceAggregate>;
  invoiceList: InvoiceConnection;
  invoiceNode?: Maybe<Invoice>;
  invoiceOverview: InvoiceOverview;
  /** @deprecated Use and extend invoiceList method */
  invoices: Array<Invoice>;
  /** @deprecated Use pageInfo on list method */
  invoicesCount: Scalars['Int'];
  jobResponsibilities: Array<JobResponsibility>;
  /** @deprecated Use and extend kitchenNode method */
  kitchen?: Maybe<Kitchen>;
  kitchenFromJoinCode?: Maybe<KitchenPrivate>;
  kitchenInvitePublic?: Maybe<InviteDetails>;
  kitchenInvoiceRule?: Maybe<KitchenInvoiceRule>;
  kitchenList: KitchenConnection;
  kitchenNameFromJoinCode?: Maybe<Scalars['String']>;
  kitchenNode?: Maybe<Kitchen>;
  kitchenPosCategories: Array<PosCategory>;
  kitchenSetupState: KitchenSetupState;
  kitchenToAddressNode?: Maybe<KitchenToAddress>;
  kitchenToPosAccounts: Array<KitchenToPosAccount>;
  kitchenToPosCategories: Array<KitchenToPosCategory>;
  kitchenToPosLocations: Array<KitchenToPosLocation>;
  kitchenToPosProduct?: Maybe<KitchenToPosProduct>;
  kitchenToPosProducts: Array<KitchenToPosProduct>;
  kitchenToPosProductsCount: Scalars['Int'];
  kitchenToUser?: Maybe<KitchenToUser>;
  kitchenTodo: KitchenTodo;
  kitchenTodos: Array<KitchenTodo>;
  kitchenUpdates?: Maybe<Array<Maybe<KitchenUpdates>>>;
  /** @deprecated Use and extend kitchenList method */
  kitchens: Array<Kitchen>;
  /** @deprecated Use pageInfo on list method */
  kitchensCount: Scalars['Int'];
  /** @deprecated Use and extend lastOrderByUserNode */
  lastOrderByUser?: Maybe<PurchaseOrder>;
  lastOrderByUserNode?: Maybe<PurchaseOrder>;
  me?: Maybe<User>;
  /** @deprecated Use and extend menuNode method */
  menu?: Maybe<Menu>;
  menuList: MenuConnection;
  menuNode?: Maybe<Menu>;
  /** @deprecated Use and extend menuList method */
  menus: Array<Menu>;
  /** @deprecated Use pageInfo on list method */
  menusCount: Scalars['Int'];
  multipleSignedS3UrlV2: Array<SignedS3Url>;
  needsAttentionInvoiceList: PendingInvoiceGroupedConnection;
  node?: Maybe<Node>;
  notes: Array<InvoiceNote>;
  /** @deprecated Use and extend pendingInvoiceNode method */
  pendingInvoice?: Maybe<PendingInvoice>;
  pendingInvoiceList: PendingInvoiceConnection;
  pendingInvoiceListGrouped: PendingInvoiceGroupedConnection;
  pendingInvoiceListGroupedV2: PendingInvoiceGroupedConnection;
  pendingInvoiceNode?: Maybe<PendingInvoice>;
  pendingInvoiceSummary?: Maybe<PendingInvoiceSummary>;
  /** @deprecated Use and extend pendingInvoiceList method */
  pendingInvoices: Array<PendingInvoice>;
  /** @deprecated Use pageInfo on list method */
  pendingInvoicesCount: Scalars['Int'];
  placeDetails?: Maybe<GooglePlaceDetailsApi>;
  placesAutoCompleteList?: Maybe<Array<GooglePlacesAutoCompleteApi>>;
  posAccount?: Maybe<PosAccount>;
  posCategory?: Maybe<PosCategory>;
  posLocation?: Maybe<PosLocation>;
  posLocations: Array<PosLocation>;
  posProduct?: Maybe<PosProduct>;
  posTill?: Maybe<PosTill>;
  posToken?: Maybe<PosToken>;
  posTokens: Array<PosToken>;
  posTransaction?: Maybe<PosTransaction>;
  posTransactionItem?: Maybe<PosTransactionItem>;
  posType?: Maybe<PosType>;
  processingInvoiceList: PendingInvoiceConnection;
  /** @deprecated Use and extend productNode method */
  product?: Maybe<Product>;
  productList: ProductConnection;
  productNode?: Maybe<Product>;
  /** @deprecated Use and extend productList method */
  products: Array<Product>;
  /** @deprecated Use pageInfo on list method */
  productsCount: Scalars['Int'];
  /** @deprecated Use and extend purchaseOrderNode */
  purchaseOrder?: Maybe<PurchaseOrder>;
  purchaseOrderList: PurchaseOrderConnection;
  purchaseOrderNode?: Maybe<PurchaseOrder>;
  /** @deprecated Use and extend purchaseOrderList */
  purchaseOrders: Array<PurchaseOrder>;
  /** @deprecated Use pageInfo on list method */
  purchaseOrdersCount: Scalars['Int'];
  /** @deprecated Use and extend recipeNode method */
  recipe?: Maybe<Recipe>;
  recipeList: RecipeConnection;
  recipeNode?: Maybe<Recipe>;
  /** @deprecated Use and extend recipeList method */
  recipes: Array<Recipe>;
  /** @deprecated Use pageInfo on list method */
  recipesCount: Scalars['Int'];
  roles: Array<Role>;
  salesList: SalesConnection;
  salesMixInsights: SalesMixInsights;
  salesNode?: Maybe<Sales>;
  sections: Array<DishType>;
  sectionsCount: Scalars['Int'];
  signedProfilePictureUpload: SignedS3Url;
  signedS3Url: SignedS3Url;
  spendInsights: SpendInsights;
  /** @deprecated Use pageInfo on list method */
  stockCount: Scalars['Int'];
  stockList: StockConnection;
  stockNode?: Maybe<Stock>;
  /** @deprecated Use and extend supplierNode method */
  supplier?: Maybe<Supplier>;
  supplierList: SupplierConnection;
  supplierNode?: Maybe<Supplier>;
  /** @deprecated Use and extend supplierList method */
  suppliers: Array<Supplier>;
  /** @deprecated Use pageInfo on list method */
  suppliersCount: Scalars['Int'];
  unitList: UnitConnection;
  unitNode?: Maybe<Unit>;
  /** @deprecated Use and extend unitList method */
  units: Array<Unit>;
  /** @deprecated Use pageInfo on list method */
  unitsCount: Scalars['Int'];
  /** @deprecated Use and extend userNode method */
  user?: Maybe<User>;
  userList: UserConnection;
  userNode?: Maybe<User>;
  /** @deprecated Use and extend userList method */
  users: Array<User>;
  /** @deprecated Use pageInfo on list method */
  usersCount: Scalars['Int'];
  verifyPasswordResetCode?: Maybe<PasswordResetCodeResponse>;
  zip?: Maybe<Scalars['String']>;
};


export type QueryMultipleSignedS3UrlsArgs = {
  urls?: InputMaybe<Array<InputMaybe<SignedS3Input>>>;
};


export type QueryAccountNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryAddressArgs = {
  where: AddressWhereUniqueInput;
};


export type QueryAddressListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryAddressNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryAddressesArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  orderBy: Array<AddressOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryAddressesCountArgs = {
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryAdminAccountsArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type QueryAdminAddressArgs = {
  where: AddressWhereUniqueInput;
};


export type QueryAdminAddressesArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  orderBy: Array<AddressOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryAdminAddressesCountArgs = {
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryAdminDishArgs = {
  where: DishWhereUniqueInput;
};


export type QueryAdminDishesArgs = {
  cursor?: InputMaybe<DishWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DishOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DishWhereInput>;
};


export type QueryAdminDishesCountArgs = {
  where?: InputMaybe<DishWhereInput>;
};


export type QueryAdminIngredientArgs = {
  where: IngredientWhereUniqueInput;
};


export type QueryAdminIngredientsArgs = {
  cursor?: InputMaybe<IngredientWhereUniqueInput>;
  orderBy?: InputMaybe<Array<IngredientOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IngredientWhereInput>;
};


export type QueryAdminIngredientsCountArgs = {
  where?: InputMaybe<IngredientWhereInput>;
};


export type QueryAdminKitchenArgs = {
  where: KitchenWhereUniqueInput;
};


export type QueryAdminKitchensArgs = {
  cursor?: InputMaybe<KitchenWhereUniqueInput>;
  orderBy?: InputMaybe<Array<KitchenOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KitchenWhereInput>;
};


export type QueryAdminKitchensCountArgs = {
  where?: InputMaybe<KitchenWhereInput>;
};


export type QueryAdminMenuArgs = {
  where: MenuWhereUniqueInput;
};


export type QueryAdminMenusArgs = {
  cursor?: InputMaybe<MenuWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MenuOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MenuWhereInput>;
};


export type QueryAdminMenusCountArgs = {
  where?: InputMaybe<MenuWhereInput>;
};


export type QueryAdminPurchaseOrderArgs = {
  where: PurchaseOrderWhereUniqueInput;
};


export type QueryAdminPurchaseOrdersArgs = {
  cursor?: InputMaybe<PurchaseOrderWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PurchaseOrderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PurchaseOrderWhereInput>;
};


export type QueryAdminPurchaseOrdersCountArgs = {
  where?: InputMaybe<PurchaseOrderWhereInput>;
};


export type QueryAdminRecipeArgs = {
  where: RecipeWhereUniqueInput;
};


export type QueryAdminRecipesArgs = {
  cursor?: InputMaybe<RecipeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RecipeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RecipeWhereInput>;
};


export type QueryAdminRecipesCountArgs = {
  where?: InputMaybe<RecipeWhereInput>;
};


export type QueryAdminStockArgs = {
  where: StockWhereUniqueInput;
};


export type QueryAdminSupplierListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryAdminSuppliersArgs = {
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryAdminSuppliersCountArgs = {
  where?: InputMaybe<SupplierWhereInput>;
};


export type QueryAdminUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryAdminUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAdminUsersCountArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAllInvoiceListArgs = {
  kitchenCursor: Scalars['ID'];
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryAllergenArgs = {
  where: AllergenWhereUniqueInput;
};


export type QueryAllergenListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryAllergenNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryAllergensArgs = {
  cursor?: InputMaybe<AllergenWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AllergenOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AllergenWhereInput>;
};


export type QueryAllergensCountArgs = {
  where?: InputMaybe<AllergenWhereInput>;
};


export type QueryApprovedInvoiceListArgs = {
  approvedOnly: Scalars['Boolean'];
  kitchenCursor: Scalars['ID'];
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryDashboardFinanceArgs = {
  kitchenId: Scalars['Int'];
  period: PeriodEnum;
};


export type QueryDashboardKitchenArgs = {
  kitchenId: Scalars['Int'];
  period: PeriodEnum;
};


export type QueryDishArgs = {
  where: DishWhereUniqueInput;
};


export type QueryDishListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryDishNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryDishesArgs = {
  cursor?: InputMaybe<DishWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DishOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DishWhereInput>;
};


export type QueryDishesAndRecipesArgs = {
  dishWhere?: InputMaybe<DishWhereInput>;
  recipeWhere?: InputMaybe<RecipeWhereInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryDishesAndRecipesCountArgs = {
  dishWhere?: InputMaybe<DishWhereInput>;
  recipeWhere?: InputMaybe<RecipeWhereInput>;
};


export type QueryDishesCountArgs = {
  where?: InputMaybe<DishWhereInput>;
};


export type QueryFlashInsightsArgs = {
  kitchenId: Scalars['Int'];
  period: PeriodEnum;
  start: Scalars['DateTime'];
};


export type QueryFlashPeriodArgs = {
  kitchenId: Scalars['Int'];
  period: PeriodEnum;
  startDate: Scalars['DateTime'];
};


export type QueryFoodFlashCountArgs = {
  where?: InputMaybe<FoodFlashWhereInput>;
};


export type QueryFoodFlashListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  kitchenId: Scalars['Int'];
  orderBy?: InputMaybe<Array<FoodFlashOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FoodFlashWhereInput>;
};


export type QueryFoodFlashNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryFuzzySearchProductListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  kitchenCursor?: InputMaybe<Scalars['ID']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  supplierCursor: Scalars['ID'];
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryFuzzySearchProductListV2Args = {
  cursor?: InputMaybe<Scalars['ID']>;
  kitchenCursor: Scalars['ID'];
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  supplierCursor: Scalars['ID'];
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryFuzzySearchProductsArgs = {
  code?: InputMaybe<Scalars['Boolean']>;
  custom?: InputMaybe<Scalars['Boolean']>;
  kitchenId?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryFuzzySearchProductsV2Args = {
  code?: InputMaybe<Scalars['Boolean']>;
  custom?: InputMaybe<Scalars['Boolean']>;
  kitchenId: Scalars['Int'];
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  supplierId: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryGetProductDuplicateArgs = {
  supplierId?: InputMaybe<Scalars['Int']>;
};


export type QueryGetProductDuplicateListSummaryArgs = {
  supplierId?: InputMaybe<Scalars['Int']>;
};


export type QueryIngredientArgs = {
  where: IngredientWhereUniqueInput;
};


export type QueryIngredientChangesArgs = {
  kitchenId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryIngredientListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  kitchenId: Scalars['Int'];
  orderBy?: InputMaybe<Array<IngredientOrderByInput>>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IngredientWhereInput>;
};


export type QueryIngredientNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryIngredientsArgs = {
  cursor?: InputMaybe<IngredientWhereUniqueInput>;
  orderBy?: InputMaybe<Array<IngredientOrderByInput>>;
  orderByName?: InputMaybe<Scalars['Boolean']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IngredientWhereInput>;
};


export type QueryIngredientsAndRecipesArgs = {
  ingredientCursor?: InputMaybe<IngredientWhereUniqueInput>;
  ingredientOrderBy?: InputMaybe<Array<IngredientOrderByInput>>;
  ingredientWhere?: InputMaybe<IngredientWhereInput>;
  orderByName?: InputMaybe<Scalars['Boolean']>;
  recipeCursor?: InputMaybe<RecipeWhereUniqueInput>;
  recipeOrderBy?: InputMaybe<Array<RecipeOrderByInput>>;
  recipeTake?: InputMaybe<Scalars['Int']>;
  recipeWhere?: InputMaybe<RecipeWhereInput>;
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryIngredientsCountArgs = {
  where?: InputMaybe<IngredientWhereInput>;
};


export type QueryInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryInvoiceAggregateArgs = {
  endDate: Scalars['Date'];
  kitchenId: Scalars['Int'];
  startDate: Scalars['Date'];
};


export type QueryInvoiceListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryInvoiceNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryInvoiceOverviewArgs = {
  kitchenId: Scalars['Int'];
};


export type QueryInvoicesArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InvoiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryInvoicesCountArgs = {
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryKitchenArgs = {
  where: KitchenWhereUniqueInput;
};


export type QueryKitchenFromJoinCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
};


export type QueryKitchenInvitePublicArgs = {
  inviteCode?: InputMaybe<Scalars['String']>;
  joinCode: Scalars['String'];
};


export type QueryKitchenInvoiceRuleArgs = {
  kitchenId: Scalars['Int'];
};


export type QueryKitchenListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryKitchenNameFromJoinCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
};


export type QueryKitchenNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryKitchenPosCategoriesArgs = {
  kitchenId: Scalars['Int'];
  linked?: InputMaybe<Scalars['Boolean']>;
  posTypeName?: InputMaybe<Scalars['String']>;
};


export type QueryKitchenSetupStateArgs = {
  kitchenId: Scalars['Int'];
};


export type QueryKitchenToAddressNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryKitchenToPosAccountsArgs = {
  kitchenId: Scalars['Int'];
};


export type QueryKitchenToPosCategoriesArgs = {
  kitchenId: Scalars['Int'];
  posTypeName?: InputMaybe<Scalars['String']>;
};


export type QueryKitchenToPosLocationsArgs = {
  kitchenId: Scalars['Int'];
  posAccountId?: InputMaybe<Scalars['Int']>;
};


export type QueryKitchenToPosProductArgs = {
  id: Scalars['Int'];
};


export type QueryKitchenToPosProductsArgs = {
  kitchenId: Scalars['Int'];
  linked?: InputMaybe<Scalars['Boolean']>;
};


export type QueryKitchenToPosProductsCountArgs = {
  kitchenId: Scalars['Int'];
  linked?: InputMaybe<Scalars['Boolean']>;
};


export type QueryKitchenToUserArgs = {
  id: Scalars['Int'];
};


export type QueryKitchenTodoArgs = {
  id: Scalars['Int'];
};


export type QueryKitchenTodosArgs = {
  kitchenId: Scalars['Int'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};


export type QueryKitchensArgs = {
  cursor?: InputMaybe<KitchenWhereUniqueInput>;
  orderBy?: InputMaybe<Array<KitchenOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KitchenWhereInput>;
};


export type QueryKitchensCountArgs = {
  where?: InputMaybe<KitchenWhereInput>;
};


export type QueryLastOrderByUserArgs = {
  kitchenId: Scalars['Int'];
};


export type QueryLastOrderByUserNodeArgs = {
  kitchenCursor: Scalars['ID'];
};


export type QueryMenuArgs = {
  where: MenuWhereUniqueInput;
};


export type QueryMenuListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  orderBy?: InputMaybe<Array<MenuOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MenuWhereInput>;
};


export type QueryMenuNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryMenusArgs = {
  cursor?: InputMaybe<MenuWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MenuOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MenuWhereInput>;
};


export type QueryMenusCountArgs = {
  where?: InputMaybe<MenuWhereInput>;
};


export type QueryMultipleSignedS3UrlV2Args = {
  urls: Array<SignedS3Input>;
};


export type QueryNeedsAttentionInvoiceListArgs = {
  approvedOnly: Scalars['Boolean'];
  kitchenCursor: Scalars['ID'];
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryNotesArgs = {
  pendingInvoiceId: Scalars['Int'];
};


export type QueryPendingInvoiceArgs = {
  where: PendingInvoiceWhereUniqueInput;
};


export type QueryPendingInvoiceListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryPendingInvoiceListGroupedArgs = {
  kitchenCursor: Scalars['ID'];
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PendingInvoiceWhereInput>;
};


export type QueryPendingInvoiceListGroupedV2Args = {
  kitchenCursor: Scalars['ID'];
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryPendingInvoiceNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryPendingInvoiceSummaryArgs = {
  kitchenName?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
};


export type QueryPendingInvoicesArgs = {
  cursor?: InputMaybe<PendingInvoiceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PendingInvoiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PendingInvoiceWhereInput>;
};


export type QueryPendingInvoicesCountArgs = {
  where?: InputMaybe<PendingInvoiceWhereInput>;
};


export type QueryPlaceDetailsArgs = {
  where?: InputMaybe<PlacesDetailsListWhereInput>;
};


export type QueryPlacesAutoCompleteListArgs = {
  where?: InputMaybe<PlacesAutoCompleteListWhereInput>;
};


export type QueryPosAccountArgs = {
  externalId: Scalars['String'];
  posTypeId: Scalars['Int'];
};


export type QueryPosCategoryArgs = {
  externalId: Scalars['String'];
  posAccountId: Scalars['Int'];
};


export type QueryPosLocationArgs = {
  externalId: Scalars['String'];
  posAccountId: Scalars['Int'];
};


export type QueryPosLocationsArgs = {
  posAccountId: Scalars['Int'];
};


export type QueryPosProductArgs = {
  externalId: Scalars['String'];
  posAccountId: Scalars['Int'];
};


export type QueryPosTillArgs = {
  externalId: Scalars['String'];
  posTypeId: Scalars['Int'];
};


export type QueryPosTokenArgs = {
  externalId: Scalars['String'];
  posTypeId: Scalars['Int'];
};


export type QueryPosTransactionArgs = {
  externalId: Scalars['String'];
  posLocationId: Scalars['Int'];
};


export type QueryPosTransactionItemArgs = {
  externalId: Scalars['String'];
  posTransactionId: Scalars['Int'];
};


export type QueryPosTypeArgs = {
  name: Scalars['String'];
};


export type QueryProcessingInvoiceListArgs = {
  kitchenCursor: Scalars['ID'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryProductListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryProductNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryProductsCountArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryPurchaseOrderArgs = {
  where: PurchaseOrderWhereUniqueInput;
};


export type QueryPurchaseOrderListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  orderBy?: InputMaybe<Array<PurchaseOrderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PurchaseOrderWhereInput>;
};


export type QueryPurchaseOrderNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryPurchaseOrdersArgs = {
  cursor?: InputMaybe<PurchaseOrderWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PurchaseOrderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PurchaseOrderWhereInput>;
};


export type QueryPurchaseOrdersCountArgs = {
  where?: InputMaybe<PurchaseOrderWhereInput>;
};


export type QueryRecipeArgs = {
  where: RecipeWhereUniqueInput;
};


export type QueryRecipeListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  orderBy?: InputMaybe<Array<RecipeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RecipeWhereInput>;
};


export type QueryRecipeNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryRecipesArgs = {
  cursor?: InputMaybe<RecipeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RecipeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RecipeWhereInput>;
};


export type QueryRecipesCountArgs = {
  where?: InputMaybe<RecipeWhereInput>;
};


export type QuerySalesListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  kitchenId: Scalars['Int'];
  orderBy?: InputMaybe<Array<SalesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SalesWhereInput>;
};


export type QuerySalesMixInsightsArgs = {
  kitchenId: Scalars['Int'];
  menuId?: InputMaybe<Scalars['Int']>;
  period: PeriodEnum;
  start: Scalars['DateTime'];
};


export type QuerySalesNodeArgs = {
  cursor: Scalars['ID'];
};


export type QuerySignedProfilePictureUploadArgs = {
  extension?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  params?: InputMaybe<S3Params>;
};


export type QuerySignedS3UrlArgs = {
  extension?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  invoiceId?: InputMaybe<Scalars['Int']>;
  kitchenId: Scalars['Int'];
  params?: InputMaybe<S3Params>;
  secure?: InputMaybe<Scalars['Boolean']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};


export type QuerySpendInsightsArgs = {
  kitchenId: Scalars['Int'];
  period: PeriodEnum;
  start: Scalars['DateTime'];
};


export type QueryStockCountArgs = {
  where?: InputMaybe<StockWhereInput>;
};


export type QueryStockListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  kitchenId: Scalars['Int'];
  orderBy?: InputMaybe<Array<StockOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StockWhereInput>;
};


export type QueryStockNodeArgs = {
  cursor: Scalars['ID'];
};


export type QuerySupplierArgs = {
  where: SupplierWhereUniqueInput;
};


export type QuerySupplierListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QuerySupplierNodeArgs = {
  cursor: Scalars['ID'];
};


export type QuerySuppliersArgs = {
  cursor?: InputMaybe<SupplierWhereUniqueInput>;
  fuzzySearch?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<SupplierOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SupplierWhereInput>;
};


export type QuerySuppliersCountArgs = {
  where?: InputMaybe<SupplierWhereInput>;
};


export type QueryUnitListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryUnitNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryUnitsArgs = {
  cursor?: InputMaybe<UnitWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UnitOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UnitWhereInput>;
};


export type QueryUnitsCountArgs = {
  where?: InputMaybe<UnitWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUserListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserListWhereInput>;
};


export type QueryUserNodeArgs = {
  cursor: Scalars['ID'];
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryUsersCountArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type QueryVerifyPasswordResetCodeArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  passwordResetCode: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type QueryZipArgs = {
  files: Array<Scalars['String']>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Recipe = Node & {
  __typename?: 'Recipe';
  _cursor: Scalars['ID'];
  allergenList: RecipeToAllergenConnection;
  /** @deprecated Use or extend allergenList field */
  allergens: Array<RecipeToAllergen>;
  amount?: Maybe<Scalars['Float']>;
  childRecipeList: RecipeToRecipeConnection;
  /** @deprecated Use or extend childRecipeList field */
  childRecipes: Array<RecipeToRecipe>;
  conversionUnit?: Maybe<Scalars['Float']>;
  conversionUnitType?: Maybe<Unit>;
  conversionUnitValue?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  dishList: DishConnection;
  /** @deprecated Use or extend dishList field */
  dishes: Array<Dish>;
  hasNoAllergens?: Maybe<Scalars['Boolean']>;
  history: Array<ChangeHistory>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  ingredientList: RecipeToIngredientConnection;
  /** @deprecated Use or extend ingredientList field */
  ingredients: Array<RecipeToIngredient>;
  instructions?: Maybe<Scalars['String']>;
  isCalculatedDraft?: Maybe<Scalars['Boolean']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  isUsed: Scalars['Boolean'];
  kitchen: Kitchen;
  name: Scalars['String'];
  parentRecipeList: RecipeToRecipeConnection;
  /** @deprecated Use or extend parentRecipeList field */
  parentRecipes: Array<RecipeToRecipe>;
  section?: Maybe<Scalars['String']>;
  status: Status;
  stockData?: Maybe<Scalars['Json']>;
  unit?: Maybe<Unit>;
  unitCost?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
};


export type RecipeAllergenListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type RecipeChildRecipeListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type RecipeChildRecipesArgs = {
  cursor?: InputMaybe<RecipeToRecipeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RecipeToRecipeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RecipeToRecipeWhereInput>;
};


export type RecipeDishListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type RecipeDishesArgs = {
  cursor?: InputMaybe<DishWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DishOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DishWhereInput>;
};


export type RecipeIngredientListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type RecipeIngredientsArgs = {
  cursor?: InputMaybe<RecipeToIngredientWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RecipeToIngredientOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RecipeToIngredientWhereInput>;
};


export type RecipeParentRecipeListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type RecipeParentRecipesArgs = {
  cursor?: InputMaybe<RecipeToRecipeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RecipeToRecipeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RecipeToRecipeWhereInput>;
};

export type RecipeConnection = {
  __typename?: 'RecipeConnection';
  edges: Array<RecipeEdge>;
  nodes: Array<Recipe>;
  pageInfo: PageInfo;
};

export type RecipeCreateManyCreatedByInput = {
  amount?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchenId: Scalars['Int'];
  name: Scalars['String'];
  section?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitCost?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type RecipeCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<RecipeCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RecipeCreateManyKitchenInput = {
  amount?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  section?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitCost?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type RecipeCreateManyKitchenInputEnvelope = {
  data?: InputMaybe<Array<RecipeCreateManyKitchenInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RecipeCreateManyUnitInput = {
  amount?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchenId: Scalars['Int'];
  name: Scalars['String'];
  section?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type RecipeCreateManyUnitInputEnvelope = {
  data?: InputMaybe<Array<RecipeCreateManyUnitInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RecipeCreateManyUpdatedByInput = {
  amount?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchenId: Scalars['Int'];
  name: Scalars['String'];
  section?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitCost?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeCreateManyUpdatedByInputEnvelope = {
  data?: InputMaybe<Array<RecipeCreateManyUpdatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RecipeCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<RecipeCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<RecipeCreateManyCreatedByInputEnvelope>;
};

export type RecipeCreateNestedManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<RecipeCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<RecipeCreateManyKitchenInputEnvelope>;
};

export type RecipeCreateNestedManyWithoutUnitInput = {
  connect?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<RecipeCreateWithoutUnitInput>>;
  createMany?: InputMaybe<RecipeCreateManyUnitInputEnvelope>;
};

export type RecipeCreateNestedManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<RecipeCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<RecipeCreateManyUpdatedByInputEnvelope>;
};

export type RecipeCreateNestedOneWithoutChildRecipesInput = {
  connect?: InputMaybe<RecipeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RecipeCreateOrConnectWithoutChildRecipesInput>;
  create?: InputMaybe<RecipeCreateWithoutChildRecipesInput>;
};

export type RecipeCreateNestedOneWithoutDishesInput = {
  connect?: InputMaybe<RecipeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RecipeCreateOrConnectWithoutDishesInput>;
  create?: InputMaybe<RecipeCreateWithoutDishesInput>;
};

export type RecipeCreateNestedOneWithoutIngredientsInput = {
  connect?: InputMaybe<RecipeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RecipeCreateOrConnectWithoutIngredientsInput>;
  create?: InputMaybe<RecipeCreateWithoutIngredientsInput>;
};

export type RecipeCreateNestedOneWithoutParentRecipesInput = {
  connect?: InputMaybe<RecipeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RecipeCreateOrConnectWithoutParentRecipesInput>;
  create?: InputMaybe<RecipeCreateWithoutParentRecipesInput>;
};

export type RecipeCreateOrConnectWithoutChildRecipesInput = {
  create: RecipeCreateWithoutChildRecipesInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeCreateOrConnectWithoutCreatedByInput = {
  create: RecipeCreateWithoutCreatedByInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeCreateOrConnectWithoutDishesInput = {
  create: RecipeCreateWithoutDishesInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeCreateOrConnectWithoutIngredientsInput = {
  create: RecipeCreateWithoutIngredientsInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeCreateOrConnectWithoutKitchenInput = {
  create: RecipeCreateWithoutKitchenInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeCreateOrConnectWithoutParentRecipesInput = {
  create: RecipeCreateWithoutParentRecipesInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeCreateOrConnectWithoutUnitInput = {
  create: RecipeCreateWithoutUnitInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeCreateOrConnectWithoutUpdatedByInput = {
  create: RecipeCreateWithoutUpdatedByInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeCreateWithoutChildRecipesInput = {
  allergens?: InputMaybe<RecipeToAllergenCreateNestedManyWithoutRecipeInput>;
  amount?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutRecipeCreatedInput;
  dishes?: InputMaybe<DishCreateNestedManyWithoutRecipeInput>;
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  ingredients?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutRecipeInput>;
  instructions?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutRecipesInput;
  name: Scalars['String'];
  parentRecipes?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutChildRecipeInput>;
  section?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  stockData?: InputMaybe<Scalars['Json']>;
  unit?: InputMaybe<UnitCreateNestedOneWithoutRecipeUnitInput>;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutRecipeUpdatedInput;
};

export type RecipeCreateWithoutCreatedByInput = {
  allergens?: InputMaybe<RecipeToAllergenCreateNestedManyWithoutRecipeInput>;
  amount?: InputMaybe<Scalars['Float']>;
  childRecipes?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutParentRecipeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishes?: InputMaybe<DishCreateNestedManyWithoutRecipeInput>;
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  ingredients?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutRecipeInput>;
  instructions?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutRecipesInput;
  name: Scalars['String'];
  parentRecipes?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutChildRecipeInput>;
  section?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  stockData?: InputMaybe<Scalars['Json']>;
  unit?: InputMaybe<UnitCreateNestedOneWithoutRecipeUnitInput>;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutRecipeUpdatedInput;
};

export type RecipeCreateWithoutDishesInput = {
  allergens?: InputMaybe<RecipeToAllergenCreateNestedManyWithoutRecipeInput>;
  amount?: InputMaybe<Scalars['Float']>;
  childRecipes?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutParentRecipeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutRecipeCreatedInput;
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  ingredients?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutRecipeInput>;
  instructions?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutRecipesInput;
  name: Scalars['String'];
  parentRecipes?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutChildRecipeInput>;
  section?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  stockData?: InputMaybe<Scalars['Json']>;
  unit?: InputMaybe<UnitCreateNestedOneWithoutRecipeUnitInput>;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutRecipeUpdatedInput;
};

export type RecipeCreateWithoutIngredientsInput = {
  allergens?: InputMaybe<RecipeToAllergenCreateNestedManyWithoutRecipeInput>;
  amount?: InputMaybe<Scalars['Float']>;
  childRecipes?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutParentRecipeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutRecipeCreatedInput;
  dishes?: InputMaybe<DishCreateNestedManyWithoutRecipeInput>;
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutRecipesInput;
  name: Scalars['String'];
  parentRecipes?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutChildRecipeInput>;
  section?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  stockData?: InputMaybe<Scalars['Json']>;
  unit?: InputMaybe<UnitCreateNestedOneWithoutRecipeUnitInput>;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutRecipeUpdatedInput;
};

export type RecipeCreateWithoutKitchenInput = {
  allergens?: InputMaybe<RecipeToAllergenCreateNestedManyWithoutRecipeInput>;
  amount?: InputMaybe<Scalars['Float']>;
  childRecipes?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutParentRecipeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutRecipeCreatedInput;
  dishes?: InputMaybe<DishCreateNestedManyWithoutRecipeInput>;
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  ingredients?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutRecipeInput>;
  instructions?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentRecipes?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutChildRecipeInput>;
  section?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  stockData?: InputMaybe<Scalars['Json']>;
  unit?: InputMaybe<UnitCreateNestedOneWithoutRecipeUnitInput>;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutRecipeUpdatedInput;
};

export type RecipeCreateWithoutParentRecipesInput = {
  allergens?: InputMaybe<RecipeToAllergenCreateNestedManyWithoutRecipeInput>;
  amount?: InputMaybe<Scalars['Float']>;
  childRecipes?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutParentRecipeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutRecipeCreatedInput;
  dishes?: InputMaybe<DishCreateNestedManyWithoutRecipeInput>;
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  ingredients?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutRecipeInput>;
  instructions?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutRecipesInput;
  name: Scalars['String'];
  section?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  stockData?: InputMaybe<Scalars['Json']>;
  unit?: InputMaybe<UnitCreateNestedOneWithoutRecipeUnitInput>;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutRecipeUpdatedInput;
};

export type RecipeCreateWithoutUnitInput = {
  allergens?: InputMaybe<RecipeToAllergenCreateNestedManyWithoutRecipeInput>;
  amount?: InputMaybe<Scalars['Float']>;
  childRecipes?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutParentRecipeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutRecipeCreatedInput;
  dishes?: InputMaybe<DishCreateNestedManyWithoutRecipeInput>;
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  ingredients?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutRecipeInput>;
  instructions?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutRecipesInput;
  name: Scalars['String'];
  parentRecipes?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutChildRecipeInput>;
  section?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutRecipeUpdatedInput;
};

export type RecipeCreateWithoutUpdatedByInput = {
  allergens?: InputMaybe<RecipeToAllergenCreateNestedManyWithoutRecipeInput>;
  amount?: InputMaybe<Scalars['Float']>;
  childRecipes?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutParentRecipeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutRecipeCreatedInput;
  dishes?: InputMaybe<DishCreateNestedManyWithoutRecipeInput>;
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  ingredients?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutRecipeInput>;
  instructions?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutRecipesInput;
  name: Scalars['String'];
  parentRecipes?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutChildRecipeInput>;
  section?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  stockData?: InputMaybe<Scalars['Json']>;
  unit?: InputMaybe<UnitCreateNestedOneWithoutRecipeUnitInput>;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeEdge = {
  __typename?: 'RecipeEdge';
  cursor: Scalars['ID'];
  node: Recipe;
};

export type RecipeListRelationFilter = {
  every?: InputMaybe<RecipeWhereInput>;
  none?: InputMaybe<RecipeWhereInput>;
  some?: InputMaybe<RecipeWhereInput>;
};

export type RecipeOrderByInput = {
  allergens?: InputMaybe<RecipeToAllergenOrderByRelationAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  childRecipes?: InputMaybe<RecipeToRecipeOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  dishes?: InputMaybe<DishOrderByRelationAggregateInput>;
  hasNoAllergens?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  ingredients?: InputMaybe<RecipeToIngredientOrderByRelationAggregateInput>;
  instructions?: InputMaybe<SortOrder>;
  isDraft?: InputMaybe<SortOrder>;
  kitchen?: InputMaybe<KitchenOrderByInput>;
  kitchenId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentRecipes?: InputMaybe<RecipeToRecipeOrderByRelationAggregateInput>;
  section?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  stockData?: InputMaybe<SortOrder>;
  unit?: InputMaybe<UnitOrderByInput>;
  unitCost?: InputMaybe<SortOrder>;
  unitId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
};

export type RecipeOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type RecipeScalarWhereInput = {
  AND?: InputMaybe<Array<RecipeScalarWhereInput>>;
  NOT?: InputMaybe<Array<RecipeScalarWhereInput>>;
  OR?: InputMaybe<Array<RecipeScalarWhereInput>>;
  amount?: InputMaybe<FloatNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdById?: InputMaybe<IntFilter>;
  hasNoAllergens?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<IntFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  instructions?: InputMaybe<StringNullableFilter>;
  isDraft?: InputMaybe<BoolNullableFilter>;
  kitchenId?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  section?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  stockData?: InputMaybe<JsonNullableFilter>;
  unitCost?: InputMaybe<FloatNullableFilter>;
  unitId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedById?: InputMaybe<IntFilter>;
};

export type RecipeToAllergen = Node & {
  __typename?: 'RecipeToAllergen';
  _cursor: Scalars['ID'];
  allergen: Allergen;
  contains: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  recipe: Recipe;
  removable: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type RecipeToAllergenConnection = {
  __typename?: 'RecipeToAllergenConnection';
  edges: Array<RecipeToAllergenEdge>;
  nodes: Array<RecipeToAllergen>;
  pageInfo: PageInfo;
};

export type RecipeToAllergenCreateManyRecipeInput = {
  allergenId: Scalars['Int'];
  contains: Scalars['Boolean'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  removable: Scalars['Boolean'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeToAllergenCreateManyRecipeInputEnvelope = {
  data?: InputMaybe<Array<RecipeToAllergenCreateManyRecipeInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RecipeToAllergenCreateNestedManyWithoutRecipeInput = {
  connect?: InputMaybe<Array<RecipeToAllergenWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeToAllergenCreateOrConnectWithoutRecipeInput>>;
  create?: InputMaybe<Array<RecipeToAllergenCreateWithoutRecipeInput>>;
  createMany?: InputMaybe<RecipeToAllergenCreateManyRecipeInputEnvelope>;
};

export type RecipeToAllergenCreateOrConnectWithoutRecipeInput = {
  create: RecipeToAllergenCreateWithoutRecipeInput;
  where: RecipeToAllergenWhereUniqueInput;
};

export type RecipeToAllergenCreateWithoutRecipeInput = {
  allergen: AllergenCreateNestedOneWithoutRecipesInput;
  contains: Scalars['Boolean'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  removable: Scalars['Boolean'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeToAllergenEdge = {
  __typename?: 'RecipeToAllergenEdge';
  cursor: Scalars['ID'];
  node: RecipeToAllergen;
};

export type RecipeToAllergenInputObject = {
  contains: Scalars['Boolean'];
  id: Scalars['Int'];
  removable: Scalars['Boolean'];
};

export type RecipeToAllergenListRelationFilter = {
  every?: InputMaybe<RecipeToAllergenWhereInput>;
  none?: InputMaybe<RecipeToAllergenWhereInput>;
  some?: InputMaybe<RecipeToAllergenWhereInput>;
};

export type RecipeToAllergenOrderByInput = {
  allergen?: InputMaybe<AllergenOrderByInput>;
  allergenId?: InputMaybe<SortOrder>;
  contains?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  recipe?: InputMaybe<RecipeOrderByInput>;
  recipeId?: InputMaybe<SortOrder>;
  removable?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RecipeToAllergenOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type RecipeToAllergenRecipeIdAllergenIdCompoundUniqueInput = {
  allergenId: Scalars['Int'];
  recipeId: Scalars['Int'];
};

export type RecipeToAllergenScalarWhereInput = {
  AND?: InputMaybe<Array<RecipeToAllergenScalarWhereInput>>;
  NOT?: InputMaybe<Array<RecipeToAllergenScalarWhereInput>>;
  OR?: InputMaybe<Array<RecipeToAllergenScalarWhereInput>>;
  allergenId?: InputMaybe<IntFilter>;
  contains?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  recipeId?: InputMaybe<IntFilter>;
  removable?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RecipeToAllergenUpdateManyMutationInput = {
  contains?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  removable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RecipeToAllergenUpdateManyWithWhereWithoutRecipeInput = {
  data: RecipeToAllergenUpdateManyMutationInput;
  where: RecipeToAllergenScalarWhereInput;
};

export type RecipeToAllergenUpdateManyWithoutRecipeInput = {
  connect?: InputMaybe<Array<RecipeToAllergenWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeToAllergenCreateOrConnectWithoutRecipeInput>>;
  create?: InputMaybe<Array<RecipeToAllergenCreateWithoutRecipeInput>>;
  createMany?: InputMaybe<RecipeToAllergenCreateManyRecipeInputEnvelope>;
  delete?: InputMaybe<Array<RecipeToAllergenWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RecipeToAllergenScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RecipeToAllergenWhereUniqueInput>>;
  set?: InputMaybe<Array<RecipeToAllergenWhereUniqueInput>>;
  update?: InputMaybe<Array<RecipeToAllergenUpdateWithWhereUniqueWithoutRecipeInput>>;
  updateMany?: InputMaybe<Array<RecipeToAllergenUpdateManyWithWhereWithoutRecipeInput>>;
  upsert?: InputMaybe<Array<RecipeToAllergenUpsertWithWhereUniqueWithoutRecipeInput>>;
};

export type RecipeToAllergenUpdateWithWhereUniqueWithoutRecipeInput = {
  data: RecipeToAllergenUpdateWithoutRecipeInput;
  where: RecipeToAllergenWhereUniqueInput;
};

export type RecipeToAllergenUpdateWithoutRecipeInput = {
  allergen?: InputMaybe<AllergenUpdateOneRequiredWithoutRecipesInput>;
  contains?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  removable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RecipeToAllergenUpsertWithWhereUniqueWithoutRecipeInput = {
  create: RecipeToAllergenCreateWithoutRecipeInput;
  update: RecipeToAllergenUpdateWithoutRecipeInput;
  where: RecipeToAllergenWhereUniqueInput;
};

export type RecipeToAllergenWhereInput = {
  AND?: InputMaybe<Array<RecipeToAllergenWhereInput>>;
  NOT?: InputMaybe<Array<RecipeToAllergenWhereInput>>;
  OR?: InputMaybe<Array<RecipeToAllergenWhereInput>>;
  allergen?: InputMaybe<AllergenWhereInput>;
  allergenId?: InputMaybe<IntFilter>;
  contains?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  recipe?: InputMaybe<RecipeWhereInput>;
  recipeId?: InputMaybe<IntFilter>;
  removable?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RecipeToAllergenWhereUniqueInput = {
  recipeId_allergenId?: InputMaybe<RecipeToAllergenRecipeIdAllergenIdCompoundUniqueInput>;
};

export type RecipeToIngredient = Node & {
  __typename?: 'RecipeToIngredient';
  _cursor: Scalars['ID'];
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  ingredient: Ingredient;
  recipe: Recipe;
  unit?: Maybe<Unit>;
  unitCost?: Maybe<Scalars['Float']>;
  unitId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type RecipeToIngredientConnection = {
  __typename?: 'RecipeToIngredientConnection';
  edges: Array<RecipeToIngredientEdge>;
  nodes: Array<RecipeToIngredient>;
  pageInfo: PageInfo;
};

export type RecipeToIngredientCreateManyIngredientInput = {
  amount: Scalars['Float'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  recipeId: Scalars['Int'];
  unitCost?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeToIngredientCreateManyIngredientInputEnvelope = {
  data?: InputMaybe<Array<RecipeToIngredientCreateManyIngredientInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RecipeToIngredientCreateManyRecipeInput = {
  amount: Scalars['Float'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ingredientId: Scalars['Int'];
  unitCost?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeToIngredientCreateManyRecipeInputEnvelope = {
  data?: InputMaybe<Array<RecipeToIngredientCreateManyRecipeInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RecipeToIngredientCreateManyUnitInput = {
  amount: Scalars['Float'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ingredientId: Scalars['Int'];
  recipeId: Scalars['Int'];
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeToIngredientCreateManyUnitInputEnvelope = {
  data?: InputMaybe<Array<RecipeToIngredientCreateManyUnitInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RecipeToIngredientCreateNestedManyWithoutIngredientInput = {
  connect?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeToIngredientCreateOrConnectWithoutIngredientInput>>;
  create?: InputMaybe<Array<RecipeToIngredientCreateWithoutIngredientInput>>;
  createMany?: InputMaybe<RecipeToIngredientCreateManyIngredientInputEnvelope>;
};

export type RecipeToIngredientCreateNestedManyWithoutRecipeInput = {
  connect?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeToIngredientCreateOrConnectWithoutRecipeInput>>;
  create?: InputMaybe<Array<RecipeToIngredientCreateWithoutRecipeInput>>;
  createMany?: InputMaybe<RecipeToIngredientCreateManyRecipeInputEnvelope>;
};

export type RecipeToIngredientCreateNestedManyWithoutUnitInput = {
  connect?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeToIngredientCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<RecipeToIngredientCreateWithoutUnitInput>>;
  createMany?: InputMaybe<RecipeToIngredientCreateManyUnitInputEnvelope>;
};

export type RecipeToIngredientCreateOrConnectWithoutIngredientInput = {
  create: RecipeToIngredientCreateWithoutIngredientInput;
  where: RecipeToIngredientWhereUniqueInput;
};

export type RecipeToIngredientCreateOrConnectWithoutRecipeInput = {
  create: RecipeToIngredientCreateWithoutRecipeInput;
  where: RecipeToIngredientWhereUniqueInput;
};

export type RecipeToIngredientCreateOrConnectWithoutUnitInput = {
  create: RecipeToIngredientCreateWithoutUnitInput;
  where: RecipeToIngredientWhereUniqueInput;
};

export type RecipeToIngredientCreateWithoutIngredientInput = {
  amount: Scalars['Float'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  recipe: RecipeCreateNestedOneWithoutIngredientsInput;
  unit?: InputMaybe<UnitCreateNestedOneWithoutRecipeToIngredientUnitInput>;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeToIngredientCreateWithoutRecipeInput = {
  amount: Scalars['Float'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ingredient: IngredientCreateNestedOneWithoutRecipesInput;
  unit?: InputMaybe<UnitCreateNestedOneWithoutRecipeToIngredientUnitInput>;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeToIngredientCreateWithoutUnitInput = {
  amount: Scalars['Float'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ingredient: IngredientCreateNestedOneWithoutRecipesInput;
  recipe: RecipeCreateNestedOneWithoutIngredientsInput;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeToIngredientEdge = {
  __typename?: 'RecipeToIngredientEdge';
  cursor: Scalars['ID'];
  node: RecipeToIngredient;
};

export type RecipeToIngredientInputObject = {
  amount: Scalars['Float'];
  id: Scalars['Int'];
  unitCost: Scalars['Float'];
  unitId: Scalars['Int'];
};

export type RecipeToIngredientListRelationFilter = {
  every?: InputMaybe<RecipeToIngredientWhereInput>;
  none?: InputMaybe<RecipeToIngredientWhereInput>;
  some?: InputMaybe<RecipeToIngredientWhereInput>;
};

export type RecipeToIngredientOrderByInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  ingredient?: InputMaybe<IngredientOrderByInput>;
  ingredientId?: InputMaybe<SortOrder>;
  recipe?: InputMaybe<RecipeOrderByInput>;
  recipeId?: InputMaybe<SortOrder>;
  unit?: InputMaybe<UnitOrderByInput>;
  unitCost?: InputMaybe<SortOrder>;
  unitId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RecipeToIngredientOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type RecipeToIngredientRecipeIdIngredientIdCompoundUniqueInput = {
  ingredientId: Scalars['Int'];
  recipeId: Scalars['Int'];
};

export type RecipeToIngredientScalarWhereInput = {
  AND?: InputMaybe<Array<RecipeToIngredientScalarWhereInput>>;
  NOT?: InputMaybe<Array<RecipeToIngredientScalarWhereInput>>;
  OR?: InputMaybe<Array<RecipeToIngredientScalarWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  ingredientId?: InputMaybe<IntFilter>;
  recipeId?: InputMaybe<IntFilter>;
  unitCost?: InputMaybe<FloatNullableFilter>;
  unitId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RecipeToIngredientUpdateManyMutationInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RecipeToIngredientUpdateManyWithWhereWithoutIngredientInput = {
  data: RecipeToIngredientUpdateManyMutationInput;
  where: RecipeToIngredientScalarWhereInput;
};

export type RecipeToIngredientUpdateManyWithWhereWithoutRecipeInput = {
  data: RecipeToIngredientUpdateManyMutationInput;
  where: RecipeToIngredientScalarWhereInput;
};

export type RecipeToIngredientUpdateManyWithWhereWithoutUnitInput = {
  data: RecipeToIngredientUpdateManyMutationInput;
  where: RecipeToIngredientScalarWhereInput;
};

export type RecipeToIngredientUpdateManyWithoutIngredientInput = {
  connect?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeToIngredientCreateOrConnectWithoutIngredientInput>>;
  create?: InputMaybe<Array<RecipeToIngredientCreateWithoutIngredientInput>>;
  createMany?: InputMaybe<RecipeToIngredientCreateManyIngredientInputEnvelope>;
  delete?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RecipeToIngredientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  set?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  update?: InputMaybe<Array<RecipeToIngredientUpdateWithWhereUniqueWithoutIngredientInput>>;
  updateMany?: InputMaybe<Array<RecipeToIngredientUpdateManyWithWhereWithoutIngredientInput>>;
  upsert?: InputMaybe<Array<RecipeToIngredientUpsertWithWhereUniqueWithoutIngredientInput>>;
};

export type RecipeToIngredientUpdateManyWithoutRecipeInput = {
  connect?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeToIngredientCreateOrConnectWithoutRecipeInput>>;
  create?: InputMaybe<Array<RecipeToIngredientCreateWithoutRecipeInput>>;
  createMany?: InputMaybe<RecipeToIngredientCreateManyRecipeInputEnvelope>;
  delete?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RecipeToIngredientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  set?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  update?: InputMaybe<Array<RecipeToIngredientUpdateWithWhereUniqueWithoutRecipeInput>>;
  updateMany?: InputMaybe<Array<RecipeToIngredientUpdateManyWithWhereWithoutRecipeInput>>;
  upsert?: InputMaybe<Array<RecipeToIngredientUpsertWithWhereUniqueWithoutRecipeInput>>;
};

export type RecipeToIngredientUpdateManyWithoutUnitInput = {
  connect?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeToIngredientCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<RecipeToIngredientCreateWithoutUnitInput>>;
  createMany?: InputMaybe<RecipeToIngredientCreateManyUnitInputEnvelope>;
  delete?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RecipeToIngredientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  set?: InputMaybe<Array<RecipeToIngredientWhereUniqueInput>>;
  update?: InputMaybe<Array<RecipeToIngredientUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: InputMaybe<Array<RecipeToIngredientUpdateManyWithWhereWithoutUnitInput>>;
  upsert?: InputMaybe<Array<RecipeToIngredientUpsertWithWhereUniqueWithoutUnitInput>>;
};

export type RecipeToIngredientUpdateWithWhereUniqueWithoutIngredientInput = {
  data: RecipeToIngredientUpdateWithoutIngredientInput;
  where: RecipeToIngredientWhereUniqueInput;
};

export type RecipeToIngredientUpdateWithWhereUniqueWithoutRecipeInput = {
  data: RecipeToIngredientUpdateWithoutRecipeInput;
  where: RecipeToIngredientWhereUniqueInput;
};

export type RecipeToIngredientUpdateWithWhereUniqueWithoutUnitInput = {
  data: RecipeToIngredientUpdateWithoutUnitInput;
  where: RecipeToIngredientWhereUniqueInput;
};

export type RecipeToIngredientUpdateWithoutIngredientInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  recipe?: InputMaybe<RecipeUpdateOneRequiredWithoutIngredientsInput>;
  unit?: InputMaybe<UnitUpdateOneWithoutRecipeToIngredientUnitInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RecipeToIngredientUpdateWithoutRecipeInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ingredient?: InputMaybe<IngredientUpdateOneRequiredWithoutRecipesInput>;
  unit?: InputMaybe<UnitUpdateOneWithoutRecipeToIngredientUnitInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RecipeToIngredientUpdateWithoutUnitInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ingredient?: InputMaybe<IngredientUpdateOneRequiredWithoutRecipesInput>;
  recipe?: InputMaybe<RecipeUpdateOneRequiredWithoutIngredientsInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RecipeToIngredientUpsertWithWhereUniqueWithoutIngredientInput = {
  create: RecipeToIngredientCreateWithoutIngredientInput;
  update: RecipeToIngredientUpdateWithoutIngredientInput;
  where: RecipeToIngredientWhereUniqueInput;
};

export type RecipeToIngredientUpsertWithWhereUniqueWithoutRecipeInput = {
  create: RecipeToIngredientCreateWithoutRecipeInput;
  update: RecipeToIngredientUpdateWithoutRecipeInput;
  where: RecipeToIngredientWhereUniqueInput;
};

export type RecipeToIngredientUpsertWithWhereUniqueWithoutUnitInput = {
  create: RecipeToIngredientCreateWithoutUnitInput;
  update: RecipeToIngredientUpdateWithoutUnitInput;
  where: RecipeToIngredientWhereUniqueInput;
};

export type RecipeToIngredientWhereInput = {
  AND?: InputMaybe<Array<RecipeToIngredientWhereInput>>;
  NOT?: InputMaybe<Array<RecipeToIngredientWhereInput>>;
  OR?: InputMaybe<Array<RecipeToIngredientWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  ingredient?: InputMaybe<IngredientWhereInput>;
  ingredientId?: InputMaybe<IntFilter>;
  recipe?: InputMaybe<RecipeWhereInput>;
  recipeId?: InputMaybe<IntFilter>;
  unit?: InputMaybe<UnitWhereInput>;
  unitCost?: InputMaybe<FloatNullableFilter>;
  unitId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RecipeToIngredientWhereUniqueInput = {
  recipeId_ingredientId?: InputMaybe<RecipeToIngredientRecipeIdIngredientIdCompoundUniqueInput>;
};

export type RecipeToRecipe = Node & {
  __typename?: 'RecipeToRecipe';
  _cursor: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
  childRecipe: Recipe;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  parentRecipe: Recipe;
  unit?: Maybe<Unit>;
  unitCost?: Maybe<Scalars['Float']>;
  unitId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type RecipeToRecipeChildRecipeIdParentRecipeIdCompoundUniqueInput = {
  childRecipeId: Scalars['Int'];
  parentRecipeId: Scalars['Int'];
};

export type RecipeToRecipeConnection = {
  __typename?: 'RecipeToRecipeConnection';
  edges: Array<RecipeToRecipeEdge>;
  nodes: Array<RecipeToRecipe>;
  pageInfo: PageInfo;
};

export type RecipeToRecipeCreateManyChildRecipeInput = {
  amount?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  parentRecipeId: Scalars['Int'];
  unitCost?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeToRecipeCreateManyChildRecipeInputEnvelope = {
  data?: InputMaybe<Array<RecipeToRecipeCreateManyChildRecipeInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RecipeToRecipeCreateManyParentRecipeInput = {
  amount?: InputMaybe<Scalars['Float']>;
  childRecipeId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  unitCost?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeToRecipeCreateManyParentRecipeInputEnvelope = {
  data?: InputMaybe<Array<RecipeToRecipeCreateManyParentRecipeInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RecipeToRecipeCreateManyUnitInput = {
  amount?: InputMaybe<Scalars['Float']>;
  childRecipeId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  parentRecipeId: Scalars['Int'];
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeToRecipeCreateManyUnitInputEnvelope = {
  data?: InputMaybe<Array<RecipeToRecipeCreateManyUnitInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RecipeToRecipeCreateNestedManyWithoutChildRecipeInput = {
  connect?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeToRecipeCreateOrConnectWithoutChildRecipeInput>>;
  create?: InputMaybe<Array<RecipeToRecipeCreateWithoutChildRecipeInput>>;
  createMany?: InputMaybe<RecipeToRecipeCreateManyChildRecipeInputEnvelope>;
};

export type RecipeToRecipeCreateNestedManyWithoutParentRecipeInput = {
  connect?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeToRecipeCreateOrConnectWithoutParentRecipeInput>>;
  create?: InputMaybe<Array<RecipeToRecipeCreateWithoutParentRecipeInput>>;
  createMany?: InputMaybe<RecipeToRecipeCreateManyParentRecipeInputEnvelope>;
};

export type RecipeToRecipeCreateNestedManyWithoutUnitInput = {
  connect?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeToRecipeCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<RecipeToRecipeCreateWithoutUnitInput>>;
  createMany?: InputMaybe<RecipeToRecipeCreateManyUnitInputEnvelope>;
};

export type RecipeToRecipeCreateOrConnectWithoutChildRecipeInput = {
  create: RecipeToRecipeCreateWithoutChildRecipeInput;
  where: RecipeToRecipeWhereUniqueInput;
};

export type RecipeToRecipeCreateOrConnectWithoutParentRecipeInput = {
  create: RecipeToRecipeCreateWithoutParentRecipeInput;
  where: RecipeToRecipeWhereUniqueInput;
};

export type RecipeToRecipeCreateOrConnectWithoutUnitInput = {
  create: RecipeToRecipeCreateWithoutUnitInput;
  where: RecipeToRecipeWhereUniqueInput;
};

export type RecipeToRecipeCreateWithoutChildRecipeInput = {
  amount?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  parentRecipe: RecipeCreateNestedOneWithoutChildRecipesInput;
  unit?: InputMaybe<UnitCreateNestedOneWithoutRecipeToRecipeUnitInput>;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeToRecipeCreateWithoutParentRecipeInput = {
  amount?: InputMaybe<Scalars['Float']>;
  childRecipe: RecipeCreateNestedOneWithoutParentRecipesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  unit?: InputMaybe<UnitCreateNestedOneWithoutRecipeToRecipeUnitInput>;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeToRecipeCreateWithoutUnitInput = {
  amount?: InputMaybe<Scalars['Float']>;
  childRecipe: RecipeCreateNestedOneWithoutParentRecipesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  parentRecipe: RecipeCreateNestedOneWithoutChildRecipesInput;
  unitCost?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RecipeToRecipeEdge = {
  __typename?: 'RecipeToRecipeEdge';
  cursor: Scalars['ID'];
  node: RecipeToRecipe;
};

export type RecipeToRecipeInputObject = {
  amount?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  unitCost?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Int']>;
};

export type RecipeToRecipeListRelationFilter = {
  every?: InputMaybe<RecipeToRecipeWhereInput>;
  none?: InputMaybe<RecipeToRecipeWhereInput>;
  some?: InputMaybe<RecipeToRecipeWhereInput>;
};

export type RecipeToRecipeOrderByInput = {
  amount?: InputMaybe<SortOrder>;
  childRecipe?: InputMaybe<RecipeOrderByInput>;
  childRecipeId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  parentRecipe?: InputMaybe<RecipeOrderByInput>;
  parentRecipeId?: InputMaybe<SortOrder>;
  unit?: InputMaybe<UnitOrderByInput>;
  unitCost?: InputMaybe<SortOrder>;
  unitId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RecipeToRecipeOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type RecipeToRecipeScalarWhereInput = {
  AND?: InputMaybe<Array<RecipeToRecipeScalarWhereInput>>;
  NOT?: InputMaybe<Array<RecipeToRecipeScalarWhereInput>>;
  OR?: InputMaybe<Array<RecipeToRecipeScalarWhereInput>>;
  amount?: InputMaybe<FloatNullableFilter>;
  childRecipeId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  parentRecipeId?: InputMaybe<IntFilter>;
  unitCost?: InputMaybe<FloatNullableFilter>;
  unitId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RecipeToRecipeUpdateManyMutationInput = {
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RecipeToRecipeUpdateManyWithWhereWithoutChildRecipeInput = {
  data: RecipeToRecipeUpdateManyMutationInput;
  where: RecipeToRecipeScalarWhereInput;
};

export type RecipeToRecipeUpdateManyWithWhereWithoutParentRecipeInput = {
  data: RecipeToRecipeUpdateManyMutationInput;
  where: RecipeToRecipeScalarWhereInput;
};

export type RecipeToRecipeUpdateManyWithWhereWithoutUnitInput = {
  data: RecipeToRecipeUpdateManyMutationInput;
  where: RecipeToRecipeScalarWhereInput;
};

export type RecipeToRecipeUpdateManyWithoutChildRecipeInput = {
  connect?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeToRecipeCreateOrConnectWithoutChildRecipeInput>>;
  create?: InputMaybe<Array<RecipeToRecipeCreateWithoutChildRecipeInput>>;
  createMany?: InputMaybe<RecipeToRecipeCreateManyChildRecipeInputEnvelope>;
  delete?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RecipeToRecipeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  set?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  update?: InputMaybe<Array<RecipeToRecipeUpdateWithWhereUniqueWithoutChildRecipeInput>>;
  updateMany?: InputMaybe<Array<RecipeToRecipeUpdateManyWithWhereWithoutChildRecipeInput>>;
  upsert?: InputMaybe<Array<RecipeToRecipeUpsertWithWhereUniqueWithoutChildRecipeInput>>;
};

export type RecipeToRecipeUpdateManyWithoutParentRecipeInput = {
  connect?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeToRecipeCreateOrConnectWithoutParentRecipeInput>>;
  create?: InputMaybe<Array<RecipeToRecipeCreateWithoutParentRecipeInput>>;
  createMany?: InputMaybe<RecipeToRecipeCreateManyParentRecipeInputEnvelope>;
  delete?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RecipeToRecipeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  set?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  update?: InputMaybe<Array<RecipeToRecipeUpdateWithWhereUniqueWithoutParentRecipeInput>>;
  updateMany?: InputMaybe<Array<RecipeToRecipeUpdateManyWithWhereWithoutParentRecipeInput>>;
  upsert?: InputMaybe<Array<RecipeToRecipeUpsertWithWhereUniqueWithoutParentRecipeInput>>;
};

export type RecipeToRecipeUpdateManyWithoutUnitInput = {
  connect?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeToRecipeCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<RecipeToRecipeCreateWithoutUnitInput>>;
  createMany?: InputMaybe<RecipeToRecipeCreateManyUnitInputEnvelope>;
  delete?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RecipeToRecipeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  set?: InputMaybe<Array<RecipeToRecipeWhereUniqueInput>>;
  update?: InputMaybe<Array<RecipeToRecipeUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: InputMaybe<Array<RecipeToRecipeUpdateManyWithWhereWithoutUnitInput>>;
  upsert?: InputMaybe<Array<RecipeToRecipeUpsertWithWhereUniqueWithoutUnitInput>>;
};

export type RecipeToRecipeUpdateWithWhereUniqueWithoutChildRecipeInput = {
  data: RecipeToRecipeUpdateWithoutChildRecipeInput;
  where: RecipeToRecipeWhereUniqueInput;
};

export type RecipeToRecipeUpdateWithWhereUniqueWithoutParentRecipeInput = {
  data: RecipeToRecipeUpdateWithoutParentRecipeInput;
  where: RecipeToRecipeWhereUniqueInput;
};

export type RecipeToRecipeUpdateWithWhereUniqueWithoutUnitInput = {
  data: RecipeToRecipeUpdateWithoutUnitInput;
  where: RecipeToRecipeWhereUniqueInput;
};

export type RecipeToRecipeUpdateWithoutChildRecipeInput = {
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  parentRecipe?: InputMaybe<RecipeUpdateOneRequiredWithoutChildRecipesInput>;
  unit?: InputMaybe<UnitUpdateOneWithoutRecipeToRecipeUnitInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RecipeToRecipeUpdateWithoutParentRecipeInput = {
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  childRecipe?: InputMaybe<RecipeUpdateOneRequiredWithoutParentRecipesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  unit?: InputMaybe<UnitUpdateOneWithoutRecipeToRecipeUnitInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RecipeToRecipeUpdateWithoutUnitInput = {
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  childRecipe?: InputMaybe<RecipeUpdateOneRequiredWithoutParentRecipesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  parentRecipe?: InputMaybe<RecipeUpdateOneRequiredWithoutChildRecipesInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RecipeToRecipeUpsertWithWhereUniqueWithoutChildRecipeInput = {
  create: RecipeToRecipeCreateWithoutChildRecipeInput;
  update: RecipeToRecipeUpdateWithoutChildRecipeInput;
  where: RecipeToRecipeWhereUniqueInput;
};

export type RecipeToRecipeUpsertWithWhereUniqueWithoutParentRecipeInput = {
  create: RecipeToRecipeCreateWithoutParentRecipeInput;
  update: RecipeToRecipeUpdateWithoutParentRecipeInput;
  where: RecipeToRecipeWhereUniqueInput;
};

export type RecipeToRecipeUpsertWithWhereUniqueWithoutUnitInput = {
  create: RecipeToRecipeCreateWithoutUnitInput;
  update: RecipeToRecipeUpdateWithoutUnitInput;
  where: RecipeToRecipeWhereUniqueInput;
};

export type RecipeToRecipeWhereInput = {
  AND?: InputMaybe<Array<RecipeToRecipeWhereInput>>;
  NOT?: InputMaybe<Array<RecipeToRecipeWhereInput>>;
  OR?: InputMaybe<Array<RecipeToRecipeWhereInput>>;
  amount?: InputMaybe<FloatNullableFilter>;
  childRecipe?: InputMaybe<RecipeWhereInput>;
  childRecipeId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  parentRecipe?: InputMaybe<RecipeWhereInput>;
  parentRecipeId?: InputMaybe<IntFilter>;
  unit?: InputMaybe<UnitWhereInput>;
  unitCost?: InputMaybe<FloatNullableFilter>;
  unitId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RecipeToRecipeWhereUniqueInput = {
  childRecipeId_parentRecipeId?: InputMaybe<RecipeToRecipeChildRecipeIdParentRecipeIdCompoundUniqueInput>;
};

export type RecipeUpdateManyMutationInput = {
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hasNoAllergens?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  section?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type RecipeUpdateManyWithWhereWithoutCreatedByInput = {
  data: RecipeUpdateManyMutationInput;
  where: RecipeScalarWhereInput;
};

export type RecipeUpdateManyWithWhereWithoutKitchenInput = {
  data: RecipeUpdateManyMutationInput;
  where: RecipeScalarWhereInput;
};

export type RecipeUpdateManyWithWhereWithoutUnitInput = {
  data: RecipeUpdateManyMutationInput;
  where: RecipeScalarWhereInput;
};

export type RecipeUpdateManyWithWhereWithoutUpdatedByInput = {
  data: RecipeUpdateManyMutationInput;
  where: RecipeScalarWhereInput;
};

export type RecipeUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<RecipeCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<RecipeCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RecipeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  set?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  update?: InputMaybe<Array<RecipeUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<RecipeUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<RecipeUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type RecipeUpdateManyWithoutKitchenInput = {
  connect?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeCreateOrConnectWithoutKitchenInput>>;
  create?: InputMaybe<Array<RecipeCreateWithoutKitchenInput>>;
  createMany?: InputMaybe<RecipeCreateManyKitchenInputEnvelope>;
  delete?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RecipeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  set?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  update?: InputMaybe<Array<RecipeUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: InputMaybe<Array<RecipeUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: InputMaybe<Array<RecipeUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type RecipeUpdateManyWithoutUnitInput = {
  connect?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<RecipeCreateWithoutUnitInput>>;
  createMany?: InputMaybe<RecipeCreateManyUnitInputEnvelope>;
  delete?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RecipeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  set?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  update?: InputMaybe<Array<RecipeUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: InputMaybe<Array<RecipeUpdateManyWithWhereWithoutUnitInput>>;
  upsert?: InputMaybe<Array<RecipeUpsertWithWhereUniqueWithoutUnitInput>>;
};

export type RecipeUpdateManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecipeCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<RecipeCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<RecipeCreateManyUpdatedByInputEnvelope>;
  delete?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RecipeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  set?: InputMaybe<Array<RecipeWhereUniqueInput>>;
  update?: InputMaybe<Array<RecipeUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: InputMaybe<Array<RecipeUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: InputMaybe<Array<RecipeUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type RecipeUpdateOneRequiredWithoutChildRecipesInput = {
  connect?: InputMaybe<RecipeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RecipeCreateOrConnectWithoutChildRecipesInput>;
  create?: InputMaybe<RecipeCreateWithoutChildRecipesInput>;
  update?: InputMaybe<RecipeUpdateWithoutChildRecipesInput>;
  upsert?: InputMaybe<RecipeUpsertWithoutChildRecipesInput>;
};

export type RecipeUpdateOneRequiredWithoutDishesInput = {
  connect?: InputMaybe<RecipeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RecipeCreateOrConnectWithoutDishesInput>;
  create?: InputMaybe<RecipeCreateWithoutDishesInput>;
  update?: InputMaybe<RecipeUpdateWithoutDishesInput>;
  upsert?: InputMaybe<RecipeUpsertWithoutDishesInput>;
};

export type RecipeUpdateOneRequiredWithoutIngredientsInput = {
  connect?: InputMaybe<RecipeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RecipeCreateOrConnectWithoutIngredientsInput>;
  create?: InputMaybe<RecipeCreateWithoutIngredientsInput>;
  update?: InputMaybe<RecipeUpdateWithoutIngredientsInput>;
  upsert?: InputMaybe<RecipeUpsertWithoutIngredientsInput>;
};

export type RecipeUpdateOneRequiredWithoutParentRecipesInput = {
  connect?: InputMaybe<RecipeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RecipeCreateOrConnectWithoutParentRecipesInput>;
  create?: InputMaybe<RecipeCreateWithoutParentRecipesInput>;
  update?: InputMaybe<RecipeUpdateWithoutParentRecipesInput>;
  upsert?: InputMaybe<RecipeUpsertWithoutParentRecipesInput>;
};

export type RecipeUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: RecipeUpdateWithoutCreatedByInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeUpdateWithWhereUniqueWithoutKitchenInput = {
  data: RecipeUpdateWithoutKitchenInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeUpdateWithWhereUniqueWithoutUnitInput = {
  data: RecipeUpdateWithoutUnitInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: RecipeUpdateWithoutUpdatedByInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeUpdateWithoutChildRecipesInput = {
  allergens?: InputMaybe<RecipeToAllergenUpdateManyWithoutRecipeInput>;
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutRecipeCreatedInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutRecipeInput>;
  hasNoAllergens?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredients?: InputMaybe<RecipeToIngredientUpdateManyWithoutRecipeInput>;
  instructions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutRecipesInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentRecipes?: InputMaybe<RecipeToRecipeUpdateManyWithoutChildRecipeInput>;
  section?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unit?: InputMaybe<UnitUpdateOneWithoutRecipeUnitInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutRecipeUpdatedInput>;
};

export type RecipeUpdateWithoutCreatedByInput = {
  allergens?: InputMaybe<RecipeToAllergenUpdateManyWithoutRecipeInput>;
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  childRecipes?: InputMaybe<RecipeToRecipeUpdateManyWithoutParentRecipeInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutRecipeInput>;
  hasNoAllergens?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredients?: InputMaybe<RecipeToIngredientUpdateManyWithoutRecipeInput>;
  instructions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutRecipesInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentRecipes?: InputMaybe<RecipeToRecipeUpdateManyWithoutChildRecipeInput>;
  section?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unit?: InputMaybe<UnitUpdateOneWithoutRecipeUnitInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutRecipeUpdatedInput>;
};

export type RecipeUpdateWithoutDishesInput = {
  allergens?: InputMaybe<RecipeToAllergenUpdateManyWithoutRecipeInput>;
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  childRecipes?: InputMaybe<RecipeToRecipeUpdateManyWithoutParentRecipeInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutRecipeCreatedInput>;
  hasNoAllergens?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredients?: InputMaybe<RecipeToIngredientUpdateManyWithoutRecipeInput>;
  instructions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutRecipesInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentRecipes?: InputMaybe<RecipeToRecipeUpdateManyWithoutChildRecipeInput>;
  section?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unit?: InputMaybe<UnitUpdateOneWithoutRecipeUnitInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutRecipeUpdatedInput>;
};

export type RecipeUpdateWithoutIngredientsInput = {
  allergens?: InputMaybe<RecipeToAllergenUpdateManyWithoutRecipeInput>;
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  childRecipes?: InputMaybe<RecipeToRecipeUpdateManyWithoutParentRecipeInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutRecipeCreatedInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutRecipeInput>;
  hasNoAllergens?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutRecipesInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentRecipes?: InputMaybe<RecipeToRecipeUpdateManyWithoutChildRecipeInput>;
  section?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unit?: InputMaybe<UnitUpdateOneWithoutRecipeUnitInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutRecipeUpdatedInput>;
};

export type RecipeUpdateWithoutKitchenInput = {
  allergens?: InputMaybe<RecipeToAllergenUpdateManyWithoutRecipeInput>;
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  childRecipes?: InputMaybe<RecipeToRecipeUpdateManyWithoutParentRecipeInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutRecipeCreatedInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutRecipeInput>;
  hasNoAllergens?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredients?: InputMaybe<RecipeToIngredientUpdateManyWithoutRecipeInput>;
  instructions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentRecipes?: InputMaybe<RecipeToRecipeUpdateManyWithoutChildRecipeInput>;
  section?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unit?: InputMaybe<UnitUpdateOneWithoutRecipeUnitInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutRecipeUpdatedInput>;
};

export type RecipeUpdateWithoutParentRecipesInput = {
  allergens?: InputMaybe<RecipeToAllergenUpdateManyWithoutRecipeInput>;
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  childRecipes?: InputMaybe<RecipeToRecipeUpdateManyWithoutParentRecipeInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutRecipeCreatedInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutRecipeInput>;
  hasNoAllergens?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredients?: InputMaybe<RecipeToIngredientUpdateManyWithoutRecipeInput>;
  instructions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutRecipesInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  section?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unit?: InputMaybe<UnitUpdateOneWithoutRecipeUnitInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutRecipeUpdatedInput>;
};

export type RecipeUpdateWithoutUnitInput = {
  allergens?: InputMaybe<RecipeToAllergenUpdateManyWithoutRecipeInput>;
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  childRecipes?: InputMaybe<RecipeToRecipeUpdateManyWithoutParentRecipeInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutRecipeCreatedInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutRecipeInput>;
  hasNoAllergens?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredients?: InputMaybe<RecipeToIngredientUpdateManyWithoutRecipeInput>;
  instructions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutRecipesInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentRecipes?: InputMaybe<RecipeToRecipeUpdateManyWithoutChildRecipeInput>;
  section?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutRecipeUpdatedInput>;
};

export type RecipeUpdateWithoutUpdatedByInput = {
  allergens?: InputMaybe<RecipeToAllergenUpdateManyWithoutRecipeInput>;
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  childRecipes?: InputMaybe<RecipeToRecipeUpdateManyWithoutParentRecipeInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutRecipeCreatedInput>;
  dishes?: InputMaybe<DishUpdateManyWithoutRecipeInput>;
  hasNoAllergens?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredients?: InputMaybe<RecipeToIngredientUpdateManyWithoutRecipeInput>;
  instructions?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDraft?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  kitchen?: InputMaybe<KitchenUpdateOneRequiredWithoutRecipesInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentRecipes?: InputMaybe<RecipeToRecipeUpdateManyWithoutChildRecipeInput>;
  section?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  stockData?: InputMaybe<Scalars['Json']>;
  unit?: InputMaybe<UnitUpdateOneWithoutRecipeUnitInput>;
  unitCost?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type RecipeUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: RecipeCreateWithoutCreatedByInput;
  update: RecipeUpdateWithoutCreatedByInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeUpsertWithWhereUniqueWithoutKitchenInput = {
  create: RecipeCreateWithoutKitchenInput;
  update: RecipeUpdateWithoutKitchenInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeUpsertWithWhereUniqueWithoutUnitInput = {
  create: RecipeCreateWithoutUnitInput;
  update: RecipeUpdateWithoutUnitInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: RecipeCreateWithoutUpdatedByInput;
  update: RecipeUpdateWithoutUpdatedByInput;
  where: RecipeWhereUniqueInput;
};

export type RecipeUpsertWithoutChildRecipesInput = {
  create: RecipeCreateWithoutChildRecipesInput;
  update: RecipeUpdateWithoutChildRecipesInput;
};

export type RecipeUpsertWithoutDishesInput = {
  create: RecipeCreateWithoutDishesInput;
  update: RecipeUpdateWithoutDishesInput;
};

export type RecipeUpsertWithoutIngredientsInput = {
  create: RecipeCreateWithoutIngredientsInput;
  update: RecipeUpdateWithoutIngredientsInput;
};

export type RecipeUpsertWithoutParentRecipesInput = {
  create: RecipeCreateWithoutParentRecipesInput;
  update: RecipeUpdateWithoutParentRecipesInput;
};

export type RecipeWhereInput = {
  AND?: InputMaybe<Array<RecipeWhereInput>>;
  NOT?: InputMaybe<Array<RecipeWhereInput>>;
  OR?: InputMaybe<Array<RecipeWhereInput>>;
  allergens?: InputMaybe<RecipeToAllergenListRelationFilter>;
  amount?: InputMaybe<FloatNullableFilter>;
  childRecipes?: InputMaybe<RecipeToRecipeListRelationFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  dishes?: InputMaybe<DishListRelationFilter>;
  hasNoAllergens?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<IntFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  ingredients?: InputMaybe<RecipeToIngredientListRelationFilter>;
  instructions?: InputMaybe<StringNullableFilter>;
  isDraft?: InputMaybe<BoolNullableFilter>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  parentRecipes?: InputMaybe<RecipeToRecipeListRelationFilter>;
  section?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  stockData?: InputMaybe<JsonNullableFilter>;
  unit?: InputMaybe<UnitWhereInput>;
  unitCost?: InputMaybe<FloatNullableFilter>;
  unitId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type RecipeWhereUniqueInput = {
  id: Scalars['Int'];
};

export type Role = {
  __typename?: 'Role';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type S3Params = {
  ContentDisposition?: InputMaybe<Scalars['String']>;
  ContentType?: InputMaybe<Scalars['String']>;
  Expires?: InputMaybe<Scalars['Int']>;
  ResponseContentDisposition?: InputMaybe<Scalars['String']>;
  ResponseContentType?: InputMaybe<Scalars['String']>;
};

export type Sales = Node & {
  __typename?: 'Sales';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  id: Scalars['Int'];
  kitchen: Kitchen;
  sales?: Maybe<Scalars['Float']>;
  salesDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type SalesConnection = {
  __typename?: 'SalesConnection';
  edges: Array<SalesEdge>;
  nodes: Array<Sales>;
  pageInfo: PageInfo;
};

export type SalesEdge = {
  __typename?: 'SalesEdge';
  cursor: Scalars['ID'];
  node: Sales;
};

export type SalesListRelationFilter = {
  every?: InputMaybe<SalesWhereInput>;
  none?: InputMaybe<SalesWhereInput>;
  some?: InputMaybe<SalesWhereInput>;
};

export type SalesMixData = {
  __typename?: 'SalesMixData';
  automated?: Maybe<SalesMixDataObject>;
  dishId: Scalars['Int'];
  dishName: Scalars['String'];
  isDog: Scalars['Boolean'];
  isStar: Scalars['Boolean'];
  manual?: Maybe<SalesMixDataObject>;
};

export type SalesMixDataObject = {
  __typename?: 'SalesMixDataObject';
  gp: Scalars['Float'];
  profit: Scalars['BigInt'];
  qtySold: Scalars['Int'];
  revenue: Scalars['BigInt'];
};

export type SalesMixInsights = {
  __typename?: 'SalesMixInsights';
  salesMixData: Array<SalesMixData>;
  totalGp?: Maybe<Scalars['Float']>;
  totalProfit: Scalars['BigInt'];
  totalRevenue: Scalars['BigInt'];
  unlinkedDishes: Scalars['Int'];
};

export type SalesOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  kitchen?: InputMaybe<KitchenOrderByInput>;
  kitchenId?: InputMaybe<SortOrder>;
  sales?: InputMaybe<SortOrder>;
  salesDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
};

export type SalesWhereInput = {
  AND?: InputMaybe<Array<SalesWhereInput>>;
  NOT?: InputMaybe<Array<SalesWhereInput>>;
  OR?: InputMaybe<Array<SalesWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  sales?: InputMaybe<IntFilter>;
  salesDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type SalesWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Section = {
  __typename?: 'Section';
  name: Scalars['String'];
};

export type SignUpPhoneResult = {
  __typename?: 'SignUpPhoneResult';
  expiresAt: Scalars['String'];
};

export type SignedS3Input = {
  extension?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  invoiceId?: InputMaybe<Scalars['Int']>;
  kitchenId: Scalars['Int'];
  params?: InputMaybe<S3Params>;
  secure?: InputMaybe<Scalars['Boolean']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type SignedS3Url = {
  __typename?: 'SignedS3Url';
  signedURL: Scalars['String'];
  unsignedURL: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SpendData = {
  __typename?: 'SpendData';
  date: Scalars['Date'];
  spend: Scalars['Float'];
};

export type SpendInsights = {
  __typename?: 'SpendInsights';
  previousSpend: Scalars['Float'];
  spendData: Array<SpendData>;
  spendTotal: Array<SpendTotal>;
  totalInvoicesNeedAttention: Scalars['Int'];
  totalSpend: Scalars['Float'];
};

export type SpendTotal = {
  __typename?: 'SpendTotal';
  invoiceCount: Scalars['Int'];
  supplierId: Scalars['Int'];
  supplierName: Scalars['String'];
  total: Scalars['Float'];
};

export type SquareAuthoriseUrl = {
  __typename?: 'SquareAuthoriseUrl';
  url: Scalars['String'];
};

export enum Status {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending'
}

export type Stock = Node & {
  __typename?: 'Stock';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  date: Scalars['DateTime'];
  entryList: StockEntryConnection;
  history: Array<ChangeHistory>;
  id: Scalars['Int'];
  kitchen: Kitchen;
  name?: Maybe<Scalars['String']>;
  stockEntries: Array<StockEntry>;
  total: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};


export type StockEntryListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type StockConnection = {
  __typename?: 'StockConnection';
  edges: Array<StockEdge>;
  nodes: Array<Stock>;
  pageInfo: PageInfo;
};

export type StockEdge = {
  __typename?: 'StockEdge';
  cursor: Scalars['ID'];
  node: Stock;
};

export type StockEntry = Node & {
  __typename?: 'StockEntry';
  _cursor: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  id: Scalars['Int'];
  ingredient?: Maybe<Ingredient>;
  ingredientId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  recipe?: Maybe<Recipe>;
  recipeId?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Float']>;
  unit: Unit;
  unitId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type StockEntryConnection = {
  __typename?: 'StockEntryConnection';
  edges: Array<StockEntryEdge>;
  nodes: Array<StockEntry>;
  pageInfo: PageInfo;
};

export type StockEntryEdge = {
  __typename?: 'StockEntryEdge';
  cursor: Scalars['ID'];
  node: StockEntry;
};

export type StockListRelationFilter = {
  every?: InputMaybe<StockWhereInput>;
  none?: InputMaybe<StockWhereInput>;
  some?: InputMaybe<StockWhereInput>;
};

export type StockOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  kitchen?: InputMaybe<KitchenOrderByInput>;
  kitchenId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
};

export type StockOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type StockWhereInput = {
  AND?: InputMaybe<Array<StockWhereInput>>;
  NOT?: InputMaybe<Array<StockWhereInput>>;
  OR?: InputMaybe<Array<StockWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  date?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  kitchen?: InputMaybe<KitchenWhereInput>;
  kitchenId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type StockWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Stream = {
  __typename?: 'Stream';
  channelId: Scalars['Int'];
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  template: Scalars['String'];
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type Supplier = Node & {
  __typename?: 'Supplier';
  _cursor: Scalars['ID'];
  addressList: SupplierToAddressConnection;
  automated: Scalars['Boolean'];
  catalogue?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  deliveryDays?: Maybe<Scalars['Json']>;
  /** @deprecated Use emails field */
  email?: Maybe<Scalars['String']>;
  emails: Array<Scalars['String']>;
  id: Scalars['Int'];
  kitchenDetailList: KitchenSupplierDetailConnection;
  /** @deprecated Use and extend kitchenDetailList field */
  kitchenDetails: Array<KitchenSupplierDetail>;
  kitchenList: KitchenToSupplierConnection;
  /** @deprecated Use and extend kitchenList field */
  kitchens: Array<KitchenToSupplier>;
  logoUrl?: Maybe<Scalars['String']>;
  mergeEventList: EventLogConnection;
  /** @deprecated Use and extend mergeEventList field */
  mergeEvents?: Maybe<Array<Maybe<EventLog>>>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** @deprecated Use pageInfo on list method */
  productCount: Scalars['Int'];
  productList: ProductConnection;
  /** @deprecated Use and extend productList field */
  products: Array<Product>;
  /** @deprecated Use pageInfo on list method */
  purchaseOrderCount: Scalars['Int'];
  purchaseOrderList: PurchaseOrderConnection;
  /** @deprecated Use and extend purchaseOrderList field */
  purchaseOrders: Array<PurchaseOrder>;
  similarSupplierList: SupplierConnection;
  /** @deprecated Use and extend similarSupplierList field */
  similarSuppliers?: Maybe<Array<Maybe<Supplier>>>;
  status: Status;
  tradingName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
  website?: Maybe<Scalars['String']>;
};


export type SupplierAddressListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type SupplierAutomatedArgs = {
  kitchenId: Scalars['Int'];
};


export type SupplierKitchenDetailListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type SupplierKitchenDetailsArgs = {
  cursor?: InputMaybe<KitchenSupplierDetailWhereUniqueInput>;
  orderBy?: InputMaybe<Array<KitchenSupplierDetailOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KitchenSupplierDetailWhereInput>;
};


export type SupplierKitchenListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  kitchenCursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type SupplierKitchensArgs = {
  cursor?: InputMaybe<KitchenToSupplierWhereUniqueInput>;
  orderBy?: InputMaybe<Array<KitchenToSupplierOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KitchenToSupplierWhereInput>;
};


export type SupplierMergeEventListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type SupplierProductCountArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type SupplierProductListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type SupplierProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type SupplierPurchaseOrderCountArgs = {
  where?: InputMaybe<PurchaseOrderWhereInput>;
};


export type SupplierPurchaseOrderListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type SupplierPurchaseOrdersArgs = {
  cursor?: InputMaybe<PurchaseOrderWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PurchaseOrderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PurchaseOrderWhereInput>;
};


export type SupplierSimilarSupplierListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SupplierWhereInput>;
};


export type SupplierSimilarSuppliersArgs = {
  cursor?: InputMaybe<SupplierWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SupplierOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SupplierWhereInput>;
};

export type SupplierConnection = {
  __typename?: 'SupplierConnection';
  edges: Array<SupplierEdge>;
  nodes: Array<Supplier>;
  pageInfo: PageInfo;
};

export type SupplierCreateManyCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deliveryDays?: InputMaybe<Scalars['Json']>;
  email?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['Int']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  tradingName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
  website?: InputMaybe<Scalars['String']>;
};

export type SupplierCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<SupplierCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SupplierCreateManyUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  deliveryDays?: InputMaybe<Scalars['Json']>;
  email?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['Int']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  tradingName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  website?: InputMaybe<Scalars['String']>;
};

export type SupplierCreateManyUpdatedByInputEnvelope = {
  data?: InputMaybe<Array<SupplierCreateManyUpdatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SupplierCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<SupplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SupplierCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<SupplierCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<SupplierCreateManyCreatedByInputEnvelope>;
};

export type SupplierCreateNestedManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<SupplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SupplierCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<SupplierCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<SupplierCreateManyUpdatedByInputEnvelope>;
};

export type SupplierCreateNestedOneWithoutKitchenDetailsInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutKitchenDetailsInput>;
  create?: InputMaybe<SupplierCreateWithoutKitchenDetailsInput>;
};

export type SupplierCreateNestedOneWithoutKitchensInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutKitchensInput>;
  create?: InputMaybe<SupplierCreateWithoutKitchensInput>;
};

export type SupplierCreateNestedOneWithoutProductDuplicatesInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutProductDuplicatesInput>;
  create?: InputMaybe<SupplierCreateWithoutProductDuplicatesInput>;
};

export type SupplierCreateNestedOneWithoutProductMergesInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutProductMergesInput>;
  create?: InputMaybe<SupplierCreateWithoutProductMergesInput>;
};

export type SupplierCreateNestedOneWithoutProductsInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<SupplierCreateWithoutProductsInput>;
};

export type SupplierCreateNestedOneWithoutPurchaseOrdersInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutPurchaseOrdersInput>;
  create?: InputMaybe<SupplierCreateWithoutPurchaseOrdersInput>;
};

export type SupplierCreateOrConnectWithoutCreatedByInput = {
  create: SupplierCreateWithoutCreatedByInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierCreateOrConnectWithoutKitchenDetailsInput = {
  create: SupplierCreateWithoutKitchenDetailsInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierCreateOrConnectWithoutKitchensInput = {
  create: SupplierCreateWithoutKitchensInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierCreateOrConnectWithoutProductDuplicatesInput = {
  create: SupplierCreateWithoutProductDuplicatesInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierCreateOrConnectWithoutProductMergesInput = {
  create: SupplierCreateWithoutProductMergesInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierCreateOrConnectWithoutProductsInput = {
  create: SupplierCreateWithoutProductsInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierCreateOrConnectWithoutPurchaseOrdersInput = {
  create: SupplierCreateWithoutPurchaseOrdersInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierCreateOrConnectWithoutUpdatedByInput = {
  create: SupplierCreateWithoutUpdatedByInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierCreateWithoutCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutSupplierInput>;
  kitchens?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutSupplierInput>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  productDuplicates?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutSupplierInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutSupplierInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutSupplierInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderCreateNestedManyWithoutSupplierInput>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutSupplierUpdatedInput;
};

export type SupplierCreateWithoutKitchenDetailsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutSupplierCreatedInput;
  email?: InputMaybe<Scalars['String']>;
  kitchens?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutSupplierInput>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  productDuplicates?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutSupplierInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutSupplierInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutSupplierInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderCreateNestedManyWithoutSupplierInput>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutSupplierUpdatedInput;
};

export type SupplierCreateWithoutKitchensInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutSupplierCreatedInput;
  email?: InputMaybe<Scalars['String']>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutSupplierInput>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  productDuplicates?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutSupplierInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutSupplierInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutSupplierInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderCreateNestedManyWithoutSupplierInput>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutSupplierUpdatedInput;
};

export type SupplierCreateWithoutProductDuplicatesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutSupplierCreatedInput;
  email?: InputMaybe<Scalars['String']>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutSupplierInput>;
  kitchens?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutSupplierInput>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutSupplierInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutSupplierInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderCreateNestedManyWithoutSupplierInput>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutSupplierUpdatedInput;
};

export type SupplierCreateWithoutProductMergesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutSupplierCreatedInput;
  email?: InputMaybe<Scalars['String']>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutSupplierInput>;
  kitchens?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutSupplierInput>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  productDuplicates?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutSupplierInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutSupplierInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderCreateNestedManyWithoutSupplierInput>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutSupplierUpdatedInput;
};

export type SupplierCreateWithoutProductsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutSupplierCreatedInput;
  email?: InputMaybe<Scalars['String']>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutSupplierInput>;
  kitchens?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutSupplierInput>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  productDuplicates?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutSupplierInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutSupplierInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderCreateNestedManyWithoutSupplierInput>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutSupplierUpdatedInput;
};

export type SupplierCreateWithoutPurchaseOrdersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutSupplierCreatedInput;
  email?: InputMaybe<Scalars['String']>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutSupplierInput>;
  kitchens?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutSupplierInput>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  productDuplicates?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutSupplierInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutSupplierInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutSupplierInput>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutSupplierUpdatedInput;
};

export type SupplierCreateWithoutUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutSupplierCreatedInput;
  email?: InputMaybe<Scalars['String']>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailCreateNestedManyWithoutSupplierInput>;
  kitchens?: InputMaybe<KitchenToSupplierCreateNestedManyWithoutSupplierInput>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  productDuplicates?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutSupplierInput>;
  productMerges?: InputMaybe<ProductMergeCreateNestedManyWithoutSupplierInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutSupplierInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderCreateNestedManyWithoutSupplierInput>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SupplierDetailInputObject = {
  id?: InputMaybe<Scalars['Int']>;
  type: KitchenSupplierDataType;
  value: Scalars['String'];
};

export type SupplierEdge = {
  __typename?: 'SupplierEdge';
  cursor: Scalars['ID'];
  node: Supplier;
};

export type SupplierInformationInputObject = {
  email?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type SupplierListRelationFilter = {
  every?: InputMaybe<SupplierWhereInput>;
  none?: InputMaybe<SupplierWhereInput>;
  some?: InputMaybe<SupplierWhereInput>;
};

export type SupplierOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailOrderByRelationAggregateInput>;
  kitchens?: InputMaybe<KitchenToSupplierOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  notes?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  productDuplicates?: InputMaybe<ProductDuplicatesOrderByRelationAggregateInput>;
  productMerges?: InputMaybe<ProductMergeOrderByRelationAggregateInput>;
  products?: InputMaybe<ProductOrderByRelationAggregateInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
};

export type SupplierOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type SupplierScalarWhereInput = {
  AND?: InputMaybe<Array<SupplierScalarWhereInput>>;
  NOT?: InputMaybe<Array<SupplierScalarWhereInput>>;
  OR?: InputMaybe<Array<SupplierScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdById?: InputMaybe<IntFilter>;
  email?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedById?: InputMaybe<IntFilter>;
};

export type SupplierToAddress = Node & {
  __typename?: 'SupplierToAddress';
  _cursor: Scalars['ID'];
  address: Address;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  id: Scalars['Int'];
  supplier: Supplier;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type SupplierToAddressConnection = {
  __typename?: 'SupplierToAddressConnection';
  edges: Array<SupplierToAddressEdge>;
  nodes: Array<SupplierToAddress>;
  pageInfo: PageInfo;
};

export type SupplierToAddressEdge = {
  __typename?: 'SupplierToAddressEdge';
  cursor: Scalars['ID'];
  node: SupplierToAddress;
};

export type SupplierUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type SupplierUpdateManyWithWhereWithoutCreatedByInput = {
  data: SupplierUpdateManyMutationInput;
  where: SupplierScalarWhereInput;
};

export type SupplierUpdateManyWithWhereWithoutUpdatedByInput = {
  data: SupplierUpdateManyMutationInput;
  where: SupplierScalarWhereInput;
};

export type SupplierUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<SupplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SupplierCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<SupplierCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<SupplierCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<SupplierWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SupplierScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SupplierWhereUniqueInput>>;
  set?: InputMaybe<Array<SupplierWhereUniqueInput>>;
  update?: InputMaybe<Array<SupplierUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<SupplierUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<SupplierUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type SupplierUpdateManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<SupplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SupplierCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<SupplierCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<SupplierCreateManyUpdatedByInputEnvelope>;
  delete?: InputMaybe<Array<SupplierWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SupplierScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SupplierWhereUniqueInput>>;
  set?: InputMaybe<Array<SupplierWhereUniqueInput>>;
  update?: InputMaybe<Array<SupplierUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: InputMaybe<Array<SupplierUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: InputMaybe<Array<SupplierUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type SupplierUpdateOneRequiredWithoutKitchenDetailsInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutKitchenDetailsInput>;
  create?: InputMaybe<SupplierCreateWithoutKitchenDetailsInput>;
  update?: InputMaybe<SupplierUpdateWithoutKitchenDetailsInput>;
  upsert?: InputMaybe<SupplierUpsertWithoutKitchenDetailsInput>;
};

export type SupplierUpdateOneRequiredWithoutKitchensInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutKitchensInput>;
  create?: InputMaybe<SupplierCreateWithoutKitchensInput>;
  update?: InputMaybe<SupplierUpdateWithoutKitchensInput>;
  upsert?: InputMaybe<SupplierUpsertWithoutKitchensInput>;
};

export type SupplierUpdateOneRequiredWithoutProductDuplicatesInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutProductDuplicatesInput>;
  create?: InputMaybe<SupplierCreateWithoutProductDuplicatesInput>;
  update?: InputMaybe<SupplierUpdateWithoutProductDuplicatesInput>;
  upsert?: InputMaybe<SupplierUpsertWithoutProductDuplicatesInput>;
};

export type SupplierUpdateOneRequiredWithoutProductMergesInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutProductMergesInput>;
  create?: InputMaybe<SupplierCreateWithoutProductMergesInput>;
  update?: InputMaybe<SupplierUpdateWithoutProductMergesInput>;
  upsert?: InputMaybe<SupplierUpsertWithoutProductMergesInput>;
};

export type SupplierUpdateOneRequiredWithoutProductsInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<SupplierCreateWithoutProductsInput>;
  update?: InputMaybe<SupplierUpdateWithoutProductsInput>;
  upsert?: InputMaybe<SupplierUpsertWithoutProductsInput>;
};

export type SupplierUpdateOneRequiredWithoutPurchaseOrdersInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutPurchaseOrdersInput>;
  create?: InputMaybe<SupplierCreateWithoutPurchaseOrdersInput>;
  update?: InputMaybe<SupplierUpdateWithoutPurchaseOrdersInput>;
  upsert?: InputMaybe<SupplierUpsertWithoutPurchaseOrdersInput>;
};

export type SupplierUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: SupplierUpdateWithoutCreatedByInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: SupplierUpdateWithoutUpdatedByInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierUpdateWithoutCreatedByInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutSupplierInput>;
  kitchens?: InputMaybe<KitchenToSupplierUpdateManyWithoutSupplierInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productDuplicates?: InputMaybe<ProductDuplicatesUpdateManyWithoutSupplierInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutSupplierInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSupplierInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderUpdateManyWithoutSupplierInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutSupplierUpdatedInput>;
};

export type SupplierUpdateWithoutKitchenDetailsInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutSupplierCreatedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: InputMaybe<KitchenToSupplierUpdateManyWithoutSupplierInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productDuplicates?: InputMaybe<ProductDuplicatesUpdateManyWithoutSupplierInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutSupplierInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSupplierInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderUpdateManyWithoutSupplierInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutSupplierUpdatedInput>;
};

export type SupplierUpdateWithoutKitchensInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutSupplierCreatedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutSupplierInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productDuplicates?: InputMaybe<ProductDuplicatesUpdateManyWithoutSupplierInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutSupplierInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSupplierInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderUpdateManyWithoutSupplierInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutSupplierUpdatedInput>;
};

export type SupplierUpdateWithoutProductDuplicatesInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutSupplierCreatedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutSupplierInput>;
  kitchens?: InputMaybe<KitchenToSupplierUpdateManyWithoutSupplierInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutSupplierInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSupplierInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderUpdateManyWithoutSupplierInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutSupplierUpdatedInput>;
};

export type SupplierUpdateWithoutProductMergesInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutSupplierCreatedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutSupplierInput>;
  kitchens?: InputMaybe<KitchenToSupplierUpdateManyWithoutSupplierInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productDuplicates?: InputMaybe<ProductDuplicatesUpdateManyWithoutSupplierInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSupplierInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderUpdateManyWithoutSupplierInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutSupplierUpdatedInput>;
};

export type SupplierUpdateWithoutProductsInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutSupplierCreatedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutSupplierInput>;
  kitchens?: InputMaybe<KitchenToSupplierUpdateManyWithoutSupplierInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productDuplicates?: InputMaybe<ProductDuplicatesUpdateManyWithoutSupplierInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutSupplierInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderUpdateManyWithoutSupplierInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutSupplierUpdatedInput>;
};

export type SupplierUpdateWithoutPurchaseOrdersInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutSupplierCreatedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutSupplierInput>;
  kitchens?: InputMaybe<KitchenToSupplierUpdateManyWithoutSupplierInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productDuplicates?: InputMaybe<ProductDuplicatesUpdateManyWithoutSupplierInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutSupplierInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSupplierInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutSupplierUpdatedInput>;
};

export type SupplierUpdateWithoutUpdatedByInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutSupplierCreatedInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailUpdateManyWithoutSupplierInput>;
  kitchens?: InputMaybe<KitchenToSupplierUpdateManyWithoutSupplierInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productDuplicates?: InputMaybe<ProductDuplicatesUpdateManyWithoutSupplierInput>;
  productMerges?: InputMaybe<ProductMergeUpdateManyWithoutSupplierInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSupplierInput>;
  purchaseOrders?: InputMaybe<PurchaseOrderUpdateManyWithoutSupplierInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type SupplierUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: SupplierCreateWithoutCreatedByInput;
  update: SupplierUpdateWithoutCreatedByInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: SupplierCreateWithoutUpdatedByInput;
  update: SupplierUpdateWithoutUpdatedByInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierUpsertWithoutKitchenDetailsInput = {
  create: SupplierCreateWithoutKitchenDetailsInput;
  update: SupplierUpdateWithoutKitchenDetailsInput;
};

export type SupplierUpsertWithoutKitchensInput = {
  create: SupplierCreateWithoutKitchensInput;
  update: SupplierUpdateWithoutKitchensInput;
};

export type SupplierUpsertWithoutProductDuplicatesInput = {
  create: SupplierCreateWithoutProductDuplicatesInput;
  update: SupplierUpdateWithoutProductDuplicatesInput;
};

export type SupplierUpsertWithoutProductMergesInput = {
  create: SupplierCreateWithoutProductMergesInput;
  update: SupplierUpdateWithoutProductMergesInput;
};

export type SupplierUpsertWithoutProductsInput = {
  create: SupplierCreateWithoutProductsInput;
  update: SupplierUpdateWithoutProductsInput;
};

export type SupplierUpsertWithoutPurchaseOrdersInput = {
  create: SupplierCreateWithoutPurchaseOrdersInput;
  update: SupplierUpdateWithoutPurchaseOrdersInput;
};

export type SupplierWhereInput = {
  AND?: InputMaybe<Array<SupplierWhereInput>>;
  NOT?: InputMaybe<Array<SupplierWhereInput>>;
  OR?: InputMaybe<Array<SupplierWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  email?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  kitchenDetails?: InputMaybe<KitchenSupplierDetailListRelationFilter>;
  kitchens?: InputMaybe<KitchenToSupplierListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  productDuplicates?: InputMaybe<ProductDuplicatesListRelationFilter>;
  productMerges?: InputMaybe<ProductMergeListRelationFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  purchaseOrders?: InputMaybe<PurchaseOrderListRelationFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type SupplierWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type TodoNote = {
  __typename?: 'TodoNote';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  createdById: Scalars['Int'];
  id: Scalars['Int'];
  kitchenTodo: KitchenTodo;
  todoId: Scalars['Int'];
  type: NoteTypeEnum;
  updatedAt: Scalars['DateTime'];
};

export type Unit = Node & {
  __typename?: 'Unit';
  _cursor: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  editable: Scalars['Boolean'];
  gramsPerUnit?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  measurementSystem: MeasurementSystem;
  name: Scalars['String'];
  type: UnitType;
  unitsPerGram?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy: User;
};

export type UnitConnection = {
  __typename?: 'UnitConnection';
  edges: Array<UnitEdge>;
  nodes: Array<Unit>;
  pageInfo: PageInfo;
};

export type UnitCreateManyCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  editable?: InputMaybe<Scalars['Boolean']>;
  gramsPerUnit?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  type?: InputMaybe<UnitType>;
  unitsPerGram?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedById: Scalars['Int'];
};

export type UnitCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<UnitCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UnitCreateManyUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdById: Scalars['Int'];
  editable?: InputMaybe<Scalars['Boolean']>;
  gramsPerUnit?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  type?: InputMaybe<UnitType>;
  unitsPerGram?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UnitCreateManyUpdatedByInputEnvelope = {
  data?: InputMaybe<Array<UnitCreateManyUpdatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UnitCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<UnitWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UnitCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<UnitCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<UnitCreateManyCreatedByInputEnvelope>;
};

export type UnitCreateNestedManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<UnitWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UnitCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<UnitCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<UnitCreateManyUpdatedByInputEnvelope>;
};

export type UnitCreateNestedOneWithoutIngredientConversionUnitTypeInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutIngredientConversionUnitTypeInput>;
  create?: InputMaybe<UnitCreateWithoutIngredientConversionUnitTypeInput>;
};

export type UnitCreateNestedOneWithoutProductUnitInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutProductUnitInput>;
  create?: InputMaybe<UnitCreateWithoutProductUnitInput>;
};

export type UnitCreateNestedOneWithoutPurchaseOrderEntityUnitInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutPurchaseOrderEntityUnitInput>;
  create?: InputMaybe<UnitCreateWithoutPurchaseOrderEntityUnitInput>;
};

export type UnitCreateNestedOneWithoutRecipeToIngredientUnitInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutRecipeToIngredientUnitInput>;
  create?: InputMaybe<UnitCreateWithoutRecipeToIngredientUnitInput>;
};

export type UnitCreateNestedOneWithoutRecipeToRecipeUnitInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutRecipeToRecipeUnitInput>;
  create?: InputMaybe<UnitCreateWithoutRecipeToRecipeUnitInput>;
};

export type UnitCreateNestedOneWithoutRecipeUnitInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutRecipeUnitInput>;
  create?: InputMaybe<UnitCreateWithoutRecipeUnitInput>;
};

export type UnitCreateOrConnectWithoutCreatedByInput = {
  create: UnitCreateWithoutCreatedByInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateOrConnectWithoutIngredientConversionUnitTypeInput = {
  create: UnitCreateWithoutIngredientConversionUnitTypeInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateOrConnectWithoutProductUnitInput = {
  create: UnitCreateWithoutProductUnitInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateOrConnectWithoutPurchaseOrderEntityUnitInput = {
  create: UnitCreateWithoutPurchaseOrderEntityUnitInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateOrConnectWithoutRecipeToIngredientUnitInput = {
  create: UnitCreateWithoutRecipeToIngredientUnitInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateOrConnectWithoutRecipeToRecipeUnitInput = {
  create: UnitCreateWithoutRecipeToRecipeUnitInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateOrConnectWithoutRecipeUnitInput = {
  create: UnitCreateWithoutRecipeUnitInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateOrConnectWithoutUpdatedByInput = {
  create: UnitCreateWithoutUpdatedByInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateWithoutCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  editable?: InputMaybe<Scalars['Boolean']>;
  gramsPerUnit?: InputMaybe<Scalars['Float']>;
  ingredientConversionUnitType?: InputMaybe<IngredientCreateNestedManyWithoutConversionUnitTypeInput>;
  name: Scalars['String'];
  productUnit?: InputMaybe<ProductCreateNestedManyWithoutUnitInput>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutUnitInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutUnitInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutUnitInput>;
  recipeUnit?: InputMaybe<RecipeCreateNestedManyWithoutUnitInput>;
  type?: InputMaybe<UnitType>;
  unitsPerGram?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutUnitUpdatedInput;
};

export type UnitCreateWithoutIngredientConversionUnitTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutUnitCreatedInput;
  editable?: InputMaybe<Scalars['Boolean']>;
  gramsPerUnit?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  productUnit?: InputMaybe<ProductCreateNestedManyWithoutUnitInput>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutUnitInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutUnitInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutUnitInput>;
  recipeUnit?: InputMaybe<RecipeCreateNestedManyWithoutUnitInput>;
  type?: InputMaybe<UnitType>;
  unitsPerGram?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutUnitUpdatedInput;
};

export type UnitCreateWithoutProductUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutUnitCreatedInput;
  editable?: InputMaybe<Scalars['Boolean']>;
  gramsPerUnit?: InputMaybe<Scalars['Float']>;
  ingredientConversionUnitType?: InputMaybe<IngredientCreateNestedManyWithoutConversionUnitTypeInput>;
  name: Scalars['String'];
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutUnitInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutUnitInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutUnitInput>;
  recipeUnit?: InputMaybe<RecipeCreateNestedManyWithoutUnitInput>;
  type?: InputMaybe<UnitType>;
  unitsPerGram?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutUnitUpdatedInput;
};

export type UnitCreateWithoutPurchaseOrderEntityUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutUnitCreatedInput;
  editable?: InputMaybe<Scalars['Boolean']>;
  gramsPerUnit?: InputMaybe<Scalars['Float']>;
  ingredientConversionUnitType?: InputMaybe<IngredientCreateNestedManyWithoutConversionUnitTypeInput>;
  name: Scalars['String'];
  productUnit?: InputMaybe<ProductCreateNestedManyWithoutUnitInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutUnitInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutUnitInput>;
  recipeUnit?: InputMaybe<RecipeCreateNestedManyWithoutUnitInput>;
  type?: InputMaybe<UnitType>;
  unitsPerGram?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutUnitUpdatedInput;
};

export type UnitCreateWithoutRecipeToIngredientUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutUnitCreatedInput;
  editable?: InputMaybe<Scalars['Boolean']>;
  gramsPerUnit?: InputMaybe<Scalars['Float']>;
  ingredientConversionUnitType?: InputMaybe<IngredientCreateNestedManyWithoutConversionUnitTypeInput>;
  name: Scalars['String'];
  productUnit?: InputMaybe<ProductCreateNestedManyWithoutUnitInput>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutUnitInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutUnitInput>;
  recipeUnit?: InputMaybe<RecipeCreateNestedManyWithoutUnitInput>;
  type?: InputMaybe<UnitType>;
  unitsPerGram?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutUnitUpdatedInput;
};

export type UnitCreateWithoutRecipeToRecipeUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutUnitCreatedInput;
  editable?: InputMaybe<Scalars['Boolean']>;
  gramsPerUnit?: InputMaybe<Scalars['Float']>;
  ingredientConversionUnitType?: InputMaybe<IngredientCreateNestedManyWithoutConversionUnitTypeInput>;
  name: Scalars['String'];
  productUnit?: InputMaybe<ProductCreateNestedManyWithoutUnitInput>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutUnitInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutUnitInput>;
  recipeUnit?: InputMaybe<RecipeCreateNestedManyWithoutUnitInput>;
  type?: InputMaybe<UnitType>;
  unitsPerGram?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutUnitUpdatedInput;
};

export type UnitCreateWithoutRecipeUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutUnitCreatedInput;
  editable?: InputMaybe<Scalars['Boolean']>;
  gramsPerUnit?: InputMaybe<Scalars['Float']>;
  ingredientConversionUnitType?: InputMaybe<IngredientCreateNestedManyWithoutConversionUnitTypeInput>;
  name: Scalars['String'];
  productUnit?: InputMaybe<ProductCreateNestedManyWithoutUnitInput>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutUnitInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutUnitInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutUnitInput>;
  type?: InputMaybe<UnitType>;
  unitsPerGram?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy: UserCreateNestedOneWithoutUnitUpdatedInput;
};

export type UnitCreateWithoutUpdatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserCreateNestedOneWithoutUnitCreatedInput;
  editable?: InputMaybe<Scalars['Boolean']>;
  gramsPerUnit?: InputMaybe<Scalars['Float']>;
  ingredientConversionUnitType?: InputMaybe<IngredientCreateNestedManyWithoutConversionUnitTypeInput>;
  name: Scalars['String'];
  productUnit?: InputMaybe<ProductCreateNestedManyWithoutUnitInput>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryCreateNestedManyWithoutUnitInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientCreateNestedManyWithoutUnitInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeCreateNestedManyWithoutUnitInput>;
  recipeUnit?: InputMaybe<RecipeCreateNestedManyWithoutUnitInput>;
  type?: InputMaybe<UnitType>;
  unitsPerGram?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UnitEdge = {
  __typename?: 'UnitEdge';
  cursor: Scalars['ID'];
  node: Unit;
};

export type UnitListRelationFilter = {
  every?: InputMaybe<UnitWhereInput>;
  none?: InputMaybe<UnitWhereInput>;
  some?: InputMaybe<UnitWhereInput>;
};

export type UnitOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByInput>;
  createdById?: InputMaybe<SortOrder>;
  editable?: InputMaybe<SortOrder>;
  gramsPerUnit?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ingredientConversionUnitType?: InputMaybe<IngredientOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  productUnit?: InputMaybe<ProductOrderByRelationAggregateInput>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryOrderByRelationAggregateInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientOrderByRelationAggregateInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeOrderByRelationAggregateInput>;
  recipeUnit?: InputMaybe<RecipeOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  unitsPerGram?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<UserOrderByInput>;
  updatedById?: InputMaybe<SortOrder>;
};

export type UnitOrderByRelationAggregateInput = {
  count: SortOrder;
};

export type UnitScalarWhereInput = {
  AND?: InputMaybe<Array<UnitScalarWhereInput>>;
  NOT?: InputMaybe<Array<UnitScalarWhereInput>>;
  OR?: InputMaybe<Array<UnitScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdById?: InputMaybe<IntFilter>;
  editable?: InputMaybe<BoolFilter>;
  gramsPerUnit?: InputMaybe<FloatNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumUnitTypeFilter>;
  unitsPerGram?: InputMaybe<FloatNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedById?: InputMaybe<IntFilter>;
};

export enum UnitType {
  Custom = 'custom',
  Volume = 'volume',
  Weight = 'weight'
}

export type UnitUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  editable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  gramsPerUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUnitTypeFieldUpdateOperationsInput>;
  unitsPerGram?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UnitUpdateManyWithWhereWithoutCreatedByInput = {
  data: UnitUpdateManyMutationInput;
  where: UnitScalarWhereInput;
};

export type UnitUpdateManyWithWhereWithoutUpdatedByInput = {
  data: UnitUpdateManyMutationInput;
  where: UnitScalarWhereInput;
};

export type UnitUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<UnitWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UnitCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<UnitCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<UnitCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<UnitWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UnitScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UnitWhereUniqueInput>>;
  set?: InputMaybe<Array<UnitWhereUniqueInput>>;
  update?: InputMaybe<Array<UnitUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<UnitUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<UnitUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type UnitUpdateManyWithoutUpdatedByInput = {
  connect?: InputMaybe<Array<UnitWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UnitCreateOrConnectWithoutUpdatedByInput>>;
  create?: InputMaybe<Array<UnitCreateWithoutUpdatedByInput>>;
  createMany?: InputMaybe<UnitCreateManyUpdatedByInputEnvelope>;
  delete?: InputMaybe<Array<UnitWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UnitScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UnitWhereUniqueInput>>;
  set?: InputMaybe<Array<UnitWhereUniqueInput>>;
  update?: InputMaybe<Array<UnitUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: InputMaybe<Array<UnitUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: InputMaybe<Array<UnitUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type UnitUpdateOneRequiredWithoutProductUnitInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutProductUnitInput>;
  create?: InputMaybe<UnitCreateWithoutProductUnitInput>;
  update?: InputMaybe<UnitUpdateWithoutProductUnitInput>;
  upsert?: InputMaybe<UnitUpsertWithoutProductUnitInput>;
};

export type UnitUpdateOneRequiredWithoutPurchaseOrderEntityUnitInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutPurchaseOrderEntityUnitInput>;
  create?: InputMaybe<UnitCreateWithoutPurchaseOrderEntityUnitInput>;
  update?: InputMaybe<UnitUpdateWithoutPurchaseOrderEntityUnitInput>;
  upsert?: InputMaybe<UnitUpsertWithoutPurchaseOrderEntityUnitInput>;
};

export type UnitUpdateOneWithoutIngredientConversionUnitTypeInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutIngredientConversionUnitTypeInput>;
  create?: InputMaybe<UnitCreateWithoutIngredientConversionUnitTypeInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UnitUpdateWithoutIngredientConversionUnitTypeInput>;
  upsert?: InputMaybe<UnitUpsertWithoutIngredientConversionUnitTypeInput>;
};

export type UnitUpdateOneWithoutRecipeToIngredientUnitInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutRecipeToIngredientUnitInput>;
  create?: InputMaybe<UnitCreateWithoutRecipeToIngredientUnitInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UnitUpdateWithoutRecipeToIngredientUnitInput>;
  upsert?: InputMaybe<UnitUpsertWithoutRecipeToIngredientUnitInput>;
};

export type UnitUpdateOneWithoutRecipeToRecipeUnitInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutRecipeToRecipeUnitInput>;
  create?: InputMaybe<UnitCreateWithoutRecipeToRecipeUnitInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UnitUpdateWithoutRecipeToRecipeUnitInput>;
  upsert?: InputMaybe<UnitUpsertWithoutRecipeToRecipeUnitInput>;
};

export type UnitUpdateOneWithoutRecipeUnitInput = {
  connect?: InputMaybe<UnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitCreateOrConnectWithoutRecipeUnitInput>;
  create?: InputMaybe<UnitCreateWithoutRecipeUnitInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UnitUpdateWithoutRecipeUnitInput>;
  upsert?: InputMaybe<UnitUpsertWithoutRecipeUnitInput>;
};

export type UnitUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: UnitUpdateWithoutCreatedByInput;
  where: UnitWhereUniqueInput;
};

export type UnitUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: UnitUpdateWithoutUpdatedByInput;
  where: UnitWhereUniqueInput;
};

export type UnitUpdateWithoutCreatedByInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  editable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  gramsPerUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ingredientConversionUnitType?: InputMaybe<IngredientUpdateManyWithoutConversionUnitTypeInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productUnit?: InputMaybe<ProductUpdateManyWithoutUnitInput>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutUnitInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientUpdateManyWithoutUnitInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeUpdateManyWithoutUnitInput>;
  recipeUnit?: InputMaybe<RecipeUpdateManyWithoutUnitInput>;
  type?: InputMaybe<EnumUnitTypeFieldUpdateOperationsInput>;
  unitsPerGram?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutUnitUpdatedInput>;
};

export type UnitUpdateWithoutIngredientConversionUnitTypeInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutUnitCreatedInput>;
  editable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  gramsPerUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productUnit?: InputMaybe<ProductUpdateManyWithoutUnitInput>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutUnitInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientUpdateManyWithoutUnitInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeUpdateManyWithoutUnitInput>;
  recipeUnit?: InputMaybe<RecipeUpdateManyWithoutUnitInput>;
  type?: InputMaybe<EnumUnitTypeFieldUpdateOperationsInput>;
  unitsPerGram?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutUnitUpdatedInput>;
};

export type UnitUpdateWithoutProductUnitInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutUnitCreatedInput>;
  editable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  gramsPerUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ingredientConversionUnitType?: InputMaybe<IngredientUpdateManyWithoutConversionUnitTypeInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutUnitInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientUpdateManyWithoutUnitInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeUpdateManyWithoutUnitInput>;
  recipeUnit?: InputMaybe<RecipeUpdateManyWithoutUnitInput>;
  type?: InputMaybe<EnumUnitTypeFieldUpdateOperationsInput>;
  unitsPerGram?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutUnitUpdatedInput>;
};

export type UnitUpdateWithoutPurchaseOrderEntityUnitInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutUnitCreatedInput>;
  editable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  gramsPerUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ingredientConversionUnitType?: InputMaybe<IngredientUpdateManyWithoutConversionUnitTypeInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productUnit?: InputMaybe<ProductUpdateManyWithoutUnitInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientUpdateManyWithoutUnitInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeUpdateManyWithoutUnitInput>;
  recipeUnit?: InputMaybe<RecipeUpdateManyWithoutUnitInput>;
  type?: InputMaybe<EnumUnitTypeFieldUpdateOperationsInput>;
  unitsPerGram?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutUnitUpdatedInput>;
};

export type UnitUpdateWithoutRecipeToIngredientUnitInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutUnitCreatedInput>;
  editable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  gramsPerUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ingredientConversionUnitType?: InputMaybe<IngredientUpdateManyWithoutConversionUnitTypeInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productUnit?: InputMaybe<ProductUpdateManyWithoutUnitInput>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutUnitInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeUpdateManyWithoutUnitInput>;
  recipeUnit?: InputMaybe<RecipeUpdateManyWithoutUnitInput>;
  type?: InputMaybe<EnumUnitTypeFieldUpdateOperationsInput>;
  unitsPerGram?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutUnitUpdatedInput>;
};

export type UnitUpdateWithoutRecipeToRecipeUnitInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutUnitCreatedInput>;
  editable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  gramsPerUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ingredientConversionUnitType?: InputMaybe<IngredientUpdateManyWithoutConversionUnitTypeInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productUnit?: InputMaybe<ProductUpdateManyWithoutUnitInput>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutUnitInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientUpdateManyWithoutUnitInput>;
  recipeUnit?: InputMaybe<RecipeUpdateManyWithoutUnitInput>;
  type?: InputMaybe<EnumUnitTypeFieldUpdateOperationsInput>;
  unitsPerGram?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutUnitUpdatedInput>;
};

export type UnitUpdateWithoutRecipeUnitInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutUnitCreatedInput>;
  editable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  gramsPerUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ingredientConversionUnitType?: InputMaybe<IngredientUpdateManyWithoutConversionUnitTypeInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productUnit?: InputMaybe<ProductUpdateManyWithoutUnitInput>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutUnitInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientUpdateManyWithoutUnitInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeUpdateManyWithoutUnitInput>;
  type?: InputMaybe<EnumUnitTypeFieldUpdateOperationsInput>;
  unitsPerGram?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedBy?: InputMaybe<UserUpdateOneRequiredWithoutUnitUpdatedInput>;
};

export type UnitUpdateWithoutUpdatedByInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutUnitCreatedInput>;
  editable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  gramsPerUnit?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  ingredientConversionUnitType?: InputMaybe<IngredientUpdateManyWithoutConversionUnitTypeInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productUnit?: InputMaybe<ProductUpdateManyWithoutUnitInput>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryUpdateManyWithoutUnitInput>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientUpdateManyWithoutUnitInput>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeUpdateManyWithoutUnitInput>;
  recipeUnit?: InputMaybe<RecipeUpdateManyWithoutUnitInput>;
  type?: InputMaybe<EnumUnitTypeFieldUpdateOperationsInput>;
  unitsPerGram?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UnitUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: UnitCreateWithoutCreatedByInput;
  update: UnitUpdateWithoutCreatedByInput;
  where: UnitWhereUniqueInput;
};

export type UnitUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: UnitCreateWithoutUpdatedByInput;
  update: UnitUpdateWithoutUpdatedByInput;
  where: UnitWhereUniqueInput;
};

export type UnitUpsertWithoutIngredientConversionUnitTypeInput = {
  create: UnitCreateWithoutIngredientConversionUnitTypeInput;
  update: UnitUpdateWithoutIngredientConversionUnitTypeInput;
};

export type UnitUpsertWithoutProductUnitInput = {
  create: UnitCreateWithoutProductUnitInput;
  update: UnitUpdateWithoutProductUnitInput;
};

export type UnitUpsertWithoutPurchaseOrderEntityUnitInput = {
  create: UnitCreateWithoutPurchaseOrderEntityUnitInput;
  update: UnitUpdateWithoutPurchaseOrderEntityUnitInput;
};

export type UnitUpsertWithoutRecipeToIngredientUnitInput = {
  create: UnitCreateWithoutRecipeToIngredientUnitInput;
  update: UnitUpdateWithoutRecipeToIngredientUnitInput;
};

export type UnitUpsertWithoutRecipeToRecipeUnitInput = {
  create: UnitCreateWithoutRecipeToRecipeUnitInput;
  update: UnitUpdateWithoutRecipeToRecipeUnitInput;
};

export type UnitUpsertWithoutRecipeUnitInput = {
  create: UnitCreateWithoutRecipeUnitInput;
  update: UnitUpdateWithoutRecipeUnitInput;
};

export type UnitWhereInput = {
  AND?: InputMaybe<Array<UnitWhereInput>>;
  NOT?: InputMaybe<Array<UnitWhereInput>>;
  OR?: InputMaybe<Array<UnitWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<IntFilter>;
  editable?: InputMaybe<BoolFilter>;
  gramsPerUnit?: InputMaybe<FloatNullableFilter>;
  id?: InputMaybe<IntFilter>;
  ingredientConversionUnitType?: InputMaybe<IngredientListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  productUnit?: InputMaybe<ProductListRelationFilter>;
  purchaseOrderEntityUnit?: InputMaybe<PurchaseOrderEntryListRelationFilter>;
  recipeToIngredientUnit?: InputMaybe<RecipeToIngredientListRelationFilter>;
  recipeToRecipeUnit?: InputMaybe<RecipeToRecipeListRelationFilter>;
  recipeUnit?: InputMaybe<RecipeListRelationFilter>;
  type?: InputMaybe<EnumUnitTypeFilter>;
  unitsPerGram?: InputMaybe<FloatNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
  updatedById?: InputMaybe<IntFilter>;
};

export type UnitWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateDishToAllergenInputObject = {
  contains?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  removable?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateDishToIngredientInputObject = {
  amount?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  unitCost?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Int']>;
};

export type UpdateDishToRecipeInputObject = {
  amount?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  unitCost?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Int']>;
};

export type UpdateFlashPeriodInput = {
  closeAdjustments?: InputMaybe<Scalars['Float']>;
  closeStockIds: Array<Scalars['Int']>;
  id: Scalars['Int'];
  openAdjustments?: InputMaybe<Scalars['Float']>;
  openStockIds: Array<Scalars['Int']>;
};

export type UpdateKitchenInvoiceRuleInput = {
  approvalThreshold?: InputMaybe<Scalars['Float']>;
  approveAllInvoices: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type UpdateKitchenTodoInput = {
  assigneeId?: InputMaybe<Scalars['Int']>;
  completed: Scalars['Boolean'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type UpdateOneDishInputObject = {
  gpTarget?: InputMaybe<Scalars['Float']>;
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  imageUrl?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  overheads?: InputMaybe<Scalars['Float']>;
  portion?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<Scalars['String']>;
  unitCost?: InputMaybe<Scalars['Float']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type UpdateOneFoodFlashInputObject = {
  complimentary?: InputMaybe<Scalars['Float']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  expenses?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  sales?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  stockIn: Array<InputMaybe<Scalars['Int']>>;
  stockOut: Array<InputMaybe<Scalars['Int']>>;
  wastage?: InputMaybe<Scalars['Float']>;
};

export type UpdateOneIngredientInputObject = {
  code?: InputMaybe<Scalars['String']>;
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitTypeId?: InputMaybe<Scalars['Int']>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  packSize?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Int']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  unitValue?: InputMaybe<Scalars['Float']>;
  wastage?: InputMaybe<Scalars['Int']>;
};

export type UpdateOneInvoiceEntryInputObject = {
  id: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
};

export type UpdateOneInvoiceInputObject = {
  entries?: InputMaybe<Array<InputMaybe<UpdateOneInvoiceEntryInputObject>>>;
  images?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  invoiceDate?: InputMaybe<Scalars['DateTime']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  invoiceTotal?: InputMaybe<Scalars['Float']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type UpdateOneKitchenAddressInputObject = {
  city: Scalars['String'];
  deliveryInstructions?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  line1: Scalars['String'];
  mainAddress?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  postCode: Scalars['String'];
};

export type UpdateOneKitchenInputObject = {
  activatedPixelFiredTime?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  onboardState?: InputMaybe<OnboardState>;
  setupCompleted?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Status>;
};

export type UpdateOneMenuInputObject = {
  gpTarget?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type UpdateOneProductInputObject = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  packSize?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<ProductStatus>;
  unitId?: InputMaybe<Scalars['Int']>;
  unitValue?: InputMaybe<Scalars['Float']>;
};

export type UpdateOneRecipeInputObject = {
  amount?: InputMaybe<Scalars['Float']>;
  conversionUnit?: InputMaybe<Scalars['Float']>;
  conversionUnitTypeId?: InputMaybe<Scalars['Int']>;
  conversionUnitValue?: InputMaybe<Scalars['Float']>;
  hasNoAllergens?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  imageUrl?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  section?: InputMaybe<Scalars['String']>;
  unitCost?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Int']>;
};

export type UpdateOneStockEntryInputObject = {
  id?: InputMaybe<Scalars['Int']>;
  ingredientId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
  recipeId?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Float']>;
  unitId: Scalars['Int'];
};

export type UpdateOneStockInputObject = {
  date?: InputMaybe<Scalars['DateTime']>;
  entries?: InputMaybe<Array<UpdateOneStockEntryInputObject>>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  total?: InputMaybe<Scalars['Float']>;
};

export type UpdateOneSupplierInputObject = {
  addressId?: InputMaybe<Scalars['Int']>;
  addressName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  deliveryDays?: InputMaybe<Scalars['Json']>;
  email?: InputMaybe<SupplierDetailInputObject>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  line1?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<SupplierDetailInputObject>;
  phone?: InputMaybe<SupplierDetailInputObject>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  supplierInfo?: InputMaybe<SupplierInformationInputObject>;
  supplierNotes?: InputMaybe<Scalars['String']>;
  tradingName?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateOneUserInputObject = {
  countryCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: InputMaybe<Scalars['String']>;
  jobResponsibilities?: InputMaybe<Array<Scalars['Int']>>;
  jobRoles?: InputMaybe<Array<Scalars['Int']>>;
  kitchen?: InputMaybe<UpdateOneUserKitchenInput>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
};

export type UpdateOneUserKitchenInput = {
  id: Scalars['Int'];
  joinCode: Scalars['String'];
};

export type UpdateRecipeToAllergenInputObject = {
  contains?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  removable?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateRecipeToIngredientInputObject = {
  amount?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  unitCost?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Int']>;
};

export type UpdateRecipeToRecipeInputObject = {
  amount?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  unitCost?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Int']>;
};

export type UpsertPosAccountInput = {
  externalId: Scalars['String'];
  name: Scalars['String'];
  posTypeId: Scalars['Int'];
};

export type UpsertPosCategoryInput = {
  externalId: Scalars['String'];
  isWet?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  posAccountId?: InputMaybe<Scalars['Int']>;
  posParentCategoryId?: InputMaybe<Scalars['Int']>;
};

export type UpsertPosLocationInput = {
  externalId: Scalars['String'];
  name: Scalars['String'];
  posAccountId: Scalars['Int'];
};

export type UpsertPosProductInput = {
  externalId: Scalars['String'];
  isWet?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  posAccountId: Scalars['Int'];
  posCategoryId: Scalars['Int'];
  price?: InputMaybe<Scalars['Int']>;
};

export type UpsertPosTillInput = {
  externalId: Scalars['String'];
  name: Scalars['String'];
  posLocationId: Scalars['Int'];
  posTypeId: Scalars['Int'];
};

export type UpsertPosTokenInput = {
  externalId: Scalars['String'];
  posAccountId: Scalars['Int'];
  posLocationId?: InputMaybe<Scalars['Int']>;
  posTypeId: Scalars['Int'];
  token: Scalars['String'];
};

export type UpsertPosTransactionInput = {
  discount: Scalars['Int'];
  externalId: Scalars['String'];
  fees: Scalars['Int'];
  gratuity: Scalars['Int'];
  isRefund: Scalars['Boolean'];
  posLocationId: Scalars['Int'];
  sales: Scalars['Int'];
  serviceCharge: Scalars['Int'];
  tax: Scalars['Int'];
  total: Scalars['Int'];
  transactionAt: Scalars['DateTime'];
};

export type UpsertPosTransactionItemInput = {
  discount: Scalars['Int'];
  externalId: Scalars['String'];
  gratuity: Scalars['Int'];
  modifiers: Scalars['Int'];
  posProductId: Scalars['Int'];
  posTransactionId: Scalars['Int'];
  quantity: Scalars['Int'];
  serviceCharge: Scalars['Int'];
  tax: Scalars['Int'];
  total: Scalars['Int'];
  unitPrice: Scalars['Int'];
};

export type UpsertPosTypeInput = {
  name: Scalars['String'];
};

export type User = Node & {
  __typename?: 'User';
  _cursor: Scalars['ID'];
  appVersion?: Maybe<Scalars['String']>;
  completedSignUpPhone: Scalars['Boolean'];
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailVerificationCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  hubspotKitchenToSync?: Maybe<Kitchen>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  isMe?: Maybe<Scalars['Boolean']>;
  jobResponsibilityIds?: Maybe<Array<Scalars['Int']>>;
  jobRoleIds?: Maybe<Array<Scalars['Int']>>;
  kitchenList: KitchenToUserConnection;
  /** @deprecated Use and extend kitchenList field */
  kitchens: Array<KitchenToUser>;
  lastName?: Maybe<Scalars['String']>;
  passwordResetCode?: Maybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberNational?: Maybe<Scalars['String']>;
  status: Status;
  type: UserRole;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UserKitchenListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type UserKitchensArgs = {
  cursor?: InputMaybe<KitchenToUserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<KitchenToUserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<KitchenToUserWhereInput>;
};

export type UserActivity = Node & {
  __typename?: 'UserActivity';
  _cursor: Scalars['ID'];
  date?: Maybe<Scalars['DateTime']>;
  userId: Scalars['Int'];
};

export type UserActivityOrderByInput = {
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserActivityUserIdDateCompoundUniqueInput = {
  date: Scalars['DateTime'];
  userId: Scalars['Int'];
};

export type UserActivityWhereInput = {
  AND?: InputMaybe<Array<UserActivityWhereInput>>;
  NOT?: InputMaybe<Array<UserActivityWhereInput>>;
  OR?: InputMaybe<Array<UserActivityWhereInput>>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type UserActivityWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  userId_date?: InputMaybe<UserActivityUserIdDateCompoundUniqueInput>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type UserCreateInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateNestedOneWithoutBatchCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBatchCreatedInput>;
  create?: InputMaybe<UserCreateWithoutBatchCreatedInput>;
};

export type UserCreateNestedOneWithoutBatchUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBatchUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutBatchUpdatedInput>;
};

export type UserCreateNestedOneWithoutDishCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDishCreatedInput>;
  create?: InputMaybe<UserCreateWithoutDishCreatedInput>;
};

export type UserCreateNestedOneWithoutDishUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDishUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutDishUpdatedInput>;
};

export type UserCreateNestedOneWithoutEventLogCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEventLogCreatedInput>;
  create?: InputMaybe<UserCreateWithoutEventLogCreatedInput>;
};

export type UserCreateNestedOneWithoutEventLogUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEventLogUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutEventLogUpdatedInput>;
};

export type UserCreateNestedOneWithoutIngredientCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutIngredientCreatedInput>;
  create?: InputMaybe<UserCreateWithoutIngredientCreatedInput>;
};

export type UserCreateNestedOneWithoutIngredientLogCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutIngredientLogCreatedInput>;
  create?: InputMaybe<UserCreateWithoutIngredientLogCreatedInput>;
};

export type UserCreateNestedOneWithoutIngredientLogUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutIngredientLogUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutIngredientLogUpdatedInput>;
};

export type UserCreateNestedOneWithoutIngredientUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutIngredientUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutIngredientUpdatedInput>;
};

export type UserCreateNestedOneWithoutKitchenCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutKitchenCreatedInput>;
  create?: InputMaybe<UserCreateWithoutKitchenCreatedInput>;
};

export type UserCreateNestedOneWithoutKitchenUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutKitchenUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutKitchenUpdatedInput>;
};

export type UserCreateNestedOneWithoutKitchensInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutKitchensInput>;
  create?: InputMaybe<UserCreateWithoutKitchensInput>;
};

export type UserCreateNestedOneWithoutLookupCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLookupCreatedInput>;
  create?: InputMaybe<UserCreateWithoutLookupCreatedInput>;
};

export type UserCreateNestedOneWithoutLookupTypeCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLookupTypeCreatedInput>;
  create?: InputMaybe<UserCreateWithoutLookupTypeCreatedInput>;
};

export type UserCreateNestedOneWithoutLookupTypeUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLookupTypeUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutLookupTypeUpdatedInput>;
};

export type UserCreateNestedOneWithoutLookupUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLookupUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutLookupUpdatedInput>;
};

export type UserCreateNestedOneWithoutMenuCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutMenuCreatedInput>;
  create?: InputMaybe<UserCreateWithoutMenuCreatedInput>;
};

export type UserCreateNestedOneWithoutMenuUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutMenuUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutMenuUpdatedInput>;
};

export type UserCreateNestedOneWithoutProductCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProductCreatedInput>;
  create?: InputMaybe<UserCreateWithoutProductCreatedInput>;
};

export type UserCreateNestedOneWithoutProductDuplicatesAssignedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProductDuplicatesAssignedInput>;
  create?: InputMaybe<UserCreateWithoutProductDuplicatesAssignedInput>;
};

export type UserCreateNestedOneWithoutProductDuplicatesUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProductDuplicatesUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutProductDuplicatesUpdatedInput>;
};

export type UserCreateNestedOneWithoutProductMergeCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProductMergeCreatedInput>;
  create?: InputMaybe<UserCreateWithoutProductMergeCreatedInput>;
};

export type UserCreateNestedOneWithoutProductMergeUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProductMergeUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutProductMergeUpdatedInput>;
};

export type UserCreateNestedOneWithoutProductUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProductUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutProductUpdatedInput>;
};

export type UserCreateNestedOneWithoutPurchaseOrderCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPurchaseOrderCreatedInput>;
  create?: InputMaybe<UserCreateWithoutPurchaseOrderCreatedInput>;
};

export type UserCreateNestedOneWithoutPurchaseOrderUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPurchaseOrderUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutPurchaseOrderUpdatedInput>;
};

export type UserCreateNestedOneWithoutRecipeCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRecipeCreatedInput>;
  create?: InputMaybe<UserCreateWithoutRecipeCreatedInput>;
};

export type UserCreateNestedOneWithoutRecipeUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRecipeUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutRecipeUpdatedInput>;
};

export type UserCreateNestedOneWithoutSupplierCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSupplierCreatedInput>;
  create?: InputMaybe<UserCreateWithoutSupplierCreatedInput>;
};

export type UserCreateNestedOneWithoutSupplierUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSupplierUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutSupplierUpdatedInput>;
};

export type UserCreateNestedOneWithoutUnitCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUnitCreatedInput>;
  create?: InputMaybe<UserCreateWithoutUnitCreatedInput>;
};

export type UserCreateNestedOneWithoutUnitUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUnitUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutUnitUpdatedInput>;
};

export type UserCreateOrConnectWithoutBatchCreatedInput = {
  create: UserCreateWithoutBatchCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutBatchUpdatedInput = {
  create: UserCreateWithoutBatchUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDishCreatedInput = {
  create: UserCreateWithoutDishCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDishUpdatedInput = {
  create: UserCreateWithoutDishUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEventLogCreatedInput = {
  create: UserCreateWithoutEventLogCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEventLogUpdatedInput = {
  create: UserCreateWithoutEventLogUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutIngredientCreatedInput = {
  create: UserCreateWithoutIngredientCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutIngredientLogCreatedInput = {
  create: UserCreateWithoutIngredientLogCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutIngredientLogUpdatedInput = {
  create: UserCreateWithoutIngredientLogUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutIngredientUpdatedInput = {
  create: UserCreateWithoutIngredientUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutKitchenCreatedInput = {
  create: UserCreateWithoutKitchenCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutKitchenUpdatedInput = {
  create: UserCreateWithoutKitchenUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutKitchensInput = {
  create: UserCreateWithoutKitchensInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLookupCreatedInput = {
  create: UserCreateWithoutLookupCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLookupTypeCreatedInput = {
  create: UserCreateWithoutLookupTypeCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLookupTypeUpdatedInput = {
  create: UserCreateWithoutLookupTypeUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLookupUpdatedInput = {
  create: UserCreateWithoutLookupUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutMenuCreatedInput = {
  create: UserCreateWithoutMenuCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutMenuUpdatedInput = {
  create: UserCreateWithoutMenuUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutProductCreatedInput = {
  create: UserCreateWithoutProductCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutProductDuplicatesAssignedInput = {
  create: UserCreateWithoutProductDuplicatesAssignedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutProductDuplicatesUpdatedInput = {
  create: UserCreateWithoutProductDuplicatesUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutProductMergeCreatedInput = {
  create: UserCreateWithoutProductMergeCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutProductMergeUpdatedInput = {
  create: UserCreateWithoutProductMergeUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutProductUpdatedInput = {
  create: UserCreateWithoutProductUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPurchaseOrderCreatedInput = {
  create: UserCreateWithoutPurchaseOrderCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPurchaseOrderUpdatedInput = {
  create: UserCreateWithoutPurchaseOrderUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRecipeCreatedInput = {
  create: UserCreateWithoutRecipeCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRecipeUpdatedInput = {
  create: UserCreateWithoutRecipeUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSupplierCreatedInput = {
  create: UserCreateWithoutSupplierCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSupplierUpdatedInput = {
  create: UserCreateWithoutSupplierUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUnitCreatedInput = {
  create: UserCreateWithoutUnitCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUnitUpdatedInput = {
  create: UserCreateWithoutUnitUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutBatchCreatedInput = {
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutBatchUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutDishCreatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutDishUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutEventLogCreatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutEventLogUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutIngredientCreatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutIngredientLogCreatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutIngredientLogUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutIngredientUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutKitchenCreatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutKitchenUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutKitchensInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutLookupCreatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutLookupTypeCreatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutLookupTypeUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutLookupUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutMenuCreatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutMenuUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutProductCreatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutProductDuplicatesAssignedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutProductDuplicatesUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutProductMergeCreatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutProductMergeUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutProductUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutPurchaseOrderCreatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutPurchaseOrderUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutRecipeCreatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutRecipeUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutSupplierCreatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutSupplierUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutUnitCreatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitUpdated?: InputMaybe<UnitCreateNestedManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutUnitUpdatedInput = {
  batchCreated?: InputMaybe<BatchCreateNestedManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchCreateNestedManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dishCreated?: InputMaybe<DishCreateNestedManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishCreateNestedManyWithoutUpdatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  eventLogCreated?: InputMaybe<EventLogCreateNestedManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogCreateNestedManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<Scalars['String']>;
  ingredientCreated?: InputMaybe<IngredientCreateNestedManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogCreateNestedManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogCreateNestedManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientCreateNestedManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenCreateNestedManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenCreateNestedManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserCreateNestedManyWithoutUserInput>;
  lastName?: InputMaybe<Scalars['String']>;
  menuCreated?: InputMaybe<MenuCreateNestedManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuCreateNestedManyWithoutUpdatedByInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordResetCodeExpiresAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational?: InputMaybe<Scalars['String']>;
  productCreated?: InputMaybe<ProductCreateNestedManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesCreateNestedManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeCreateNestedManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeCreateNestedManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductCreateNestedManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderCreateNestedManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeCreateNestedManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeCreateNestedManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserCreaterefreshTokensInput>;
  status?: InputMaybe<Status>;
  supplierCreated?: InputMaybe<SupplierCreateNestedManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierCreateNestedManyWithoutUpdatedByInput>;
  type?: InputMaybe<UserRole>;
  unitCreated?: InputMaybe<UnitCreateNestedManyWithoutCreatedByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreaterefreshTokensInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['ID'];
  node: User;
};

export type UserListWhereInput = {
  kitchenCursor?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<UserRole>;
};

export type UserNotificationQueue = {
  __typename?: 'UserNotificationQueue';
  id: Scalars['Int'];
  status: NotificationStatus;
};

export type UserOrderByInput = {
  batchCreated?: InputMaybe<BatchOrderByRelationAggregateInput>;
  batchUpdated?: InputMaybe<BatchOrderByRelationAggregateInput>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dishCreated?: InputMaybe<DishOrderByRelationAggregateInput>;
  dishUpdated?: InputMaybe<DishOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  eventLogCreated?: InputMaybe<EventLogOrderByRelationAggregateInput>;
  eventLogUpdated?: InputMaybe<EventLogOrderByRelationAggregateInput>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ingredientCreated?: InputMaybe<IngredientOrderByRelationAggregateInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogOrderByRelationAggregateInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogOrderByRelationAggregateInput>;
  ingredientUpdated?: InputMaybe<IngredientOrderByRelationAggregateInput>;
  kitchenCreated?: InputMaybe<KitchenOrderByRelationAggregateInput>;
  kitchenUpdated?: InputMaybe<KitchenOrderByRelationAggregateInput>;
  kitchens?: InputMaybe<KitchenToUserOrderByRelationAggregateInput>;
  lastName?: InputMaybe<SortOrder>;
  menuCreated?: InputMaybe<MenuOrderByRelationAggregateInput>;
  menuUpdated?: InputMaybe<MenuOrderByRelationAggregateInput>;
  password?: InputMaybe<SortOrder>;
  passwordResetCode?: InputMaybe<SortOrder>;
  passwordResetCodeExpiresAt?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  phoneNumberNational?: InputMaybe<SortOrder>;
  productCreated?: InputMaybe<ProductOrderByRelationAggregateInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesOrderByRelationAggregateInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesOrderByRelationAggregateInput>;
  productMergeCreated?: InputMaybe<ProductMergeOrderByRelationAggregateInput>;
  productMergeUpdated?: InputMaybe<ProductMergeOrderByRelationAggregateInput>;
  productUpdated?: InputMaybe<ProductOrderByRelationAggregateInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderOrderByRelationAggregateInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderOrderByRelationAggregateInput>;
  recipeCreated?: InputMaybe<RecipeOrderByRelationAggregateInput>;
  recipeUpdated?: InputMaybe<RecipeOrderByRelationAggregateInput>;
  refreshTokens?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  supplierCreated?: InputMaybe<SupplierOrderByRelationAggregateInput>;
  supplierUpdated?: InputMaybe<SupplierOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  unitCreated?: InputMaybe<UnitOrderByRelationAggregateInput>;
  unitUpdated?: InputMaybe<UnitOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserPhoneNumberNationalCountryCodeCompoundUniqueInput = {
  countryCode: Scalars['String'];
  phoneNumberNational: Scalars['String'];
};

export type UserRefreshAdditionalDataInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  kitchenId?: InputMaybe<Scalars['Int']>;
};

export enum UserRole {
  Admin = 'admin',
  Kitchen = 'kitchen'
}

export type UserToStream = {
  __typename?: 'UserToStream';
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  stream: Stream;
  user: User;
};

export type UserTrackingInputObject = {
  feature: Scalars['String'];
  kitchenId: Scalars['Int'];
};

export type UserUpdateInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateOneRequiredWithoutBatchCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBatchCreatedInput>;
  create?: InputMaybe<UserCreateWithoutBatchCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutBatchCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutBatchCreatedInput>;
};

export type UserUpdateOneRequiredWithoutBatchUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBatchUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutBatchUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutBatchUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutBatchUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutDishCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDishCreatedInput>;
  create?: InputMaybe<UserCreateWithoutDishCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutDishCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutDishCreatedInput>;
};

export type UserUpdateOneRequiredWithoutDishUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDishUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutDishUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutDishUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutDishUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutEventLogCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEventLogCreatedInput>;
  create?: InputMaybe<UserCreateWithoutEventLogCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutEventLogCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutEventLogCreatedInput>;
};

export type UserUpdateOneRequiredWithoutEventLogUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEventLogUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutEventLogUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutEventLogUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutEventLogUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutIngredientCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutIngredientCreatedInput>;
  create?: InputMaybe<UserCreateWithoutIngredientCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutIngredientCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutIngredientCreatedInput>;
};

export type UserUpdateOneRequiredWithoutIngredientLogCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutIngredientLogCreatedInput>;
  create?: InputMaybe<UserCreateWithoutIngredientLogCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutIngredientLogCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutIngredientLogCreatedInput>;
};

export type UserUpdateOneRequiredWithoutIngredientLogUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutIngredientLogUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutIngredientLogUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutIngredientLogUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutIngredientLogUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutIngredientUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutIngredientUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutIngredientUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutIngredientUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutIngredientUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutKitchenCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutKitchenCreatedInput>;
  create?: InputMaybe<UserCreateWithoutKitchenCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutKitchenCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutKitchenCreatedInput>;
};

export type UserUpdateOneRequiredWithoutKitchenUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutKitchenUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutKitchenUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutKitchenUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutKitchenUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutKitchensInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutKitchensInput>;
  create?: InputMaybe<UserCreateWithoutKitchensInput>;
  update?: InputMaybe<UserUpdateWithoutKitchensInput>;
  upsert?: InputMaybe<UserUpsertWithoutKitchensInput>;
};

export type UserUpdateOneRequiredWithoutLookupCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLookupCreatedInput>;
  create?: InputMaybe<UserCreateWithoutLookupCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutLookupCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutLookupCreatedInput>;
};

export type UserUpdateOneRequiredWithoutLookupTypeCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLookupTypeCreatedInput>;
  create?: InputMaybe<UserCreateWithoutLookupTypeCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutLookupTypeCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutLookupTypeCreatedInput>;
};

export type UserUpdateOneRequiredWithoutLookupTypeUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLookupTypeUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutLookupTypeUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutLookupTypeUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutLookupTypeUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutLookupUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLookupUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutLookupUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutLookupUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutLookupUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutMenuCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutMenuCreatedInput>;
  create?: InputMaybe<UserCreateWithoutMenuCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutMenuCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutMenuCreatedInput>;
};

export type UserUpdateOneRequiredWithoutMenuUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutMenuUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutMenuUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutMenuUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutMenuUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutProductCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProductCreatedInput>;
  create?: InputMaybe<UserCreateWithoutProductCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutProductCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutProductCreatedInput>;
};

export type UserUpdateOneRequiredWithoutProductDuplicatesUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProductDuplicatesUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutProductDuplicatesUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutProductDuplicatesUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutProductDuplicatesUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutProductMergeCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProductMergeCreatedInput>;
  create?: InputMaybe<UserCreateWithoutProductMergeCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutProductMergeCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutProductMergeCreatedInput>;
};

export type UserUpdateOneRequiredWithoutProductMergeUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProductMergeUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutProductMergeUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutProductMergeUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutProductMergeUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutProductUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProductUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutProductUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutProductUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutProductUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutPurchaseOrderCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPurchaseOrderCreatedInput>;
  create?: InputMaybe<UserCreateWithoutPurchaseOrderCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutPurchaseOrderCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutPurchaseOrderCreatedInput>;
};

export type UserUpdateOneRequiredWithoutPurchaseOrderUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPurchaseOrderUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutPurchaseOrderUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutPurchaseOrderUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutPurchaseOrderUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutRecipeCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRecipeCreatedInput>;
  create?: InputMaybe<UserCreateWithoutRecipeCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutRecipeCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutRecipeCreatedInput>;
};

export type UserUpdateOneRequiredWithoutRecipeUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRecipeUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutRecipeUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutRecipeUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutRecipeUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutSupplierCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSupplierCreatedInput>;
  create?: InputMaybe<UserCreateWithoutSupplierCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutSupplierCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutSupplierCreatedInput>;
};

export type UserUpdateOneRequiredWithoutSupplierUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSupplierUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutSupplierUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutSupplierUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutSupplierUpdatedInput>;
};

export type UserUpdateOneRequiredWithoutUnitCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUnitCreatedInput>;
  create?: InputMaybe<UserCreateWithoutUnitCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutUnitCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutUnitCreatedInput>;
};

export type UserUpdateOneRequiredWithoutUnitUpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUnitUpdatedInput>;
  create?: InputMaybe<UserCreateWithoutUnitUpdatedInput>;
  update?: InputMaybe<UserUpdateWithoutUnitUpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutUnitUpdatedInput>;
};

export type UserUpdateOneWithoutProductDuplicatesAssignedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProductDuplicatesAssignedInput>;
  create?: InputMaybe<UserCreateWithoutProductDuplicatesAssignedInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutProductDuplicatesAssignedInput>;
  upsert?: InputMaybe<UserUpsertWithoutProductDuplicatesAssignedInput>;
};

export type UserUpdateWithoutBatchCreatedInput = {
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutBatchUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutDishCreatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutDishUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutEventLogCreatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutEventLogUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutIngredientCreatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutIngredientLogCreatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutIngredientLogUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutIngredientUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutKitchenCreatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutKitchenUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutKitchensInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLookupCreatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLookupTypeCreatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLookupTypeUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLookupUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutMenuCreatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutMenuUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutProductCreatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutProductDuplicatesAssignedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutProductDuplicatesUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutProductMergeCreatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutProductMergeUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutProductUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPurchaseOrderCreatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPurchaseOrderUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutRecipeCreatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutRecipeUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSupplierCreatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSupplierUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutUnitCreatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitUpdated?: InputMaybe<UnitUpdateManyWithoutUpdatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutUnitUpdatedInput = {
  batchCreated?: InputMaybe<BatchUpdateManyWithoutCreatedByInput>;
  batchUpdated?: InputMaybe<BatchUpdateManyWithoutUpdatedByInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dishCreated?: InputMaybe<DishUpdateManyWithoutCreatedByInput>;
  dishUpdated?: InputMaybe<DishUpdateManyWithoutUpdatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  eventLogCreated?: InputMaybe<EventLogUpdateManyWithoutCreatedByInput>;
  eventLogUpdated?: InputMaybe<EventLogUpdateManyWithoutUpdatedByInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ingredientCreated?: InputMaybe<IngredientUpdateManyWithoutCreatedByInput>;
  ingredientLogCreated?: InputMaybe<IngredientLogUpdateManyWithoutCreatedByInput>;
  ingredientLogUpdated?: InputMaybe<IngredientLogUpdateManyWithoutUpdatedByInput>;
  ingredientUpdated?: InputMaybe<IngredientUpdateManyWithoutUpdatedByInput>;
  kitchenCreated?: InputMaybe<KitchenUpdateManyWithoutCreatedByInput>;
  kitchenUpdated?: InputMaybe<KitchenUpdateManyWithoutUpdatedByInput>;
  kitchens?: InputMaybe<KitchenToUserUpdateManyWithoutUserInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  menuCreated?: InputMaybe<MenuUpdateManyWithoutCreatedByInput>;
  menuUpdated?: InputMaybe<MenuUpdateManyWithoutUpdatedByInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCodeExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberNational?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productCreated?: InputMaybe<ProductUpdateManyWithoutCreatedByInput>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesUpdateManyWithoutAssignedToInput>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesUpdateManyWithoutUpdatedByInput>;
  productMergeCreated?: InputMaybe<ProductMergeUpdateManyWithoutCreatedByInput>;
  productMergeUpdated?: InputMaybe<ProductMergeUpdateManyWithoutUpdatedByInput>;
  productUpdated?: InputMaybe<ProductUpdateManyWithoutUpdatedByInput>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderUpdateManyWithoutCreatedByInput>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderUpdateManyWithoutUpdatedByInput>;
  recipeCreated?: InputMaybe<RecipeUpdateManyWithoutCreatedByInput>;
  recipeUpdated?: InputMaybe<RecipeUpdateManyWithoutUpdatedByInput>;
  refreshTokens?: InputMaybe<UserUpdaterefreshTokensInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  supplierCreated?: InputMaybe<SupplierUpdateManyWithoutCreatedByInput>;
  supplierUpdated?: InputMaybe<SupplierUpdateManyWithoutUpdatedByInput>;
  type?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  unitCreated?: InputMaybe<UnitUpdateManyWithoutCreatedByInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserUpdaterefreshTokensInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UserUpsertWithoutBatchCreatedInput = {
  create: UserCreateWithoutBatchCreatedInput;
  update: UserUpdateWithoutBatchCreatedInput;
};

export type UserUpsertWithoutBatchUpdatedInput = {
  create: UserCreateWithoutBatchUpdatedInput;
  update: UserUpdateWithoutBatchUpdatedInput;
};

export type UserUpsertWithoutDishCreatedInput = {
  create: UserCreateWithoutDishCreatedInput;
  update: UserUpdateWithoutDishCreatedInput;
};

export type UserUpsertWithoutDishUpdatedInput = {
  create: UserCreateWithoutDishUpdatedInput;
  update: UserUpdateWithoutDishUpdatedInput;
};

export type UserUpsertWithoutEventLogCreatedInput = {
  create: UserCreateWithoutEventLogCreatedInput;
  update: UserUpdateWithoutEventLogCreatedInput;
};

export type UserUpsertWithoutEventLogUpdatedInput = {
  create: UserCreateWithoutEventLogUpdatedInput;
  update: UserUpdateWithoutEventLogUpdatedInput;
};

export type UserUpsertWithoutIngredientCreatedInput = {
  create: UserCreateWithoutIngredientCreatedInput;
  update: UserUpdateWithoutIngredientCreatedInput;
};

export type UserUpsertWithoutIngredientLogCreatedInput = {
  create: UserCreateWithoutIngredientLogCreatedInput;
  update: UserUpdateWithoutIngredientLogCreatedInput;
};

export type UserUpsertWithoutIngredientLogUpdatedInput = {
  create: UserCreateWithoutIngredientLogUpdatedInput;
  update: UserUpdateWithoutIngredientLogUpdatedInput;
};

export type UserUpsertWithoutIngredientUpdatedInput = {
  create: UserCreateWithoutIngredientUpdatedInput;
  update: UserUpdateWithoutIngredientUpdatedInput;
};

export type UserUpsertWithoutKitchenCreatedInput = {
  create: UserCreateWithoutKitchenCreatedInput;
  update: UserUpdateWithoutKitchenCreatedInput;
};

export type UserUpsertWithoutKitchenUpdatedInput = {
  create: UserCreateWithoutKitchenUpdatedInput;
  update: UserUpdateWithoutKitchenUpdatedInput;
};

export type UserUpsertWithoutKitchensInput = {
  create: UserCreateWithoutKitchensInput;
  update: UserUpdateWithoutKitchensInput;
};

export type UserUpsertWithoutLookupCreatedInput = {
  create: UserCreateWithoutLookupCreatedInput;
  update: UserUpdateWithoutLookupCreatedInput;
};

export type UserUpsertWithoutLookupTypeCreatedInput = {
  create: UserCreateWithoutLookupTypeCreatedInput;
  update: UserUpdateWithoutLookupTypeCreatedInput;
};

export type UserUpsertWithoutLookupTypeUpdatedInput = {
  create: UserCreateWithoutLookupTypeUpdatedInput;
  update: UserUpdateWithoutLookupTypeUpdatedInput;
};

export type UserUpsertWithoutLookupUpdatedInput = {
  create: UserCreateWithoutLookupUpdatedInput;
  update: UserUpdateWithoutLookupUpdatedInput;
};

export type UserUpsertWithoutMenuCreatedInput = {
  create: UserCreateWithoutMenuCreatedInput;
  update: UserUpdateWithoutMenuCreatedInput;
};

export type UserUpsertWithoutMenuUpdatedInput = {
  create: UserCreateWithoutMenuUpdatedInput;
  update: UserUpdateWithoutMenuUpdatedInput;
};

export type UserUpsertWithoutProductCreatedInput = {
  create: UserCreateWithoutProductCreatedInput;
  update: UserUpdateWithoutProductCreatedInput;
};

export type UserUpsertWithoutProductDuplicatesAssignedInput = {
  create: UserCreateWithoutProductDuplicatesAssignedInput;
  update: UserUpdateWithoutProductDuplicatesAssignedInput;
};

export type UserUpsertWithoutProductDuplicatesUpdatedInput = {
  create: UserCreateWithoutProductDuplicatesUpdatedInput;
  update: UserUpdateWithoutProductDuplicatesUpdatedInput;
};

export type UserUpsertWithoutProductMergeCreatedInput = {
  create: UserCreateWithoutProductMergeCreatedInput;
  update: UserUpdateWithoutProductMergeCreatedInput;
};

export type UserUpsertWithoutProductMergeUpdatedInput = {
  create: UserCreateWithoutProductMergeUpdatedInput;
  update: UserUpdateWithoutProductMergeUpdatedInput;
};

export type UserUpsertWithoutProductUpdatedInput = {
  create: UserCreateWithoutProductUpdatedInput;
  update: UserUpdateWithoutProductUpdatedInput;
};

export type UserUpsertWithoutPurchaseOrderCreatedInput = {
  create: UserCreateWithoutPurchaseOrderCreatedInput;
  update: UserUpdateWithoutPurchaseOrderCreatedInput;
};

export type UserUpsertWithoutPurchaseOrderUpdatedInput = {
  create: UserCreateWithoutPurchaseOrderUpdatedInput;
  update: UserUpdateWithoutPurchaseOrderUpdatedInput;
};

export type UserUpsertWithoutRecipeCreatedInput = {
  create: UserCreateWithoutRecipeCreatedInput;
  update: UserUpdateWithoutRecipeCreatedInput;
};

export type UserUpsertWithoutRecipeUpdatedInput = {
  create: UserCreateWithoutRecipeUpdatedInput;
  update: UserUpdateWithoutRecipeUpdatedInput;
};

export type UserUpsertWithoutSupplierCreatedInput = {
  create: UserCreateWithoutSupplierCreatedInput;
  update: UserUpdateWithoutSupplierCreatedInput;
};

export type UserUpsertWithoutSupplierUpdatedInput = {
  create: UserCreateWithoutSupplierUpdatedInput;
  update: UserUpdateWithoutSupplierUpdatedInput;
};

export type UserUpsertWithoutUnitCreatedInput = {
  create: UserCreateWithoutUnitCreatedInput;
  update: UserUpdateWithoutUnitCreatedInput;
};

export type UserUpsertWithoutUnitUpdatedInput = {
  create: UserCreateWithoutUnitUpdatedInput;
  update: UserUpdateWithoutUnitUpdatedInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  batchCreated?: InputMaybe<BatchListRelationFilter>;
  batchUpdated?: InputMaybe<BatchListRelationFilter>;
  countryCode?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  dishCreated?: InputMaybe<DishListRelationFilter>;
  dishUpdated?: InputMaybe<DishListRelationFilter>;
  email?: InputMaybe<StringNullableFilter>;
  eventLogCreated?: InputMaybe<EventLogListRelationFilter>;
  eventLogUpdated?: InputMaybe<EventLogListRelationFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  ingredientCreated?: InputMaybe<IngredientListRelationFilter>;
  ingredientLogCreated?: InputMaybe<IngredientLogListRelationFilter>;
  ingredientLogUpdated?: InputMaybe<IngredientLogListRelationFilter>;
  ingredientUpdated?: InputMaybe<IngredientListRelationFilter>;
  kitchenCreated?: InputMaybe<KitchenListRelationFilter>;
  kitchenUpdated?: InputMaybe<KitchenListRelationFilter>;
  kitchens?: InputMaybe<KitchenToUserListRelationFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  menuCreated?: InputMaybe<MenuListRelationFilter>;
  menuUpdated?: InputMaybe<MenuListRelationFilter>;
  password?: InputMaybe<StringNullableFilter>;
  passwordResetCode?: InputMaybe<StringNullableFilter>;
  passwordResetCodeExpiresAt?: InputMaybe<DateTimeNullableFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  phoneNumberNational?: InputMaybe<StringNullableFilter>;
  productCreated?: InputMaybe<ProductListRelationFilter>;
  productDuplicatesAssigned?: InputMaybe<ProductDuplicatesListRelationFilter>;
  productDuplicatesUpdated?: InputMaybe<ProductDuplicatesListRelationFilter>;
  productMergeCreated?: InputMaybe<ProductMergeListRelationFilter>;
  productMergeUpdated?: InputMaybe<ProductMergeListRelationFilter>;
  productUpdated?: InputMaybe<ProductListRelationFilter>;
  purchaseOrderCreated?: InputMaybe<PurchaseOrderListRelationFilter>;
  purchaseOrderUpdated?: InputMaybe<PurchaseOrderListRelationFilter>;
  recipeCreated?: InputMaybe<RecipeListRelationFilter>;
  recipeUpdated?: InputMaybe<RecipeListRelationFilter>;
  refreshTokens?: InputMaybe<StringNullableListFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  supplierCreated?: InputMaybe<SupplierListRelationFilter>;
  supplierUpdated?: InputMaybe<SupplierListRelationFilter>;
  type?: InputMaybe<EnumUserRoleFilter>;
  unitCreated?: InputMaybe<UnitListRelationFilter>;
  unitUpdated?: InputMaybe<UnitListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumberNational_countryCode?: InputMaybe<UserPhoneNumberNationalCountryCodeCompoundUniqueInput>;
};

export type KitchenAvailableFeaturesQueryVariables = Exact<{
  cursor: Scalars['ID'];
}>;


export type KitchenAvailableFeaturesQuery = { __typename?: 'Query', kitchenNode?: { __typename?: 'Kitchen', _cursor: string, id: number, featureGuards: { __typename?: 'FeatureGuards', availableFeatures?: Array<string | null> | null } } | null };

export type BasicSupplierFragment = { __typename?: 'Supplier', _cursor: string, id: number, status: Status, name: string, email?: string | null, phone?: string | null, notes?: string | null, deliveryDays?: any | null };

export type GetSupplierQueryVariables = Exact<{
  kitchenId: Scalars['Int'];
  supplierId?: InputMaybe<Scalars['Int']>;
}>;


export type GetSupplierQuery = { __typename?: 'Query', supplier?: { __typename?: 'Supplier', _cursor: string, id: number, status: Status, name: string, email?: string | null, phone?: string | null, notes?: string | null, deliveryDays?: any | null, kitchenDetails: Array<{ __typename?: 'KitchenSupplierDetail', _cursor: string, id: number, type: KitchenSupplierDataType, value: string }> } | null };

export type FuzzySearchProductListQueryVariables = Exact<{
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['ID']>;
  supplierCursor: Scalars['ID'];
  kitchenCursor?: InputMaybe<Scalars['ID']>;
  kitchenId?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
}>;


export type FuzzySearchProductListQuery = { __typename?: 'Query', fuzzySearchProductList: { __typename?: 'ProductFuzzyConnection', recentCount: number, kitchenCount: number, catalogueCount: number, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor?: string | null, endCursor?: string | null, skip: number, take: number, currentPage?: number | null, totalCount?: number | null, totalPages?: number | null }, nodes: Array<{ __typename?: 'Product', _cursor: string, category?: string | null, id: number, code?: string | null, packSize: number, unitValue: number, status: ProductStatus, name: string, unit: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType }, ingredients: Array<{ __typename?: 'Ingredient', _cursor: string, id: number, price?: number | null }> }> } };

export type PriceChangeBaseFragment = { __typename?: 'PriceChange', _cursor: string, id: number, price: number, supplierName: string, ingredientName: string, percentageChange: number };

export type PriceChangesBaseFragment = { __typename?: 'PriceChangesOverview', total: number, priceChanges: Array<{ __typename?: 'PriceChanges', date: any, changes: Array<{ __typename?: 'PriceChange', _cursor: string, id: number, price: number, supplierName: string, ingredientName: string, percentageChange: number } | null> } | null> };

export type GetPriceChangesQueryVariables = Exact<{
  cursor: Scalars['ID'];
}>;


export type GetPriceChangesQuery = { __typename?: 'Query', ingredientNode?: { __typename?: 'Ingredient', _cursor: string, id: number, price?: number | null, createdAt?: any | null, updatedAt?: any | null, wastage: number, unitPrice?: number | null, wasteAdjustedUnitPrice?: number | null, conversionUnit?: number | null, conversionUnitValue?: number | null, conversionUnitType?: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType, gramsPerUnit?: number | null } | null, priceChangesList: { __typename?: 'PriceChangesOverview', total: number, priceChanges: Array<{ __typename?: 'PriceChanges', date: any, changes: Array<{ __typename?: 'PriceChange', _cursor: string, id: number, price: number, supplierName: string, ingredientName: string, percentageChange: number } | null> } | null> }, product: { __typename?: 'Product', _cursor: string, code?: string | null, id: number, status: ProductStatus, name: string, packSize: number, unitValue: number, unit: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType }, supplier: { __typename?: 'Supplier', _cursor: string, id: number, name: string } } } | null };

export type InvoiceBaseFragment = { __typename?: 'Invoice', images: Array<string>, signedImages: Array<string>, _cursor: string, id: number, invoiceDate?: any | null, invoiceId?: string | null, invoiceTotal?: number | null, vat?: number | null, createdAt?: any | null, supplier: { __typename?: 'Supplier', _cursor: string, id: number, name: string }, createdBy: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null }, updatedBy: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null } };

export type PendingInvoiceBaseFragment = { __typename?: 'PendingInvoice', createdAt?: any | null, _cursor: string, id: number, images: Array<string>, signedImages: Array<string>, updatedAt?: any | null, status: InvoiceStatus, rejectionReason: Array<InvoiceRejectionReason>, rejectionReasonNotes?: string | null, createdBy: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null }, updatedBy: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null }, invoice?: { __typename?: 'Invoice', images: Array<string>, signedImages: Array<string>, _cursor: string, id: number, invoiceDate?: any | null, invoiceId?: string | null, invoiceTotal?: number | null, vat?: number | null, createdAt?: any | null, supplier: { __typename?: 'Supplier', _cursor: string, id: number, name: string }, createdBy: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null }, updatedBy: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null } } | null };

export type InvoiceEntryBaseFragment = { __typename?: 'InvoiceEntry', _cursor: string, id: number, packSize: number, price: number, quantity: number, productCode?: string | null, unitValue: number, unit: { __typename?: 'Unit', _cursor: string, id: number, name: string }, product?: { __typename?: 'Product', _cursor: string, id: number, name: string } | null };

export type InvoiceWithPendingInvoiceFragment = { __typename?: 'Invoice', _cursor: string, id: number, approved: boolean, approvedAt?: any | null, invoiceId?: string | null, invoiceDate?: any | null, invoiceTotal?: number | null, isAuto: boolean, vat?: number | null, images: Array<string>, signedImages: Array<string>, createdAt?: any | null, downloadImages: Array<string>, pendingInvoice?: { __typename?: 'PendingInvoice', _cursor: string, id: number, isAuto: boolean, rejectionReason: Array<InvoiceRejectionReason>, rejectionReasonNotes?: string | null, status: InvoiceStatus, createdAt?: any | null, createdBy: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null } } | null, approvedBy?: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null } | null, entries: Array<{ __typename?: 'InvoiceEntry', _cursor: string, id: number, packSize: number, price: number, quantity: number, productCode?: string | null, unitValue: number, unit: { __typename?: 'Unit', _cursor: string, id: number, name: string }, product?: { __typename?: 'Product', _cursor: string, id: number, name: string } | null }>, supplier: { __typename?: 'Supplier', _cursor: string, id: number, name: string }, kitchen: { __typename?: 'Kitchen', _cursor: string, id: number, name: string }, createdBy: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null } };

export type DeleteOneInvoiceMutationVariables = Exact<{
  data: DeleteOneInvoiceInputObject;
}>;


export type DeleteOneInvoiceMutation = { __typename?: 'Mutation', deleteOneInvoice?: { __typename?: 'Invoice', _cursor: string, id: number } | null };

export type ApproveOneInvoiceMutationVariables = Exact<{
  id: Scalars['Int'];
  approved: Scalars['Boolean'];
}>;


export type ApproveOneInvoiceMutation = { __typename?: 'Mutation', approveOneInvoice: { __typename?: 'Invoice', _cursor: string, id: number, approved: boolean, approvedAt?: any | null, invoiceId?: string | null, invoiceDate?: any | null, invoiceTotal?: number | null, isAuto: boolean, vat?: number | null, images: Array<string>, signedImages: Array<string>, createdAt?: any | null, downloadImages: Array<string>, pendingInvoice?: { __typename?: 'PendingInvoice', _cursor: string, id: number, isAuto: boolean, rejectionReason: Array<InvoiceRejectionReason>, rejectionReasonNotes?: string | null, status: InvoiceStatus, createdAt?: any | null, createdBy: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null } } | null, approvedBy?: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null } | null, entries: Array<{ __typename?: 'InvoiceEntry', _cursor: string, id: number, packSize: number, price: number, quantity: number, productCode?: string | null, unitValue: number, unit: { __typename?: 'Unit', _cursor: string, id: number, name: string }, product?: { __typename?: 'Product', _cursor: string, id: number, name: string } | null }>, supplier: { __typename?: 'Supplier', _cursor: string, id: number, name: string }, kitchen: { __typename?: 'Kitchen', _cursor: string, id: number, name: string }, createdBy: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null } } };

export type GetInvoiceByIdQueryVariables = Exact<{
  where: InvoiceWhereUniqueInput;
}>;


export type GetInvoiceByIdQuery = { __typename?: 'Query', invoice?: { __typename?: 'Invoice', _cursor: string, id: number, approved: boolean, approvedAt?: any | null, invoiceId?: string | null, invoiceDate?: any | null, invoiceTotal?: number | null, isAuto: boolean, vat?: number | null, images: Array<string>, signedImages: Array<string>, createdAt?: any | null, downloadImages: Array<string>, pendingInvoice?: { __typename?: 'PendingInvoice', _cursor: string, id: number, isAuto: boolean, rejectionReason: Array<InvoiceRejectionReason>, rejectionReasonNotes?: string | null, status: InvoiceStatus, createdAt?: any | null, createdBy: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null } } | null, approvedBy?: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null } | null, entries: Array<{ __typename?: 'InvoiceEntry', _cursor: string, id: number, packSize: number, price: number, quantity: number, productCode?: string | null, unitValue: number, unit: { __typename?: 'Unit', _cursor: string, id: number, name: string }, product?: { __typename?: 'Product', _cursor: string, id: number, name: string } | null }>, supplier: { __typename?: 'Supplier', _cursor: string, id: number, name: string }, kitchen: { __typename?: 'Kitchen', _cursor: string, id: number, name: string }, createdBy: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null } } | null };

export type GetDownloadQueryVariables = Exact<{
  files: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetDownloadQuery = { __typename?: 'Query', zip?: string | null };

export type UnitFragmentFragment = { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType };

export type BasicIngredientFragmentFragment = { __typename?: 'Ingredient', _cursor: string, id: number, price?: number | null, wastage: number, conversionUnit?: number | null, unitPrice?: number | null, wasteAdjustedUnitPrice?: number | null, updatedAt?: any | null, conversionUnitValue?: number | null, conversionUnitType?: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType, gramsPerUnit?: number | null } | null, product: { __typename?: 'Product', _cursor: string, id: number, code?: string | null, name: string, packSize: number, unitValue: number, price?: number | null, supplier: { __typename?: 'Supplier', _cursor: string, id: number, name: string }, unit: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType } } };

export type BasicRecipeFragmentFragment = { __typename?: 'Recipe', _cursor: string, id: number, isUsed: boolean, amount?: number | null, name: string, unitCost?: number | null, updatedAt?: any | null, unit?: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType } | null };

export type StockEntryFragmentFragment = { __typename?: 'StockEntry', _cursor: string, id: number, recipeId?: number | null, unitId: number, price?: number | null, total?: number | null, quantity?: number | null, ingredientId?: number | null, unit: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType }, ingredient?: { __typename?: 'Ingredient', _cursor: string, id: number, price?: number | null, wastage: number, conversionUnit?: number | null, unitPrice?: number | null, wasteAdjustedUnitPrice?: number | null, updatedAt?: any | null, conversionUnitValue?: number | null, conversionUnitType?: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType, gramsPerUnit?: number | null } | null, product: { __typename?: 'Product', _cursor: string, id: number, code?: string | null, name: string, packSize: number, unitValue: number, price?: number | null, supplier: { __typename?: 'Supplier', _cursor: string, id: number, name: string }, unit: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType } } } | null, recipe?: { __typename?: 'Recipe', _cursor: string, id: number, isUsed: boolean, amount?: number | null, name: string, unitCost?: number | null, updatedAt?: any | null, unit?: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType } | null } | null };

export type StockFragmentFragment = { __typename?: 'Stock', _cursor: string, id: number, date: any, name?: string | null, total: number, updatedAt: any };

export type GetStockQueryVariables = Exact<{
  cursor: Scalars['ID'];
}>;


export type GetStockQuery = { __typename?: 'Query', stockNode?: { __typename?: 'Stock', _cursor: string, id: number, date: any, name?: string | null, total: number, updatedAt: any, entryList: { __typename?: 'StockEntryConnection', nodes: Array<{ __typename?: 'StockEntry', _cursor: string, id: number, recipeId?: number | null, unitId: number, price?: number | null, total?: number | null, quantity?: number | null, ingredientId?: number | null, unit: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType }, ingredient?: { __typename?: 'Ingredient', _cursor: string, id: number, price?: number | null, wastage: number, conversionUnit?: number | null, unitPrice?: number | null, wasteAdjustedUnitPrice?: number | null, updatedAt?: any | null, conversionUnitValue?: number | null, conversionUnitType?: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType, gramsPerUnit?: number | null } | null, product: { __typename?: 'Product', _cursor: string, id: number, code?: string | null, name: string, packSize: number, unitValue: number, price?: number | null, supplier: { __typename?: 'Supplier', _cursor: string, id: number, name: string }, unit: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType } } } | null, recipe?: { __typename?: 'Recipe', _cursor: string, id: number, isUsed: boolean, amount?: number | null, name: string, unitCost?: number | null, updatedAt?: any | null, unit?: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType } | null } | null }> } } | null };

export type GetBareStockQueryVariables = Exact<{
  cursor: Scalars['ID'];
}>;


export type GetBareStockQuery = { __typename?: 'Query', stockNode?: { __typename?: 'Stock', _cursor: string, id: number, date: any, name?: string | null, total: number, updatedAt: any, updatedBy: { __typename?: 'User', _cursor: string, id: number, firstName?: string | null, lastName?: string | null } } | null };

export type StockListQueryVariables = Exact<{
  kitchenId: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StockWhereInput>;
}>;


export type StockListQuery = { __typename?: 'Query', stockList: { __typename?: 'StockConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, nodes: Array<{ __typename?: 'Stock', _cursor: string, id: number, date: any, name?: string | null, total: number, updatedAt: any }> } };

export type UpdateOneStockMutationVariables = Exact<{
  data: UpdateOneStockInputObject;
}>;


export type UpdateOneStockMutation = { __typename?: 'Mutation', updateOneStock: { __typename?: 'Stock', _cursor: string, id: number, date: any, name?: string | null, total: number, updatedAt: any } };

export type GetKitchenPermissionsQueryVariables = Exact<{
  where: KitchenWhereUniqueInput;
}>;


export type GetKitchenPermissionsQuery = { __typename?: 'Query', kitchen?: { __typename?: 'Kitchen', _cursor: string, id: number, permissions?: { __typename?: 'KitchenPermissions', foodFlash: boolean, ordering: boolean, stock: boolean, requests?: any | null } | null } | null };

export type RequestKitchenPermissionsMutationVariables = Exact<{
  kitchenId: Scalars['Int'];
}>;


export type RequestKitchenPermissionsMutation = { __typename?: 'Mutation', requestKitchenPermissions: { __typename?: 'Kitchen', _cursor: string, id: number, permissions?: { __typename?: 'KitchenPermissions', foodFlash: boolean, ordering: boolean, stock: boolean, requests?: any | null } | null } };

export type DeleteOneStockMutationVariables = Exact<{
  data: DeleteOneStockInputObject;
}>;


export type DeleteOneStockMutation = { __typename?: 'Mutation', deleteOneStock: { __typename?: 'Stock', _cursor: string, id: number } };

export type CreateOneStockMutationVariables = Exact<{
  data: CreateOneStockInputObject;
}>;


export type CreateOneStockMutation = { __typename?: 'Mutation', createOneStock: { __typename?: 'Stock', _cursor: string, id: number, date: any, name?: string | null, total: number, updatedAt: any } };

export type GetFullKitchenQueryVariables = Exact<{
  kitchenId: Scalars['Int'];
  time: Scalars['DateTime'];
}>;


export type GetFullKitchenQuery = { __typename?: 'Query', ingredientList: { __typename?: 'IngredientConnection', nodes: Array<{ __typename?: 'Ingredient', _cursor: string, id: number, price?: number | null, wastage: number, conversionUnit?: number | null, unitPrice?: number | null, wasteAdjustedUnitPrice?: number | null, updatedAt?: any | null, conversionUnitValue?: number | null, conversionUnitType?: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType, gramsPerUnit?: number | null } | null, product: { __typename?: 'Product', _cursor: string, id: number, code?: string | null, name: string, packSize: number, unitValue: number, price?: number | null, supplier: { __typename?: 'Supplier', _cursor: string, id: number, name: string }, unit: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType } } }> }, recipeList: { __typename?: 'RecipeConnection', nodes: Array<{ __typename?: 'Recipe', _cursor: string, id: number, isUsed: boolean, amount?: number | null, name: string, unitCost?: number | null, updatedAt?: any | null, unit?: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType } | null }> } };

export type GetStockNodeQueryVariables = Exact<{
  cursor: Scalars['ID'];
}>;


export type GetStockNodeQuery = { __typename?: 'Query', stockNode?: { __typename?: 'Stock', _cursor: string, id: number, entryList: { __typename?: 'StockEntryConnection', nodes: Array<{ __typename?: 'StockEntry', _cursor: string, id: number, recipeId?: number | null, unitId: number, price?: number | null, total?: number | null, quantity?: number | null, ingredientId?: number | null, unit: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType }, ingredient?: { __typename?: 'Ingredient', _cursor: string, id: number, price?: number | null, wastage: number, conversionUnit?: number | null, unitPrice?: number | null, wasteAdjustedUnitPrice?: number | null, updatedAt?: any | null, conversionUnitValue?: number | null, conversionUnitType?: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType, gramsPerUnit?: number | null } | null, product: { __typename?: 'Product', _cursor: string, id: number, code?: string | null, name: string, packSize: number, unitValue: number, price?: number | null, supplier: { __typename?: 'Supplier', _cursor: string, id: number, name: string }, unit: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType } } } | null, recipe?: { __typename?: 'Recipe', _cursor: string, id: number, isUsed: boolean, amount?: number | null, name: string, unitCost?: number | null, updatedAt?: any | null, unit?: { __typename?: 'Unit', _cursor: string, id: number, name: string, type: UnitType } | null } | null }> } } | null };

export const BasicSupplierFragmentDoc = gql`
    fragment BasicSupplier on Supplier {
  _cursor
  id
  status
  name
  email
  phone
  notes
  deliveryDays
}
    `;
export const PriceChangeBaseFragmentDoc = gql`
    fragment PriceChangeBase on PriceChange {
  _cursor
  id
  price
  supplierName
  ingredientName
  percentageChange
}
    `;
export const PriceChangesBaseFragmentDoc = gql`
    fragment PriceChangesBase on PriceChangesOverview {
  total
  priceChanges {
    changes {
      ...PriceChangeBase
    }
    date
  }
}
    ${PriceChangeBaseFragmentDoc}`;
export const InvoiceBaseFragmentDoc = gql`
    fragment InvoiceBase on Invoice {
  images
  signedImages(download: false)
  _cursor
  id
  supplier {
    _cursor
    id
    name
  }
  createdBy {
    _cursor
    id
    firstName
    lastName
  }
  updatedBy {
    _cursor
    id
    firstName
    lastName
  }
  invoiceDate
  invoiceId
  invoiceTotal
  vat
  createdAt
}
    `;
export const PendingInvoiceBaseFragmentDoc = gql`
    fragment PendingInvoiceBase on PendingInvoice {
  createdAt
  createdBy {
    _cursor
    id
    firstName
    lastName
  }
  _cursor
  id
  images
  signedImages(download: false)
  updatedAt
  updatedBy {
    _cursor
    id
    firstName
    lastName
  }
  status
  rejectionReason
  rejectionReasonNotes
  invoice {
    ...InvoiceBase
  }
}
    ${InvoiceBaseFragmentDoc}`;
export const InvoiceEntryBaseFragmentDoc = gql`
    fragment InvoiceEntryBase on InvoiceEntry {
  _cursor
  id
  packSize
  price
  quantity
  productCode
  unitValue
  unit {
    _cursor
    id
    name
  }
  product {
    _cursor
    id
    name
  }
}
    `;
export const InvoiceWithPendingInvoiceFragmentDoc = gql`
    fragment InvoiceWithPendingInvoice on Invoice {
  pendingInvoice {
    _cursor
    id
    isAuto
    rejectionReason
    rejectionReasonNotes
    isAuto
    status
    createdAt
    createdBy {
      _cursor
      id
      firstName
      lastName
    }
  }
  _cursor
  id
  approved
  approvedAt
  approvedBy {
    _cursor
    id
    firstName
    lastName
  }
  invoiceId
  invoiceDate
  invoiceTotal
  isAuto
  vat
  images
  signedImages
  downloadImages: signedImages(download: true)
  entries(skip: 0, take: 1000) {
    ...InvoiceEntryBase
  }
  supplier {
    _cursor
    id
    name
  }
  kitchen {
    _cursor
    id
    name
  }
  createdAt
  createdBy {
    _cursor
    id
    firstName
    lastName
  }
}
    ${InvoiceEntryBaseFragmentDoc}`;
export const UnitFragmentFragmentDoc = gql`
    fragment UnitFragment on Unit {
  _cursor
  id
  name
  type
}
    `;
export const BasicIngredientFragmentFragmentDoc = gql`
    fragment BasicIngredientFragment on Ingredient {
  _cursor
  id
  price
  wastage
  conversionUnit
  unitPrice
  wasteAdjustedUnitPrice
  updatedAt
  conversionUnitType {
    _cursor
    id
    name
    type
    gramsPerUnit
  }
  conversionUnitValue
  product {
    _cursor
    id
    code
    name
    packSize
    unitValue
    price
    supplier {
      _cursor
      id
      name
    }
    unit {
      ...UnitFragment
    }
  }
}
    ${UnitFragmentFragmentDoc}`;
export const BasicRecipeFragmentFragmentDoc = gql`
    fragment BasicRecipeFragment on Recipe {
  _cursor
  id
  isUsed
  amount
  name
  unitCost
  updatedAt
  unit {
    ...UnitFragment
  }
}
    ${UnitFragmentFragmentDoc}`;
export const StockEntryFragmentFragmentDoc = gql`
    fragment StockEntryFragment on StockEntry {
  _cursor
  id
  recipeId
  unitId
  price
  total
  quantity
  ingredientId
  unit {
    ...UnitFragment
  }
  ingredient {
    ...BasicIngredientFragment
  }
  recipe {
    ...BasicRecipeFragment
  }
}
    ${UnitFragmentFragmentDoc}
${BasicIngredientFragmentFragmentDoc}
${BasicRecipeFragmentFragmentDoc}`;
export const StockFragmentFragmentDoc = gql`
    fragment StockFragment on Stock {
  _cursor
  id
  date
  name
  total
  updatedAt
}
    `;
export const KitchenAvailableFeaturesDocument = gql`
    query kitchenAvailableFeatures($cursor: ID!) {
  kitchenNode(cursor: $cursor) {
    _cursor
    id
    featureGuards {
      availableFeatures
    }
  }
}
    `;

/**
 * __useKitchenAvailableFeaturesQuery__
 *
 * To run a query within a React component, call `useKitchenAvailableFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenAvailableFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenAvailableFeaturesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useKitchenAvailableFeaturesQuery(baseOptions: Apollo.QueryHookOptions<KitchenAvailableFeaturesQuery, KitchenAvailableFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenAvailableFeaturesQuery, KitchenAvailableFeaturesQueryVariables>(KitchenAvailableFeaturesDocument, options);
      }
export function useKitchenAvailableFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenAvailableFeaturesQuery, KitchenAvailableFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenAvailableFeaturesQuery, KitchenAvailableFeaturesQueryVariables>(KitchenAvailableFeaturesDocument, options);
        }
export type KitchenAvailableFeaturesQueryHookResult = ReturnType<typeof useKitchenAvailableFeaturesQuery>;
export type KitchenAvailableFeaturesLazyQueryHookResult = ReturnType<typeof useKitchenAvailableFeaturesLazyQuery>;
export type KitchenAvailableFeaturesQueryResult = Apollo.QueryResult<KitchenAvailableFeaturesQuery, KitchenAvailableFeaturesQueryVariables>;
export const GetSupplierDocument = gql`
    query getSupplier($kitchenId: Int!, $supplierId: Int) {
  supplier(where: {id: $supplierId}) {
    ...BasicSupplier
    kitchenDetails(
      where: {kitchenId: {equals: $kitchenId}, supplierId: {equals: $supplierId}}
    ) {
      _cursor
      id
      type
      value
    }
  }
}
    ${BasicSupplierFragmentDoc}`;

/**
 * __useGetSupplierQuery__
 *
 * To run a query within a React component, call `useGetSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierQuery({
 *   variables: {
 *      kitchenId: // value for 'kitchenId'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useGetSupplierQuery(baseOptions: Apollo.QueryHookOptions<GetSupplierQuery, GetSupplierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplierQuery, GetSupplierQueryVariables>(GetSupplierDocument, options);
      }
export function useGetSupplierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplierQuery, GetSupplierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplierQuery, GetSupplierQueryVariables>(GetSupplierDocument, options);
        }
export type GetSupplierQueryHookResult = ReturnType<typeof useGetSupplierQuery>;
export type GetSupplierLazyQueryHookResult = ReturnType<typeof useGetSupplierLazyQuery>;
export type GetSupplierQueryResult = Apollo.QueryResult<GetSupplierQuery, GetSupplierQueryVariables>;
export const FuzzySearchProductListDocument = gql`
    query fuzzySearchProductList($searchTerm: String, $skip: Int, $take: Int, $cursor: ID, $supplierCursor: ID!, $kitchenCursor: ID, $kitchenId: Int, $where: ProductWhereInput) {
  fuzzySearchProductList(
    cursor: $cursor
    supplierCursor: $supplierCursor
    kitchenCursor: $kitchenCursor
    searchTerm: $searchTerm
    skip: $skip
    take: $take
    where: $where
  ) {
    recentCount
    kitchenCount
    catalogueCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
      skip
      take
      currentPage
      totalCount
      totalPages
    }
    nodes {
      _cursor
      category
      id
      code
      packSize
      unitValue
      status
      name
      unit {
        _cursor
        id
        name
        type
      }
      ingredients(where: {kitchen: {id: {equals: $kitchenId}}}) {
        _cursor
        id
        price
      }
    }
  }
}
    `;

/**
 * __useFuzzySearchProductListQuery__
 *
 * To run a query within a React component, call `useFuzzySearchProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFuzzySearchProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFuzzySearchProductListQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      cursor: // value for 'cursor'
 *      supplierCursor: // value for 'supplierCursor'
 *      kitchenCursor: // value for 'kitchenCursor'
 *      kitchenId: // value for 'kitchenId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFuzzySearchProductListQuery(baseOptions: Apollo.QueryHookOptions<FuzzySearchProductListQuery, FuzzySearchProductListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FuzzySearchProductListQuery, FuzzySearchProductListQueryVariables>(FuzzySearchProductListDocument, options);
      }
export function useFuzzySearchProductListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FuzzySearchProductListQuery, FuzzySearchProductListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FuzzySearchProductListQuery, FuzzySearchProductListQueryVariables>(FuzzySearchProductListDocument, options);
        }
export type FuzzySearchProductListQueryHookResult = ReturnType<typeof useFuzzySearchProductListQuery>;
export type FuzzySearchProductListLazyQueryHookResult = ReturnType<typeof useFuzzySearchProductListLazyQuery>;
export type FuzzySearchProductListQueryResult = Apollo.QueryResult<FuzzySearchProductListQuery, FuzzySearchProductListQueryVariables>;
export const GetPriceChangesDocument = gql`
    query getPriceChanges($cursor: ID!) {
  ingredientNode(cursor: $cursor) {
    _cursor
    id
    price
    createdAt
    updatedAt
    price
    wastage
    unitPrice
    wasteAdjustedUnitPrice
    conversionUnit
    conversionUnitType {
      _cursor
      id
      name
      type
      gramsPerUnit
    }
    conversionUnitValue
    priceChangesList(skip: 0, take: 100) {
      ...PriceChangesBase
    }
    product {
      _cursor
      code
      id
      status
      name
      packSize
      unitValue
      unit {
        _cursor
        id
        name
        type
      }
      supplier {
        _cursor
        id
        name
      }
    }
  }
}
    ${PriceChangesBaseFragmentDoc}`;

/**
 * __useGetPriceChangesQuery__
 *
 * To run a query within a React component, call `useGetPriceChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriceChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriceChangesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetPriceChangesQuery(baseOptions: Apollo.QueryHookOptions<GetPriceChangesQuery, GetPriceChangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPriceChangesQuery, GetPriceChangesQueryVariables>(GetPriceChangesDocument, options);
      }
export function useGetPriceChangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPriceChangesQuery, GetPriceChangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPriceChangesQuery, GetPriceChangesQueryVariables>(GetPriceChangesDocument, options);
        }
export type GetPriceChangesQueryHookResult = ReturnType<typeof useGetPriceChangesQuery>;
export type GetPriceChangesLazyQueryHookResult = ReturnType<typeof useGetPriceChangesLazyQuery>;
export type GetPriceChangesQueryResult = Apollo.QueryResult<GetPriceChangesQuery, GetPriceChangesQueryVariables>;
export const DeleteOneInvoiceDocument = gql`
    mutation deleteOneInvoice($data: DeleteOneInvoiceInputObject!) {
  deleteOneInvoice(data: $data) {
    _cursor
    id
  }
}
    `;
export type DeleteOneInvoiceMutationFn = Apollo.MutationFunction<DeleteOneInvoiceMutation, DeleteOneInvoiceMutationVariables>;

/**
 * __useDeleteOneInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteOneInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneInvoiceMutation, { data, loading, error }] = useDeleteOneInvoiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteOneInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneInvoiceMutation, DeleteOneInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneInvoiceMutation, DeleteOneInvoiceMutationVariables>(DeleteOneInvoiceDocument, options);
      }
export type DeleteOneInvoiceMutationHookResult = ReturnType<typeof useDeleteOneInvoiceMutation>;
export type DeleteOneInvoiceMutationResult = Apollo.MutationResult<DeleteOneInvoiceMutation>;
export type DeleteOneInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteOneInvoiceMutation, DeleteOneInvoiceMutationVariables>;
export const ApproveOneInvoiceDocument = gql`
    mutation approveOneInvoice($id: Int!, $approved: Boolean!) {
  approveOneInvoice(id: $id, approved: $approved) {
    ...InvoiceWithPendingInvoice
  }
}
    ${InvoiceWithPendingInvoiceFragmentDoc}`;
export type ApproveOneInvoiceMutationFn = Apollo.MutationFunction<ApproveOneInvoiceMutation, ApproveOneInvoiceMutationVariables>;

/**
 * __useApproveOneInvoiceMutation__
 *
 * To run a mutation, you first call `useApproveOneInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveOneInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveOneInvoiceMutation, { data, loading, error }] = useApproveOneInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      approved: // value for 'approved'
 *   },
 * });
 */
export function useApproveOneInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<ApproveOneInvoiceMutation, ApproveOneInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveOneInvoiceMutation, ApproveOneInvoiceMutationVariables>(ApproveOneInvoiceDocument, options);
      }
export type ApproveOneInvoiceMutationHookResult = ReturnType<typeof useApproveOneInvoiceMutation>;
export type ApproveOneInvoiceMutationResult = Apollo.MutationResult<ApproveOneInvoiceMutation>;
export type ApproveOneInvoiceMutationOptions = Apollo.BaseMutationOptions<ApproveOneInvoiceMutation, ApproveOneInvoiceMutationVariables>;
export const GetInvoiceByIdDocument = gql`
    query getInvoiceById($where: InvoiceWhereUniqueInput!) {
  invoice(where: $where) {
    ...InvoiceWithPendingInvoice
  }
}
    ${InvoiceWithPendingInvoiceFragmentDoc}`;

/**
 * __useGetInvoiceByIdQuery__
 *
 * To run a query within a React component, call `useGetInvoiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceByIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetInvoiceByIdQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>(GetInvoiceByIdDocument, options);
      }
export function useGetInvoiceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>(GetInvoiceByIdDocument, options);
        }
export type GetInvoiceByIdQueryHookResult = ReturnType<typeof useGetInvoiceByIdQuery>;
export type GetInvoiceByIdLazyQueryHookResult = ReturnType<typeof useGetInvoiceByIdLazyQuery>;
export type GetInvoiceByIdQueryResult = Apollo.QueryResult<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>;
export const GetDownloadDocument = gql`
    query getDownload($files: [String!]!) {
  zip(files: $files)
}
    `;

/**
 * __useGetDownloadQuery__
 *
 * To run a query within a React component, call `useGetDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDownloadQuery({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useGetDownloadQuery(baseOptions: Apollo.QueryHookOptions<GetDownloadQuery, GetDownloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDownloadQuery, GetDownloadQueryVariables>(GetDownloadDocument, options);
      }
export function useGetDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDownloadQuery, GetDownloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDownloadQuery, GetDownloadQueryVariables>(GetDownloadDocument, options);
        }
export type GetDownloadQueryHookResult = ReturnType<typeof useGetDownloadQuery>;
export type GetDownloadLazyQueryHookResult = ReturnType<typeof useGetDownloadLazyQuery>;
export type GetDownloadQueryResult = Apollo.QueryResult<GetDownloadQuery, GetDownloadQueryVariables>;
export const GetStockDocument = gql`
    query getStock($cursor: ID!) {
  stockNode(cursor: $cursor) {
    ...StockFragment
    entryList(take: 9999) {
      nodes {
        ...StockEntryFragment
      }
    }
  }
}
    ${StockFragmentFragmentDoc}
${StockEntryFragmentFragmentDoc}`;

/**
 * __useGetStockQuery__
 *
 * To run a query within a React component, call `useGetStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetStockQuery(baseOptions: Apollo.QueryHookOptions<GetStockQuery, GetStockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStockQuery, GetStockQueryVariables>(GetStockDocument, options);
      }
export function useGetStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStockQuery, GetStockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStockQuery, GetStockQueryVariables>(GetStockDocument, options);
        }
export type GetStockQueryHookResult = ReturnType<typeof useGetStockQuery>;
export type GetStockLazyQueryHookResult = ReturnType<typeof useGetStockLazyQuery>;
export type GetStockQueryResult = Apollo.QueryResult<GetStockQuery, GetStockQueryVariables>;
export const GetBareStockDocument = gql`
    query getBareStock($cursor: ID!) {
  stockNode(cursor: $cursor) {
    ...StockFragment
    updatedBy {
      _cursor
      id
      firstName
      lastName
    }
  }
}
    ${StockFragmentFragmentDoc}`;

/**
 * __useGetBareStockQuery__
 *
 * To run a query within a React component, call `useGetBareStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBareStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBareStockQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetBareStockQuery(baseOptions: Apollo.QueryHookOptions<GetBareStockQuery, GetBareStockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBareStockQuery, GetBareStockQueryVariables>(GetBareStockDocument, options);
      }
export function useGetBareStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBareStockQuery, GetBareStockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBareStockQuery, GetBareStockQueryVariables>(GetBareStockDocument, options);
        }
export type GetBareStockQueryHookResult = ReturnType<typeof useGetBareStockQuery>;
export type GetBareStockLazyQueryHookResult = ReturnType<typeof useGetBareStockLazyQuery>;
export type GetBareStockQueryResult = Apollo.QueryResult<GetBareStockQuery, GetBareStockQueryVariables>;
export const StockListDocument = gql`
    query stockList($kitchenId: Int!, $take: Int = 1, $where: StockWhereInput) {
  stockList(
    kitchenId: $kitchenId
    orderBy: {date: desc}
    take: $take
    where: $where
  ) {
    pageInfo {
      hasNextPage
    }
    nodes {
      ...StockFragment
    }
  }
}
    ${StockFragmentFragmentDoc}`;

/**
 * __useStockListQuery__
 *
 * To run a query within a React component, call `useStockListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockListQuery({
 *   variables: {
 *      kitchenId: // value for 'kitchenId'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStockListQuery(baseOptions: Apollo.QueryHookOptions<StockListQuery, StockListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StockListQuery, StockListQueryVariables>(StockListDocument, options);
      }
export function useStockListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StockListQuery, StockListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StockListQuery, StockListQueryVariables>(StockListDocument, options);
        }
export type StockListQueryHookResult = ReturnType<typeof useStockListQuery>;
export type StockListLazyQueryHookResult = ReturnType<typeof useStockListLazyQuery>;
export type StockListQueryResult = Apollo.QueryResult<StockListQuery, StockListQueryVariables>;
export const UpdateOneStockDocument = gql`
    mutation updateOneStock($data: UpdateOneStockInputObject!) {
  updateOneStock(data: $data) {
    ...StockFragment
  }
}
    ${StockFragmentFragmentDoc}`;
export type UpdateOneStockMutationFn = Apollo.MutationFunction<UpdateOneStockMutation, UpdateOneStockMutationVariables>;

/**
 * __useUpdateOneStockMutation__
 *
 * To run a mutation, you first call `useUpdateOneStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneStockMutation, { data, loading, error }] = useUpdateOneStockMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneStockMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneStockMutation, UpdateOneStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneStockMutation, UpdateOneStockMutationVariables>(UpdateOneStockDocument, options);
      }
export type UpdateOneStockMutationHookResult = ReturnType<typeof useUpdateOneStockMutation>;
export type UpdateOneStockMutationResult = Apollo.MutationResult<UpdateOneStockMutation>;
export type UpdateOneStockMutationOptions = Apollo.BaseMutationOptions<UpdateOneStockMutation, UpdateOneStockMutationVariables>;
export const GetKitchenPermissionsDocument = gql`
    query getKitchenPermissions($where: KitchenWhereUniqueInput!) {
  kitchen(where: $where) {
    _cursor
    id
    permissions {
      foodFlash
      ordering
      stock
      requests
    }
  }
}
    `;

/**
 * __useGetKitchenPermissionsQuery__
 *
 * To run a query within a React component, call `useGetKitchenPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKitchenPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKitchenPermissionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetKitchenPermissionsQuery(baseOptions: Apollo.QueryHookOptions<GetKitchenPermissionsQuery, GetKitchenPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKitchenPermissionsQuery, GetKitchenPermissionsQueryVariables>(GetKitchenPermissionsDocument, options);
      }
export function useGetKitchenPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKitchenPermissionsQuery, GetKitchenPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKitchenPermissionsQuery, GetKitchenPermissionsQueryVariables>(GetKitchenPermissionsDocument, options);
        }
export type GetKitchenPermissionsQueryHookResult = ReturnType<typeof useGetKitchenPermissionsQuery>;
export type GetKitchenPermissionsLazyQueryHookResult = ReturnType<typeof useGetKitchenPermissionsLazyQuery>;
export type GetKitchenPermissionsQueryResult = Apollo.QueryResult<GetKitchenPermissionsQuery, GetKitchenPermissionsQueryVariables>;
export const RequestKitchenPermissionsDocument = gql`
    mutation requestKitchenPermissions($kitchenId: Int!) {
  requestKitchenPermissions(kitchenId: $kitchenId, stock: true) {
    _cursor
    id
    permissions {
      foodFlash
      ordering
      stock
      requests
    }
  }
}
    `;
export type RequestKitchenPermissionsMutationFn = Apollo.MutationFunction<RequestKitchenPermissionsMutation, RequestKitchenPermissionsMutationVariables>;

/**
 * __useRequestKitchenPermissionsMutation__
 *
 * To run a mutation, you first call `useRequestKitchenPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestKitchenPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestKitchenPermissionsMutation, { data, loading, error }] = useRequestKitchenPermissionsMutation({
 *   variables: {
 *      kitchenId: // value for 'kitchenId'
 *   },
 * });
 */
export function useRequestKitchenPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<RequestKitchenPermissionsMutation, RequestKitchenPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestKitchenPermissionsMutation, RequestKitchenPermissionsMutationVariables>(RequestKitchenPermissionsDocument, options);
      }
export type RequestKitchenPermissionsMutationHookResult = ReturnType<typeof useRequestKitchenPermissionsMutation>;
export type RequestKitchenPermissionsMutationResult = Apollo.MutationResult<RequestKitchenPermissionsMutation>;
export type RequestKitchenPermissionsMutationOptions = Apollo.BaseMutationOptions<RequestKitchenPermissionsMutation, RequestKitchenPermissionsMutationVariables>;
export const DeleteOneStockDocument = gql`
    mutation deleteOneStock($data: DeleteOneStockInputObject!) {
  deleteOneStock(data: $data) {
    _cursor
    id
  }
}
    `;
export type DeleteOneStockMutationFn = Apollo.MutationFunction<DeleteOneStockMutation, DeleteOneStockMutationVariables>;

/**
 * __useDeleteOneStockMutation__
 *
 * To run a mutation, you first call `useDeleteOneStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneStockMutation, { data, loading, error }] = useDeleteOneStockMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteOneStockMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneStockMutation, DeleteOneStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneStockMutation, DeleteOneStockMutationVariables>(DeleteOneStockDocument, options);
      }
export type DeleteOneStockMutationHookResult = ReturnType<typeof useDeleteOneStockMutation>;
export type DeleteOneStockMutationResult = Apollo.MutationResult<DeleteOneStockMutation>;
export type DeleteOneStockMutationOptions = Apollo.BaseMutationOptions<DeleteOneStockMutation, DeleteOneStockMutationVariables>;
export const CreateOneStockDocument = gql`
    mutation createOneStock($data: CreateOneStockInputObject!) {
  createOneStock(data: $data) {
    ...StockFragment
  }
}
    ${StockFragmentFragmentDoc}`;
export type CreateOneStockMutationFn = Apollo.MutationFunction<CreateOneStockMutation, CreateOneStockMutationVariables>;

/**
 * __useCreateOneStockMutation__
 *
 * To run a mutation, you first call `useCreateOneStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneStockMutation, { data, loading, error }] = useCreateOneStockMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneStockMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneStockMutation, CreateOneStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneStockMutation, CreateOneStockMutationVariables>(CreateOneStockDocument, options);
      }
export type CreateOneStockMutationHookResult = ReturnType<typeof useCreateOneStockMutation>;
export type CreateOneStockMutationResult = Apollo.MutationResult<CreateOneStockMutation>;
export type CreateOneStockMutationOptions = Apollo.BaseMutationOptions<CreateOneStockMutation, CreateOneStockMutationVariables>;
export const GetFullKitchenDocument = gql`
    query getFullKitchen($kitchenId: Int!, $time: DateTime!) {
  ingredientList(
    take: 9999
    kitchenId: $kitchenId
    where: {kitchenId: {equals: $kitchenId}, updatedAt: {gte: $time}}
  ) {
    nodes {
      ...BasicIngredientFragment
    }
  }
  recipeList(take: 9999, where: {kitchenId: {equals: $kitchenId}}) {
    nodes {
      ...BasicRecipeFragment
    }
  }
}
    ${BasicIngredientFragmentFragmentDoc}
${BasicRecipeFragmentFragmentDoc}`;

/**
 * __useGetFullKitchenQuery__
 *
 * To run a query within a React component, call `useGetFullKitchenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullKitchenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullKitchenQuery({
 *   variables: {
 *      kitchenId: // value for 'kitchenId'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useGetFullKitchenQuery(baseOptions: Apollo.QueryHookOptions<GetFullKitchenQuery, GetFullKitchenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFullKitchenQuery, GetFullKitchenQueryVariables>(GetFullKitchenDocument, options);
      }
export function useGetFullKitchenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFullKitchenQuery, GetFullKitchenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFullKitchenQuery, GetFullKitchenQueryVariables>(GetFullKitchenDocument, options);
        }
export type GetFullKitchenQueryHookResult = ReturnType<typeof useGetFullKitchenQuery>;
export type GetFullKitchenLazyQueryHookResult = ReturnType<typeof useGetFullKitchenLazyQuery>;
export type GetFullKitchenQueryResult = Apollo.QueryResult<GetFullKitchenQuery, GetFullKitchenQueryVariables>;
export const GetStockNodeDocument = gql`
    query getStockNode($cursor: ID!) {
  stockNode(cursor: $cursor) {
    _cursor
    id
    entryList(take: 9999) {
      nodes {
        ...StockEntryFragment
      }
    }
  }
}
    ${StockEntryFragmentFragmentDoc}`;

/**
 * __useGetStockNodeQuery__
 *
 * To run a query within a React component, call `useGetStockNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockNodeQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetStockNodeQuery(baseOptions: Apollo.QueryHookOptions<GetStockNodeQuery, GetStockNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStockNodeQuery, GetStockNodeQueryVariables>(GetStockNodeDocument, options);
      }
export function useGetStockNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStockNodeQuery, GetStockNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStockNodeQuery, GetStockNodeQueryVariables>(GetStockNodeDocument, options);
        }
export type GetStockNodeQueryHookResult = ReturnType<typeof useGetStockNodeQuery>;
export type GetStockNodeLazyQueryHookResult = ReturnType<typeof useGetStockNodeLazyQuery>;
export type GetStockNodeQueryResult = Apollo.QueryResult<GetStockNodeQuery, GetStockNodeQueryVariables>;